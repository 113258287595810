import React from 'react';

// Libraries
import clsx from 'clsx';

// Styles
import { useStyles } from './style';

interface IProps {
    title: string;
}

const SystemStartupOptions: React.FC<IProps> = ({ title, children }) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.header, 'flexStartSpaceBetweenRow')}>
      <h3 className={classes.title}>
        {title}
      </h3>
      <div>
        {children}
        <span className={classes.spacer} />
      </div>
    </div>
  );
};

export default SystemStartupOptions;
