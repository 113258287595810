import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {},
  itemWrapper: {
    border: '1px solid #dbdbdb',
    boxShadow: '0 0 10px rgb(0 0 0 / 14%)',
    overflow: 'hidden',
    padding: '0 !important',
    marginTop: '5px',
  },
  itemHeading: {
    backgroundColor: '#fff',
    borderBottom: '1px solid #d0d0d0',
    color: '#393939',
    display: 'block',
    padding: '15px 10px',
    fontSize: 20,
  },
  container: {
    padding: '0px 5px',
    paddingBottom: '5px !important',
  },
});
