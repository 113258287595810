/* eslint-disable camelcase */
import axios from 'axios';
import { format } from 'date-fns';
import { concatenateFields, deduplicateFields } from '../../utils';
import {
  GET_ALL_EMBBEDED_COLUMNS,
  LOAD_EMBEDDED_RECORD,
  LOAD_EMBEDDED_SCREEN,
  SWITCH_EMBEDDED_NAVIGATION,
  BATCH_MODE_COLUMS_EMBEDDED,
  SET_RECORD_LIST_EMBEDDED,
  DELETE_RECORD_EMBEDDED,
  UPDATE_RECORD,
  SAVE_BATCH_MODE_QUERY_EMBEDDED,
  OPEN_COLUMN_MODAL_EMBEDDED,
  CLOSE_COLUMN_MODAL_EMBEDDED,
  OPEN_FILTER_MODAL_EMBEDDED,
  CLOSE_FILTER_MODAL_EMBEDDED,
  START_LOADING_EMBEDDED,
  STOP_LOADING_EMBEDDED,
  START_LOADING_EXPORT_EMBEDDED,
  STOP_LOADING_EXPORT_EMBEDDED,
  SAVE_FILTER_EMBEDDED,
  DELETE_FILTER_EMBEDDED,
  LOAD_TABS,
  START_LOADING,
  LOAD_EMBEDDED_TABS,
  UPDATE_EMBEDDED_RECORD,
  DISSMISS_EMBEDDED_CONFIRMATION_ALERT,
} from '../constants/Screens';

type screenOption = {
  tableID: any;
  page?: any;
  results_per_page?: string;
  fields?: string;
  fieldsConcatenated?: string;
  where?: string;
  order_by?: string;
  screenID?: string;
  lang?: string;
  Selectedcolumns?: any;
  navigate?: any;
  redirect?: any;
  EmptyRows?: any;
  whereRecord?: any;
  screenId?: any;
  keys?: any;
  search?: string;
  dependentKeyEmbedded?: any;
  data?: any;
};
export const LoadEmbbededTables = (options: any) => async (dispatch: any) => {
  console.log('options', options);
  let newContractItemNumber:any;
  try {
    const fieldsConcatenated = concatenateFields(
      options.fields?.split(',') || [],
      options.fieldsConcatenated || '',
    );

    const fields = await Array.from(
      new Set([...options.fieldsConcatenated?.split(','), ...options.keys.split(',')]),
    ).join(',');

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}tablescreen/tablemaintdata/?tableID=${
        options.tableID
      }
        &page=${options.page}&results_per_page=${options.results_per_page}
      &fields=${fields}&where=${options.where}&search=${
  options.search ? options.search : ''
}&order_by=${options.order_by}&lang=${options.lang}`,
      {
        headers: {
          accept: '*/*',
        },
      },
    );
    // get the last contractItem number and add 1 for the new contractItem number
    if (['ContractItem', 'Contract']?.includes(options.tableID)) {
      const ContractItemResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}tablescreen/tablemaintdata/?tableID=${
          options.tableID
        }
          &page=1&results_per_page=${response.data.totalItems}
        &fields=${fields}&where=${options.where}&search=${
  options.search ? options.search : ''
}&order_by=${options.order_by}&lang=${options.lang}`,
        {
          headers: {
            accept: '*/*',
          },
        },
      );
      newContractItemNumber = `${Number(ContractItemResponse.data.data?.[ContractItemResponse.data.data.length - 1]?.ContractItemNumber?.value) + 1}` || '1';
    }
    dispatch({
      type: LOAD_EMBEDDED_SCREEN,
      payload: response.data,
      screen: options,
      newContractItemNumber: newContractItemNumber ? newContractItemNumber.padStart(20, '0') : '',
    });
  } catch (error: any) {
    console.log(error.data);
  }
};
export const LoadEmbeddedTabs = (screenID: string) => async (dispatch: any) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}configscreentable`,
      {
        screenID,
      },
      {
        headers: {
          accept: '*/*',
          'Content-Type': 'application/json',
        },
      },
    );
    const embbededActiveTabs = await Object.entries(
      response.data.config.integratedScreenIDList,
    ).reduce(
      (h: any, tabs: any) => Object.assign(h, { [tabs[0]]: [tabs[1], { embbeded: true }] || [] }),
      {},
    );
    dispatch({ type: LOAD_EMBEDDED_TABS, payload: embbededActiveTabs, embeddedTabsLabels: response.data.config?.integratedScreenNameList });
  } catch (error: any) {
    console.log('LoadTabs error', error.data);
  }
};
export const LoadRecordEmbbeded = (options: any) => async (dispatch: any) => {
  console.log('LoadRecord Options', options);
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}tablescreen/showrecord?tableID=${options.tableID}
      &screenID=${options.screenID}&where=${options.where}&lang=${options.lang}`,
      {
        headers: {
          accept: '*/*',
        },
      },
    );

    const res = await response.data.data.map((item: any) => (
      {
        [item.id || item]: {
          default: item.default,
          id: item.id,
          label: item.label,
          helpertext: item.helpertext,
          type: item.type,
          obj: item.obj,
          query: item.query,
          selectList: item.selectList,
          chain: item.chain,
          valForm: item.value,
          attr: item.attr,
          mask: item.mask,
          required: item.attr?.includes('required'),
          gridId: item.gridId,
          validate: item.validate,
          visible: item.visible || 'true',
          tab: item.tab,
          childsDependency: item?.childsDependency,
        },
      }
    ));
    // add customCondition attribute for the detail view on condition screen
    const doc2conditon = await [...res, {
      CustomCondition: {
        default: '',
        id: 'CustomCondition',
        label: 'CustomCondition',
        helpertext: 'CustomCondition',
        type: 'aftx',
        tab: 'Detail',
        obj: {
          '\u0000Select\u0000selectList': [],
          '\u0000*\u0000parm': {
            label: 'CustomCondition',
            enabled: true,
            display: true,
            iconBackground: false,
            multiple: false,
            tagmultiple: false,
            subtext: false,
            bootstrapcol: true,
            icon: '',
            class: '',
            fieldbar: true,
            forcefieldbar: false,
            showundefinedtext: true,
            dropdownheight: 10,
            nothingselected: null,
            value: '',
            livesearch: true,
            options: [
              {
                label: 'Yes',
                value: true,
                class: '',
                selected: false,
              },
              {
                label: 'No',
                value: false,
                class: '',
                selected: false,
              },
            ],
            'data-validate': '',
          },
          '\u0000*\u0000detect': null,
          '\u0000*\u0000PrefixID': '',
          '\u0000*\u0000nameID': 'CustomCondition',
          '\u0000*\u0000scrollOpts': [],
          prefixID: 'SL',
        },
        chain: [],
        valForm: '',
        attr: [],
        mask: '',
        required: false,
        gridId: null,
        validate: '',
        visible: true,
      },
    }];
    const result = await doc2conditon.reduce((acc: any, item: any) => ({ ...acc, ...item }), {});
    // add the customCondition to the tabs structure
    if (options.tableID === 'Doc2Condition') {
      response.data.fieldTabs.Detail.unshift('CustomCondition');
    }
    const resultAll = await Object.keys(response.data.fieldTabs).reduce(
      (acc: any, tab: any) => ({
        ...acc,
        [tab]: response.data.fieldTabs[tab].map((field: any) => (!field.startsWith('#') ? result[field] : { type: 'grid', id: field })),
      }),
      {},
    );
    // const resultAll = await response.data.data.reduce(
    //   (h: any, tabs: any) => Object.assign(h, {
    //     [tabs.tab]: (h[tabs.tab] || []).concat({
    //       default: tabs.default,
    //       id: tabs.id,
    //       label: tabs.label,
    //       helpertext: tabs.helpertext,
    //       type: tabs.type,
    //       obj: tabs.obj,
    //       query: tabs.query,
    //       selectList: tabs.selectList,
    //       chain: tabs.chain,
    //       valForm: tabs.value,
    //       attr: tabs.attr,
    //       mask: tabs.mask,
    //       required: tabs.attr.includes('required'),
    //       gridId: tabs.gridId,
    //       validate: tabs.validate,
    //       visible: tabs.visible,
    //     }),
    //   }),
    //   {},
    // );
    const values = await response.data.data.reduce(
      (h: any, tabs: any) => Object.assign(h, {
        [tabs.id]: tabs.value || '',
      }),
      {},
    );
    // for Doc2Condition Exention custom condition field defaultvalue should be false
    if (options.tableID === 'Doc2Condition') {
      if (values.FieldName !== '') {
        values.CustomCondition = false;
      } else {
        values.CustomCondition = true;
      }
    }
    const allTypes = await new Set(
      Object.values(
        response.data.data.reduce(
          (h: any, tabs: any) => Object.assign(h, {
            [tabs.id]: tabs.type,
          }),
          {},
        ),
      ),
    );

    const activelabels = await response.data.data.reduce(
      (h: any, tabs: any) => Object.assign(h, {
        [tabs.id]: tabs.label,
      }),
      {},
    );

    const fields = options?.fields
      ? options.fields.split(',')
      : Object.keys(activelabels);
    const activeLabelsTableView = fields.map(
      (value: any) => activelabels[value] || value,
    );
    const mappedData: any = await Object.values(resultAll);
    const Ids = Object.entries(mappedData)
      .map((elt: any) => elt?.[1]?.map((element: any) => [element?.id, element?.valForm]))
      .flat();
    // const FormatedLabels = await Object.entries(activelabels)
    //   .map((elt: any) => elt[1].map((element: any) => [element.id, element.valForm]))
    //   .flat();
    // const arrayToObject = Ids.reduce((o, k) => ({ ...o, [k]: '' }), {});
    // const Ids:any = mappedData.map((v: any)=>v.map((element: any) => [element.id, element.valForm]));
    // const mapData = new Map(Ids);
    const arrayToObject = Object.fromEntries(Ids);
    const { grid } = response.data;
    Object.keys(grid).forEach((key: any) => {
      grid[key].fieldlist = grid[key].fieldlist.map((field:any) => field.map((item:any) => result[item]));
    });

    dispatch({
      type: LOAD_EMBEDDED_RECORD,
      payload: resultAll,
      cloneForm: arrayToObject,
      parsedValues: values,
      labels: activeLabelsTableView,
      allTypes,
      allLabels: activelabels,
      whereDetailCondition: options.where,
      grid: Object.entries(grid),
      whereModify: options?.where,
      unitCalculator: response.data?.unitCalculator,
    });
  } catch (error: any) {
    console.log('error Load Record Action:', error);
  }
};
export const LoadAllColumnsEmbbededTables = (options: screenOption) => async (dispatch: any) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}tablescreen/?tableID=${options.tableID}
        &page=${options.page}&results_per_page=${options.results_per_page}`,
      {
        headers: {
          accept: '*/*',
        },
      },
    );
    const allTableColumns = Object.keys(response.data.data[0]);
    // const AllfiltredColumns = allTableColumns.filter((item:any) => !options.Selectedcolumns.includes(item));
    dispatch({ type: GET_ALL_EMBBEDED_COLUMNS, payload: allTableColumns });
  } catch (error: any) {
    console.log(error.data);
  }
};
export const SwitchEmbeddedNavigation = (options: string) => async (dispatch: any) => {
  try {
    dispatch({ type: SWITCH_EMBEDDED_NAVIGATION, payload: options });
  } catch (error: any) {
    console.log(error.data);
  }
};

export const UpdateRecordEmbedded = (query: any) => async (dispatch: any) => {
  try {
    const formDataEntries = Object.fromEntries(
      Object.entries(query.form_data),
    );
    console.log('compulsory_data', query);
    const compData = await [query.compulsory_data];

    // Related to PR for Bug#10078: Removed the filler prop since it isn't needed/non-existent in the update request
    // compData.map((v: any) => ({ ...v, filler: '00' }));

    // console.log('my formDataEntries old', formDataEntries);

    const data = await compData?.map((element: any) => {
      const newFormData = deduplicateFields(element, formDataEntries);

      const filteredElements = Object.entries(newFormData).map((entry: any) => {
        if (entry[1] === 'null') {
          return [entry[0], null];
        }
        return entry;
      }).reduce((acc: any, curr: any) => ({ ...acc, [curr[0]]: curr[1] }), {});
      // clean object to send for Doc2Condition extention
      if (query.screenId === 'MAINT-084') {
        delete filteredElements.CustomCondition;
      }
      // add modified date to form Data
      if (query.table === 'Contract') {
        newFormData.DateLstMod = `${format(new Date(), 'yyyy/MM/dd kk:mm:ss')}`;
        // get user who did the update for now i only put TMS but it will be replaced once we finish the users workflows
        newFormData.UserID = 'tms';
      }

      return {
        compulsory_data: JSON.parse(query.compulsory_data),
        form_data: filteredElements,
      };
    });
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}batch/modifyformdata`,
      {
        table: query.table,
        data,
      },
      {
        headers: {
          accept: '*/*',
          'Content-Type': 'application/json',
        },
      },
    );
    if (response.status !== 407) {
      dispatch({
        type: UPDATE_EMBEDDED_RECORD,
        payload: response.data,
        message: `${
          data.length > 1
            ? `(${data.length}) `
            : ''
        }Record modified successfully Review`,
      });
    } else {
      dispatch({
        type: UPDATE_EMBEDDED_RECORD,
        message: 'failed to modify record:Error Processing Request',
      });
    }
    dispatch({ type: SWITCH_EMBEDDED_NAVIGATION, payload: 'tableScreen' });
  } catch (error: any) {
    console.error(error);
  }
};
export const dissmissEmbeddeddConfirmationAlert = () => async (dispatch: any) => {
  try {
    dispatch({ type: DISSMISS_EMBEDDED_CONFIRMATION_ALERT, payload: false });
  } catch (error: any) {
    console.log(error.data);
  }
};

export const SetBatchModeEmbedded = ({
  tableOpt, selected, deleteQuery, backScreen, selectedBatch,
}: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: BATCH_MODE_COLUMS_EMBEDDED,
      payload: {
        tableOpt,
        selected,
        backScreen,
        deleteQuery,
        selectedBatch,
      },
    });
  } catch (error: any) {
    console.log(error.data);
  }
};

export const SetRecordsListEmbedded = (list: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: SET_RECORD_LIST_EMBEDDED,
      payload: list,
    });
  } catch (error: any) {
    console.log(error.data);
  }
};

export const DeleteRecordEmbedded = ({ options, activeScreen }: any) => async (dispatch: any) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_URL}tablescreen/deleterec`,
      {
        params: {
          tableID: options.tableID,
          where: JSON.stringify([JSON.parse(options.where)]),
        },
        headers: {
          accept: '*/*',
        },
      },
    );
    dispatch(
      LoadEmbbededTables({
        tableID: activeScreen.tableID,
        page: '1',
        results_per_page: '10',
        fields: activeScreen.fields,
        order_by: '',
        where: '',
        EmptyRows: activeScreen.EmptyRows,
        redirect: activeScreen.redirect,
        keys: activeScreen.keys,
        screenId: activeScreen.screenId,
      }),
    );
    dispatch({ type: DELETE_RECORD_EMBEDDED, payload: response.data });
  } catch (error: any) {
    console.log(error.data);
  }
};
export const BatchDeleteRecordEmbedded = ({
  options, navigate, tableId, activeScreen,
}: any) => async (dispatch: any) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_URL}tablescreen/deleterec`,
      {
        params: {
          tableID: tableId,
          where: JSON.stringify(options),
        },
        headers: {
          accept: '*/*',
        },
      },
    );
    dispatch({ type: DELETE_RECORD_EMBEDDED, payload: response.data });
    dispatch(
      LoadEmbbededTables({
        tableID: activeScreen.tableID,
        page: '1',
        results_per_page: '10',
        fields: activeScreen.fields,
        order_by: '',
        where: '',
        EmptyRows: activeScreen.EmptyRows,
        redirect: activeScreen.redirect,
        keys: activeScreen.keys,
        screenId: activeScreen.screenId,
      }),
    );
    // navigate(activeScreen.redirect);
  } catch (error: any) {
    console.log(error.data);
  }
};

export const CloneRecordSubmitEmbedded = (query: any) => async (dispatch: any) => {
  const formDataEntries = Object.fromEntries(
    Object.entries(query.form_data).filter(([_, v]) => v !== null),
  );
  if (query.table === 'ContractItem') {
    formDataEntries.DateLstMod = format(new Date(), 'yyyy/MM/dd kk:mm:ss');
    // get user who created or updated for now i only put TMS but it will be replaced once we finish the users workflows
    formDataEntries.UserID = 'tms';
  }
  formDataEntries.last_upd_usr = 'chaione';
  fetch(`${process.env.REACT_APP_API_URL}formscreen/storeformdata`, {
    method: 'post',
    headers: { 'Content-Type': 'application/json', accept: '*/*' },
    body: JSON.stringify({
      table: query.table,
      form_data: formDataEntries,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status < 230) {
        dispatch(SwitchEmbeddedNavigation('tableScreen'));
        dispatch({
          type: UPDATE_RECORD,
          payload: res,
          message: 'Record created/cloned successfully',
        });
      } else {
        dispatch({
          type: UPDATE_RECORD,
          payload: res,
          message: res.message || 'An error has occured!',
        });
      }
    });
};

export const saveFieldBatchModeEmbedded = ({ valuesToSend, values }: any) => async (dispatch: any) => {
  try {
    // const batchFormat=query.map((val:any,key:any)=>{
    //   return(
    //     [{Key:val.}]
    //   )
    // })
    console.log('query', values);
    const batchFormat = Object.keys(valuesToSend);
    const batchFormatVal = Object.values(valuesToSend);
    const batchFormatLabels: any[] = Object.values(values);
    const armixed = batchFormat.map((x, i) => ({
      key: x,
      value: batchFormatVal[i],
    }));
    const armixedLables = batchFormat.map((x, i) => ({
      key: x,
      value: batchFormatLabels[i]?.label || batchFormatLabels[i],
    }));
    dispatch({
      type: SAVE_BATCH_MODE_QUERY_EMBEDDED,
      payload: { armixed, query: valuesToSend, values: armixedLables },
    });
  } catch (error: any) {
    console.log(error.data);
  }
};

export const openColumnModalEmbedded = () => async (dispatch: any) => {
  try {
    dispatch({ type: OPEN_COLUMN_MODAL_EMBEDDED, payload: true });
  } catch (error: any) {
    console.log(error.data);
  }
};
export const CloseColumnModalEmbedded = () => async (dispatch: any) => {
  try {
    dispatch({ type: CLOSE_COLUMN_MODAL_EMBEDDED, payload: false });
  } catch (error: any) {
    console.log(error.data);
  }
};
export const openFilterModalEmbedded = () => async (dispatch: any) => {
  try {
    dispatch({ type: OPEN_FILTER_MODAL_EMBEDDED, payload: true });
  } catch (error: any) {
    console.log(error.data);
  }
};
export const CloseFilterModalEmbedded = () => async (dispatch: any) => {
  try {
    dispatch({ type: CLOSE_FILTER_MODAL_EMBEDDED, payload: false });
  } catch (error: any) {
    console.log(error.data);
  }
};
export const StartLoadingEmbedded = () => async (dispatch: any) => {
  dispatch({ type: START_LOADING_EMBEDDED });
};
export const StopLoading = () => async (dispatch: any) => {
  dispatch({ type: STOP_LOADING_EMBEDDED });
};
export const StartExporting = () => async (dispatch: any) => {
  dispatch({ type: START_LOADING_EXPORT_EMBEDDED });
};
export const StopExporting = () => async (dispatch: any) => {
  dispatch({ type: STOP_LOADING_EXPORT_EMBEDDED });
};
export const SaveFilterEmbedded = (screenId: string, filter: any) => async (dispatch: any) => {
  try {
    dispatch({ type: SAVE_FILTER_EMBEDDED, payload: filter, screenId });
  } catch (error: any) {
    console.log(error.data);
  }
};

export const DeleteFilterEmbedded = (screenId: string) => async (dispatch: any) => {
  try {
    dispatch({ type: DELETE_FILTER_EMBEDDED, payload: screenId });
  } catch (error: any) {
    console.log(error.data);
  }
};
