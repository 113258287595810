/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-undef */
import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ArrowUpward } from '@mui/icons-material';
import RackStatusTable from '../../../components/rackStatusTable/rackStatusTable';
import { RcuCard } from '../../../components/RcuCard/RcuCard.component';
import rackImage from '../../../assets/terminalRackStatus.png';
import RakStautsPresetsCard from '../../../components/rackStatusPresetsCard/RackStatusPresetsCard';
import TmsHeader from '../../../components/header';
import { getScreensList } from '../../../redux/actions/ScreenActions';
import { KeyboardComponent } from '../../../components/KeyboardComponent/Keyboard';

const RackStatusDetail = () => {
  const [Values, setValues] = useState([1, 2, 3, 4]);
  const [showingKeyboardIndex, setShowingKeyboardIndex] = useState<
    number | null
  >(null);
  const dispatch = useDispatch();
  const history = useNavigate();
  useEffect(() => {
    dispatch(getScreensList());
  }, []);
  const handleOpenKeyboard = (index: number) => {
    if (String(showingKeyboardIndex)) {
      if (showingKeyboardIndex === index) {
        setShowingKeyboardIndex(null);
      } else {
        setShowingKeyboardIndex(index);
      }
    } else {
      setShowingKeyboardIndex(index);
    }
  };

  return (
    <>
      <TmsHeader />
      <div className="sub-nav-container">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h3 id="pagetitlebar_title" className="page-title">
            Rack Status: BAY01 - BAY 1
          </h3>
        </div>
      </div>
      <div style={{
        width: '100%', height: '50px', backgroundColor: '#95e695', display: 'flex', justifyContent: 'center', alignItems: 'center',
      }}
      >
        <div
          onClick={() => history('/workflow/rack-status')}
          style={{
            cursor: 'pointer',
            position: 'absolute',
            left: '20px',
          }}
        >
          <ArrowUpward />
        </div>
        <Typography variant="h4">IDLE</Typography>
      </div>
      <div>
        <RackStatusTable
          pages={1}
          headCells={[
            'Driver',
            'Carrier',
            'Supplier',
            'Customer',
            'Account',
            'Destination',
            'Order',
            'Loading',
          ]}
          rows={[{
            Driver: '-',
            Carrier: '-',
            Supplier: '-',
            Customer: '-',
            Account: '-',
            Destination: '-',
            Order: '-',
            Loading: '-',
          }]}
          tableOpt={{
            keys: 'Driver, Carrier, Supplier, Customer, Account, Destination, Order, Loading ',
            fields: 'Driver, Carrier, Supplier, Customer, Account, Destination, Order, Loading ',
            ScreenID: 'Workflow-088',
            tableId: 'allocations',
            search: '',
          }}
          routeDetails="/maint-details"
          screenID="Workflow-088"
          backScreen="/workflow/allocations"
          BatchModeScreen="/batch-mode"
        />
        <div style={{
          width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '20%',
        }}
        >
          {[1].map((x, i) => (
            <div style={{ position: 'relative' }}>
              <RcuCard
                bodyContent={null}
                titleContent={null}
                onClick={() => handleOpenKeyboard(i)}
              />
              {showingKeyboardIndex === i ? <KeyboardComponent /> : null}
            </div>
          ))}
          <img src={rackImage} style={{ width: '60%', height: '40vh' }} />
        </div>
      </div>
      <div style={{
        width: '98%', height: '70vh', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexWrap: 'wrap', gap: '5px', padding: '30px 50px', marginBottom: '30px',
      }}
      >
        {
        Values.map((item:any) => (
          <RakStautsPresetsCard />
        ))
      }
      </div>
    </>
  );
};

export default RackStatusDetail;
