/* eslint-disable @typescript-eslint/no-shadow */
import React from 'react';

// Libraries
import {
  ButtonBase,
  DialogActions,
  DialogContent,
  Divider,
} from '@mui/material';

// Components
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { DialogComponent } from '../Dialog/Dialog.component';

// Styles
import { useStyles } from './style';

interface IProps {
  onClose: () => void;
  open: boolean;
}

const priotyList: string[] = [
  'Number of Loading Product and Total Products at Bay',
  'Bay Accumulated Load Time and Total Batch Count',
  'Minimum Wait Time',
  'Minimum Wait Time Including Staging Driver',
];

const TrafficPriorityDialog: React.FC<IProps> = ({ onClose, open }) => {
  const classes = useStyles();

  const handleDrageElements = () => null;

  return (
    <DialogComponent
      title="Traffic Priority"
      onClose={onClose}
      open={open}
      className={classes.root}
    >
      <DialogContent style={{ marginTop: 10 }}>
        <DragDropContext onDragEnd={handleDrageElements}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {priotyList.map((item: any, index) => (
                  <Draggable key={item} draggableId={item} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <div className={classes.itemWrapper}>
                          <div className={classes.item}>{item}</div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </DialogContent>
      <Divider variant="middle" />
      <DialogActions>
        <span className={classes.note}>Note: Drag to change traffic priority</span>
        <span className="takeTheRest" />
        <ButtonBase classes={{ root: classes.confirmButton }} onClick={onClose}>
          Apply Priority
        </ButtonBase>
        <ButtonBase classes={{ root: classes.cancelButton }} onClick={onClose}>
          Cancel
        </ButtonBase>
      </DialogActions>
    </DialogComponent>
  );
};

export default TrafficPriorityDialog;
