/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import * as React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  TextField,
  Typography,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';

const FolioCustomDateTime = (props: any) => {
  const {
    size,
    label,
    type,
    name,
    value,
    onChange,
    defaultValue,
    isDisabled,
    key,
  } = props;
  const [datePickerOpen, setDatePickerOpen] = React.useState(false);
  const [dateAnchorEl, setDateAnchorEl] = React.useState<null | HTMLElement>(
    null,
  );
  const handleDateInputClick = (event: React.MouseEvent<HTMLInputElement>) => {
    setDateAnchorEl(event.currentTarget);
    isDisabled
      ? setDatePickerOpen(false)
      : setDatePickerOpen(true);
  };
  return (
    <div
      style={
        false || {
          width: '100%',
          alignSelf: 'center',
          marginTop: '5px',
          display: 'flex',
          alignItems: 'center',
          gap: 10,
          justifyContent: 'flex-end',
        }
      }
    >
      <div
        style={{
          flex: 1,
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'right',
        }}
      >
        {true && (
          <Typography
            className="f12"
            style={{ flex: 1, textAlign: 'right' }}
          >
            {label}
          </Typography>
        )}
      </div>
      <div
        style={{
          flexWrap: 'nowrap',
          display: 'flex',
          flex: 3,
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            PopperProps={{ anchorEl: dateAnchorEl }}
            onOpen={() => {
              isDisabled ? setDatePickerOpen(false) : setDatePickerOpen(true);
            }}
            onClose={() => setDatePickerOpen(false)}
            inputFormat="MM/dd"
            value={new Date(value)}
            readOnly={isDisabled}
            open={datePickerOpen}
            onChange={(newValue: any) => {
              const options = {
                month: 'long',
                day: '2-digit',
              };
              const newValueFormatted = newValue?.toLocaleDateString(
                'en-US',
                options,
              );
              onChange(name, newValueFormatted.replace(' ', '-'));
            }}
            renderInput={() => (
              <TextField
                onClick={handleDateInputClick}
                value={value}
                onChange={(newValue: any) => {
                  onChange(name, newValue.target.value);
                }}
                placeholder={label}
                sx={{
                  '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
                    padding: '4px 4px',
                    border: '1px solid #E4E4E4',
                    fontSize: '12px',
                  },
                  width: '98.5%',
                  borderLeft: '5px solid #555',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '0px !important',
                  },
                  borderRadius: '0px !important',
                  '& input[type="date"]::-webkit-calendar-picker-indicator': {
                    display: 'inlineBlock',
                    background: 'transparent',
                    '& .MuiInputBase-root': {
                      borderRadius: '0px !important',
                      '& > fieldset': {
                        borderColor: 'transparent',
                      },
                    },
                    bottom: 0,
                    color: 'transparent',
                    cursor: 'pointer',
                    height: 'auto',
                    left: 0,
                    position: 'absolute',
                    borderLeft: '5px solid #555',
                    right: 0,
                    top: 0,
                    width: size,
                  },
                  '& input[type="date"]:disabled': {
                    borderLeft: '5px solid #555',
                  },
                }}
              />
            )}
          />
        </LocalizationProvider>
      </div>
    </div>
  );
};
export default FolioCustomDateTime;
