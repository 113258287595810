import React, { useState } from 'react';

// Components
import TmsHeader from '../../../components/header';
import SubHeader from '../../../components/subHeader';
import DeviceCommunicationsOptions from '../../../components/DeviceCommunicationsOptions';
import DeviceCommunicationsDialog from '../../../components/deviceCommunicationsDialog';

// Styles
import { useStyles } from './Style';
import OptionsButton from '../../../components/optionsButton';
import DeviceCommunicationsTable from '../../../components/deviceCommunicationsTable';

const SystemStartupOptions: React.FC = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const handleOptionClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenDialog = () => {
    setDialogOpen(true);
    handleCloseMenu();
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  return (
    <div className={classes.root}>
      <TmsHeader />
      <SubHeader title="Device Communications">
        <OptionsButton handleClick={handleOptionClick} />
        <DeviceCommunicationsOptions
          openDialog={handleOpenDialog}
          anchorEl={anchorEl}
          onClose={handleCloseMenu}
        />
      </SubHeader>
      <DeviceCommunicationsTable handleClickRow={handleOpenDialog} />
      <DeviceCommunicationsDialog open={dialogOpen} onClose={handleCloseDialog} />
    </div>
  );
};

export default SystemStartupOptions;
