/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, SelectChangeEvent } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { purple, blue } from '@mui/material/colors';
import ControlButton from '../controlButton';
import CustomDropdown from '../customDropdown';
import CustomInput from '../customInput';
import './style.css';
import ConfirmationModal from '../confirmationModal';
import ConfirmationModalEmbedded from '../confirmationModal/ConfirmationModalEmbedded';
import ConfirmationModalLoseChanges from '../confirmationModal/ConfirmationModalLoseChanges';
import ConfirmationNextPrevious from '../confirmationModal/ConfirmationNextPrevious';
import CustomField from '../CustomField/CustomField';
import useWindowDimensions from '../../utils/windowDimensions';
import { SwitchEmbeddedNavigation } from '../../redux/actions/embbededScreensActions';

const theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: purple[500],
    },
    secondary: {
      // This is green.A700 as hex.
      main: blue[500],
    },
  },
});

const FormHeaderTerminal = (props: any) => {
  const {
    dataHeader,
    routeBack,
    Visible,
    VisibleConfirm,
    VisibleNextPrevious,
    NumberRangeCheck,
    hasScreenSpecificUnmetValidations,
    handleCloseConfirm,
    handleLoseConfirm,
    handleMoveNextPrevious,
    options,
    values,
    handleSelectChange,
    handleInputChange,
    handleSave,
    isModifiy,
    setIsModifiy,
    compare,
    previousRecord,
    nextRecord,
    count,
    listLength,
    valuesToSend,
    handleClose,
    totalRecords,
    isEmbedded,
    page,
    tableId,
  } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [clickCancel, setClickCancel] = useState(false);
  const [clickNext, setClickNext] = useState(false);
  const [clickPrevious, setClickPrevious] = useState(false);
  const { recordDetails, totalItems } = useSelector((state:any) => state?.[isEmbedded ? 'EmbbededScreenReducer' : 'ScreenReducer']);
  const handleCancel = () => {
    // if (handleClose !== undefined) {
    //   handleClose();
    //   return;
    // }

    // eslint-disable-next-line no-unused-expressions
    if (isModifiy) {
      setClickCancel(true);
      handleLoseConfirm();
    } else {
      // eslint-disable-next-line no-unused-expressions
      isEmbedded ? dispatch(SwitchEmbeddedNavigation('tableScreen'))
        : navigate(`${routeBack.routeDetails}`);
    }
  };
  const handleNext = () => {
    if (isModifiy) {
      setClickNext(true);
      handleMoveNextPrevious();
    } else {
      nextRecord();
    }
    setOpen(false);
  };
  const handlePrevious = () => {
    if (isModifiy) {
      setClickPrevious(true);
      handleMoveNextPrevious();
    } else {
      previousRecord();
      setOpen(false);
    }
  };
  const handleDiscard = () => {
    if (clickNext) {
      setClickNext(false);
      setIsModifiy(false);
      handleMoveNextPrevious();
      nextRecord();
    } else if (clickPrevious) {
      setClickPrevious(false);
      setIsModifiy(false);
      handleMoveNextPrevious();
      previousRecord();
    }
  };
  const handleSaveMove = () => {
    handleSave(true);
    if (clickNext) {
      setClickNext(false);
      setIsModifiy(false);
      handleMoveNextPrevious();
      nextRecord();
    } else if (clickPrevious) {
      setClickPrevious(false);
      setIsModifiy(false);
      handleMoveNextPrevious();
      previousRecord();
    }
  };
  const handleCloseModalYes = ():void => {
    if (clickCancel) {
      setClickCancel(false);
      setIsModifiy(false);
      // eslint-disable-next-line no-unused-expressions
      isEmbedded ? dispatch(SwitchEmbeddedNavigation('tableScreen'))
        : navigate(`${routeBack.routeDetails}`);
    }
  };
  const { height, width } = useWindowDimensions();
  const confirmChange = () => {
    handleSave();
  };
  const handleDelete = () => {
    handleCloseConfirm();
  };
  const requiredFields = React.useMemo(() => {
    const fields = Object.keys(recordDetails).reduce((acc:any, tab:any) => {
      if (tab === 'System' || tab === '_') {
        return [...acc];
      }
      const tabRequiredFields = recordDetails?.[tab].reduce((acc2:any, field:any) => {
        if (!field?.required) {
          return [...acc2];
        }
        return [...acc2, field];
      }, []);
      return [...acc, ...tabRequiredFields];
    }, []);
    return fields;
  }, [recordDetails]);

  const validationFields = React.useMemo(() => {
    const fields = Object.keys(recordDetails).reduce((acc:any, tab:any) => {
      if (tab === 'System' || tab === '_') {
        return [...acc];
      }
      const tabValidateFields = recordDetails?.[tab].reduce((acc2:any, field:any) => {
        if (!field?.validate) {
          return [...acc2];
        }
        return [...acc2, field];
      }, []);
      return [...acc, ...tabValidateFields];
    }, []);
    return fields;
  }, [recordDetails]);

  const shouldDisableSaveButton = React.useMemo(() => {
    const requiredIsOkay = requiredFields.every((field:any) => values[field.id] !== undefined && values[field.id] !== '');
    // eslint-disable-next-line
    const validateIsOkay = validationFields.every((field: any) => !isNaN(values[field.id]) && (values[field.id] === '' || /^\d+$/.test(values[field.id])));
    return !(requiredIsOkay && validateIsOkay);
  }, [requiredFields, validationFields, values]);

  return (
    <>
      <Box
        sx={{
          width: '60%',
          padding: '5px',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <div className="headerContainer">
          <div className="controlers">
            <ControlButton
              label="Remove"
              color="red"
              disabled={false}
              handleClick={handleDelete}
            />
            <ControlButton
              label="Save"
              color="#00c851"
              // disabled={!isModifiy || shouldDisableSaveButton}
              // Save button should be always enabled
              // If checking needs disabled then an alert should be shown
              // instead of disabling
              disabled={shouldDisableSaveButton || NumberRangeCheck || hasScreenSpecificUnmetValidations}
              handleClick={confirmChange}
            />
            <ControlButton
              label="Cancel"
              color="#ffbb33"
              disabled={false}
              handleClick={handleCancel}
            />
          </div>
          <div className="records">
            <ThemeProvider theme={theme}>
              <Button
                className="f12"
                variant="contained"
                color="secondary"
                size="small"
                sx={{
                  color: '#fff',
                  width: 'auto',
                  borderRadius: '0px !important',
                  textTransform: 'none',
                }}
                onClick={count === 0 && page === 1 ? () => setOpen(true) : handlePrevious}
              >
                Previous record
              </Button>
              <Button
                className="f12"
                variant="contained"
                size="small"
                sx={{
                  color: '#fff',
                  width: 'auto',
                  borderRadius: '0px !important',
                  textTransform: 'none',
                }}
                onClick={count + 1 === listLength && page === Math.ceil(totalRecords / Math.ceil(height / 65)) ? () => setOpen(true) : handleNext}
              >
                Next record
              </Button>
            </ThemeProvider>
          </div>
        </div>
        {open
        && (
        <div>
          <Stack sx={{ width: '100%' }} spacing={2}>
            <Alert
              severity="warning"
              onClose={() => (setOpen(false))}
            >
              <span style={{ fontWeight: '800', color: '#FEAF20' }}>
                Warning! &nbsp;
              </span>
              At
              {' '}
              {count === 0 && page === 1 ? 'First' : count === 0 && page === 1 ? 'Last' : null}
              {' '}
              Record
            </Alert>
          </Stack>
        </div>
        )}
      </Box>

      <div>
        <div className="fields" style={{ width: '99.5%', paddingBottom: '10px' }}>
          {dataHeader?.map((val: any, key: any) => (
            <CustomField
              val={val}
              values={values}
              valuesToSend={valuesToSend}
              handleInputChange={handleInputChange}
              handleSelectChange={handleSelectChange}
              size="100%"
              headerDisabled={val?.attr?.includes('required')}
              tableId={tableId}
            />
          ))}
        </div>
      </div>
      {!isEmbedded ? (
        <ConfirmationModal
          Visible={Visible}
          handleCloseConfirm={handleCloseConfirm}
          options={options}
        />
      ) : (
        <ConfirmationModalEmbedded
          Visible={clickCancel}
          handleCloseConfirm={handleCloseConfirm}
          options={options}
        />
      )}
      <ConfirmationModalLoseChanges
        Visible={VisibleConfirm}
        handleCloseConfirm={handleLoseConfirm}
        handleCloseModalYes={handleCloseModalYes}
        options={options}
      />
      <ConfirmationNextPrevious
        Visible={VisibleNextPrevious}
        handleCloseConfirm={handleMoveNextPrevious}
        handleSaveMove={handleSaveMove}
        handleDiscard={handleDiscard}
        Step={clickNext ? 'Next' : 'Previous'}
        options={options}
      />
    </>
  );
};

export default FormHeaderTerminal;
