import React, { useState } from 'react';

// Components
import TmsHeader from '../../../components/header';
import SubHeader from '../../../components/subHeader';
import ViewTraceLogsSearchCrad from '../../../components/ViewTraceLogsSearchCrad';
import ViewTraceLogs from '../../../components/ViewTraceLogs';

// Styles
import { useStyles } from './style';

const SystemStartupOptions: React.FC = () => {
  const classes = useStyles();
  const [showResult, setShowResult] = useState(false);

  const onApply = () => {
    setShowResult(true);
  };

  const onCancel = () => {
    setShowResult(true);
  };

  const onRemove = () => {
    setShowResult(true);
  };

  const modifySearch = () => {
    setShowResult(false);
  };

  return (
    <div className={classes.root}>
      <TmsHeader />
      <SubHeader title="View Trace Logs" />
      <div className={classes.wrapper}>
        {!showResult ? (
          <ViewTraceLogsSearchCrad
            onApply={onApply}
            onCancel={onCancel}
            onRemove={onRemove}
          />
        ) : null}
        <ViewTraceLogs modifySearch={modifySearch} />
      </div>
    </div>
  );
};

export default SystemStartupOptions;
