import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {},
  container: {
    display: 'block',
    position: 'relative',
    paddingLeft: '35px',
    marginBottom: '12px',
    cursor: 'pointer',
    fontSize: '12px',
    fontWeight: '400 !important',
    lineHeight: '1.42857143',
    userSelect: 'none',
    '& input': {
      position: 'absolute',
      opacity: 0,
      cursor: 'pointer',
      height: 0,
      width: 0,
      '&:checked': {
        '& ~ $checkmark': {
          backgroundColor: '#2196F3',
          '&:after': {
            display: 'block',
          },
        },
      },
    },
    '& $checkmark': {
      '&:after': {
        left: '7px',
        top: '3px',
        width: '4px',
        height: '8px',
        border: 'solid white',
        borderWidth: '0 3px 3px 0',
        transform: 'rotate(45deg)',
      },
    },
    '&:hover': {
      '& input': {
        '& ~ $checkmark': {
          backgroundColor: '#ccc',
        },
      },
    },
  },
  checkmark: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '20px',
    width: '20px',
    backgroundColor: '#eee',
    border: '1px solid #006fff',
    '&:after': {
      content: '""',
      position: 'absolute',
      display: 'none',
    },
  },
});
