import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

type Props = {
    HandleServiceCall: any,
    errorCode: any,
}

const AlertUpdate = (props: Props) => {
  const { HandleServiceCall, errorCode } = props;
  const dispatch = useDispatch();
  useEffect(() => {
    if (errorCode) {
      HandleServiceCall();
    }
    return () => {
      dispatch({ type: 'CLEAR_ERROR_CODE' });
    };
  }, []);
  return (
    <div id="alertcontainer">
      <div
        id="AL634956b297bbc"
        className="alertstyle-leftborder alertstyle-info alertstyle-lg"
        role="alert"
      >
        <button
          type="button"
          className="close"
          data-dismiss="alert"
          aria-label="Close"
          onClick={() => dispatch({ type: 'CLEAR_ERROR_CODE' })}
        >
          <span aria-hidden="true">×</span>
        </button>
        <strong>Info!</strong>
        {' '}
        Original record has been modified and is reloaded!
      </div>
    </div>

  );
};

export default AlertUpdate;
