/* eslint-disable no-nested-ternary */
import React from 'react';

// Librairies
import {
  ButtonBase,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import clsx from 'clsx';

// Styles
import { useStyles } from './style';

// Utils
const tableHeader: string[] = [
  'Submit Date',
  'Reference Number',
  'Status',
  'Parameters',
  'Comment',
];

const SystemStartupTable: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Table classes={{ root: classes.tableRoot }}>
        <TableHead>
          <TableRow classes={{ root: classes.headerRow }}>
            {tableHeader.map((h) => (
              <TableCell classes={{ root: classes.headerCell }} key={h}>
                {h}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow className={classes.tableRow}>
            {tableHeader.map((field: string) => (
              <TableCell classes={{ root: classes.bodyCell }} key={field}>
                {field}
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
      <div className={clsx('flexStartSpaceBetweenRow', classes.actionsContainer)}>
        <span className={classes.label}>No records available</span>
        <div className={classes.buttonContainer}>
          <ButtonBase disabled className={classes.button}>
            Previous
          </ButtonBase>
          <ButtonBase disabled className={classes.button}>
            Next
          </ButtonBase>
        </div>
      </div>
    </div>
  );
};

export default React.memo(SystemStartupTable);
