/* eslint-disable max-len */
import { removeFromObject } from '../../utils';
import {
  COLUMNS_LIST,
  DOWNLOAD_FORMAT_EXCEL,
  LOAD_SCREEN,
  LOAD_TABS,
  SAVE_FILTER,
  SET_COLUMNS,
  LOAD_RECORD,
  LOAD_RECORD_DEFAULT_COLUMNS,
  RESET_BATCH_RECORDS_LIST,
  DELETE_FILTER,
  BATCH_MODE_COLUMS,
  GET_ALL_COLUMNS,
  OPEN_COLUMN_MODAL,
  CLOSE_COLUMN_MODAL,
  SAVE_BATCH_MODE_QUERY,
  UPDATE_RECORD,
  START_LOADING,
  STOP_LOADING,
  GET_MAINT_SCREENS,
  UPDATE_SCREEN_CONFIGURATION,
  SET_RECORD_LIST,
  OPEN_CHANGE_HISTORY_MODAL,
  CLOSE_CHANGE_HISTORY_MODAL,
  CLOSE_FILTER_MODAL,
  OPEN_FILTER_MODAL,
  DISSMISS_CONFIRMATION_ALERT,
  SET_MESSAGE,
  CLEAN_ACTIVE_SCREEN_CACHE,
  SAVE_SCREEN_FILTER,
  START_LOADING_EXPORT,
  STOP_LOADING_EXPORT,
  SET_RECENT_SCREENS,
  GET_DATA_BULK_TRANSACTION,
  DELETE_RECORD,
  GET_TERMINAL_NAME,
  UPDATE_VISIBLE_USER_COLUMNS,
  UPDATE_HIDDEN_USER_COLUMNS,
  CLEAR_ERROR_CODE,
} from '../constants/Screens';

type action = {
  type: string;
  payload: any;
  screen?:any;
  cloneForm?:any;
  parsedValues?:any;
  RecordsList?: any;
  labels?: any;
  allTypes?: any;
  allSizes? :any;
  message?: any;
  errorCode?: number;
  embbededTabsConfig?: any;
  allLabels?: any;
  grid?: any;
  screenId?: any;
  unitCalculator?:any;
  whereDetailCondition?: any;
  reviewWhereCondition?: any;
  embeddedTabsLabels?: any;
  newContractItemNumber?: any;
  newContractNumber?: any;
};
type ScreenState = {
  data: [];
  loading: boolean;
  columns:[];
  savedFilter:string;
  recordDetails:any;
  batchRecordsDefaultColumns:any;
  batchRecordsDefaultLabels:any;
  activeScreen:any;
  recordsColumns:any,
  allTableColumns:[];
  ColumnsVisibleModal:boolean;
  BatchModeQuery:any
  ScreensList:[]
  changeRecordModal:boolean;
  FilterDropdowStatus:boolean;
  embbededTabsConfig:[];
  allLabels:any;
  grid:any;
  exportLoading:any;
  activeFilter: any;
  FilterSaved: any;
  recentList:any;
  errorCode?: number;
};
const initialState = {
  data: [],
  loading: true,
  columnss: [],
  allTableColumns: [],
  recordsColumns: {},
  recordDetails: [],
  batchRecordsDefaultColumns: [],
  batchRecordsDefaultLabels: [],
  ColumnsVisibleModal: false,
  BatchModeQuery: [],
  clone: null,
  ScreensList: [],
  RecordsList: [],
  changeRecordModal: false,
  FilterDropdowStatus: false,
  activeScreenLabels: [],
  confirmationAlert: '',
  openConfirmationAlert: false,
  reviewWhereCondition: {},
  embbededTabsConfig: [],
  grid: null,
  exportLoading: false,
  activeFilter: {},
  FilterSaved: {},
  whereDetailCondition: '',
  recentList: [],
  stepperData: [],
  detailsPageLoadingStatus: true,
  terminalID: '',
  changeHistoryState: true,
  embeddedTabsLabels: [],
  errorCode: null,
  newContractItemNumber: '',
  newContractNumber: '',
};

const ScreenReducer = (
  state: any = initialState,
  action: action,
): ScreenState => {
  switch (action.type) {
    case START_LOADING:
      return {
        ...state,
        loading: true,

      };
    case CLEAN_ACTIVE_SCREEN_CACHE:
      return {
        ...state,
        loading: true,
        recordDetails: null,
        data: null,
        activeScreen: null,
      };
    case LOAD_SCREEN:
      return {
        ...state,
        data: action.payload,
        activeScreen: action.screen,
        newContractItemNumber: action.newContractItemNumber,
        newContractNumber: action.newContractNumber,
      };
    case GET_TERMINAL_NAME:
      return {
        ...state,
        terminalID: action.payload,
      };
    case SAVE_SCREEN_FILTER:
      return {
        ...state,
        activeFilter: { ...state.activeFilter, [action.screenId]: action.payload },
      };
    case LOAD_TABS:
      return {
        ...state,
        loading: false,
        config: action.payload,
        embeddedTabsLabels: action.embeddedTabsLabels,
      };
    case LOAD_RECORD:
      return {
        ...state,
        loading: false,
        recordDetails: action.payload,
        clone: action.cloneForm,
        parsedValues: action.parsedValues,
        detailsPageLoadingStatus: false,
        activeScreenLabels: action.labels,
        allTypes: action.allTypes,
        embbededTabsConfig: action.embbededTabsConfig,
        allLabels: action.allLabels,
        allSizes: action.allSizes,
        grid: action.grid,
        unitCalculator: action.unitCalculator,
        whereDetailCondition: action.whereDetailCondition,
      };
    case LOAD_RECORD_DEFAULT_COLUMNS:
      return {
        ...state,
        batchRecordsDefaultColumns: state.batchRecordsDefaultColumns.concat(action.payload),
        batchRecordsDefaultLabels: action.labels,
      };
    case RESET_BATCH_RECORDS_LIST:
      return {
        ...state,
        batchRecordsDefaultColumns: [],
      };
    case UPDATE_VISIBLE_USER_COLUMNS:
      return {
        ...state,
        activeScreen: { ...state.activeScreen, fields: action.payload.visibleTableFields },
      };
    case UPDATE_HIDDEN_USER_COLUMNS:
      return {
        ...state,
        activeScreen: { ...state.activeScreen, hidden: action.payload.hiddenTableFields },
      };
    case GET_ALL_COLUMNS:
      return {
        ...state,
        loading: false,
        allTableColumns: action.payload,
      };
    case COLUMNS_LIST:
      return {
        ...state,
        loading: false,
        columnss: action.payload,
      };
    case SET_COLUMNS:
      return {
        ...state,
        loading: false,
        Selectedcolumnss: action.payload,
      };
    case SAVE_FILTER:
      return {
        ...state,
        loading: false,
        FilterSaved: { ...state.FilterSaved, [action.screenId]: action.payload },
      };
    case DELETE_FILTER:
      return {
        ...state,
        loading: false,
        FilterSaved: removeFromObject(state.FilterSaved, action.payload),
        activeFilter: removeFromObject(state.activeFilter, action.payload),
      };
    case BATCH_MODE_COLUMS:
      return {
        ...state,
        loading: false,
        recordsColumns: action.payload,
      };
    case DOWNLOAD_FORMAT_EXCEL:
      return {
        ...state,
        loading: false,
        exportTableXL: action.payload,
      };
    case OPEN_COLUMN_MODAL:
      return {
        ...state,
        ColumnsVisibleModal: true,
      };
    case CLOSE_COLUMN_MODAL:
      return {
        ...state,
        ColumnsVisibleModal: false,
      };
    case OPEN_FILTER_MODAL:
      return {
        ...state,
        FilterDropdowStatus: true,
      };
    case CLOSE_FILTER_MODAL:
      return {
        ...state,
        FilterDropdowStatus: false,
      };
    case SAVE_BATCH_MODE_QUERY:
      return {
        ...state,
        loading: false,
        BatchModeQuery: action.payload,
      };
    case STOP_LOADING:
      return {
        ...state,
        loading: false,

      };
    case START_LOADING_EXPORT:
      return {
        ...state,
        exportLoading: true,

      };
    case STOP_LOADING_EXPORT:
      return {
        ...state,
        exportLoading: false,

      };
    case SET_MESSAGE:
      return {
        ...state,
        confirmationAlert: action.message,
        openConfirmationAlert: true,
      };
    case UPDATE_RECORD:
      return {
        ...state,
        loading: false,
        BatchModeQuery: [],
        confirmationAlert: action.message,
        openConfirmationAlert: true,
        reviewWhereCondition: action.reviewWhereCondition,
        errorCode: action.errorCode,
      };
    case CLEAR_ERROR_CODE:
      return {
        ...state,
        errorCode: null,
      };
    case DELETE_RECORD:
      return {
        ...state,
        loading: false,
        BatchModeQuery: [],
        confirmationAlert: action.message,
        openConfirmationAlert: true,
      };
    case GET_MAINT_SCREENS:
      return {
        ...state,
        loading: false,
        ScreensList: action.payload,
      };
    case UPDATE_SCREEN_CONFIGURATION:
      return {
        ...state,
        ScreensList: action.payload,
      };
    case SET_RECORD_LIST:
      return {
        ...state,
        loading: false,
        RecordsList: action.payload,
      };
    case DISSMISS_CONFIRMATION_ALERT:
      return {
        ...state,
        loading: false,
        confirmationAlert: '',
        openConfirmationAlert: false,
      };
    case OPEN_CHANGE_HISTORY_MODAL:
      return {
        ...state,
        loading: false,
        changeRecordModal: true,
      };
    case CLOSE_CHANGE_HISTORY_MODAL:
      return {
        ...state,
        loading: false,
        changeRecordModal: false,
      };
    case SET_RECENT_SCREENS:
      return {
        ...state,
        recentList: !state.recentList?.find((recentScreen: any) => recentScreen?.screenId === action.payload?.screenId)
          ? Array.from(new Set([...state.recentList, action.payload]))
          : [...state.recentList],
      };
    case GET_DATA_BULK_TRANSACTION:
      return {
        ...state,
        stepperData: action.payload,
      };
    default:
      return state;
  }
};

export default ScreenReducer;
