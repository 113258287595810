import React, { useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import Subnavbar from '../../components/Sub-navbar/Subnavbar';
import Footer from '../../components/Footer/Footer';
import HomeSearch from '../../components/HomeSearchInput/HomeSearchInput';
import Logo from '../../assets/logo.png';
import './LandingPage.css';
import TmsHeader from '../../components/header';
import { GetTerminalName } from '../../redux/actions/ScreenActions';

const LandingPage = () => {
  const {
    loading,
    terminalID,
  } = useSelector((state: any) => state?.ScreenReducer);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetTerminalName());
  }, []);

  return (
    <>
      <Helmet
        defer={false}
      >
        <meta charSet="utf-8" />
        <title>{`${terminalID?.[0]?.term_id?.value} : Home`}</title>
      </Helmet>
      <TmsHeader />
      <div className="container">
        <Subnavbar version={`8. 0. 0 Build ${process.env.REACT_APP_VERSION || '0'}`} />
        <div className="logogroup">
          <img src={Logo} className="logoimage" alt="logo" />
          <div className="prodname">Terminal Management System</div>
        </div>
        <div className="searchInput">
          <HomeSearch />
        </div>
      </div>
    </>
  );
};

export default LandingPage;
