import React from 'react';

// Librairies
import { Divider, Menu, MenuItem } from '@mui/material';

// Styles
import { useStyles } from './style';

interface IProps {
  anchorEl: any;
  onClose: () => void;
}

const SystemStartupOptions: React.FC<IProps> = ({ anchorEl, onClose }) => {
  const classes = useStyles();
  return (
    <Menu
      classes={{ root: classes.root, list: classes.list, paper: classes.paper }}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
    >
      <MenuItem classes={{ root: classes.menuItem }}> New </MenuItem>
      <Divider />
      <MenuItem classes={{ root: classes.menuItem }}> Start TMS </MenuItem>
      <MenuItem classes={{ root: classes.menuItem }}> Stop TMS </MenuItem>
      <Divider />
      <MenuItem classes={{ root: classes.menuItem }}>
        Record change history...
      </MenuItem>
    </Menu>
  );
};

export default SystemStartupOptions;
