import * as React from 'react';
import ReactDOM from 'react-dom';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { useDispatch, useSelector } from 'react-redux';
import Draggable from 'react-draggable';
import {
  Dialog, DialogContent, DialogTitle, Paper, PaperProps, Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import ColumnsScreen from '../columns';
import { CloseColumnModal, openColumnModal } from '../../redux/actions/ScreenActions';

const style = {
  position: 'absolute' as const,
  left: '10%',
  width: '50%',
  height: 700,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
};
const PaperComponent = (props: PaperProps) => (
  <Draggable
    handle="#draggable-dialog-title"
    cancel={'[class*="MuiDialogContent-root"]'}
  >
    <Paper {...props} />
  </Draggable>
);
const ColumnsModal = () => {
  const { ColumnsVisibleModal } = useSelector(
    (state: any) => state.ScreenReducer,
  );
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(CloseColumnModal());
  };

  return ReactDOM.createPortal(
    <Dialog
      aria-labelledby="draggable-dialog-title"
      open={ColumnsVisibleModal}
      PaperComponent={PaperComponent}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 460,
      }}
      PaperProps={{
        style: {
          minHeight: 700,
          minWidth: '70%',
          position: 'absolute',
        },
      }}
    >
      <DialogTitle style={{ cursor: 'move', padding: '0px' }} id="draggable-dialog-title">
        <div
          style={{
            backgroundColor: '#d2d2d2',
            height: '50px',
            position: 'relative',
          }}
        >
          <Typography variant="h6">Configure Visible Column</Typography>
          <div
            style={{
              position: 'absolute',
              right: '1rem',
              top: '1rem',
            }}
          >
            <button
              style={{
                border: 'none',
                background: 'transparent',
                cursor: 'pointer',
                color: 'red',
              }}
              type="button"
              onClick={handleClose}
            >
              <Close />
            </button>
          </div>
        </div>
      </DialogTitle>
      <ColumnsScreen handleClose={handleClose} />
    </Dialog>,
    document.getElementById('draggable') as HTMLElement,
  );
};
export default ColumnsModal;
