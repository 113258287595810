import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {},
  cancleButton: {
    color: 'black !important',
    textShadow: 'none !important',
    backgroundColor: '#ffbb33 !important',
    padding: '4px 10px !important',
    marginLeft: '10px !important',
    border: '1px solid transparent',
    '&:hover': {
      backgroundColor: '#FF8800 !important',
      color: 'white !important',
    },
  },
  saveButton: {
    verticalAlign: 'middle',
    textAlign: 'center',
    backgroundColor: '#00C851 !important',
    color: 'black !important',
    padding: '4px 10px !important',
    border: '1px solid transparent',
    '&:hover': {
      backgroundColor: '#007E33 !important',
      color: 'white !important',
    },
  },
  container: {
    padding: '0px 5px',
  },
  header: {
    paddingBottom: 5,
  },
  divider: {
    marginBottom: '10px !important',
  },
  inputWrapper: {
    justifyContent: 'flex-end',
  },
  labelWrapper: {
    paddingRight: 5,
    paddingTop: 5,
    fontSize: 13,
    fontWeight: 400,
  },
  wrapper: {
    marginBottom: 5,
  },
});
