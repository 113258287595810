/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import {
  Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import { Box } from '@mui/system';
import CustomField from '../CustomField/CustomField';

const LoadDataGrid = ({
  values,
  onChangeInput,
  onChangeSelect,
  id,
  isBatch,
  isEmbedded,
}:any) => {
  const { grid, loading } = useSelector(
    (state: any) => (isEmbedded ? state.EmbbededScreenReducer : state.ScreenReducer),
  );

  console.log('LoadDataGriddddddd', id, values, grid);

  const firstHeaderCheck = (checkid: string, gridItem: Array<any>) => {
    let result: Array<string> = [];
    const ids = ['#AccessControl-period', '#VEHICLE_DEF-compartment', '#ProdTankBatch-property'];

    if (ids.includes(checkid)) {
      result = [gridItem[1]?.rowlabel[0]];
    } else if (!gridItem[1]?.rowlabel.every((e:any) => e === '')) {
      result = [''];
    }

    return result;
  };

  const correctHeader = (header: []):string[] => header.map((h:string) => {
    if (h.includes('Out Seq-')) {
      return h.replace('Out Seq-', 'Output Sequence Number ');
    } if (h.includes('Out Seq')) {
      return h.replace('Out Seq', 'Output Sequence');
    }

    return h;
  });

  return (
    (
      <div>
        {
        grid.map((gridItem: any, key: any) => {
          if (gridItem[0] !== id) {
            return <></>;
          }
          return (
            <TableContainer component={Box} sx={{ margin: '30px 0px' }}>
              <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>

                    {[...firstHeaderCheck(id, gridItem),
                      ...correctHeader(gridItem[1].header),
                    ].map((gridCell: any) => (
                      <TableCell sx={{
                        textAlign: 'center', fontWeight: 'bold', width: '1rem', maxWidth: '1rem',
                      }}
                      >
                        {gridCell}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody sx={{ m: 2 }}>
                  {gridItem[1].fieldlist.map((row:any, index:any) => (
                    <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      {!gridItem[1]?.rowlabel.every((e:any) => e === '') && row.concat([gridItem[1]?.rowlabel.slice(1)[index]]).slice(-1).map((cell: any, indice: any) => (
                        <TableCell
                          sx={{
                            whiteSpace: 'nowrap',
                            textAlign: 'center',
                            padding: 0,
                            border: 0,
                            width: '10%',

                          }}
                        >

                          <Typography
                            className="f12"
                            color="blue"
                            style={{ width: 'auto' }}
                          >
                            {cell}
                          </Typography>
                        </TableCell>
                      ))}
                      {row.concat([gridItem[1]?.rowlabel[index]]).slice(0, -1).map((cell: any, indice: any) => (
                        <TableCell
                          sx={{
                            whiteSpace: 'nowrap',
                            textAlign: 'flex-end',
                            padding: '4px 12px',
                          }}
                        >
                          <CustomField
                            val={cell}
                            values={values}
                            valuesToSend={values}
                            handleInputChange={onChangeInput}
                            handleSelectChange={onChangeSelect}
                            parentKeyName={cell?.parentKeyName}
                            isBatch={isBatch}
                            size="100%"
                            margin="0px"
                            datagrid
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          );
        })
        }

      </div>
    )
  );
};

export default LoadDataGrid;
