import { Close, Filter, FilterAlt } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EmbeddedCustomizedMenus from '../../../components/MenuDropdown/EmbeddedMenuDropdown';
import { DeleteFilter, LoadTables } from '../../../redux/actions/ScreenActions';
import '../../../components/Sub-navbar/Subnavbar.css';
import { LoadEmbbededTables } from '../../../redux/actions/embbededScreensActions';

type ConditionalProps =
  | {
      // label?: string | boolean | undefined;
      label?: any;
      menuOptionType?: string;
      menuOptions?: string;
      exportTable?: any;
      version?: never;
      handleEmbeddedClose?: any;
    }
  | {
      label?: never;
      menuOptionType?: never;
      menuOptions?: never;
      exportTable?: never;
      version?: string;
      handleEmbeddedClose?: never;
    };

const EmbeddedSubnavbar = (props: ConditionalProps) => {
  const { activeScreen, embeddedNavigation } = useSelector(
    (state: any) => state.EmbbededScreenReducer,
  );
  const { ScreensList } = useSelector(
    (state: any) => state.ScreenReducer,
  );
  const dispatch = useDispatch();
  const closeFilter = () => {
    dispatch(
      LoadEmbbededTables({
        tableID: activeScreen.tableID,
        page: '1',
        results_per_page: activeScreen.results_per_page,
        where: '',
        fields: '',
        order_by: '',
      }),
    );
    dispatch(DeleteFilter(activeScreen.screenId));
  };
  const {
    label, version, menuOptionType, menuOptions, exportTable, handleEmbeddedClose,
  } = props;
  let screenDestructed: any = null;
  if (activeScreen) {
    screenDestructed = activeScreen.screenId;
  }

  const getScreenNameByIdAndMenuType = (id: any, menuType: any) => {
    const currentScreen = ScreensList.filter((e: any) => e.screenId === id)[0];
    let labelMenu;
    if (menuType === 'simpleItems') {
      labelMenu = `${currentScreen?.label}: Detail`;
    } else if (menuType === 'exportItems') {
      labelMenu = `${currentScreen?.label}`;
    }
    return labelMenu;
  };
  return (
    <div style={{
      backgroundColor: '#e6f1ff',
      height: '40px',
      textAlign: 'start',
      margin: '5px 0px',
      justifyContent: 'space-between',
      display: 'flex',
      alignItems: 'center',
      paddingLeft: '5px',

    }}
    >
      {label && (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            { embeddedNavigation === 'tableScreen' && (
            <Button
              variant="outlined"
              size="small"
              color="error"
              sx={{
                textDecoration: 'none',
                textTransform: 'none',
                color: '#000',
                backgroundColor: '#f0f0f0',
              }}
              onClick={handleEmbeddedClose}
            >
              Close Embed

            </Button>
            )}
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <h3 id="pagetitlebar_title" className="page-title">
                {getScreenNameByIdAndMenuType(screenDestructed, menuOptionType)}
              </h3>
            </div>

            {menuOptions && (
            <div
              style={{
                width: 'auto',
                background: '#fff',
                color: '#0473bd',
                display: 'flex',
                alignItems: 'center',
                marginLeft: 5,
                flexWrap: 'nowrap',
              }}
            >
              <FilterAlt />
              <Typography>{menuOptions}</Typography>
              <Close onClick={closeFilter} />
            </div>
            )}
          </div>
          <EmbeddedCustomizedMenus menuOptionType={`${menuOptionType}`} />

        </>
      )}
      {version && <small>{version}</small>}
    </div>
  );
};

export default EmbeddedSubnavbar;
