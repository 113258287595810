/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { fontWeight } from '@mui/system';
import {
  MenuItem, Pagination, Select, TextField, Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

const BasicTable = ({ rows, headCells }: any) => {
  const [Values, setValues] = React.useState<any>([]);
  const [search, setSearch] = React.useState<string>('');
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState<any>(10);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [searching, setSearching] = React.useState(false);
  const history = useNavigate();
  // search
  const handleSearch = (e: any) => {
    console.log('test');
  };
  // row per page
  const handleChange = (e: any) => {
    console.log('test');
  };
  // handle pagination
  const handleChangePage = (e: any) => {
    console.log('test');
  };

  const test = rows.map((val: any[]) => val);
  console.log(test[0]);
  return (
    <>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            minWidth: '30%',
            gap: 15,
            display: 'flex',
            alignItems: 'center',
            margin: '0px 10px',
          }}
        >
          <Typography className="f12">Items per page:</Typography>
          <Select
            size="small"
            variant="standard"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={rowsPerPage}
            label="Rows"
            onChange={handleChange}
            style={{ width: 70 }}
          >
            <MenuItem className="f12" value={10}>auto</MenuItem>
            <MenuItem className="f12" value={15}>15</MenuItem>
            <MenuItem className="f12" value={20}>20</MenuItem>
            <MenuItem className="f12" value={25}>25</MenuItem>
            <MenuItem className="f12" value={35}>35</MenuItem>
            <MenuItem className="f12" value={50}>50</MenuItem>
            <MenuItem className="f12" value={rows?.length || 100}>all</MenuItem>
          </Select>
        </div>
        <TextField
          size="small"
          label="search"
          value={search}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSearch(e.target.value)}
        />
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650, boxShadow: 12 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {test[0].map((item: any) => (
                <TableCell sx={{ fontWeight: 'bold', color: '#000' }}>
                  {item.title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody sx={{ boxShadow: 12 }}>
            {rows.map((item: any) => (
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                onClick={() => history('/workflow/Groups-details',
                  { state: item[0].data })}
              >
                {item.map((col: any) => (
                  <TableCell
                    align="left"
                  >
                    {col.data}

                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div
        style={{
          width: '99%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          margin: '10px 5px',
        }}
      >
        {rows.length ? (
          <Typography variant="caption">
            {`Showing ${
              (currentPage - 1) * Number(rowsPerPage) + 1
            } to ${Math.min(
              currentPage * Number(rowsPerPage),
              rows?.length,
            )} of ${rows?.length} entries`}

          </Typography>
        ) : (
          <Typography variant="caption">No records available</Typography>
        )}
        <Pagination count={searching ? 0 : Math.ceil(page / rowsPerPage)} onChange={handleChangePage} page={currentPage} variant="outlined" shape="rounded" style={{ alignSelf: 'flex-end' }} />
      </div>

    </>
  );
};
export default BasicTable;
