/* eslint-disable no-cond-assign */
/* eslint-disable no-return-assign */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import CustomDropdown from '../customDropdown';
import CustomInput from '../customInput';
import Plcconfig from '../plcconfigComponent/plcconfig';
import CustomRcuscreen from '../customTextArea/customTextArea';
import ConfigOptions from '../ConfigOptions/ConfigOptions';
import Weekday from '../customDropdown/Weekday';
import LoadDataGrid from '../LoadDataGrid/LoadDataGrid';
import CustomDateTime from '../customInput/customDateTime';
import FolioCustomDateTime from '../customInput/FolioCustomDateTime';

const CustomField = (props: any) => {
  const {
    val,
    isBatch,
    handleInputChange,
    handleSelectChange,
    values,
    valuesToSend,
    size,
    margin,
    headerDisabled,
    handleTimeDateChange,
    tableId,
    datagrid,
    isEmbedded,
  } = props;
  const {
    ScreensList, activeScreen, whereDetailCondition, embeddedTabsLabels,
  } = useSelector(
    (state: any) => (isEmbedded ? state.EmbbededScreenReducer : state.ScreenReducer),
  );
  // concert date for FolioSkipDays date format
  const months:any = {
    January: '01',
    February: '02',
    March: '03',
    April: '04',
    May: '05',
    June: '06',
    July: '07',
    August: '08',
    September: '09',
    October: '10',
    November: '11',
    December: '12',
  };
  let folioDay;
  let convertedFolioMonth :any = '';
  const handleFolioDateFormat = () => {
    if (activeScreen.screenId === 'MAINT-164' && val.valForm !== '') {
      folioDay = val?.valForm?.split('-')?.[1];
      const folioMonth:any = val?.valForm?.split('-')?.[0];
      const folioYear: any = '22';
      convertedFolioMonth = months[folioMonth];
      return `22${convertedFolioMonth}${folioDay}`;
    } return values[val?.id];
  };
  // handle date for Number Range screen
  const handleDateNumberRange = (date:string) => {
    if (activeScreen.screenId === 'MAINT-210' && date !== '' && date?.search('-') > -1) {
      const dateFormat = date.split(' ')[0];
      const day = dateFormat.slice(8, 10);
      const month = dateFormat.slice(5, 7);
      const year = dateFormat.slice(2, 4);
      console.log('dateFormat', day, month, year);
      return `${year}${day}${month}`;
    } return values[val?.id];
  };

  // date format for contract extention
  const d = new Date();
  const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
  const mo = new Intl.DateTimeFormat('en', { month: 'numeric' }).format(d);
  const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
  // doc2condtionCheck is for embedded doc2condition extention
  const doc2conditionCheck = () => {
    let check = false;
    if (activeScreen.tableID === 'Doc2Condition' && values.CustomCondition?.value && ['FieldName', 'TableName']?.includes(val.id)) {
      check = true;
    } else if (activeScreen.tableID === 'Doc2Condition' && !values.CustomCondition?.value && ['Custom']?.includes(val.id)) {
      check = true;
    } else check = headerDisabled || val?.attr?.includes('readonly');
    return check;
  };
  // for Contract extension we check if the Locked date is enabled or not
  const ContractCheck = () => {
    let check = '';
    if ((activeScreen.screenId === 'MAINT-173' || activeScreen.screenId === 'MAINT-174') && values.Locked?.value === 'Y' && ['LockoutDate']?.includes(val.id)) {
      check = values[val.id]?.replaceAll('-', '/');
    } else if (activeScreen.screenId === 'MAINT-173' && values.Locked?.value === 'N' && ['LockoutDate']?.includes(val.id)) {
      check = '';
    } else {
      check = values[val.id]?.replaceAll('-', '/');
    }
    return check;
  };
  if (!val?.type) {
    return <></>;
  }
  if (val?.type === 'grid') {
    return (
      <LoadDataGrid
        id={val?.id}
        isBatch={isBatch}
        values={values}
        onChangeInput={handleInputChange}
        onChangeSelect={handleSelectChange}
        isEmbedded={isEmbedded}
      />
    );
  }

  if (
    [
      'aftx',
      'tlist',
      'baygroupbay',
      'country',
      'select',
      'state',
      'dualsel01',
      'routecode',
      'dualselYN',
      'aftx:inven_units',
      'chain',
      'emailgroup',
      'faxgroup',
      'dualselGN',
      'transval',
      'termid',
      'product',
      'language',
      'carrier',
      'accessprofile',
      'dualselAI',
      'uom',
    ].includes(val?.type)
  ) {
    const tabOpt = val?.obj['\u0000*\u0000parm'];
    let defVal;
    if (tabOpt?.options !== undefined) {
      defVal = tabOpt?.options[0];
    } else if (tabOpt?.nothingselected === null) {
      defVal = tabOpt?.selecttext;
    } else {
      defVal = tabOpt?.nothingselected;
    }
    return (
      <CustomDropdown
        key={uuidv4()}
        val={val}
        isBatch={isBatch}
        label={val?.label}
        name={val?.id}
        defaultValue={defVal}
        isDisabled={val.id === 'ContractNumber' || doc2conditionCheck()}
        value={values[val?.id]}
        isOptionEqualToValue={(option: any, value: any) => option?.label === value}
        onChange={handleSelectChange}
        size={size}
        margin={margin}
        options={
          // val?.obj['\u0000*\u0000parm'].nothingselected === null
          val?.obj['\u0000*\u0000parm'].options
        }
        // : Array.of({ label: val?.obj['\u0000*\u0000parm'].nothingselected })}
        // options={val?.obj['\u0000*\u0000parm'].options}
        type={val?.type}
        obj={val?.obj}
        query={val?.query}
        required={val?.required}
        selectList={val?.selectList}
        chain={val?.chain}
        stateValues={values}
        valuesToSend={valuesToSend}
        datagrid={datagrid}
      />
    );
  }
  if (val?.attr?.includes('noneg') && val?.type === 'time') {
    return (
      <CustomInput
        timeType
        isBatch={isBatch}
        type={val?.type}
        label={activeScreen.screenId !== 'MAINT-001' ? val?.label : ''}
        required={val?.required}
        name={val?.id}
        defaultValue={val?.valForm}
        attr={val?.attr}
        value={values[val.id]}
        onChange={handleInputChange}
        size={size}
        maxLength={val?.obj['\u0000*\u0000parm'].maxLength}
        inputProps={{
          maxLength: val?.obj['\u0000*\u0000parm'].maxLength,
        }}
        placeholder={activeScreen.screenId === 'MAINT-001' ? val?.label : val.helpertext}
        isDisabled={doc2conditionCheck()}
        validate={val?.validate}
        margin={margin}
        datagrid={datagrid}
      />
    );
  }
  if (
    (val?.attr?.includes('noneg') && val?.type === 'date')
    || val?.obj['\u0000*\u0000parm'].mode === 'date'
  ) {
    return (
      <CustomInput
        dateType
        type={val?.type}
        isBatch={isBatch}
        label={val?.label}
        required={val?.required}
        name={val?.id}
        defaultValue={val?.valForm}
        attr={val?.attr}
        value={(activeScreen.screenId === 'MAINT-173' && val.id === 'LockoutDate' && valuesToSend?.Locked === 'N') ? `${ye.slice(2, 4)}${mo}${da}` : handleDateNumberRange(values[val?.id])}
        onChange={handleInputChange}
        size={size}
        maxLength={val?.obj['\u0000*\u0000parm'].maxLength}
        inputProps={{
          maxLength: val?.obj['\u0000*\u0000parm'].maxLength,
        }}
        placeholder={val.helpertext}
        isDisabled={(activeScreen.screenId === 'MAINT-173' && val.id === 'LockoutDate' && values?.Locked === 'N')
        || doc2conditionCheck()}
        validate={val?.validate}
        margin={margin}
        datagrid={datagrid}
      />
    );
  }
  if (val?.type === 'weekday') {
    return (
      <Weekday
        key={uuidv4()}
        label={val?.label}
        isBatch={isBatch}
        name={val?.id}
        value={values?.[val?.id]}
        isOptionEqualToValue={(option: any, value: any) => option.label === value}
        onChange={handleInputChange}
        size={size}
        options={val?.obj['\u0000*\u0000parm'].options}
        isDisabled={doc2conditionCheck()}
        type={val?.type}
        obj={val?.obj}
        query={val?.query}
        selectList={val?.selectList}
        chain={val?.chain}
        stateValues={values}
        valuesToSend={valuesToSend}
        margin={margin}
      />
    );
  }
  if (val?.type === 'datetime' && activeScreen.screenId !== 'MAINT-164') {
    return (
      <CustomDateTime
        label={val?.label}
        isBatch={isBatch}
        name={val?.id}
        value={ContractCheck()}
        isOptionEqualToValue={(option: any, value: any) => option.label === value}
        onChange={handleTimeDateChange}
        size={size}
        options={val?.obj['\u0000*\u0000parm'].options}
        isDisabled={(activeScreen.screenId === 'MAINT-173' && (values?.Locked?.value === 'N' || values?.Locked === '') && val.id === 'LockoutDate')}
        type={val?.type}
        obj={val?.obj}
        query={val?.query}
        selectList={val?.selectList}
        chain={val?.chain}
        stateValues={values}
        valuesToSend={valuesToSend}
        margin={margin}
      />
    );
  }
  if (val?.type === 'datetime' && activeScreen.screenId === 'MAINT-164') {
    return (
      <FolioCustomDateTime
        label={val?.label}
        name={val?.id}
        value={values?.[val.id]}
        onChange={handleTimeDateChange}
        size={size}
        options={val?.obj['\u0000*\u0000parm'].options}
        isDisabled={false}
        type={val?.type}
        stateValues={values}
        valuesToSend={valuesToSend}
      />
    );
  }
  if (val.type === 'configopt') {
    return (
      <ConfigOptions
        name={val?.id}
        value={values[val?.id]}
        setValue={handleInputChange}
      />
    );
  }
  if (val.type === 'input') {
    // console.log('values key', values[val.id]);
    const errorCondition = `${/^\d*$/.test(values[val.id])}` === 'false'
      || values[val.id].length - val?.obj['\u0000*\u0000parm'].maxLength > 0;
    // console.log('numberwheel', val);
    // console.log('numberwheel', values);
    if (val.mask) {
      return (
        <CustomInput
          val={val}
          phone={val.mask}
          required={val?.required}
          isBatch={isBatch}
          mask={val.mask.replaceAll('0', '9')}
          label={val.label}
          name={val.id}
          value={values[val.id]}
          onChange={handleInputChange}
          size={size}
          attr={val?.attr}
          type={val.type}
          maxLength={val?.obj['\u0000*\u0000parm'].maxLength}
          inputPlaceholder={val?.obj['\u0000*\u0000parm']?.inputText}
          inputProps={{
            maxLength: val?.obj['\u0000*\u0000parm'].maxLength || 10,
          }}
          placeholder={val.mask}
          // helperText={errorCondition ? 'Must be Numeric, Cannot be negative' : ''}
          isDisabled={doc2conditionCheck() || val.id === 'access_profile'}
          validate={val?.validate}
          margin={margin}
          datagrid={datagrid}
        />
      );
    }
    return (
      <CustomInput
        label={val?.label}
        name={val?.id}
        defaultValue={activeScreen.screenId === 'MAINT-173' && val.id === 'Type' ? 'R' : val?.valForm}
        isBatch={isBatch}
        required={val?.required}
        onChange={handleInputChange}
        value={activeScreen.screenId === 'MAINT-173' && val.id === 'Type' ? 'R' : activeScreen.tableID === 'Doc2Condition' && values.CustomCondition?.value && ['FieldName', 'TableName']?.includes(val.id) ? '' : values[val.id]}
        attr={val?.attr}
        size={size}
        type="text"
        placeholder={val?.helpertext}
        isDisabled={doc2conditionCheck()}
        // helperText={errorCondition ? 'Must be Numeric, Cannot be negative' : ''}
        inputProps={{
          maxLength: val?.obj['\u0000*\u0000parm'].maxLength || 15,
        }}
        validate={val?.validate}
        margin={margin}
        datagrid={datagrid}
      />
    );
  }
  if (val.type === 'numberwheel') {
    return (
      <CustomInput
        numericValue
        label={val?.label}
        name={val?.id}
        defaultValue={val?.default}
        isBatch={isBatch}
        required={val?.required}
        onChange={handleInputChange}
        value={values?.[val?.id]}
        attr={val?.attr}
        size={size}
        type={val?.type}
        maxLength={val?.obj['\u0000*\u0000parm'].maxLength}
        placeholder={val.helpertext}
        isDisabled={headerDisabled || val?.attr?.includes('readonly') || val.id === 'access_profile'}
        inputProps={{
          maxLength: val?.obj['\u0000*\u0000parm'].maxLength,
        }}
        validate={val?.validate}
        margin={margin}
        datagrid={datagrid}
      />
    );
  }
  if (val?.attr?.includes('noneg') && ['date', 'time'].includes(val.type)) {
    return (
      <CustomInput
        label={val?.label}
        name={val?.id}
        defaultValue={val?.default}
        isBatch={isBatch}
        onChange={handleInputChange}
        required={values?.[val?.id]}
        attr={val?.attr}
        size={size}
        type={val?.type}
        maxLength={val?.obj['\u0000*\u0000parm'].maxLength}
        inputProps={{
          maxLength: val?.obj['\u0000*\u0000parm'].maxLength,
        }}
        placeholder={val.helpertext}
        isDisabled={doc2conditionCheck() || val.id === 'access_profile'}
        validate={val?.validate}
        margin={margin}
        datagrid={datagrid}
      />
    );
  }
  if (val?.type === 'plcconfig') {
    return (
      <Plcconfig
        label={val?.label}
        name={val?.id}
        defaultValue={val?.default}
        onChange={handleInputChange}
        required={val?.required}
        value={values[val.id]}
        size={size}
        type={val?.type}
        options={val?.obj}
        // maxLength={val?.obj['\u0000*\u0000parm'].maxLength}
        // inputProps={{
        //   maxLength: val?.obj['\u0000*\u0000parm'].maxLength,
        // }}
        placeholder={val.helpertext}
        isDisabled={headerDisabled || val?.attr?.includes('readonly') || val.id === 'access_profile'}
      />
    );
  }
  if (val?.type === 'rcuscreen') {
    return (
      <CustomRcuscreen
        label={val?.label}
        name={val?.id}
        defaultValue={val?.default}
        onChange={handleInputChange}
        required={val?.required}
        value={values[val.id]}
        size={size}
        type={val?.type}
        // maxLength={val?.obj['\u0000*\u0000parm'].maxLength}
        // inputProps={{
        //   maxLength: val?.obj['\u0000*\u0000parm'].maxLength,
        // }}
        placeholder={val.helpertext}
        isDisabled={headerDisabled || val?.attr?.includes('readonly') || val.id === 'access_profile'}
      />
    );
  }
  return (
    <CustomInput
      label={val?.label}
      required={val?.required}
      name={val?.id}
      isBatch={isBatch}
      defaultValue={val?.default}
      value={values[val?.id]}
      onChange={handleInputChange}
      size={size}
      isDisabled={doc2conditionCheck() || val.id === 'access_profile'}
      placeholder={val?.helpertext}
      maxLength={val?.obj['\u0000*\u0000parm'].maxLength}
      inputProps={{
        maxLength: val?.obj['\u0000*\u0000parm'].maxLength,
      }}
      validate={val?.validate}
      type="text"
      margin={margin}
      datagrid={datagrid}
    />
  );
};

export default CustomField;
