import { ArrowDownward, Title } from '@mui/icons-material';
import {
  Card, Divider, FormControl, InputLabel, MenuItem, Select, TextField, Typography,
} from '@mui/material';
import React from 'react';
import './style.css';

const RakStautsPresetsCard = () => (
  <Card sx={{
    elevation: 7, padding: 2, width: '30%', border: '1px solid gray',
  }}
  >
    <div className="RackStatusHeader">
      <Typography variant="h6">
        PRE0101 - DYED DIESEL
      </Typography>
      <FormControl sx={{ width: '30%' }}>
        <InputLabel id="demo-simple-select-label">Options</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="Options"
        >
          <MenuItem value="Stop">Stop</MenuItem>
          <MenuItem value="Drain">Drain</MenuItem>
          <MenuItem value="Restart">Restart</MenuItem>
        </Select>
      </FormControl>
    </div>
    <Divider />
    <div style={{
      width: '100%',
      height: 'fit-content',
      backgroundColor: '#95e695',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '5px 0px',
    }}
    >
      <Typography variant="h2">IDLE</Typography>
    </div>
    <div className="RackStatusBody">
      <div>
        <Typography variant="body2">Presets:</Typography>
        <TextField placeholder="" size="small" style={{ width: '80%' }} />
      </div>
      <div>
        <Typography variant="body2">Delivered:</Typography>
        <TextField placeholder="" size="small" style={{ width: '80%' }} />
      </div>
    </div>
    <div className="RackStatusBody">
      <div>
        <Typography variant="body2">Temperature:</Typography>
        <TextField placeholder="" size="small" style={{ width: '80%' }} />
      </div>
      <div>
        <Typography variant="body2">Flow Rate:</Typography>
        <TextField placeholder="" size="small" style={{ width: '80%' }} />
      </div>
      <div>
        <Typography variant="body2">Percent Complete:</Typography>
        <TextField placeholder="" size="small" style={{ width: '80%' }} />
      </div>
      <div>
        <Typography variant="body2">ETC:</Typography>
        <TextField placeholder="" type="time" size="small" style={{ width: '80%' }} />
      </div>
    </div>
    <Divider />
    <div className="rackStatusFooter">
      <Typography variant="body1">
        Meters:
      </Typography>
      <div style={{ backgroundColor: '#95e695', width: 'fit-content', padding: 10 }}>
        LSD
      </div>
      <div style={{ backgroundColor: '#95e695', width: 'fit-content', padding: 10 }}>
        BLEND UNLEAD
      </div>
    </div>

  </Card>
);

export default RakStautsPresetsCard;
