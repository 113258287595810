import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { isObject } from '../../utils';
import { openChangeHistoryModal, openColumnModal, openFilterModal } from '../../redux/actions/ScreenActions';
import { SwitchEmbeddedNavigation, openColumnModalEmbedded, openFilterModalEmbedded } from '../../redux/actions/embbededScreensActions';
import { START_LOADING_EXPORT_EMBEDDED, STOP_LOADING_EXPORT_EMBEDDED } from '../../redux/constants/Screens';

export const getDownloadFilename = (screen: string) => `${screen}-
${moment().format('YYYY-MM-DD_HHmmss')}`;

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      // eslint-disable-next-line max-len
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

interface menuProp {
  menuOptionType: string;
}
const EmbeddedCustomizedMenus = (prop: menuProp) => {
  const { menuOptionType } = prop;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const dispatch = useDispatch();

  const {
    activeScreen, data, activeFilter, FilterSaved,
  } = useSelector((state: any) => state.EmbbededScreenReducer);
  const obj: any = data.data[0];

  const keys = isObject(obj) ? Object.keys(obj) : [];

  const values = isObject(obj) ? Object.values(obj).map((e: any) => e.value) : [];

  const merged: any = activeScreen?.fields.split(',').reduce((objt: any, key: any) => ({ ...objt, [key]: 'true' }), {});

  if (data.data.length) {
    Object.keys(data?.data[0]).forEach((key) => {
      if (data.data[0][key] === null) {
        obj[key] = 'null';
      }
    });
  }
  // const visibleColumn = (str: string) => str.split(',').reduce((o: any, k: any) => ({ ...o, [k]: `${k}` }), {});

  const downloadAllFile = async (type: string, spool: boolean) => {
    const selectedColumnsWithNoEmptyValues: any = {};
    Object.keys(merged).forEach((key: string) => {
      if (merged[key]) {
        selectedColumnsWithNoEmptyValues[key] = merged[key];
      }
    });
    let fileName = '';
    const filterAdapter: any = {
      '<': 'less',
      '=': 'equal',
      '!=': 'not_equal',
      '>': 'greater',
      like: 'contains',
      'not like': 'not_contains',
    };
    const filters = FilterSaved?.[activeScreen.screenId]?.filters?.map((item: any) => ({
      column: item.field, operator: filterAdapter[item.condition], compare_value: item.value,
    }));
    const payload = {
      table: activeScreen.tableID, relation: FilterSaved?.[activeScreen.screenId]?.match === ' AND ' ? 'all' : 'any', filter: filters, filter_name: '',
    };

    const filtersPayload = {
      report_type: `${activeScreen.tableID}`,
      export_table: `${activeScreen.tableID}`,
      maint_screen_id: `${activeScreen.screenId}`,
      file_type: `${type}`,
      spool_all_columns: spool,
      selected_columns: merged,
      filter_name: '',
      filters: {
        table: payload.table,
        relation: payload.relation,
        filter_name: '',
        filters: payload.filter,
      },
    };

    const emptyPayload = {
      report_type: `${activeScreen.tableID}`,
      export_table: `${activeScreen.tableID}`,
      maint_screen_id: `${activeScreen.screenId}`,
      file_type: `${type}`,
      spool_all_columns: spool,
      selected_columns: merged,
      filter_name: '',
    };
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}export/spooldata`,
        filters ? filtersPayload : emptyPayload,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      fileName = res?.data?.data?.[0];
    } catch (error: any) {
      throw Error('Error Download File', error);
    }
    const downloadFn = () => {
      dispatch({ type: START_LOADING_EXPORT_EMBEDDED });
      const downloadLink = `${process.env.REACT_APP_API_URL}export/download-file?file=${fileName}`;
      fetch(downloadLink).then((res: any) => {
        if (res.status === 200) {
          const link = document.createElement('a');
          link.href = downloadLink;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          dispatch({ type: STOP_LOADING_EXPORT_EMBEDDED });
        } else {
          // console.log('retrying 1');
          setTimeout(downloadFn, 5000);
        }
      }).catch((err) => {
        // console.log('retrying 2');
        setTimeout(downloadFn, 5000);
      });
    };
    downloadFn();
  };
  return (
    <div>
      <Button
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="text"
        disableElevation
        size="small"
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        style={{ color: '#000' }}
      >
        <Typography variant="body2" fontWeight="bold" style={{ fontSize: 12, textTransform: 'capitalize' }}>
          Options
        </Typography>
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {(() => {
          switch (menuOptionType) {
            case 'exportItems':
              return (
                <>
                  <MenuItem
                    className="f12"
                    onClick={() => {
                    //   navigate('/create-record', { replace: true });
                      dispatch(SwitchEmbeddedNavigation('create'));
                    }}
                    disableRipple
                  >
                    New
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    className="f12"
                    onClick={async () => {
                      dispatch(openFilterModalEmbedded());
                      handleClose();
                    }}
                    disableRipple
                  >
                    {/* <TransitionsModal label="Filter" /> */}
                    Filter
                    <MoreHorizIcon />
                  </MenuItem>
                  <MenuItem
                    className="f12"
                    onClick={async () => {
                      dispatch(openColumnModalEmbedded());
                      handleClose();
                    }}
                    disableRipple
                  >
                    Columns
                    <MoreHorizIcon />
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    className="f12"
                    disabled={data.data.length === 0}
                    onClick={async () => {
                      handleClose();
                      await downloadAllFile('csv', true);
                    }}
                  >
                    Export All Columns (CSV)
                  </MenuItem>
                  <MenuItem
                    className="f12"
                    disabled={data.data.length === 0}
                    onClick={async () => {
                      handleClose();
                      await downloadAllFile('xlsx', true);
                    }}
                  >
                    Export All Columns (XLSX)
                  </MenuItem>
                  <MenuItem
                    className="f12"
                    disabled={data.data.length === 0}
                    onClick={async () => {
                      handleClose();
                      await downloadAllFile('csv', false);
                    }}
                  >
                    Export Visible Columns (CSV)
                  </MenuItem>
                  <MenuItem
                    className="f12"
                    disabled={data.data.length === 0}
                    onClick={async () => {
                      handleClose();
                      await downloadAllFile('xlsx', false);
                    }}
                  >
                    Export Visible Columns (XLSX)
                  </MenuItem>
                  <MenuItem
                    className="f12"
                    disabled={data.data.length === 0}
                    onClick={async () => {
                      handleClose();
                      await downloadAllFile('pdf', false);
                    }}
                  >
                    Export To PDF
                  </MenuItem>
                </>
              );
              break;
            case 'simpleItems':
              return (
                <>
                  <MenuItem
                    className="f12"
                    onClick={() => {
                    //   navigate('/clone-record', { replace: true });
                      dispatch(SwitchEmbeddedNavigation('clone'));
                    }}
                    disableRipple
                  >
                    Clone Record
                  </MenuItem>
                  <MenuItem
                    className="f12"
                    disableRipple
                    onClick={async () => {
                      dispatch(openChangeHistoryModal());
                      handleClose();
                    }}
                  >
                    Record Change History
                    <MoreHorizIcon />
                  </MenuItem>
                  <Divider />
                  <MenuItem className="f12" disabled disableRipple>Show Optional Fields</MenuItem>
                </>
              );
              break;
            default:
              return null;
              break;
          }
        })()}
      </StyledMenu>
    </div>
  );
};
export default EmbeddedCustomizedMenus;
