import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {},
  header: {
    width: '100%',
    backgroundColor: '#eee',
    margin: '5px 0',
    padding: '5px 0',
    marginBottom: 10,
  },
  title: {
    padding: '0 5px 0 5px',
    fontSize: 22,
    letterSpacing: -1,
    display: 'block',
    color: '#666',
    margin: '0 0 5px 0',
    fontWeight: 100,
  },
  buttonGroup: { gap: 20, display: 'flex', marginRight: 5 },
  runningButton: {
    width: 70,
    verticalAlign: 'middle',
    textAlign: 'center',
    backgroundColor: '#32CD32 !important',
  },
  optionsButton: {
    verticalAlign: 'middle',
    textAlign: 'center',
    backgroundColor: '#6086cf !important',
    color: 'white !important',
    padding: '4px 10px !important',
  },
  saveButton: {
    verticalAlign: 'middle',
    textAlign: 'center',
    backgroundColor: '#00C851 !important',
    color: 'black !important',
    padding: '4px 10px !important',
    border: '1px solid transparent',
    '&:hover': {
      backgroundColor: '#007E33 !important',
      color: 'white !important',
    },
  },
  cancleButton: {
    color: 'black !important',
    textShadow: 'none !important',
    backgroundColor: '#ffbb33 !important',
    padding: '4px 10px !important',
    marginLeft: '10px !important',
    border: '1px solid transparent',
    '&:hover': {
      backgroundColor: '#FF8800 !important',
      color: 'white !important',
    },
  },
  input: {
    fontWeight: '400 !important',
    fontSize: '12px !important',
    lineHeight: '1.42857143 !important',
    padding: '4px 4px',
    color: '#333333',
    backgroundColor: '#fff',
    border: '1px solid #e5e5e5',
    height: 24,
    width: 70,
  },
  helperText: {
    borderColor: '#e5e5e5',
    lineHeight: '1.42857143',
    fontWeight: 400,
    fontSize: 12,
    width: 32,
    padding: '4px 4px',
    background: '#e5e5e5',
  },
});
