import React from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Check from '@mui/icons-material/Check';
import ControlButton from '../../components/controlButton';
import TmsHeader from '../../components/header';
import CustomInput from '../../components/customInput';

const ChangePassword = () => {
  const [error, setError] = React.useState(false);
  const [currentPassword, setCurrentPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');

  const passwordStrength = React.useMemo(() => Math.min(newPassword.length, 16), [newPassword]);

  const navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}user/change-password`,
        {
          user_id: 'tms',
          new_password: newPassword,
          password_confirmation: confirmPassword,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        });
      navigate('/home');
    } catch (err) {
      setError(true);
    }
  };

  const handleCancel = () => {
    navigate('/home');
  };

  return (
    <div>
      <TmsHeader />
      <div className="sub-nav-container">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h3 id="pagetitlebar_title" className="page-title">
            Change Password
          </h3>
        </div>
      </div>
      { error
      && (
      <div>
        <div style={{
          margin: 5, background: '#f2dede', padding: '10px 5px', borderLeft: 'red solid 10px',
        }}
        >
          <span style={{ fontWeight: 'bold', color: 'red' }}>Error! </span>
          An error occured!
        </div>
      </div>
      )}
      <div className="headerContainer">
        <div className="controlers">
          <ControlButton
            label="Update Password"
            color="#00c851"
            disabled={!(newPassword.length > 7 && newPassword === confirmPassword)}
            handleClick={handleSubmit}
          />
          <ControlButton
            label="Cancel"
            color="#ffbb33"
            disabled={false}
            handleClick={handleCancel}
          />
        </div>
      </div>

      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <div style={{ marginRight: 10, display: 'flex', flexDirection: 'column' }}>
          <CustomInput label="User ID" isDisabled value="tms" />
          <CustomInput label="Current Password" isDisabled={false} value={currentPassword} type="password" onChange={(e:any) => { setCurrentPassword(e.target.value); }} inputProps={{ maxLength: 64 }} />
          <CustomInput label="New Password" isDisabled={false} value={newPassword} type="password" onChange={(e:any) => { setNewPassword(e.target.value); }} inputProps={{ maxLength: 64 }} />
          <CustomInput label="Confirm Password" isDisabled={false} value={confirmPassword} type="password" onChange={(e:any) => { setConfirmPassword(e.target.value); }} inputProps={{ maxLength: 64 }} />
          <div style={{ marginLeft: 145 }}>
            <p>
              {
                passwordStrength < 5 ? 'Weak' : 'Strong'
              }
            </p>
            <div style={{ width: 230, height: 10, background: 'lightgray' }}>
              <div style={{ width: (passwordStrength / 16) * 230, height: '100%', background: passwordStrength < 5 ? 'red' : 'green' }} />
            </div>
          </div>
        </div>
        <div>
          <p>Password Rules</p>
          <p>
            -
            {newPassword.length > 7 && <Check style={{ marginBottom: -5 }} color="success" /> }
            Minimum 8 Characters Required
          </p>
          <p>- Must Contain at Least One Character From the Following:</p>
          <ul>
            <li>
              {newPassword.length > 0 && <Check style={{ marginBottom: -5 }} color="success" /> }
              Upper or Lower Case (ABCDEFG...XYZ or abcdefg...xyz) or Numbers (1234...890)
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
