import { makeStyles } from '@mui/styles';

export const useDialogStyles = makeStyles({
  root: {},
  dialogTitle: {
    background: '#d2d2d2',
    padding: 15,
    cursor: 'move',
  },
});
