import { ButtonBase } from '@mui/material';
import React from 'react';

// Librairies
// Styles
import { useStyles } from './style';

interface IProps {
  modifySearch: () => void;
}

const ViewTraceLogs: React.FC<IProps> = ({ modifySearch }): JSX.Element => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <ButtonBase className={classes.button} onClick={modifySearch}>Modify Search</ButtonBase>
      <div className={classes.infoSection}>
        FILES PROCESSED = 1, LINES PROCESSED = 87200, ENTRIES PROCESSED = 101, ENTRIES FOUND = 100
      </div>
    </div>
  );
};

export default ViewTraceLogs;
