import React from 'react';
import InputMask from 'react-input-mask';
import {
  Button,
  ClickAwayListener,
  Fab,
  InputAdornment,
  TextareaAutosize,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useSelector } from 'react-redux';

type inputType = {
  size?: number;
  label: string;
  type?: string;
  maxLength?: number;
  placeholder?: string;
  isDisabled: boolean;
  name?: string;
  value?: any;
  onChange?: any;
  inputProps?: any;
  error?: boolean;
  helperText?: string;
  defaultValue?: string;
  numericValue?: boolean;
  phone?: boolean;
  timeType?: boolean;
  dateType?: boolean;
  mask?: any;
  maskChar?: any;
  key?: any;
  required?: boolean;
};
const CustomRcuscreen = (props: inputType) => {
  const {
    size,
    label,
    placeholder,
    isDisabled,
    type,
    maxLength,
    name,
    value,
    onChange,
    inputProps,
    error,
    helperText,
    required,
    defaultValue,
    numericValue,
    phone,
    timeType,
    dateType,
    mask,
    maskChar,
    key,
  } = props;
  const [count, setCount] = React.useState(0);
  const [valueDate, setValueDate] = React.useState<Date | null>(null);
  const [open, setOpen] = React.useState(false);
  const [inFocus, setInFocus] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    if (required) {
      setOpen(true);
    }
  };
  const add = () => {
    setCount(count + 1);
  };
  const { activeScreen } = useSelector((state: any) => state.ScreenReducer);
  const subtract = () => count > 0 && setCount(count - 1);
  const [InputSize, setInputSize] = React.useState('');
  return (
    <div
      style={{
        width: '100%',
        alignSelf: 'center',
        marginTop: '10px',
        display: 'flex',
        alignItems: 'flex-start',
        gap: 10,
        justifyContent: 'flex-end',
      }}
    >
      <Typography>{label}</Typography>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <div
          key={key}
          style={{ display: 'flex', alignItems: 'center', width: 1000 }}
        >
          <Tooltip
            PopperProps={{
              disablePortal: true,
              color: 'red',
            }}
            onClose={handleTooltipClose}
            open={open}
            placement="top"
            arrow
            color="red"
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title="This field is required"
          >
            <TextareaAutosize
              name={name}
              value={value}
              defaultValue={defaultValue}
              onChange={(e: any) => {
                onChange(e);
                setInputSize(e.target.value);
              }}
              onFocus={() => setInFocus(true)}
              onBlur={() => setInFocus(false)}
              placeholder={placeholder}
              onClick={handleTooltipOpen}
              style={{
                width: '100%',
                minHeight: '200px',
              }}
              disabled={isDisabled}
              color={required && value === '' ? 'error' : 'primary'}
            />
          </Tooltip>
          {inFocus && inputProps?.maxLength > 0 && (
          <Typography
            style={{
              marginLeft: -48, fontSize: 12, color: 'gray', zIndex: 2,
            }}
          >
            {`${InputSize.length} / ${
              inputProps?.maxLength
            }`}

          </Typography>
          )}
        </div>
      </ClickAwayListener>
    </div>
  );
};
CustomRcuscreen.defaultProps = {
  name: null,
  value: null,
  type: null,
  inputProps: {},
  maxLength: null,
  size: null,
  error: false,
  helperText: null,
  defaultValue: null,
  numericValue: false,
  phone: false,
  timeType: false,
  dateType: false,
  placeholder: null,
  mask: null,
  maskChar: null,
  key: null,
  required: false,
  onChange: onchange,
};
export default CustomRcuscreen;
