import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { Search } from '@mui/icons-material';
import './HomeSearchInput.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { InputAdornment, Typography } from '@mui/material';
import {
  getScreensList, LoadRecord, LoadTables, SetRecentScreens,
} from '../../redux/actions/ScreenActions';
import { getLanguage } from '../../redux/actions';
import useWindowDimensions from '../../utils/windowDimensions';

type ScreensListType = {
  label: string;
  fields?: string;
  keys?: string;
  tableId?: string;
  screenId?: string;
}

const filterScreensList = createFilterOptions({
  matchFrom: 'start',
  stringify: (option: ScreensListType) => option.label,
});

const HomeSearch = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    loading, ScreensList, CleanActiveScreenCache,
  } = useSelector((state: any) => state.ScreenReducer);
  const {
    Language,
  } = useSelector((state: any) => state.LoginReducer);
  const { height, width } = useWindowDimensions();
  const [optionCount, setOptionCount] = React.useState(0);
  const [searchString, setSearchString] = React.useState<string>('');
  const [open, setOpen] = React.useState<boolean | undefined>(false);
  const filterOptions = React.useCallback((options, state) => {
    const results = filterScreensList(options, state);

    if (optionCount !== results.length) {
      setOptionCount(results.length);
    }
    return results;
  }, [optionCount]);
  React.useEffect(() => {
    dispatch(getScreensList());
  }, [dispatch]);
  React.useEffect(() => {
    dispatch(getLanguage());
  }, []);
  console.log('screeeeeeeeeeeeeens', ScreensList);
  return (
    loading ? <Typography>Loading...</Typography>
      : (
        <div style={{
          display: 'flex',
          justifyContent: 'flex-end',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        >
          <div className="inputSearch">
            <Autocomplete
              className="search-font"
              freeSolo
              disablePortal
              filterOptions={filterOptions}
              // disableClearable
              open={open}
              onInputChange={(_, value) => (value.trim().length === 0 ? setOpen(false) : setOpen(true))}
              id="free-solo-2-demo"
              placeholder="Good Afternoon. How Can I help you"
              options={ScreensList}
              onChange={async (event: any, option: any) => {
                if (option.type === 'Workflows') {
                  navigate(`${option.path}`);
                  return;
                }
                const fields = Array.from(new Set([...option.fields.split(',')])).join(',');
                dispatch(SetRecentScreens({ options: { label: option.label, screenId: option.screenId } }));
                dispatch(
                  LoadTables({
                    tableID: option.tableId,
                    page: '1',
                    results_per_page: `${Math.ceil(height / 60)}`,
                    fields: option.fields ? fields : '',
                    fieldsConcatenated: option.fieldsConcatenated,
                    order_by: '',
                    where: option.screenConfiguration || '',
                    redirect: `/maint/${option.screenId}`,
                    screenId: option.screenId,
                    keys: option.keys || '',
                    defaultcolumns: option.defaultcolumns || '',
                    lang: Language,
                    labels: option.labels || '',
                    hidden: option.hiddenColumns || '',
                    navigate,
                  }),
                );
                dispatch(LoadRecord({
                  tableID: option.tableId,
                  screenID: option.screenId,
                  fields: option.fields || '',
                  where: '',
                  lang: Language,
                }));
              }}
              sx={{ width: '70%' }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Good Afternoon. How Can I help you"
                  size="small"
                  helperText={searchString.length > 0 && `Found ${optionCount} menu entries`}
                  value={searchString}
                  onChange={(e:any) => setSearchString(e.target.value)}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <InputAdornment position="end">
                        <Search />
                      </InputAdornment>
                    ),
                    autoComplete: 'off',
                  }}
                />
              )}
            />
          </div>
        </div>
      )
  );
};
/* ScreensListType.defaultProps = {
  fields: null,
  keys: null,
  tableId: null,
  screenId: null,
}; */
export default HomeSearch;
