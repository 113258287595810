import React from 'react';

// Librairies
import { Menu, MenuItem } from '@mui/material';

// Styles
import { useStyles } from './style';

interface IProps {
  anchorEl: any;
  onClose: () => void;
}

const ProcessMenu: React.FC<IProps> = ({ anchorEl, onClose }) => {
  const classes = useStyles();
  return (
    <Menu
      classes={{ root: classes.root, list: classes.list, paper: classes.paper }}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
    >
      <MenuItem classes={{ root: classes.menuItem }}> Stop Process </MenuItem>
      <MenuItem classes={{ root: classes.menuItem }}> Stop Group </MenuItem>
    </Menu>
  );
};

export default ProcessMenu;
