import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import {
  Alert, CircularProgress, Collapse, IconButton, Snackbar, Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation, useNavigate } from 'react-router-dom';
import { Close } from '@mui/icons-material';
import Subnavbar from '../../components/Sub-navbar/Subnavbar';
import {
  CloneRecordSubmit, dissmissConfirmationAlert, LoadRecord, UpdateRecord,
} from '../../redux/actions/ScreenActions';
import TmsHeader from '../../components/header';
import CreateRecordHeader from '../../components/createMaintcomp/createRecordHeader';
import CreateRecordBody from '../../components/createMaintcomp/createRecordBody';
import { unsetChildValuesOfChain } from '../../utils';

const CreateRecord = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const {
    recordDetails, parsedValues, activeScreen, openConfirmationAlert, confirmationAlert, loading, newContractNumber,
  } = useSelector((state: any) => state.ScreenReducer);
  const [values, setValues] = useState<any>([]);
  const [open, setopen] = useState(false);
  const [openValidationErrorStartStop, setopenValidationErrorStartStop] = useState(false);
  const [openValidationErrorNext, setopenValidationErrorNext] = useState(false);
  const [openValidationErrorIncr, setopenValidationErrorIncr] = useState(false);
  const [openValidationErrorMessages, setopenValidationErrorMessages] = useState<any>(false);
  const [showOptionalFields, setShowOptionalFields] = useState(false);
  const [opencontractDate, setopencontractDate] = useState(false);
  const [Visible, setVisible] = useState(false);
  const [isModifiy, setIsModifiy] = useState(false);
  const [valuesToSend, setValuesToSend] = useState<any>([]);
  const {
    Language,
  } = useSelector((state: any) => state.LoginReducer);
  useEffect(() => {
    if (activeScreen) {
      dispatch(LoadRecord({
        tableID: activeScreen.tableID,
        screenID: activeScreen.screenId,
        where: '',
        fields: activeScreen.fields,
        lang: Language,
      }));
    } else if (!activeScreen) {
      navigate('/home');
    }
  }, [activeScreen]);
  useEffect(() => {
    setValues(parsedValues);
    setValuesToSend(parsedValues);
  }, [parsedValues]);
  const handleCloseConfirm = () => {
    setVisible(!Visible);
  };
  const handleShowOptionalFields = () => {
    setShowOptionalFields(!showOptionalFields);
  };
  const handleClose = () => {
    dispatch(dissmissConfirmationAlert());
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    setValuesToSend({
      ...valuesToSend,
      [name]: value,
    });
    setIsModifiy(true);
  };
  const handleSelectChange = (
    label:any,
    e:React.ChangeEvent<HTMLInputElement>,
    newValue:any,
    _: any,
    chainedKeys?: string[],
  ) => {
    let newValues = { ...values, [label]: newValue };
    let newValuesToSend = { ...valuesToSend, [label]: newValue.value };

    // Handle value chaining for chain type
    if (chainedKeys) {
      const { resultValues, resultValuesToSend } = unsetChildValuesOfChain(
        chainedKeys,
        newValues,
        newValuesToSend,
      );
      newValues = resultValues;
      newValuesToSend = resultValuesToSend;
    }

    setValues(newValues);
    setValuesToSend(newValuesToSend);
    setIsModifiy(true);
  };
  const handleTimeDateChange = (
    name: any,
    newValue: any,
  ) => {
    setValues({
      ...values,
      [name]: `${newValue?.slice(6, 10)}-${newValue?.slice(3, 5)}-${newValue?.slice(0, 2)} ${newValue?.slice(10, newValue?.length)}`,
    });
    setValuesToSend({
      ...valuesToSend,
      [name]: `${newValue?.slice(6, 10)}-${newValue?.slice(3, 5)}-${newValue?.slice(0, 2)} ${newValue?.slice(10, newValue?.length)}`,
    });
    setIsModifiy(Object.keys(values)?.length > 0 || Object.keys(valuesToSend)?.length > 0);
  };
  const handleLockedDate = () => {
    if (valuesToSend.Locked) {
      setValues({ ...values, LockoutDate: `${format(new Date(), 'yyyy-MM-dd kk:mm:ss')}` });
      setValuesToSend({ ...valuesToSend, LockoutDate: `${format(new Date(), 'yyyy-MM-dd kk:mm:ss')}` });
    }
  };
    // contract extention check dates
  const checkDates = () => {
    if (activeScreen.screenId === 'MAINT-173' && new Date(valuesToSend?.EffectiveDate)?.getTime() > new Date(valuesToSend?.ExpirationDate)?.getTime()) {
      setopen(true);
    } else setopen(false);
  };
    // EXTENSIONS: Number Range validation
  const checknumberRange = () => {
    if (activeScreen.screenId === 'MAINT-210' && Number(values?.StartNumber) >= Number(values?.StopNumber)) {
      setopenValidationErrorStartStop(true);
    } else {
      setopenValidationErrorStartStop(false);
    }
    if (activeScreen.screenId === 'MAINT-210' && Number(values?.IncrementNumber) >= (Number(values?.StopNumber) - Number(values?.StartNumber))) {
      setopenValidationErrorIncr(true);
    } else {
      setopenValidationErrorIncr(false);
    }
    if (activeScreen.screenId === 'MAINT-210' && (Number(values?.StartNumber) >= Number(values?.NextNumber) || Number(values?.NextNumber) >= Number(values?.StopNumber))) {
      setopenValidationErrorNext(true);
    } else {
      setopenValidationErrorNext(false);
    }
  };
  useEffect(() => {
    handleLockedDate();
  }, [values.Locked]);
  // extensions validation
  useEffect(() => {
    checkDates();
    checknumberRange();
  }, [values]);

  const handleSave = () => {
    const requiredFieldsKeys = Object.values(recordDetails).reduce((ac: any, item: any) => {
      const kk = item.reduce((acc: any, val: any) => {
        if (val.required === true) {
          return Object.assign(acc, { [val.id]: valuesToSend[val.id] });
        }
        return Object.assign(acc, {});
      }, {});
      return Object.assign(ac, kk);
    }, {});

    dispatch(
      CloneRecordSubmit({
        table: activeScreen.tableID,
        navigate,
        backScreen: activeScreen.redirect,
        form_data: activeScreen.tableID === 'Contract' ? { ...valuesToSend, ContractNumber: newContractNumber } : valuesToSend,
        modifyDep: requiredFieldsKeys,
      }),
    );
  };
  const compare = (currentValue: any) => {
    // console.log('---currentValue -- createMaintScreen====> ', currentValue);
    const result = currentValue?.obj['\u0000*\u0000parm']?.options?.filter((e: any) => (e?.value === values[currentValue?.id]));
    // console.log('---result -- createMaintScreen====> ', result);
    return result ? result[0]?.label : '';
  };
    // contract extention check dates
  useEffect(() => {
    checkDates();
  }, [valuesToSend]);

  const { _, ...restData } = recordDetails;
  return (
    Object.keys(recordDetails).length === 0 || loading ? (
      <div style={{
        width: '100%',
        height: '90vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      >
        <CircularProgress />
      </div>
    )
      : (
        <>
          <TmsHeader />
          <Subnavbar
            label
            menuOptionType="simpleItems"
            handleShowOptionalFields={handleShowOptionalFields}
            showOptionalFields={showOptionalFields}
          />
          {/* <Collapse in={open}>
        <Alert
          severity="warning"
          action={(
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          )}
          sx={{ mb: 2 }}
        >
          <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
            <Typography style={{ fontWeight: 'bold', color: 'orange' }}>Warning!</Typography>
            {' '}
            You must change the key fields before saving!
          </div>
        </Alert>
      </Collapse> */}
          <Collapse in={openValidationErrorNext || openValidationErrorIncr || openValidationErrorStartStop}>
            <Alert
              severity="error"
              action={(
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setopen(false);
                  }}
                >
                  <Close fontSize="inherit" />
                </IconButton>
          )}
              sx={{ mb: 2 }}
            >
              <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>

                <Typography style={{ color: 'black' }}>
                  {activeScreen.screenId === 'MAINT-210' && Number(values?.StartNumber) >= Number(values?.StopNumber) && 'Start number cannot be greater or equal than Stop number. '}
                  {activeScreen.screenId === 'MAINT-210' && (Number(values?.StartNumber) >= Number(values?.NextNumber) || Number(values?.NextNumber) >= Number(values?.StopNumber)) && 'Next number must be between Start and Stop numbers, '}
                  {activeScreen.screenId === 'MAINT-210' && Number(values?.IncrementNumber) >= (Number(values?.StopNumber) - Number(values?.StartNumber)) && 'Increment number can not be greater or equal than Stop number-Start number. '}

                </Typography>
              </div>
            </Alert>
          </Collapse>
          <Collapse in={activeScreen.screenId === 'MAINT-173' && new Date(valuesToSend?.EffectiveDate)?.getTime() > new Date(valuesToSend?.ExpirationDate)?.getTime()}>
            <Alert
              severity="warning"
              action={(
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setopencontractDate(false);
                  }}
                >
                  <Close fontSize="inherit" />
                </IconButton>
          )}
              sx={{ mb: 2 }}
            >
              <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
                <Typography style={{ fontWeight: 'bold', color: 'orange' }}>Warning!</Typography>
                {' '}
                Effective Date is AFTER Expiration Date
              </div>
            </Alert>
          </Collapse>
          <CreateRecordHeader
            Visible={Visible}
            options={activeScreen.redirect}
            handleCloseConfirm={handleCloseConfirm}
            dataHeader={_}
            // routeBack={routeBack}
            handleSelectChange={handleSelectChange}
            handleInputChange={handleInputChange}
            values={values}
            handleSave={handleSave}
            isModifiy={isModifiy}
            compare={compare}
            valuesToSend={valuesToSend}
            tableId={activeScreen.tableID}
            handleTimeDateChange={handleTimeDateChange}
            NumberRangeCheck={openValidationErrorNext || openValidationErrorIncr || openValidationErrorStartStop}
          />
          <CreateRecordBody
            dataTabs={restData}
            handleSelectChange={handleSelectChange}
            handleInputChange={handleInputChange}
            values={values}
            compare={compare}
            valuesToSend={valuesToSend}
            showOptionalFields={showOptionalFields}
            handleShowOptionalFields={handleShowOptionalFields}
            activeScreen={activeScreen}
            handleTimeDateChange={handleTimeDateChange}
          />
          <Snackbar open={openConfirmationAlert} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="error">
              {confirmationAlert}
            </Alert>
          </Snackbar>
        </>
    // )
      )
  );
};

export default CreateRecord;
