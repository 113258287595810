/* eslint-disable no-cond-assign */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Alert, CircularProgress, Collapse, IconButton, Snackbar, Typography,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Close } from '@mui/icons-material';
import { format } from 'date-fns';
import Subnavbar from '../../components/Sub-navbar/Subnavbar';
import {
  dissmissConfirmationAlert,
  LoadRecord,
  LoadTables,
  UpdateRecord,
} from '../../redux/actions/ScreenActions';
import FormBodyTerminal from '../../components/formBodyTermDetail/formBodyTerminal';
import FormHeaderTerminal from '../../components/FormHeaderTermDetail/FormHeaderTerminal';
import TmsHeader from '../../components/header';
import { RecordChangeHistory } from '../../components/recordChangeHistoryDialog/RecordChangeHistory';
import { UnitCalculatorDialog } from '../../components/unitCalculatorDialog/UnitCalculatorDialog';
import EmbeddedSubnavbar from '../embbededScreens/subNavEmbedded/subNavEmbedded';
import AlertUpdate from './AlertUpdate';
import { unsetChildValuesOfChain } from '../../utils';

const DetailMaintScreen = (props: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  const routeBack: any = location.state;
  const dispatch = useDispatch();
  const { reducer } = props;
  const {
    recordDetails,
    parsedValues,
    activeScreen,
    ScreensList,
    openConfirmationAlert,
    confirmationAlert,
    config,
    data,
    terminalID,
    loading,
    errorCode,
  } = useSelector((state: any) => state?.[reducer || 'ScreenReducer']);
  const [values, setValues] = useState<any>([]);
  const [valuesToSend, setValuesToSend] = useState<any>([]);
  const [open, setopen] = useState(false);
  const [openValidationErrorStartStop, setopenValidationErrorStartStop] = useState(false);
  const [openValidationErrorNext, setopenValidationErrorNext] = useState(false);
  const [openValidationErrorIncr, setopenValidationErrorIncr] = useState(false);
  const [openValidationErrorMessages, setopenValidationErrorMessages] = useState<any>(false);
  const [screenErrors, setScreenErrors] = useState<string>('');
  const [RecordList, setRecordList] = useState<any>([]);
  const [Visible, setVisible] = useState(false);
  const [VisibleConfirm, setVisibleConfirm] = useState(false);
  const [VisibleNextPrevious, setVisibleNextPrevious] = useState(false);
  const [Page, setPage] = useState(Number(activeScreen.page));
  const [recordHistoryDialogOpen, setRecordHistoryDialogOpen] = useState(false);
  const [unitCalculatorDialogOpen, setUnitCalculatorDialogOpen] = useState(false);
  const [isModifiy, setIsModifiy] = useState(false);
  const [Count, setCount] = useState(0);
  const [IschangeRecord, setIschangeRecord] = useState(false);
  const [IschangeList, setIschangeList] = useState(false);
  const [showOptionalFields, setShowOptionalFields] = useState(false);
  const whereEdit = JSON.stringify(routeBack.modifyDep);
  const { Language } = useSelector((state: any) => state.LoginReducer);
  // getting records list
  useEffect(() => {
    const recordsList = data?.data?.map((row: any, key: any) => ({
      key,
      lang: Language,
      where: activeScreen?.keys
        ? JSON.stringify(
          activeScreen?.keys
            .split(',')
            .reduce(
              (acc: any, item: any) => ({ ...acc, [item]: row[item]?.value }),
              {},
            ),
        )
        : null,
    }));
    setRecordList(recordsList);
  }, [Page]);
  const recordsList = data?.data?.map((row: any, key: any) => ({
    key,
    lang: Language,
    where: activeScreen?.keys
      ? JSON.stringify(
        activeScreen?.keys
          .split(',')
          .reduce(
            (acc: any, item: any) => ({ ...acc, [item]: row[item]?.value }),
            {},
          ),
      )
      : null,
  }));
  const HandleServiceCall = () => {
    dispatch(
      LoadTables({
        tableID: activeScreen.tableID,
        page: `${Page}`,
        results_per_page: activeScreen.results_per_page,
        fields: activeScreen.fields,
        order_by: '',
        where: activeScreen.where,
        EmptyRows: activeScreen.EmptyRows,
        redirect: activeScreen.redirect,
        search: activeScreen.search || '',
        screenId: routeBack.screenID,
        whereRecord: activeScreen.whereRecord,
        keys: activeScreen.keys,
        defaultcolumns: activeScreen.defaultcolumns,
        lang: Language,
        fieldsConcatenated: activeScreen.fieldsConcatenated,
        labels: activeScreen.labels || '',
        hidden: activeScreen.hidden || '',
      }),
    );
    dispatch(
      LoadRecord({
        tableID: routeBack.tableID,
        screenID: routeBack.screenID,
        where: recordsList[Count]?.where,
        fields: activeScreen.fields,
        lang: Language,
      }),
    );
  };
  const handleClose = () => {
    dispatch(dissmissConfirmationAlert());
  };
  const handleShowOptionalFields = () => {
    setShowOptionalFields(!showOptionalFields);
  };
  useEffect(() => {
    if (!activeScreen) {
      navigate('/home');
    } else if (activeScreen) {
      if (!IschangeRecord && !IschangeList) {
        dispatch(
          LoadRecord({
            tableID: routeBack.tableID,
            screenID: routeBack.screenID,
            where: whereEdit,
            fields: activeScreen.fields,
            lang: Language,
          }),
        );
      } else {
        HandleServiceCall();
        setIschangeList(false);
      }
    }
  }, [Count, activeScreen.page]);
  useEffect(() => {
    const recordIndex = routeBack.recordsList.findIndex(
      (a: any) => JSON.stringify(a.modifyDep) === JSON.stringify(routeBack.modifyDep),
    );
    setCount(recordIndex);
  }, []);

  useEffect(() => {
    setValues(parsedValues);
    setValuesToSend(parsedValues);
  }, [parsedValues]);

  const changeNextRecordsList = () => {
    setPage(Page + 1);
    setCount(0);
    setIschangeRecord(true);
  };
  const changePrevRecordsList = () => {
    setPage(Page - 1);
    setCount(routeBack.recordsList.length - 1);
    setIschangeRecord(true);
  };
  const nextRecord = () => {
    if (Count === routeBack.recordsList.length - 1 && Count < data.totalItems - 1) {
      setIschangeList(true);
      changeNextRecordsList();
    } else {
      setCount(Count + 1);
      setIschangeRecord(true);
    }
  };
  const previousRecord = () => {
    if (Count === 0) {
      setIschangeList(true);
      changePrevRecordsList();
    } else {
      setCount(Count - 1);
      setIschangeRecord(true);
    }
  };
  const handleCloseConfirm = () => {
    setVisible(!Visible);
  };
  const handleLoseConfirm = () => {
    setVisibleConfirm(!VisibleConfirm);
  };
  const handleMoveNextPrevious = () => {
    setVisibleNextPrevious(!VisibleNextPrevious);
  };

  // Products Receipe percenteges validation check
  const checkProductReceipe = () => {
    if (activeScreen.screenId === 'MAINT-010') {
      // Array that contains grid entries that have values on either side: e.g: 'A2', 'A3'
      const gridEntriesKeys = Object.keys(values).reduce((arr: any[], key: string) => {
        if (key?.toString()?.includes('comp_pct') || key?.toString()?.includes('comp_prod')) {
          const gridkey: string | undefined = String(key?.match(/(?<=_)(A[\d])/g));
          // recipe can have less that 7 products
          if (gridkey && (values[`comp_prod_${gridkey}`]?.value || values[`comp_pct_${gridkey}`])) {
            return Array.from(new Set<string>([...arr, gridkey]));
          }
        }
        return [...arr];
      }, []);
      let isPositiveBaseProductPercentage100 = 0;
      let isPositiveAdditivePercentage1 = 0;
      let isPositiveNoDuplicateProducts = false;
      let isPositiveNoMissingPercentages = false;
      let isPositiveNoMissingProductSelection = false;

      const selectionUncontrolled = gridEntriesKeys?.map((item: string) => {
        if (values[`comp_prod_${item}`]?.value) {
          return values[`comp_prod_${item}`]?.value;
        }
        return true;
      });
      isPositiveNoDuplicateProducts = Array.from(new Set([...selectionUncontrolled])).length === gridEntriesKeys?.length;

      gridEntriesKeys?.map((entryKey: string) => {
        // Key references a product
        if (values[`comp_prod_${entryKey}`]) {
          if (values[`comp_prod_${entryKey}`]?.value) {
            isPositiveNoMissingPercentages = !Number.isNaN(values[`comp_pct_${entryKey}`]);
          }

          isPositiveBaseProductPercentage100 = values[`comp_prod_${entryKey}`]?.type === 'prod_base'
            ? isPositiveBaseProductPercentage100 + Number(values[`comp_pct_${entryKey}`] ?? 0)
            : isPositiveBaseProductPercentage100;

          isPositiveAdditivePercentage1 = values[`comp_prod_${entryKey}`]?.type === 'prod_additive'
            ? isPositiveAdditivePercentage1 + Number(values[`comp_pct_${entryKey}`] ?? 0)
            : isPositiveAdditivePercentage1;
        }
        // Key references a percentage
        if (!Number.isNaN(values[`comp_pct_${entryKey}`])) {
          isPositiveNoMissingProductSelection = values[`comp_prod_${entryKey}`]?.value && values[`comp_prod_${entryKey}`]?.value !== '';
        }
        return true;
      });
      const errorsList: string[] = [];
      if (isPositiveBaseProductPercentage100 !== 100) {
        errorsList.push('Recipe percentage not equal to 100.0');
      }
      if (isPositiveAdditivePercentage1 > 1.0) {
        errorsList.push('Total additive percentage exceeds 1.0 percent');
      }
      if (!isPositiveNoDuplicateProducts) {
        errorsList.push('Duplicate recipe products not allowed');
      }
      if (!isPositiveNoMissingPercentages) {
        errorsList.push('One or more products missing percentage');
      }
      if (!isPositiveNoMissingProductSelection) {
        errorsList.push('Percentage defined but no product');
      }
      setScreenErrors(errorsList?.length > 0 ? errorsList?.join('. ') : '');
      setopenValidationErrorMessages(gridEntriesKeys?.length > 0 && errorsList?.length > 0);
    }
  };
  // contract extention check dates
  const checkDates = () => {
    if (activeScreen.screenId === 'MAINT-210' && valuesToSend?.EffectiveDate > valuesToSend?.ExpirationDate && valuesToSend?.EffectiveDate !== '' && valuesToSend?.ExpirationDate !== '') {
      setopen(true);
    } else setopen(false);
  };
  // EXTENSIONS: Number Range validation
  const checknumberRange = () => {
    if (activeScreen.screenId === 'MAINT-210' && Number(values?.StartNumber) >= Number(values?.StopNumber)) {
      setopenValidationErrorStartStop(true);
    } else {
      setopenValidationErrorStartStop(false);
    }
    if (activeScreen.screenId === 'MAINT-210' && Number(values?.IncrementNumber) >= (Number(values?.StopNumber) - Number(values?.StartNumber))) {
      setopenValidationErrorIncr(true);
    } else {
      setopenValidationErrorIncr(false);
    }
    if (activeScreen.screenId === 'MAINT-210' && (Number(values?.StartNumber) >= Number(values?.NextNumber) || Number(values?.NextNumber) >= Number(values?.StopNumber))) {
      setopenValidationErrorNext(true);
    } else {
      setopenValidationErrorNext(false);
    }
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    setValuesToSend({
      ...valuesToSend,
      [name]: value,
    });
    setIsModifiy(Object.keys(values)?.length > 0 || Object.keys(valuesToSend)?.length > 0);
  };
  const handleSelectChange = (
    name: any,
    e: React.ChangeEvent<HTMLInputElement>,
    newValue: any,
    label?: any,
    chainedKeys?: string[],
  ) => {
    let newValues = { ...values, [name]: newValue };
    let newValuesToSend = { ...valuesToSend, [name]: newValue.value };

    // Handle value chaining for chain type
    if (chainedKeys) {
      const { resultValues, resultValuesToSend } = unsetChildValuesOfChain(
        chainedKeys,
        newValues,
        newValuesToSend,
      );
      newValues = resultValues;
      newValuesToSend = resultValuesToSend;
    }

    setValues(newValues);
    setValuesToSend(newValuesToSend);
    setIsModifiy(Object.keys(values)?.length > 0 || Object.keys(valuesToSend)?.length > 0);
  };
  const handleTimeDateChange = (
    name: any,
    newValue: any,
  ) => {
    if (activeScreen.screenId !== 'MAINT-164') {
      setValues({
        ...values,
        [name]: `${newValue?.slice(6, 10)}-${newValue?.slice(3, 5)}-${newValue?.slice(0, 2)} ${newValue?.slice(10, newValue?.length)}`,
      });
      setValuesToSend({
        ...valuesToSend,
        [name]: `${newValue?.slice(6, 10)}-${newValue?.slice(3, 5)}-${newValue?.slice(0, 2)} ${newValue?.slice(10, newValue?.length)}`,
      });
    } else {
      // else case for folio skip days - don't slice folio date field
      setValues({
        ...values,
        [name]: newValue,
      });
      setValuesToSend({
        ...valuesToSend,
        [name]: newValue,
      });
    }

    setIsModifiy(Object.keys(values)?.length > 0 || Object.keys(valuesToSend)?.length > 0);
  };
  const handleLockedDate = () => {
    if (valuesToSend.Locked) {
      setValues({ ...values, LockoutDate: `${format(new Date(), 'yyyy-MM-dd kk:mm:ss')}` });
      setValuesToSend({ ...valuesToSend, LockoutDate: `${format(new Date(), 'yyyy-MM-dd kk:mm:ss')}` });
    }
  };
  useEffect(() => {
    checkDates();
    checknumberRange();
    checkProductReceipe();
  }, [valuesToSend, values]);
  // check the lockdate condition
  useEffect(() => {
    if (activeScreen.screenId === 'MAINT-173') {
      if (valuesToSend.LockoutDate !== '') {
        setValuesToSend({ ...valuesToSend, Locked: 'Y' });
        setValues({
          ...values,
          Locked: 'Y',
        });
      } else {
        setValuesToSend({ ...valuesToSend, Locked: 'N' });
        setValues({
          ...values,
          Locked: 'N',
        });
      }
    }
  }, [values.LockoutDate]);

  // Must keep notNavigate condition check, do not remove.
  // If navigation needs to be omitted, we pass an undefined value to navigate
  const handleSave = (notNavigate = false) => {
    if (!IschangeRecord) {
      dispatch(
        UpdateRecord({
          table: routeBack.tableID,
          navigate: notNavigate === true ? undefined : navigate,
          backScreen: routeBack.routeDetails,
          compulsory_data: [routeBack.modifyDep],
          form_data: { ...valuesToSend, ...routeBack.modifyDep },
          default_values: parsedValues,
          review: true,
          screenId: activeScreen.screenId,
        }),
      );
      setIsModifiy(false);
    } else {
      dispatch(
        UpdateRecord({
          table: routeBack.tableID,
          navigate: notNavigate === true ? undefined : navigate,
          backScreen: routeBack.routeDetails,
          compulsory_data: [routeBack.recordsList[Count].modifyDep],
          form_data: {
            ...valuesToSend,
            ...routeBack.recordsList[Count].modifyDep,
          },
        }),
      );
      setIsModifiy(false);
    }
  };
  const handleOpenRecordHistoryDialog = () => {
    setRecordHistoryDialogOpen(true);
  };

  const handleCloseRecordHistoryDialog = () => {
    setRecordHistoryDialogOpen(false);
  };

  const handleOpenUnitCalculatorDialog = () => {
    setUnitCalculatorDialogOpen(true);
  };

  const handleCloseUnitCalculatorDialog = () => {
    setUnitCalculatorDialogOpen(false);
  };
  const getScreenNameByIdAndMenuType = () => {
    const currentScreen = ScreensList.filter((e: any) => e.tableId === activeScreen.tableID)[0];
    return currentScreen?.label;
  };

  const compare = (currentValue: any) => {
    const result = currentValue?.obj['\u0000*\u0000parm']?.options?.filter(
      (e: any) => e?.value === values[currentValue?.id],
    );
    return result ? result[0]?.label : '';
  };
  console.log('valueeeeeeeeeeeeeeees', values);

  const { _, ...restData } = recordDetails;

  return recordDetails.length ? (
    <div
      style={{
        width: '100%',
        height: '90vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress />
    </div>
  ) : (
    <>
      {!reducer ? (
        <>
          <Helmet
            defer={false}
          >
            <meta charSet="utf-8" />
            <title>{`${terminalID?.[0]?.term_id?.value} : ${getScreenNameByIdAndMenuType()}: Detail`}</title>
          </Helmet>
          <TmsHeader />
          <Subnavbar
            label
            menuOptionType="simpleItems"
            openRecordHistoryDialog={handleOpenRecordHistoryDialog}
            openUnitCalculatorDialog={handleOpenUnitCalculatorDialog}
            handleShowOptionalFields={handleShowOptionalFields}
            showOptionalFields={showOptionalFields}
          />
        </>
      ) : (
        <EmbeddedSubnavbar
          label
          menuOptionType="exportItems"
          // menuOptions={FilterSaved}
          exportTable={() => {
            console.log('sdfds');
          }}
        />
      )}
      <Collapse in={open}>
        <Alert
          severity="warning"
          action={(
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setopen(false);
              }}
            >
              <Close fontSize="inherit" />
            </IconButton>
          )}
          sx={{ mb: 2 }}
        >
          <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
            <Typography style={{ fontWeight: 'bold', color: 'orange' }}>Warning!</Typography>
            {' '}
            Effective Date is AFTER Expiration Date
          </div>
        </Alert>
      </Collapse>
      {/* Products Screen percenteges validation */}
      <Collapse in={openValidationErrorMessages}>
        <Alert
          severity="error"
          action={(
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setopenValidationErrorMessages(false);
              }}
            >
              <Close fontSize="inherit" />
            </IconButton>
          )}
          sx={{ mb: 2 }}
        >
          <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
            <Typography style={{ fontWeight: 'bold', color: 'red' }}>Error!</Typography>
            {' '}
            {screenErrors}
          </div>
        </Alert>
      </Collapse>
      {/* Number range validation alert */}
      {/* <Collapse in={openValidationErrorIncr}>
        <Alert
          severity="error"
          action={(
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setopen(false);
              }}
            >
              <Close fontSize="inherit" />
            </IconButton>
          )}
          sx={{ mb: 2 }}
        >
          <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>

            <Typography style={{ color: 'black' }}>Increment number can not be greater or equal than Stop number-Start number.</Typography>

          </div>
        </Alert>
      </Collapse> */}
      {/* <Collapse in={openValidationErrorStartStop}>
        <Alert
          severity="error"
          action={(
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setopen(false);
              }}
            >
              <Close fontSize="inherit" />
            </IconButton>
          )}
          sx={{ mb: 2 }}
        >
          <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>

            <Typography style={{ color: 'black' }}>Start number cannot be greater or equal than Stop number</Typography>

          </div>
        </Alert>
      </Collapse> */}
      <Collapse in={openValidationErrorNext || openValidationErrorIncr || openValidationErrorStartStop}>
        <Alert
          severity="error"
          action={(
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setopenValidationErrorIncr(false);
                setopenValidationErrorNext(false);
                setopenValidationErrorStartStop(false);
              }}
            >
              <Close fontSize="inherit" />
            </IconButton>
          )}
          sx={{ mb: 2 }}
        >
          <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>

            <Typography style={{ color: 'black' }}>
              {activeScreen.screenId === 'MAINT-210' && Number(values?.StartNumber) >= Number(values?.StopNumber) && 'Start number cannot be greater or equal than Stop number. '}
              {activeScreen.screenId === 'MAINT-210' && (Number(values?.StartNumber) >= Number(values?.NextNumber) || Number(values?.NextNumber) >= Number(values?.StopNumber)) && 'Next number must be between Start and Stop numbers, '}
              {activeScreen.screenId === 'MAINT-210' && Number(values?.IncrementNumber) >= (Number(values?.StopNumber) - Number(values?.StartNumber)) && 'Increment number can not be greater or equal than Stop number-Start number. '}

            </Typography>
          </div>
        </Alert>
      </Collapse>
      <Collapse in={activeScreen.screenId === 'MAINT-173' && new Date(valuesToSend?.EffectiveDate)?.getTime() > new Date(valuesToSend?.ExpirationDate)?.getTime()}>
        <Alert
          severity="warning"
          action={(
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setopen(false);
              }}
            >
              <Close fontSize="inherit" />
            </IconButton>
          )}
          sx={{ mb: 2 }}
        >
          <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>

            <Typography style={{ color: 'black' }}> Effective Date is AFTER Expiration Date. </Typography>

          </div>
        </Alert>
      </Collapse>
      {/* <Collapse in={openValidationErrorNext}>
        <Alert
          severity="error"
          action={(
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setopen(false);
              }}
            >
              <Close fontSize="inherit" />
            </IconButton>
          )}
          sx={{ mb: 2 }}
        >
          <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>

            <Typography style={{ color: 'black' }}>Next number must be between Start and Stop numbers</Typography>
          </div>
        </Alert>
      </Collapse> */}
      {errorCode && <AlertUpdate errorCode={errorCode} HandleServiceCall={HandleServiceCall} />}
      <FormHeaderTerminal
        listLength={routeBack.recordsList.length}
        count={Count}
        page={Page}
        nextRecord={nextRecord}
        previousRecord={previousRecord}
        Visible={Visible}
        VisibleConfirm={VisibleConfirm}
        VisibleNextPrevious={VisibleNextPrevious}
        options={routeBack}
        handleCloseConfirm={handleCloseConfirm}
        handleLoseConfirm={handleLoseConfirm}
        handleMoveNextPrevious={handleMoveNextPrevious}
        dataHeader={_}
        routeBack={routeBack}
        handleSelectChange={handleSelectChange}
        handleInputChange={handleInputChange}
        values={values}
        handleSave={handleSave}
        isModifiy={isModifiy}
        setIsModifiy={setIsModifiy}
        compare={compare}
        valuesToSend={valuesToSend}
        totalRecords={data.totalItems}
        tableId={activeScreen?.tableID}
        isEmbedded={false}
        NumberRangeCheck={(openValidationErrorNext || openValidationErrorIncr || openValidationErrorStartStop) && activeScreen.screenId === 'MAINT-210'}
        hasScreenSpecificUnmetValidations={openValidationErrorMessages && screenErrors !== '' && activeScreen.screenId === 'MAINT-010'}
      />
      <FormBodyTerminal
        dataTabs={{ ...restData, ...config }}
        dataHeader={_}
        handleSelectChange={handleSelectChange}
        handleInputChange={handleInputChange}
        handleTimeDateChange={handleTimeDateChange}
        values={values}
        compare={compare}
        valuesToSend={valuesToSend}
        showOptionalFields={showOptionalFields}
        handleShowOptionalFields={handleShowOptionalFields}
        activeScreen={activeScreen}
        isEmbedded={false}
        alerts={openValidationErrorNext || openValidationErrorIncr || openValidationErrorStartStop || open || openValidationErrorMessages}
      />
      <RecordChangeHistory
        onClose={handleCloseRecordHistoryDialog}
        open={recordHistoryDialogOpen}
        options={routeBack}
      />
      <UnitCalculatorDialog
        onClose={handleCloseUnitCalculatorDialog}
        open={unitCalculatorDialogOpen}
      />
      {/* <ChangeRecordModal options={routeBack} /> */}
      <Snackbar
        open={openConfirmationAlert}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={confirmationAlert.includes('successfully') ? 'success' : 'error'}>
          {confirmationAlert.split(',')[0]}
        </Alert>
      </Snackbar>
    </>
    // )
  );
};

export default DetailMaintScreen;
