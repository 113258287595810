import React from 'react';

// Librairies
import { Divider, Menu, MenuItem } from '@mui/material';

// Styles
import { useStyles } from './style';

interface IProps {
  anchorEl: any;
  onClose: () => void;
  openSuspendDialog: () => void;
  openUnsuspendDialog: () => void;
  openTrafficPriority: () => void;
}

const TrafficOptions: React.FC<IProps> = ({
  anchorEl,
  onClose,
  openSuspendDialog,
  openTrafficPriority,
  openUnsuspendDialog,
}) => {
  const classes = useStyles();
  return (
    <Menu
      classes={{ root: classes.root, list: classes.list, paper: classes.paper }}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
    >
      <MenuItem
        classes={{ root: classes.menuItem }}
        onClick={openSuspendDialog}
      >
        Suspend Traffic
      </MenuItem>
      <MenuItem
        classes={{ root: classes.menuItem }}
        onClick={openUnsuspendDialog}
      >
        Unsuspend Traffic
      </MenuItem>
      <Divider />
      <MenuItem
        classes={{ root: classes.menuItem }}
        onClick={openTrafficPriority}
      >
        Traffic Priority ...
      </MenuItem>
    </Menu>
  );
};

export default TrafficOptions;
