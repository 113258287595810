import { combineReducers } from 'redux';
import LoginReducer from './loginReducer';
import ScreenReducer from './ScreenReducer';
import EmbbededScreenReducer from './embbededReducer';
import RecordReducer from './recordReducer';
import UnitCalculatorReducer from './unitCalculatorReducer';
import SystemStartupReducer from './systemStartupReducer';
import NestedEmbbededScreenReducer from './nestedEmbeddedReducer';

const rootReducer = combineReducers({
  LoginReducer,
  ScreenReducer,
  EmbbededScreenReducer,
  RecordReducer,
  UnitCalculatorReducer,
  SystemStartupReducer,
  NestedEmbbededScreenReducer,
});

export default rootReducer;
