import { Button } from '@mui/material';
import React from 'react';

type buttonprops={
    color : string,
    label : string,
    handleClick:any,
    disabled:boolean
}
const ControlButton = ({
  color, label, handleClick, disabled,
}:buttonprops) => (
  <Button
    className="f12"
    disabled={disabled}
    onClick={handleClick}
    variant="contained"
    size="small"
    sx={{
      color: '#000',
      backgroundColor: color,
      borderRadius: '0px !important',
      textTransform: 'capitalize',
    }}
  >
    {label}
  </Button>
);

export default ControlButton;
