/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

// Librairies
import { InputBase } from '@mui/material';
import { useDispatch } from 'react-redux';
import { ThreeDots } from 'react-loader-spinner';
import clsx from 'clsx';
import './react-spinner-loader.css';

// Components
import SysQueueTable from '../../../components/SysQueueTable';
import Select from '../../../components/Select';
import TmsHeader from '../../../components/header';

// Actions
import { getScreensList } from '../../../redux/actions/ScreenActions';

// Styles
import { useStyles } from './style';

const SystemUtilityQueue = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getScreensList());
  }, []);

  return (
    <>
      <TmsHeader />
      <div className="sub-nav-container">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h3 id="pagetitlebar_title" className="page-title">
            System Utility Queue
          </h3>
        </div>
        <div
          style={{
            marginRight: '10px',
          }}
        >
          <ThreeDots
            height="30"
            width="40"
            color="#205081"
            ariaLabel="loading"
          />
        </div>
      </div>
      <div
        className={clsx('flexStartSpaceBetweenRow', classes.subHeaderContainer)}
      >
        <div className={clsx('flexRow')}>
          <span className={classes.label}>Records :</span>
          <Select options={['Auto', 10, 20, 35, 50, 'All']} width={200} />
        </div>
        <div>
          <span className={classes.label}>Search :</span>
          <InputBase className={classes.searchInput} />
        </div>
      </div>
      <SysQueueTable />
    </>
  );
};

export default SystemUtilityQueue;
