import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  wrapper: {
    padding: '0px 10px',
    marginBottom: 10,
  },
  input: {
    fontWeight: '400 !important',
    fontSize: '12px !important',
    lineHeight: '1.42857143 !important',
    padding: '4px 4px',
    color: '#333333',
    backgroundColor: '#fff',
    border: '1px solid #e5e5e5',
    height: 24,
    width: 260,
  },
  helperText: {
    borderColor: '#e5e5e5',
    lineHeight: '1.42857143',
    fontWeight: 400,
    fontSize: 12,
    width: 32,
    padding: '4px 4px',
    background: '#e5e5e5',
  },
});
