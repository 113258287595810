/* eslint-disable react/require-default-props */
/* eslint-disable react/function-component-definition */
import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Subnavbar from '../../components/Sub-navbar/Subnavbar';
import TmsHeader from '../../components/header';
import CustomDropdown from '../../components/customDropdown';
import { setLanguage } from '../../redux/actions';
import ControlButton from '../../components/controlButton';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function UserSettings() {
  const [value, setValue] = React.useState(0);
  const [values, setValues] = React.useState('');
  const [isChanging, setIsChanging] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (newValue: any, e: any) => {
    setValues(e.value);
    setIsChanging(true);
  };
  const handleSubmit = () => {
    dispatch(setLanguage({ values, navigate }));
  };
  const handleCancel = () => {
    navigate('/home');
  };

  return (
    <>
      <TmsHeader />
      <Subnavbar menuOptionType="simpleItems" />
      <div
        style={{
          width: '100%',
          padding: 5,
          display: 'flex',
          gap: 10,
        }}
      >
        {/* <ControlButton
              label="Delete"
              color="red"
              disabled={false}
              handleClick={handleDelete}
            /> */}
        <ControlButton
          label="Save"
          color="#00c851"
          disabled={!isChanging}
          handleClick={handleSubmit}
        />
        <ControlButton
          label="Cancel"
          color="#ffbb33"
          disabled={false}
          handleClick={handleCancel}
        />
      </div>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Local" {...a11yProps(0)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          {/* <CustomDropdown
            label="Language"
            name="language"
            value={values}
            defaultValue={values}
            onChange={handleChange}
            size={1000}
            options={[{ label: 'English', value: 'EN' }, { label: 'Spanish', value: 'ES' }, { label: 'Frensh', value: 'FR' }, { label: 'German', value: 'DE' }, { label: 'Italian', value: 'IT' }, { label: 'Portuguese', value: 'PT' }, { label: 'Russian', value: 'RU' }, { label: 'Arabic', value: 'AR' }, { label: 'Chinese', value: 'ZH' }, { label: 'Japanese', value: 'JA' }, { label: 'Korean', value: 'KO' }, { label: 'Turkish', value: 'TR' }, { label: 'Thai', value: 'TH' }, { label: 'Vietnamese', value: 'VI' }]}
            isDisabled={false}
            type="aftx"
            stateValues={values}
          /> */}
          <Box
            sx={{
              minWidth: 1000,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 5,
            }}
          >
            <Typography>Language</Typography>
            <FormControl fullWidth>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={[
                  { label: 'English', value: 'en-US' },
                  { label: 'Spanish', value: 'es-CO' },
                  { label: 'Frensh', value: 'fr-FR' },
                  { label: 'German', value: 'de-DE' },
                  { label: 'Arabic', value: 'ar-AE' },
                  { label: 'Chinese', value: 'zh-CN' },
                ]}
                sx={{ width: 1000 }}
                onChange={handleChange}
                renderInput={(params) => <TextField {...params} />}
              />
            </FormControl>
          </Box>
        </TabPanel>
      </Box>
    </>
  );
}
