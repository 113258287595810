import React from 'react';

// Librairies
import clsx from 'clsx';

// Styles
import { useStyles } from './style';

// Utils
import { upperSection, bottomSection } from './utils';

export const KeyboardComponent: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <table className={classes.table}>
        <tbody>
          <tr className={classes.tr}>
            {upperSection.slice(0, 6).map((item) => (
              <td className={classes.td}>
                <span className={classes.keybButton}>{item}</span>
              </td>
            ))}
          </tr>
          <tr className={classes.tr}>
            {upperSection.slice(6).map((item) => (
              <td className={classes.td}>
                <span className={classes.keybButton}>{item}</span>
              </td>
            ))}
          </tr>
        </tbody>
      </table>
      <table className={classes.table}>
        <tbody>
          <tr className={classes.tr}>
            {bottomSection.slice(0, 7).map((item) => (
              <td
                className={classes.td}
                style={
                  item === 'enter'
                    ? {
                      width: '22%',
                    }
                    : undefined
                }
              >
                <span
                  className={clsx(
                    classes.keybButton,
                    item === 'enter' && classes.enterButton,
                  )}
                >
                  {item.toUpperCase()}
                </span>
              </td>
            ))}
          </tr>
          <tr className={classes.tr}>
            {bottomSection.slice(7, 14).map((item) => (
              <td className={classes.td}>
                <span className={classes.keybButton}>{item.toUpperCase()}</span>
              </td>
            ))}
          </tr>
          <tr className={classes.tr}>
            {bottomSection.slice(14, 21).map((item) => (
              <td className={classes.td}>
                <span className={classes.keybButton}>{item.toUpperCase()}</span>
              </td>
            ))}
          </tr>
          <tr className={classes.tr}>
            {bottomSection.slice(21, 28).map((item) => (
              <td className={classes.td}>
                <span className={classes.keybButton}>{item.toUpperCase()}</span>
              </td>
            ))}
          </tr>
          <tr className={classes.tr}>
            {bottomSection.slice(28).map((item) => (
              <td className={classes.td}>
                <span
                  className={clsx(
                    classes.keybButton,
                    item === 'stop' && classes.stopButton,
                  )}
                >
                  {item.toUpperCase()}
                </span>
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};
