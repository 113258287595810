import { Divider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ControlButton from '../../../components/controlButton';
import CustomDropdown from '../../../components/customDropdown';
import CustomInput from '../../../components/customInput';
import TmsHeader from '../../../components/header';
import EnhancedTable from '../../../components/TableView/TableView';
import { getScreensList } from '../../../redux/actions/ScreenActions';

const Allocations = () => {
  const [Values, setValues] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getScreensList());
  }, []);

  return (
    <>
      <TmsHeader />
      <div className="sub-nav-container">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h3 id="pagetitlebar_title" className="page-title">
            Allocations
          </h3>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          width: '100%',
          padding: '10px',
        }}
      >
        <div>
          <CustomDropdown
            type="aftx"
            value={{ label: 'Terminal', value: '' }}
            isDisabled={false}
            options={['no data to show']}
            name="AllocationType"
            label="Allocation Type"
            obj={['']}
            size={750}
          />
          <CustomDropdown
            type="aftx"
            value={{ label: 'B344 -TOPTECH (LONGWOOD, FL)', value: '' }}
            isDisabled={false}
            options={['no data to show']}
            name="termId"
            label="Terminal ID"
            obj={['']}
            size={750}
          />
        </div>
        <div>
          <CustomDropdown
            type="aftx"
            value={{ label: '-- Select --', value: '' }}
            isDisabled={false}
            options={['no data to show']}
            name="Product/GroupType"
            label="Product/Group Type"
            obj={['']}
            size={750}
          />
          <CustomInput
            type="date"
            value=""
            isDisabled={false}
            name="KeyDate"
            label="Key Date"
            size={750}
          />
        </div>
      </div>
      <Divider />
      <div style={{
        width: '98%',
        display: 'flex',
        margin: '20px',
        justifyContent: 'flex-end',
      }}
      >
        <ControlButton color="#00c851" label="Apply" handleClick={() => { console.log('Apply'); }} disabled={false} />
      </div>

      <EnhancedTable
        pages={1}
        headCells={[
          'Terminal ID',
          'Product Type',
          'Product ID',
          'Key Date',
          'Effective Date',
          'Expiration Date',
          'Use Global Warning/Deniel',
          'Warning %',
          'Deniel %',
        ]}
        rows={[]}
        tableOpt={{
          keys: 'termId, productId, productType, keyDate, effectiveDate',
          fields: 'termId, productId, productType, keyDate, effectiveDate',
          ScreenID: 'Workflow-088',
          tableId: 'allocations',
          search: '',
        }}
        routeDetails="/maint-details"
        screenID="Workflow-088"
        backScreen="/workflow/allocations"
        BatchModeScreen="/batch-mode"
      />
    </>
  );
};

export default Allocations;
