/* eslint-disable no-nested-ternary */
import { CircularProgress } from '@mui/material';
import React, { Children, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { getToken } from '../redux/actions';

const PrivateRoutes = ({ children }: { children: JSX.Element }) => {
  const { data, loading, isAuthenticated } = useSelector(
    (state: any) => state.LoginReducer,
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getToken());
  }, []);
  return loading
    ? <CircularProgress />
    : isAuthenticated === false ? (
      <Navigate to="/login" />
    ) : (
      children
    );
};
export default PrivateRoutes;
