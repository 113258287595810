import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {},
  firstGroup: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
  },
  inputGroup: {
    display: 'flex',
    gap: 5,
    marginBottom: 5,
  },
  header: {
    width: '100%',
    backgroundColor: '#eee',
    margin: '5px 0',
    padding: '5px 0',
  },
  paper: {
    margin: '0 !important',
    position: 'absolute',
    width: '99% !important',
    zIndex: '5 !important',
  },
  modalBody: {
    padding: '5px 10px 0 10px',
  },
  title: {
    padding: '0 5px 0 5px',
    fontSize: 22,
    letterSpacing: -1,
    display: 'block',
    color: '#666',
    margin: '0 0 5px 0',
    fontWeight: 100,
  },
  modalHeader: {
    backgroundColor: '#d2d2d2',
    padding: 15,
    borderBottom: '1px solid #e5e5e5',
  },
  modalTitle: {
    margin: 0,
    fontSize: 18,
    fontWeight: 400,
  },
  form: {},
  label: {
    width: '25%',
    textAlign: 'right',
    paddingTop: 5,
    paddingRight: 5,
    fontWeight: 400,
    fontSize: 12,
    lineHeight: 1.42857143,
  },
  modalActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 5,
    marginTop: 0,
    padding: 10,
    borderTop: '1px solid #e5e5e5',
  },
  applyButton: {
    color: 'black !important',
    textShadow: 'none !important',
    backgroundColor: '#00C851 !important',
    border: '1px solid transparent !important',
    padding: '6px 10px !important',
    '&:hover': {
      backgroundColor: '#007E33 !important',
      color: '#fff !important',
    },
  },
  cancleButton: {
    color: 'black !important',
    textShadow: 'none !important',
    backgroundColor: '#ffbb33 !important',
    border: '1px solid transparent !important',
    padding: '6px 10px !important',
    '&:hover': {
      backgroundColor: '#FF8800 !important',
      color: '#fff !important',
    },
  },
  removeButton: {
    color: 'black !important',
    textShadow: 'none !important',
    backgroundColor: '#CC0000 !important',
    border: '1px solid transparent !important',
    padding: '6px 10px !important',
    '&:hover': {
      backgroundColor: '#CC0000 !important',
      color: '#fff !important',
    },
  },
});
