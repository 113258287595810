/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
import axios from 'axios';
import { Navigate } from 'react-router-dom';
import { format } from 'date-fns';
import { concatenateFields, deduplicateFields, isObject } from '../../utils';
import {
  BATCH_MODE_COLUMS,
  CLOSE_CHANGE_HISTORY_MODAL,
  CLOSE_COLUMN_MODAL,
  CLOSE_FILTER_MODAL,
  COLUMNS_LIST,
  DELETE_FILTER,
  DELETE_RECORD,
  DISSMISS_CONFIRMATION_ALERT,
  DOWNLOAD_FORMAT_EXCEL,
  GET_ALL_COLUMNS,
  GET_DATA_BULK_TRANSACTION,
  GET_MAINT_SCREENS,
  LOAD_RECORD,
  LOAD_SCREEN,
  LOAD_TABS,
  LOAD_RECORD_DEFAULT_COLUMNS,
  RESET_BATCH_RECORDS_LIST,
  OPEN_CHANGE_HISTORY_MODAL,
  OPEN_COLUMN_MODAL,
  OPEN_FILTER_MODAL,
  SAVE_BATCH_MODE_QUERY,
  SAVE_FILTER,
  SET_COLUMNS,
  SET_RECENT_SCREENS,
  SET_RECORD_LIST,
  START_LOADING,
  START_LOADING_EXPORT,
  STOP_LOADING,
  STOP_LOADING_EXPORT,
  UPDATE_RECORD,
  GET_TERMINAL_NAME,
  UPDATE_VISIBLE_USER_COLUMNS,
  UPDATE_HIDDEN_USER_COLUMNS,
  UPDATE_SCREEN_CONFIGURATION,
} from '../constants/Screens';

type screenOption = {
  tableID: any;
  page?: any;
  results_per_page?: string;
  fields?: any;
  fieldsConcatenated?: string;
  where?: string;
  order_by?: string;
  screenID?: string;
  lang?: string;
  Selectedcolumns?: any;
  navigate?: any;
  redirect?: any;
  EmptyRows?: any;
  whereRecord?: any;
  screenId?: any;
  keys?: any;
  defaultcolumns?: any;
  search?: string;
  labels?: any;
  hidden?: any;
};

export const StartLoading = () => async (dispatch: any) => {
  dispatch({ type: START_LOADING });
};
export const StopLoading = () => async (dispatch: any) => {
  dispatch({ type: STOP_LOADING });
};
export const StartExporting = () => async (dispatch: any) => {
  dispatch({ type: START_LOADING_EXPORT });
};
export const StopExporting = () => async (dispatch: any) => {
  dispatch({ type: STOP_LOADING_EXPORT });
};
export const LoadTables = (options: screenOption) => async (dispatch: any) => {
  console.log('LoadTables screen options', options);
  try {
    let newContractItemNumber: any;
    let newContracNumber: any;
    const fieldsConcatenated: any = concatenateFields(
      options.fields?.split(',') || [],
      options.fieldsConcatenated || '',
    );

    const fields = await Array.from(
      new Set([...options.fields?.split(','), ...options.keys.split(',')]),
    ).join(',');

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}tablescreen/tablemaintdata/?tableID=${
        options.tableID
      }
      &page=${options.page}&results_per_page=${options.results_per_page}
    &fields=${fields}&where=${options.where}&search=${
  options.search ? options.search : ''
}&order_by=${options.order_by}&lang=${options.lang}`,
      {
        headers: {
          accept: '*/*',
        },
      },
    );
    // get the last contractItem number and add 1 for the new contractItem number
    if (['ContractItem', 'Contract']?.includes(options.tableID)) {
      const ContractItemResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}tablescreen/tablemaintdata/?tableID=${
          options.tableID
        }
          &page=1&results_per_page=${response.data.totalItems}
        &fields=ContractNumber&where=${options.where}&search=${
  options.search ? options.search : ''
}&order_by=DateAdded desc&lang=''`,
        {
          headers: {
            accept: '*/*',
          },
        },
      );
      newContractItemNumber = `${
        Number(
          ContractItemResponse.data.data?.[
            ContractItemResponse.data.data.length - 1
          ]?.ContractItemNumber?.value,
        ) + 1
      }` || '1';
      newContracNumber = `${
        Number(
          ContractItemResponse.data.data?.[0]?.ContractNumber?.value,
        ) + 1
      }` || '1';
    }
    dispatch({
      type: LOAD_SCREEN,
      payload: response.data,
      screen: options,
      newContractItemNumber: newContractItemNumber
        ? newContractItemNumber?.padStart(20, '0')
        : '',
      newContractNumber: newContracNumber?.padStart(20, '0') || '',
    });
    if (options?.navigate) {
      options.navigate(options.redirect);
    }
  } catch (error: any) {
    console.log('LoadTables error', error);
  }
};

// Update a single screen configuration when needed on the reducer.
// This is to avoid calling getScreensList unnecessarely.
export const UpdateScreenConfiguration = (screenID: string, screensList: any[], screenConfig: any) => async (dispatch: any) => {
  try {
    const screenIndex = screensList?.findIndex(
      (screen: any) => screen?.screenId === screenID,
    );
    if (!screenIndex) {
      console.log('Error getting screen index');
      return;
    }
    screensList[screenIndex] = {
      ...screensList[screenIndex],
      ...screenConfig,
    };
    console.log('newScreenConfig for', screenID, screensList[screenIndex]);

    dispatch({ type: UPDATE_SCREEN_CONFIGURATION, payload: screensList });
  } catch (error: any) {
    console.log('UpdateScreenConfiguration error', error);
  }
};

export const UpdateUserColumns = (
  screensList: any[],
  screenID: string,
  visiblefields: string,
  hiddenFields: string,
  visibleLabels: string,
  username = 'tms',
) => async (dispatch: any) => {
  try {
    await axios.post(
      `${process.env.REACT_APP_API_URL}tablescreen/updateusercolumns`,
      {
        username,
        screenID,
        tableFields: visiblefields,
      },
      {
        headers: {
          accept: '*/*',
          'Content-Type': 'application/json',
        },
      },
    );

    if (visiblefields) {
      dispatch({
        type: UPDATE_VISIBLE_USER_COLUMNS,
        payload: { visibleTableFields: visiblefields },
      });
    }

    if (hiddenFields) {
      dispatch({
        type: UPDATE_HIDDEN_USER_COLUMNS,
        payload: { hiddenTableFields: hiddenFields },
      });
    }

    UpdateScreenConfiguration(screenID, screensList, {
      fields: visiblefields,
      hiddenColumns: hiddenFields,
      labels: visibleLabels,
    });
  } catch (error: any) {
    console.log('UpdateUserColumns error', error.data);
  }
};

export const LoadAllColumnsTables = (options: screenOption) => async (dispatch: any) => {
  try {
    dispatch({ type: START_LOADING });
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}tablescreen/?tableID=${options.tableID}
      &page=${options.page}&results_per_page=${options.results_per_page}`,
      {
        headers: {
          accept: '*/*',
        },
      },
    );
    const allTableColumns = Object.keys(response.data.data[0]);
    // const AllfiltredColumns = allTableColumns.filter((item:any) => !options.Selectedcolumns.includes(item));
    dispatch({ type: GET_ALL_COLUMNS, payload: allTableColumns });
  } catch (error: any) {
    console.log(error.data);
  }
};

export const LoadTabs = (screenID: string) => async (dispatch: any) => {
  try {
    dispatch({ type: START_LOADING });
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}configscreentable`,
      {
        screenID,
      },
      {
        headers: {
          accept: '*/*',
          'Content-Type': 'application/json',
        },
      },
    );
    const embbededActiveTabs = await Object.entries(
      response.data.config.integratedScreenIDList,
    ).reduce(
      (h: any, tabs: any) => Object.assign(h, { [tabs[0]]: [tabs[1], { embbeded: true }] || [] }),
      {},
    );
    dispatch({
      type: LOAD_TABS,
      payload: embbededActiveTabs,
      embeddedTabsLabels: response.data.config?.integratedScreenNameList,
    });
  } catch (error: any) {
    console.log('LoadTabs error', error.data);
  }
};

export const UpdateRecord = (query: any, isBatchMode = false) => async (dispatch: any) => {
  console.log('Update', query);
  try {
    // Get the current date for record update date
    const d = new Date();
    const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    const mo = new Intl.DateTimeFormat('en', { month: 'numeric' }).format(d);
    const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
    // this part is for formating saved date because we are getting a diffrent format for folioSkipDays screen
    const months: any = {
      January: '01',
      February: '02',
      March: '03',
      April: '04',
      May: '05',
      June: '06',
      July: '07',
      August: '08',
      September: '09',
      October: '10',
      November: '11',
      December: '12',
    };
    let folioDay;
    let convertedFolioMonth: any = '';
    const handleFolioDateFormat = (date: any) => {
      if (
        query.screenId === 'MAINT-164'
          && date !== ''
          && date.search('-') > -1
      ) {
        folioDay = date?.split('-')?.[1];
        const folioMonth: any = date?.split('-')?.[0];
        convertedFolioMonth = months[folioMonth];
        return `${convertedFolioMonth}${folioDay}`;
      }
      if (
        query.screenId === 'MAINT-164'
          && date !== ''
          && date.search('/') > -1
      ) {
        folioDay = date?.split('/')?.[1];
        const folioMonth: any = date?.split('/')?.[0];
        convertedFolioMonth = months[folioMonth];
        return `${convertedFolioMonth}${folioDay}`;
      }
      return date?.slice(3, 5) + date?.slice(0, 2);
    };
    // we have a special case for Folio Skip Days screen the save format is diffrent from the other screens
    const FolioQuery = await Object.entries(query.form_data)
      .filter(([key, value]) => key !== '' && key.search('date') !== -1)
      .map((item: any, index: any) => [
        item[0],
        handleFolioDateFormat(item[1]),
      ]);
    const folioObjectQuery = Object.values(FolioQuery)
      .map((item: any, index: any) => item[1])
      .join('');
    dispatch({ type: START_LOADING });
    const formDataEntries = Object.fromEntries(
      Object.entries(query.form_data),
    );
    const compData = query.compulsory_data;
    const defaultValues = query.default_values;
    console.log('default valueeeees', defaultValues);

    const data = await compData?.map((element: any) => {
      const newFormData = deduplicateFields(element, formDataEntries);
      const filteredElements = Object.entries(newFormData)
        .map((entry: any) => {
          if (entry[1] === 'null') {
            return [entry[0], null];
          }
          return entry;
        })
        .reduce(
          (acc: any, curr: any) => ({ ...acc, [curr[0]]: curr[1] }),
          {},
        );
      // add modified date to form Data
      if (query.table === 'Contract') {
        filteredElements.DateLstMod = format(
          new Date(),
          'yyyy-MM-dd kk:mm:ss',
        );
        // get user who created or updated for now i only put TMS but it will be replaced once we finish the users workflows
      }

      return {
        compulsory_data: element,
        form_data:
            query?.screenId !== 'MAINT-164'
              ? filteredElements
              : { data: folioObjectQuery },
      };
    });
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}batch/modifyformdata`,
      {
        table: query.table,
        data,
        default_values: defaultValues,
      },
      {
        headers: {
          accept: '*/*',
          'Content-Type': 'application/json',
        },
      },
    );

    if (response.status !== 407) {
      dispatch({
        type: UPDATE_RECORD,
        payload: response.data,
        message: `${
          query.compulsory_data.length > 1
            ? `(${query.compulsory_data.length}) `
            : ''
        }Record modified successfully Review`,
      });
    } else {
      dispatch({
        type: UPDATE_RECORD,
        message: 'failed to modify record:Error Processing Request',
      });
    }

    // Must keep this condition check, do not remove.
    // If navigation needs to be omitted, we pass an undefined value to navigate
    if (query?.navigate) {
      query.navigate(query.backScreen);
    }
  } catch (error: any) {
    dispatch({
      type: UPDATE_RECORD,
      message: 'failed to modify record:Error Processing Request',
      errorCode: error?.response?.status,
    });
    if (!(error?.response?.status === 409) && query?.navigate) {
      query.navigate(query.backScreen);
    }
    console.log('UpdateRecord failed to modify record', error);
  }
};

export const CloneRecordSubmit = (query: any) => async (dispatch: any) => {
  const formDataEntries = Object.fromEntries(
    Object.entries(query.form_data).filter(([_, v]) => v !== null),
  );
  formDataEntries.last_upd_usr = 'chaione';
  if (query.table === 'Contract') {
    formDataEntries.DateAdded = format(new Date(), 'yyyy-MM-dd kk:mm:ss');
    // get user who created or updated for now i only put TMS but it will be replaced once we finish the users workflows
    formDataEntries.UserID = 'tms';
  }
  dispatch({ type: START_LOADING });
  fetch(`${process.env.REACT_APP_API_URL}tablescreen/insertrec`, {
    method: 'post',
    headers: { 'Content-Type': 'application/json', accept: '*/*' },
    body: JSON.stringify({
      table: query.table,
      form_data: formDataEntries,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.message === 'Form data stored successfully.') {
        // Must keep this condition check, do not remove.
        // If navigation needs to be omitted, we pass an undefined value to navigate
        if (query?.navigate) {
          query.navigate(query.backScreen);
        }
        dispatch({
          type: UPDATE_RECORD,
          payload: res,
          reviewWhereCondition: query?.modifyDep,
          message: `Record created successfully. Review :${query.modifyDep}`,
        });
      } else {
        dispatch({
          type: UPDATE_RECORD,
          payload: res,
          message: res.message || 'An error has occured!',
        });
      }
    });
};
export const LoadRecord = (options: any) => async (dispatch: any) => {
  try {
    dispatch({ type: START_LOADING });
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}tablescreen/showrecord?tableID=${options.tableID}
      &screenID=${options.screenID}&where=${options.where}&lang=${options.lang}`,
      {
        headers: {
          accept: '*/*',
        },
      },
    );

    const result = await response.data.data
      .map((item: any) => ({
        [item.id || item]: {
          default: item.default,
          id: item.id,
          label: item.label,
          helpertext: item.helpertext,
          type: item.type,
          obj: item.obj,
          query: item.query,
          selectList: item.selectList,
          chain: item.chain,
          valForm: item.value,
          attr: item.attr,
          mask: item.mask,
          required: item.attr?.includes('required'),
          gridId: item.gridId,
          validate: item.validate,
          visible: item.visible || 'true',
          childsDependency: item?.childsDependency,
        },
      }))
      .reduce((acc: any, item: any) => ({ ...acc, ...item }), {});
    const resultAll = Object.keys(response.data.fieldTabs).reduce(
      (acc: any, tab: any) => ({
        ...acc,
        [tab]: response.data.fieldTabs[tab].map((field: any) => (!field.startsWith('#') ? result[field] : { type: 'grid', id: field })),
      }),
      {},
    );

    const values = await response.data.data.reduce(
      (h: any, tabs: any) => Object.assign(h, {
        [tabs.id]:
            tabs.type === 'termid'
              ? tabs.obj['\u0000*\u0000parm'].options[0]?.value
              : tabs.value || '',
      }),
      {},
    );
    const allTypes = await new Set(
      Object.values(
        response.data.data.reduce(
          (h: any, tabs: any) => Object.assign(h, {
            [tabs.id]: tabs.type,
          }),
          {},
        ),
      ),
    );

    const activelabels = await response.data.data.reduce(
      (h: any, tabs: any) => Object.assign(h, {
        [tabs.id]: tabs.label,
      }),
      {},
    );
    if (options.screenID === 'MAINT-146') {
      activelabels['state:country'] = 'State';
      activelabels.citystate = 'City';
    }

    const activeFieldSizes = await response.data.data.reduce(
      (h: any, tabs: any) => Object.assign(h, {
        [tabs.id]: tabs.size,
      }),
      {},
    );

    const optionFields = Array.from(new Set([...options?.fields.split(',')]));
    const fields = options?.fields ? optionFields : Object.keys(activelabels);
    const activeLabelsTableView = fields.map(
      (value: any) => activelabels[value] || value,
    );
    const mappedData: any = Object.values(resultAll);
    const Ids = Object.entries(mappedData)
      .map((elt: any) => elt?.[1]?.map((element: any) => [element?.id, element?.valForm]))
      .flat();
    // const FormatedLabels = await Object.entries(activelabels)
    //   .map((elt: any) => elt[1].map((element: any) => [element.id, element.valForm]))
    //   .flat();
    // const arrayToObject = Ids.reduce((o, k) => ({ ...o, [k]: '' }), {});
    // const Ids:any = mappedData.map((v: any)=>v.map((element: any) => [element.id, element.valForm]));
    // const mapData = new Map(Ids);
    const arrayToObject = Object.fromEntries(Ids);

    const { grid } = response.data;
    Object.keys(grid).forEach((key: any) => {
      grid[key].fieldlist = grid[key].fieldlist.map((gridRowData: any) => gridRowData.map((item: any) => ({
        ...result[item],
        parentKeyName: gridRowData.filter(
          (gridRowKeys: any) => gridRowKeys !== item,
        )[0],
      })));
    });

    dispatch({
      type: LOAD_RECORD,
      payload: resultAll,
      cloneForm: arrayToObject,
      parsedValues: values,
      labels: activeLabelsTableView,
      whereDetailCondition: options.where,
      allTypes,
      allLabels: activelabels,
      allSizes: activeFieldSizes,
      grid: Object.entries(grid),
      unitCalculator: response.data?.unitCalculator,
    });
  } catch (error: any) {
    console.log('error Load Record Action:', error);
  }
};
export const ResetBatchRecodsList = () => (dispatch: any) => {
  dispatch({ type: RESET_BATCH_RECORDS_LIST });
};
export const LoadRecordSingularDefaultColumns = (options: any) => async (dispatch: any) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}tablescreen/showrecord?tableID=${options.tableID}
      &screenID=${options.screenID}&where=${options.where}&lang=${options.lang}`,
      {
        headers: {
          accept: '*/*',
        },
      },
    );
    const result = await response.data.data
      .map((item: any) => ({
        [item.id || item]: {
          id: item.id,
          value: item.value,
          label: item.label,
        },
      }))
      .reduce((acc: any, item: any) => ({ ...acc, ...item }), {});

    const resultContent = Object.values(result);
    const defaultcolumns = Object.keys(options.defaultcolumns);

    const defaultColumnsWithMappedValues = resultContent.reduce(
      (acc: any, element: any) => {
        if (!defaultcolumns.includes(element.id)) {
          return { ...acc };
        }
        return { ...acc, [element.id]: element.value };
      },
      {},
    );

    const activelabels = resultContent.reduce((acc: any, element: any) => {
      if (!defaultcolumns.includes(element.id)) {
        return [...acc];
      }
      return [...acc, element.label];
    }, []);

    dispatch({
      type: LOAD_RECORD_DEFAULT_COLUMNS,
      payload: defaultColumnsWithMappedValues,
      labels: activelabels,
    });
  } catch (error: any) {
    console.log('error LoadRecordSingularDefaultColumns Action:', error);
  }
};
export const LoadColumns = () => async (dispatch: any) => {
  try {
    dispatch({ type: START_LOADING });
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}tablescreen/
      ?tableID=TerminalProfile&page=1&results_per_page=10&order_by=name%20desc`,
      {
        headers: {
          accept: '*/*',
        },
      },
    );

    dispatch({
      type: COLUMNS_LIST,
      payload: Object.keys(response.data.data[0]),
    });
  } catch (error: any) {
    console.log(error.data);
  }
};
export const SetColumnss = (columns: string) => async (dispatch: any) => {
  try {
    dispatch({ type: SET_COLUMNS, payload: columns });
  } catch (error: any) {
    console.log(error.data);
  }
};
export const SaveFilter = (screenId: string, filter: any) => async (dispatch: any) => {
  try {
    dispatch({ type: SAVE_FILTER, payload: filter, screenId });
  } catch (error: any) {
    console.log(error.data);
  }
};

export const DeleteFilter = (screenId: string) => async (dispatch: any) => {
  try {
    dispatch({ type: DELETE_FILTER, payload: screenId });
  } catch (error: any) {
    console.log(error.data);
  }
};

export const SetBatchMode = ({
  tableOpt, selected, deleteQuery, backScreen, selectedBatch,
}: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: BATCH_MODE_COLUMS,
      payload: {
        tableOpt,
        selected,
        backScreen,
        deleteQuery,
        selectedBatch,
        defaultColumns: [],
      },
    });
  } catch (error: any) {
    console.log(error.data);
  }
};

export const exporTableToExcelCsvPdf = () => async (dispatch: any) => {
  try {
    dispatch({ type: START_LOADING });
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}export/spooldata`,
      {
        report_type: 'profile_terminal',
        export_table: 'TerminalProfile',
        file_type: 'csv',
        spool_all_columns: true,
        filters: {},
        report_data: {},
        selected_columns: {
          term_id: 'B344',
          route_cd: 'LPRNT',
          name: 'TOPTECH (LONGWOOD, FL)',
          state: 'FL',
        },
      },
      {
        headers: {
          accept: '*/*',
          // 'Content-Type': 'application/json',
        },
      },
    );

    dispatch({
      type: DOWNLOAD_FORMAT_EXCEL,
      payload: response.data,
    });
  } catch (error: any) {
    throw new Error('Error export:', error);
  }
};
export const openColumnModal = () => async (dispatch: any) => {
  try {
    dispatch({ type: OPEN_COLUMN_MODAL, payload: true });
  } catch (error: any) {
    console.log(error.data);
  }
};
export const CloseColumnModal = () => async (dispatch: any) => {
  try {
    dispatch({ type: CLOSE_COLUMN_MODAL, payload: false });
  } catch (error: any) {
    console.log(error.data);
  }
};
export const openFilterModal = () => async (dispatch: any) => {
  try {
    dispatch({ type: OPEN_FILTER_MODAL, payload: true });
  } catch (error: any) {
    console.log(error.data);
  }
};
export const CloseFilterModal = () => async (dispatch: any) => {
  try {
    dispatch({ type: CLOSE_FILTER_MODAL, payload: false });
  } catch (error: any) {
    console.log(error.data);
  }
};
export const openChangeHistoryModal = () => async (dispatch: any) => {
  try {
    dispatch({ type: OPEN_CHANGE_HISTORY_MODAL, payload: true });
  } catch (error: any) {
    console.log(error.data);
  }
};
export const closeChangeHistoryModal = () => async (dispatch: any) => {
  try {
    dispatch({ type: CLOSE_CHANGE_HISTORY_MODAL, payload: false });
  } catch (error: any) {
    console.log(error.data);
  }
};
export const saveFieldBatchMode = ({ valuesToSend, values }: any) => async (dispatch: any) => {
  try {
    await delete values.term_id;
    const batchFormat = Object.keys(valuesToSend);
    const batchFormatVal = Object.values(valuesToSend);
    const batchFormatLabels: any[] = Object.values(values);
    const armixed = batchFormat.map((x, i) => ({
      key: x,
      value: batchFormatVal[i],
    }));
    const armixedLables = batchFormat.map((x, i) => ({
      key: x,
      value: batchFormatLabels[i]?.label || batchFormatLabels[i],
    }));
    dispatch({
      type: SAVE_BATCH_MODE_QUERY,
      payload: { armixed, query: valuesToSend, values: armixedLables },
    });
  } catch (error: any) {
    console.log(error.data);
  }
};

export const DeleteRecord = ({ options, activeScreen }: any) => async (dispatch: any) => {
  try {
    dispatch({ type: START_LOADING });
    const response = await axios.delete(
      `${process.env.REACT_APP_API_URL}tablescreen/deleterec`,
      {
        params: {
          tableID: options.tableID,
          where: JSON.stringify([JSON.parse(options.where)]),
        },
        headers: {
          accept: '*/*',
        },
      },
    );
    dispatch(
      LoadTables({
        tableID: activeScreen.tableID,
        page: '1',
        results_per_page: '10',
        fields: activeScreen.fields,
        fieldsConcatenated: activeScreen.fieldsConcatenated,
        order_by: '',
        where: '',
        EmptyRows: activeScreen.EmptyRows,
        redirect: activeScreen.redirect,
        keys: activeScreen.keys,
        defaultcolumns: activeScreen.defaultcolumns,
        screenId: activeScreen.screenId,
      }),
    );
    dispatch({
      type: DELETE_RECORD,
      payload: response.data,
      message: 'Record deleted successfully',
    });
  } catch (error: any) {
    console.log(error.data);
  }
};
export const BatchDeleteRecord = ({
  options, navigate, tableId, activeScreen,
}: any) => async (dispatch: any) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_URL}tablescreen/deleterec`,
      {
        params: {
          tableID: tableId,
          where: JSON.stringify(options),
        },
        headers: {
          accept: '*/*',
        },
      },
    );
    dispatch(
      LoadTables({
        tableID: activeScreen.tableID,
        page: '1',
        results_per_page: '10',
        fields: activeScreen.fields,
        order_by: '',
        where: '',
        EmptyRows: activeScreen.EmptyRows,
        redirect: activeScreen.redirect,
        keys: activeScreen.keys,
        defaultcolumns: activeScreen.defaultcolumns,
        screenId: activeScreen.screenId,
      }),
    );
    // navigate(activeScreen.redirect);
  } catch (error: any) {
    console.log(error.data);
  }
};

// get MAINT screens list
export const getScreensList = () => async (dispatch: any) => {
  try {
    dispatch({ type: START_LOADING });

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}configscreentable/screendef?username=tms`,
      {
        headers: {
          accept: '*/*',
        },
      },
    );
    console.log('response List', response.data);
    const searchFormat = await response.data.screendef
      .map((val: any, key: any) => ({
        screenId: val.screenid,
        screenConfiguration: val.auxwhere,
        tableId: val.tableid,
        label: val.screenname,
        fields:
          Object.keys(val.columnsUser?.visibleColumn)
            .map((item) => item)
            .join(',') || '',
        fieldsConcatenated: val.tablefieldsconcat ? val.tablefieldsconcat : '',
        keys: val.keys || '',
        labels: val.columnsUser.visibleColumn,
        hiddenColumns: val.columnsUser.hiddenColumn,
        defaultTableFields:
          Object.keys(val.columns?.visibleColumn)
            .map((item) => item)
            .join(',') || '',
        defaultTableVisibleColumns: val.columns.visibleColumn,
        defaultTableHiddenColumns: val.columns.hiddenColumn,
        defaultcolumns: val.defaultcolumns || '',
        visibleList: val.visibleMenu,
      }))
      .filter((item: any) => item.visibleList === true);
    dispatch({
      type: GET_MAINT_SCREENS,
      payload: [
        ...searchFormat,
        {
          tableId: 'ChangePassword',
          label: 'Change Password',
          type: 'Workflows',
          path: '/change-password',
        },
        {
          tableId: 'RackStatus',
          label: 'Rack Status',
          type: 'Workflows',
          path: '/workflow/rack-status',
        },
        {
          tableId: 'TankGaugeEntry',
          label: 'Tank Gauge Entry',
          type: 'Workflows',
          path: '/workflow/tank-gauge-entry',
        },
        {
          tableId: 'MeterEntry',
          label: 'Meter Entry',
          type: 'Workflows',
          path: '/workflow/meter-entry',
        },
        {
          tableId: 'IO-monitor',
          label: 'I/O Monitor',
          type: 'Workflows',
          path: '/workflow/IO-monitor',
        },
        {
          tableId: 'TankStrappingTables',
          label: 'Tank Strapping Tables',
          type: 'Workflows',
          path: '/workflow/tank-strapping-tables',
        },
        {
          tableId: 'DriversInFacility',
          label: 'Drivers In Facility',
          type: 'Workflows',
          path: '/workflow/drivers-in-facility',
        },
        {
          tableId: 'FolioFreeze/Close',
          label: 'Folio Freeze/Close',
          type: 'Workflows',
          path: '/workflow/folio-freeze-close',
        },
        {
          tableId: 'MarqueeManager',
          label: 'Marquee Manager',
          type: 'Workflows',
          path: '/workflow/marquee-manager',
        },
        {
          tableId: 'FileExport',
          label: 'File Export',
          type: 'Workflows',
          path: '/workflow/file-export',
        },
        {
          tableId: 'Traffic',
          label: 'Traffic',
          type: 'Workflows',
          path: '/workflow/traffic',
        },
        {
          tableId: 'EmcsConfiguration',
          label: 'EMCS Configuration',
          type: 'Workflows',
          path: '/workflow/emcs-configuration',
        },
        {
          tableId: 'ViewTraceLogs',
          label: 'View Trace Logs',
          type: 'Workflows',
          path: '/workflow/view-trace-logs',
        },
        {
          tableId: 'ViewTotalizerData',
          label: 'View Totalizer Data',
          type: 'Workflows',
          path: '/workflow/view-totalizer-data',
        },
        {
          tableId: 'DeviceCommunications',
          label: 'Device Communications',
          type: 'Workflows',
          path: '/workflow/device-communications',
        },
        {
          tableId: 'ManagePrinters',
          label: 'Manage Printers',
          type: 'Workflows',
          path: '/workflow/manage-printers',
        },
        {
          tableId: 'changeActiveTank',
          label: 'Change active tank',
          type: 'Workflows',
          path: '/workflow/change-active-tank',
        },
        {
          tableId: 'ReportQueue',
          label: 'Report Queue',
          type: 'Workflows',
          path: '/workflow/report-queue',
        },
        {
          tableId: 'TankStatus',
          label: 'Tank Status',
          type: 'Workflows',
          path: '/workflow/tank-status',
        },
        {
          tableId: 'SystemStartups',
          label: 'System Startups',
          type: 'Workflows',
          path: '/workflow/System-startup',
        },
        {
          tableId: 'NonBulkTransaction',
          label: 'Non Bulk Transaction',
          type: 'Workflows',
          path: '/workflow/non-bulk-transacation',
        },
        {
          tableId: 'BulkTransaction',
          label: 'Bulk Transaction',
          type: 'Workflows',
          path: '/workflow/bulk-transacation',
        },
        {
          tableId: 'Groups',
          label: 'Groups',
          type: 'Workflows',
          path: '/workflow/Groups',
        },
        {
          tableId: 'OrderMaintenance',
          label: 'Order Maintenance',
          type: 'Workflows',
          path: '/workflow/order-maintenance',
        },
        {
          tableId: 'systemUtilityQueue',
          label: 'System Utility-Queue',
          type: 'Workflows',
          path: '/workflow/system-utility-queue',
        },
        {
          tableId: 'Allocations',
          label: 'Allocations',
          type: 'Workflows',
          path: '/workflow/allocations',
        },
        {
          tableId: 'RCUDitto',
          label: 'RCU Ditto',
          type: 'Workflows',
          path: '/workflow/rcu-ditto',
        },
        {
          tableId: 'auditLogs',
          label: 'Audit Logs',
          type: 'Workflows',
          path: '/workflow/audit-logs',
        },
        {
          tableId: 'VIPScullyCommunications',
          label: 'VIP Scully Communications',
          type: 'Workflows',
          path: '/workflow/vip-scully-communications',
        },
      ],
    });
  } catch (error: any) {
    console.log(error);
  }
};
export const SetRecordsList = (list: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: SET_RECORD_LIST,
      payload: list,
    });
  } catch (error: any) {
    console.log(error.data);
  }
};
export const dissmissConfirmationAlert = () => async (dispatch: any) => {
  try {
    dispatch({ type: DISSMISS_CONFIRMATION_ALERT, payload: false });
  } catch (error: any) {
    console.log(error.data);
  }
};
export const SetRecentScreens = ({ options }: any) => async (dispatch: any) => {
  try {
    console.log('recenht screens', options);
    dispatch({ type: SET_RECENT_SCREENS, payload: options });
  } catch (error: any) {
    console.log(error.data);
  }
};
// workflows screens endpoints
export const GetBulkTransactionData = () => async (dispatch: any) => {
  try {
    dispatch({ type: START_LOADING });
    const response = await axios.get(
      'https://api-dev-tf.toptechc1.com/v1/workflow004/showrecord',
      {
        params: {
          tableID: 'PresetProfile',
          screenID: 'WORKFLOW-004',
          manualTransferMode: 'bulk',
          where: "{'term_id' : 'B344', 'ld_bay':'01' }",
        },
        headers: {
          accept: '*/*',
        },
      },
    );
    console.log(response.data);
    // const values = await response.data.data.reduce(
    //   (h: any, tabs: any) => Object.assign(h, {
    //     [tabs.id]: tabs.type === 'termid' ? tabs.obj['\u0000*\u0000parm'].options[0]?.value : tabs.value || '',
    //   }),
    //   {},
    // );

    dispatch({
      type: GET_DATA_BULK_TRANSACTION,
      payload: response.data.data,
      // parsedValues: values,
    });
  } catch (error: any) {
    console.log('error Load Record Action:', error);
  }
};
// get Terminal name
export const GetTerminalName = () => async (dispatch: any) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}tablescreen/tablemaintdata/?tableID=TerminalProfile
      &page=1&results_per_page=1
    &fields=term_id`,
      {
        headers: {
          accept: '*/*',
        },
      },
    );
    dispatch({ type: GET_TERMINAL_NAME, payload: response.data.data });
  } catch (error: any) {
    console.log('GetTerminalName error', error);
  }
};
