import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  optionsButton: {
    fontSize: '12px',
    verticalAlign: 'middle',
    textAlign: 'center',
    backgroundColor: '#6086cf !important',
    color: 'white !important',
    padding: '4px 10px !important',
    '&:hover': {
      backgroundColor: '#003a93 !important',
      color: 'white !important',
    },
  },
});
