/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import FormControl from '@mui/material/FormControl';
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Checkbox, Typography } from '@mui/material';
import './style.css';
import { handleDuplication, hasNonEmptyValue, isObject } from '../../utils/index';

type selectProps = {
  label: string;
  size: any;
  options: any;
  isDisabled: boolean;
  name?: any;
  value?: any;
  defaultValue?: string;
  onChange?: any;
  isOptionEqualToValue?: any;
  helperText?: any;
  key?: any;
  type?: any;
  obj?: any;
  query?: any;
  chain?: any;
  stateValues?: any;
  selectList?: any;
  required?: any;
  isBatch?: any;
  valuesToSend?: any;
  multiple?: boolean;
  margin?: any;
  val?: any;
  datagrid?: boolean;
  isParentSetOfDatagridField?: boolean;
};

const useStyles = makeStyles({
  input: {
    '& input': {
      cursor: 'pointer !important',
    },
  },
  autoComplete: {
    cursor: 'pointer !important',
  },
});

const CustomDropdown = ({
  type,
  query,
  isBatch,
  valuesToSend,
  required,
  selectList,
  stateValues,
  label,
  val,
  size,
  options,
  isDisabled,
  name,
  value,
  helperText,
  onChange,
  isOptionEqualToValue,
  key,
  multiple,
  datagrid,
  isParentSetOfDatagridField,
}: selectProps) => {
  const [dynamicValues, setDynamicValues] = React.useState<any>([]);
  const classes = useStyles();
  console.log('labeeeeeeeeeeeeeeeeel', label);
  const [chainedKeys, setChainedkeys] = React.useState<string[]>(() => {
    if (val?.childsDependency?.length > 0) {
      let chainkeys = val?.childsDependency ?? [];
      if (val?.childsDependency?.includes(name)) {
        const keyIndex = val?.childsDependency?.indexOf(name);
        if (keyIndex !== -1) {
          chainkeys = chainkeys?.splice(keyIndex, 1);
        }
      }
      return chainkeys;
    }
    return [];
  });

  // Intermediate method that updates values for chain type child keys
  // or invoke non-chain type onChange method.
  const InvokeOnChange = (
    fieldName: string, e: any, newValue: any, fieldLabel: string, isChainType = (type === 'chain'),
  ) => {
    if (isChainType) {
      onChange(name, e, newValue, label, chainedKeys);
    } else {
      onChange(name, e, newValue, label, undefined);
    }
  };

  const [isElementChecked, setIsElementChecked] = React.useState(false);
  const [localChangesMadeDropdown, setlocalChangesMadeDropdown] = React.useState({});

  const handleLocalChanges = (
    _name: any,
    e: any,
    newValue: any,
    _label: string,
  ) => {
    if (isObject(newValue)) {
      console.log('handleLocalChanges x', newValue);
    }
    setIsElementChecked(true);
    setlocalChangesMadeDropdown({
      label: _name,
      value: newValue.value,
      class: newValue.class,
      selected: newValue.selected,
    });

    if (isBatch === 'Batch') {
      InvokeOnChange(name, e, newValue, label);
    } else {
      InvokeOnChange(name, e, newValue, label);
    }
  };

  React.useEffect(() => {
    if (['chain'].includes(type)) {
      if (selectList) {
        setDynamicValues([
          { label: '-- Select --', value: '' },
          ...selectList.map((item: any) => ({
            label: Object.values(item).join(' - '),
            value: Object.values(item)[0],
          })),
        ]);
      } else {
        // if (dynamicValues?.filter((someValue: any) => someValue?.value !== '')?.length > 0) {
        //   return;
        // }
        const whereQuery = query?.where.map(
          (item: any) => `${Object.keys(item)[0]}='${
            valuesToSend[
              (Object.values(item)[0] as string)
                .replace('[', '')
                .replace(']', '')
            ] || Object.values(item)[0]
          }'`,
        );
        const fetchQuery = `${
          process.env.REACT_APP_API_URL
        }tablescreen/tablemaintdata/?tableID=${query?.table}
        &page=1&results_per_page=10000&ShowRecordQuery=1&fields=${query?.fields?.join(
    ',',
  )}&where=${whereQuery.join(' AND ')}`;
        // Fetch was used here since each dropdown has its own internal state of options
        // which cannot be fetched using axios/redux
        fetch(fetchQuery)
          .then((res) => res.json())
          .then((res) => {
            const queryResult = res.data.map((item: any) => ({
              label: `${item[query.fields[0].replace('DISTINCT ', '')]}${item[query.fields[1]] ? ':' : ''}${' '}${item[query.fields[1]] || ''}${item?.prod_type ? `${' '}(${item?.prod_type})` : ''}`,
              value: item[query.fields[0].replace('DISTINCT ', '')],
              type: item?.prod_type?.toString(),
            }));
            if (queryResult?.length > 0) {
              setDynamicValues([
                { label: '-- Select --', value: '' },
                ...queryResult,
              ]);
            } else {
              setDynamicValues([]);
            }
          });
      }
    }
  }, [type, stateValues]);

  const backgroundColor = React.useMemo(() => {
    if (val && !val.visible) {
      return '#fff9f0';
    }
    if (isDisabled) {
      return '#f8f8f8';
    }
    return '#fff';
  }, [value, isDisabled, val]);

  // UseMemo seems not working but for slow loading issue
  // Adding a faster algorithm
  const optionsObject = React.useMemo(
    () => (type === 'chain' ? dynamicValues : options)?.reduce(
      (acc: any, item: any) => {
        acc[item.value] = { label: item.label, value: item.value };
        return acc;
      },
      [],
    ),
    [dynamicValues, options],
  );

  const enableDisableLocalChanges = () => {
    setIsElementChecked(!isElementChecked);
    // e parameter is not being used on the BatchModeTerminalProfile.tsx, so I give it undefined for now
    if (isBatch === 'Batch') {
      const existingVal = dynamicValues?.[name]
        ? dynamicValues?.[name]
        : value === '' || value === undefined
          ? { label: '-- Select --', value: '' }
          : isObject(value)
            ? value
            : type === 'weekday'
              ? value
              : optionsObject?.[value] || {
                label: `${!value ? '-- Select --' : value}`,
                value,
              };
      if (existingVal && existingVal?.value !== '') {
        const mValue = isElementChecked === false
          ? { label: name, value: undefined }
          : { label: name, value: existingVal?.value };
        InvokeOnChange(name, null, mValue, label);
      } else if (existingVal && existingVal?.value === '') {
        InvokeOnChange(
          name,
          null,
          isElementChecked === false
            ? { label: '-- Select --', value: null }
            : { label: name, value: null },
          label,
        );
      }
    }
  };

  const CheckSuitableValue = () => {
    if (type === 'aftx') {
      const valueForType = !isObject(value)
        ? options?.length > 0 && !Number.isNaN(value) && value !== undefined
          ? options?.filter((item: any) => item?.value === value)[0]
          : options.find((x: any) => x.value === value) || {
            label: '-- Select --',
            value: '',
          }
        : value;
      return valueForType;
    }

    if (['dualsel01', 'dualselYN'].includes(type)) {
      const valueForType = !isObject(value)
        ? value !== '' && value !== undefined && options?.length > 0
          ? options?.filter((item: any) => item?.value === value)[0]
          : { label: 'No', value: 'No' }
        : value;
      return valueForType;
    }

    if (type === 'chain') {
      const valueForType = !isObject(value) && (value !== '' || value !== undefined)
        ? dynamicValues?.filter((opt: any) => opt?.value !== '')?.length === 0
          ? 'No Data Available'
          : dynamicValues?.filter((opt: any) => opt?.value !== '' && opt?.value === value)?.length > 0
            ? dynamicValues?.filter((opt: any) => opt?.value !== '' && opt?.value === value)[0]
            : { label: '-- Select --', value: '' }
        : isObject(value)
          ? value?.value === 'null'
            ? { label: '-- Select --', value: '' }
            : value
          : { label: '-- Select --', value: '' };
      return valueForType;
    }

    const otherTypeValue = !isObject(value)
      ? optionsObject?.[value] || (optionsObject?.filter((opt: any) => opt?.value !== '')?.length === 0
        ? 'No Data Available'
        : optionsObject?.filter((opt: any) => opt?.value !== '')[0])
        || {
          label: `${!value ? '-- Select --' : value}`,
          value,
        }
      : value;
    return otherTypeValue;
  };

  return (
    <div
      key={key}
      style={{
        // width: '100%',
        flex: 1,
        alignSelf: 'center',
        marginTop: datagrid ? '0px' : '5px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: 10,
      }}
    >
      <div
        className="salih"
        style={
          !datagrid ? {
            flex: 1,
            flexGrow: 1,
            zIndex: 1,
            display: 'inline-flex',
            alignItems: 'center',
          } : { display: 'none' }
      }
      >
        {!datagrid && <Typography className="f12" style={{ flex: 1, textAlign: 'right' }}>{label}</Typography>}
        {!['grid'].includes(type)
          && isDisabled === false
          && isBatch === 'Batch' && (
            <Checkbox
              checked={value !== undefined}
              onClick={() => enableDisableLocalChanges()}
            />
        )}
      </div>
      <FormControl
        disabled={isDisabled}
        size="small"
        variant="outlined"
        color={isDisabled ? 'error' : 'info'}
        sx={{ flex: 3 }}
      >
        <Autocomplete
          className="autocomplete dropdownWidth"
          // disablePortal
          disableClearable
          classes={{
            root: classes.autoComplete,
            input: classes.autoComplete,
            inputRoot: classes.autoComplete,
          }}
          // Please keep this method, old code was hard to read and manage
          value={CheckSuitableValue()}
          // isOptionEqualToValue={isOptionEqualToValue}
          disabled={isDisabled}
          disableCloseOnSelect={multiple}
          id="simple-select-autocomplete"
          options={
            dynamicValues?.filter((opt: any) => opt?.value !== '')?.length > 0
              ? handleDuplication(dynamicValues)
              : options?.filter((opt: any) => opt?.value !== '')?.length > 0
                ? handleDuplication(options)
                : ['No Data Available']
          }
          onChange={(e: any, newValue: any) => (isBatch === 'Batch'
            ? handleLocalChanges(name, e, newValue, label)
            : InvokeOnChange(name, e, newValue, label))}
          sx={{ width: size || 1000, color: '#f7f7f7' }}
          getOptionLabel={(option: any) => (isObject(option) ? option.label : option)}
          multiple={multiple}
          isOptionEqualToValue={isOptionEqualToValue}
          defaultValue=""
          renderOption={(props:any, option:any, { selected }) => (
            <li {...props} key={uuidv4()}>
              <>{option.label}</>
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              className={classes.input}
              label={helperText}
              key={name}
              size="small"
              sx={{
                '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
                  padding: '2px',
                  border: '1px solid #dddddd',
                },
                '& .MuiInputBase-root': {
                  padding: ' 4px 4px !important',
                  '& > fieldset': {
                    borderColor: (required && value === '') || (datagrid && isParentSetOfDatagridField && !hasNonEmptyValue(value)) ? 'red' : 'gray',
                    color: 'gray',
                    borderLeft: required || (datagrid && isParentSetOfDatagridField && !hasNonEmptyValue(value))
                      ? '5px solid #ff3b3b'
                      : '5px solid #555',
                    borderRadius: '0px !important',
                  },
                },
                '& .MuiInputBase-root.Mui-disabled': {
                  '& > fieldset': {
                    borderLeft: required || (datagrid && isParentSetOfDatagridField && !hasNonEmptyValue(value))
                      ? '5px solid #ff3b3b'
                      : '5px solid #555',
                    borderRadius: '0px !important',
                  },
                },
                background: backgroundColor,
              }}
            />
          )}
        />
      </FormControl>
    </div>
  );
};
CustomDropdown.defaultProps = {
  name: undefined,
  isBatch: undefined,
  value: undefined,
  defaultValue: undefined,
  isOptionEqualToValue: undefined,
  helperText: undefined,
  val: undefined,
  onChange: onchange,
  key: null,
  multiple: false,
};
export default CustomDropdown;
