import React from 'react';

// Librairies
import { Menu, MenuItem, Divider } from '@mui/material';
// Components
import OptionsButton from '../optionsButton';

// Styles
import { useStyles } from './style';

interface IProps {
  anchorEl: any;
  onClose: () => void;
  openDialog: () => void;
  items: Array<string>;
  onItemClick: (itemId: number) => void;
  handleClick: (event: React.MouseEvent<HTMLElement>) => void;
}

const Options: React.FC<IProps> = ({
  anchorEl, onClose, openDialog, onItemClick, handleClick, items,
}) => {
  const classes = useStyles();
  return (
    <>
      <OptionsButton handleClick={handleClick} />
      <Menu
        classes={{ root: classes.root, list: classes.list, paper: classes.paper }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={onClose}
        className="f12"
      >
        {items.map(
          (item:string, index: number) => (!item ? <Divider /> : (
            <MenuItem
              classes={{ root: classes.menuItem }}
              onClick={() => onItemClick(index)}
            >
              {item}
            </MenuItem>
          )),
        )}
      </Menu>
    </>
  );
};

export default Options;
