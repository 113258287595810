import React from 'react';

// Libraries
import {
  ButtonBase,
  DialogActions,
  Divider,
  TextareaAutosize,
  Typography,
} from '@mui/material';
import clsx from 'clsx';

// Components
import { DialogComponent } from '../Dialog/Dialog.component';

// Styles
import { useStyles } from './style';
import Select from '../Select';

interface IProps {
  onClose: () => void;
  open: boolean;
}

const DeviceCommunicationsDialog: React.FC<IProps> = ({ onClose, open }) => {
  const classes = useStyles();
  return (
    <DialogComponent
      title="Device Communications"
      onClose={onClose}
      open={open}
    >
      <div className={clsx('flexRow', classes.wrapper)}>
        <Typography className={classes.label}>Line</Typography>
        <Select options={[]} width="75%" style={{ margin: '2px 10px' }} />
      </div>
      <div className={clsx('flexRow', classes.wrapper)}>
        <Typography className={classes.label}>Protocol</Typography>
        <Select options={[]} width="75%" style={{ margin: '2px 10px' }} />
      </div>
      <div className={clsx('flexRow', classes.wrapper)}>
        <Typography className={classes.label}>Unit</Typography>
        <Select options={[]} width="75%" style={{ margin: '2px 10px' }} />
      </div>
      <div className={clsx('flexRow', classes.wrapper)}>
        <Typography className={classes.label}>Command</Typography>
        <Select options={[]} width="75%" style={{ margin: '2px 10px' }} />
      </div>
      <Divider variant="middle" />
      <div className={clsx('flexRow', classes.wrapper)}>
        <Typography className={classes.label}>Response</Typography>
        <TextareaAutosize className={classes.textArea} />
      </div>
      <Divider variant="middle" />
      <DialogActions>
        <ButtonBase classes={{ root: classes.closeButton }}> Close </ButtonBase>
      </DialogActions>
    </DialogComponent>
  );
};

export default DeviceCommunicationsDialog;
