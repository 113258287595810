/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { useStyles } from './styles';

interface IProps {
  titleContent: any;
  bodyContent: any;
  onClick: () => void;
}

export const RcuCard: React.FC<IProps> = ({ titleContent, bodyContent, onClick }) => {
  const classes = useStyles();
  return (
    <div className={classes.root} onClick={() => onClick()}>
      <div className={classes.header}>header</div>
      <div className={classes.body}>body</div>
    </div>
  );
};
