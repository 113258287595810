export const upperSection = [1, 2, 3, 4, 5, '+/-', 6, 7, 8, 9, 0, '.'];
export const bottomSection = [
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'enter',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'prev',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  'next',
  's',
  't',
  'u',
  'v',
  '<-',
  '->',
  'exit',
  'w',
  'x',
  'y',
  'z',
  '#',
  'CTRL',
  'stop',
];
