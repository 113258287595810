import React, { useState } from 'react';

// Components
import { RcuCard } from '../../../components/RcuCard/RcuCard.component';
import { KeyboardComponent } from '../../../components/KeyboardComponent/Keyboard';
import SubHeader from '../../../components/subHeader';

// Styles
import { useStyles } from './style';
import TmsHeader from '../../../components/header';

const RCUDittoScreen: React.FC = () => {
  const [showingKeyboardIndex, setShowingKeyboardIndex] = useState<
    number | null
  >(null);
  const classes = useStyles();

  const handleOpenKeyboard = (index: number) => {
    if (String(showingKeyboardIndex)) {
      if (showingKeyboardIndex === index) {
        setShowingKeyboardIndex(null);
      } else {
        setShowingKeyboardIndex(index);
      }
    } else {
      setShowingKeyboardIndex(index);
    }
  };

  return (
    <div className={classes.root}>
      <TmsHeader />
      <SubHeader title="RCU Ditto" />
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {[1, 1, 1, 1].map((x, i) => (
          <div style={{ position: 'relative' }}>
            <RcuCard
              bodyContent={null}
              titleContent={null}
              onClick={() => handleOpenKeyboard(i)}
            />
            {showingKeyboardIndex === i ? <KeyboardComponent /> : null}
          </div>
        ))}
      </div>
    </div>
  );
};

export default RCUDittoScreen;
