import React, { useState } from 'react';

// Components
import SubHeader from '../../../components/subHeader';
import TmsHeader from '../../../components/header';
import OptionsButton from '../../../components/optionsButton';
import TrafficOptions from '../../../components/TrafficOptions';
import ConfirmationDialog from '../../../components/ConfirmDialog';
import TrafficTable from '../../../components/TrafficTable';

// Styles
import { useStyles } from './style';
import TrafficPriorityDialog from '../../../components/TrafficPriorityDialog';

const TrafficScreen: React.FC = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [suspendConfirmDialogOpen, setSuspendConfirmDialogOpen] = useState<boolean>(false);
  const [unsuspendConfirmDialogOpen, setUnsuspendConfirmDialogOpen] = useState<boolean>(false);
  const [trafficPriorityDialogOpen, setTrafficPriorityDialogOpen] = useState<boolean>(false);

  const handleOptionClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenSuspendConfirmDialog = () => {
    setSuspendConfirmDialogOpen(true);
    handleCloseMenu();
  };
  const handleCloseSuspendConfirmDialog = () => {
    setSuspendConfirmDialogOpen(false);
  };

  const handleOpenUnsuspendConfirmDialog = () => {
    setUnsuspendConfirmDialogOpen(true);
    handleCloseMenu();
  };
  const handleCloseUnsuspendConfirmDialog = () => {
    setUnsuspendConfirmDialogOpen(false);
  };

  const handleOpenTrafficPriorityDialog = () => {
    setTrafficPriorityDialogOpen(true);
    handleCloseMenu();
  };
  const handleCloseTrafficPriorityDialog = () => {
    setTrafficPriorityDialogOpen(false);
  };

  return (
    <div className={classes.root}>
      <TmsHeader />
      <SubHeader title="Traffic">
        <OptionsButton handleClick={handleOptionClick} />
        <TrafficOptions
          anchorEl={anchorEl}
          onClose={handleCloseMenu}
          openSuspendDialog={handleOpenSuspendConfirmDialog}
          openTrafficPriority={handleOpenTrafficPriorityDialog}
          openUnsuspendDialog={handleOpenUnsuspendConfirmDialog}
        />
      </SubHeader>
      <TrafficTable />
      <ConfirmationDialog
        open={suspendConfirmDialogOpen}
        handleCloseAgree={handleCloseSuspendConfirmDialog}
        message="Are you sure to suspend the traffic?"
        handleCloseCancel={handleCloseSuspendConfirmDialog}
        title="Suspend Traffic"
        cancelLabel="Cancel"
        confirmLabel="Yes"
      />
      <ConfirmationDialog
        open={unsuspendConfirmDialogOpen}
        handleCloseAgree={handleCloseUnsuspendConfirmDialog}
        message="Are you sure to unsuspend the traffic?"
        handleCloseCancel={handleCloseUnsuspendConfirmDialog}
        title="Unsuspend Traffic"
        cancelLabel="Cancel"
        confirmLabel="Yes"
      />
      <TrafficPriorityDialog
        onClose={handleCloseTrafficPriorityDialog}
        open={trafficPriorityDialogOpen}
      />
    </div>
  );
};

export default TrafficScreen;
