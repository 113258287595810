import React from 'react';
import { Close, FilterAlt } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteFilter, LoadTables } from '../../redux/actions/ScreenActions';
import CustomizedMenus from '../MenuDropdown';
import './Subnavbar.css';

type ConditionalProps =
  | {
      // label?: string | boolean | undefined;
      label?: any;
      menuOptionType?: string;
      menuOptions?: string;
      exportTable?: any;
      version?: never;
      handleShowOptionalFields?: any;
      openRecordHistoryDialog?: () => void;
      openUnitCalculatorDialog?: () => void;
      showOptionalFields?:any;
    }
  | {
      label?: never;
      menuOptionType?: never;
      menuOptions?: never;
      exportTable?: never;
      version?: string;
      openRecordHistoryDialog?: () => void;
      openUnitCalculatorDialog?: () => void;
      handleShowOptionalFields?: any;
      showOptionalFields?:any;
    };

const Subnavbar = (props: ConditionalProps) => {
  const { activeScreen, ScreensList } = useSelector(
    (state: any) => state.ScreenReducer,
  );
  const dispatch = useDispatch();
  const closeFilter = () => {
    dispatch(
      LoadTables({
        tableID: activeScreen.tableID,
        page: activeScreen.page,
        results_per_page: activeScreen.results_per_page,
        where: '',
        fields: activeScreen.fields,
        keys: activeScreen.keys,
        defaultcolumns: activeScreen.defaultcolumns,
        EmptyRows: activeScreen.EmptyRows,
        redirect: activeScreen.redirect,
        screenId: activeScreen.screenId,
        whereRecord: activeScreen.whereRecord,
        order_by: '',
        fieldsConcatenated: activeScreen.fieldsConcatenated,
        labels: activeScreen.labels || '',
      }),
    );
    dispatch(DeleteFilter(activeScreen.screenId));
  };
  const {
    label,
    version,
    menuOptionType,
    menuOptions,
    openRecordHistoryDialog,
    openUnitCalculatorDialog,
    handleShowOptionalFields,
    showOptionalFields,
  } = props;
  let screenDestructed: any = null;
  if (activeScreen) {
    screenDestructed = activeScreen.screenId;
  }

  const getScreenNameByIdAndMenuType = (id: any, menuType: any) => {
    const currentScreen = ScreensList.filter((e: any) => e.screenId === id)[0];
    let labelMenu;
    if (menuType === 'simpleItems') {
      labelMenu = `${currentScreen?.label}: Detail`;
    } else if (menuType === 'exportItems') {
      labelMenu = `${currentScreen?.label}`;
    }
    return labelMenu;
  };
  return (
    <div className="sub-nav-container">
      {label && (
        <>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <h3 id="pagetitlebar_title" className="page-title">
              {getScreenNameByIdAndMenuType(screenDestructed, menuOptionType)}
            </h3>
            {menuOptions && (
              <div
                style={{
                  width: 'auto',
                  background: '#fff',
                  color: '#0473bd',
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: 5,
                  flexWrap: 'nowrap',
                }}
              >
                <FilterAlt />
                <Typography>{menuOptions}</Typography>
                <Close onClick={closeFilter} />
              </div>
            )}
          </div>
          <CustomizedMenus
            menuOptionType={`${menuOptionType}`}
            openRecordHistoryDialog={openRecordHistoryDialog}
            handleShowOptionalFields={handleShowOptionalFields}
            openUnitCalculatorDialog={openUnitCalculatorDialog}
            showOptionalFields={showOptionalFields}
          />
        </>
      )}
      {version && <small>{version}</small>}
    </div>
  );
};

export default Subnavbar;
