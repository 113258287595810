/* eslint-disable camelcase */
import axios from 'axios';
import { format } from 'date-fns';
import { concatenateFields, deduplicateFields } from '../../utils';
import {
  GET_ALL_NESTED_EMBBEDED_COLUMNS,
  LOAD_NESTED_EMBEDDED_RECORD,
  LOAD_NESTED_EMBEDDED_SCREEN,
  SWITCH_NESTED_EMBEDDED_NAVIGATION,
} from '../constants/Screens';

type screenOption = {
  tableID: any;
  page?: any;
  results_per_page?: string;
  fields?: string;
  fieldsConcatenated?: string;
  where?: string;
  order_by?: string;
  screenID?: string;
  lang?: string;
  Selectedcolumns?: any;
  navigate?: any;
  redirect?: any;
  EmptyRows?: any;
  whereRecord?: any;
  screenId?: any;
  keys?: any;
  search?: string;
  dependentKeyEmbedded?: any;
  data?: any;
};
export const LoadNestedEmbbededTables = (options: any) => async (dispatch: any) => {
  console.log('options', options);
  let newContractItemNumber:any;
  try {
    const fieldsConcatenated = concatenateFields(
      options.fields?.split(',') || [],
      options.fieldsConcatenated || '',
    );

    const fields = await Array.from(
      new Set([...options.fieldsConcatenated?.split(','), ...options.keys.split(',')]),
    ).join(',');

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}tablescreen/tablemaintdata/?tableID=${
        options.tableID
      }
        &page=${options.page}&results_per_page=${options.results_per_page}
      &fields=${fields}&where=${options.where}&search=${
  options.search ? options.search : ''
}&order_by=${options.order_by}&lang=${options.lang}`,
      {
        headers: {
          accept: '*/*',
        },
      },
    );
    // get the last contractItem number and add 1 for the new contractItem number
    if (['ContractItem', 'Contract']?.includes(options.tableID)) {
      const ContractItemResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}tablescreen/tablemaintdata/?tableID=${
          options.tableID
        }
          &page=1&results_per_page=${response.data.totalItems}
        &fields=${fields}&where=${options.where}&search=${
  options.search ? options.search : ''
}&order_by=${options.order_by}&lang=${options.lang}`,
        {
          headers: {
            accept: '*/*',
          },
        },
      );
      newContractItemNumber = `${Number(ContractItemResponse.data.data?.[ContractItemResponse.data.data.length - 1]?.ContractItemNumber?.value) + 1}` || '1';
    }
    dispatch({
      type: LOAD_NESTED_EMBEDDED_SCREEN,
      payload: response.data,
      screen: options,
      newContractItemNumber: newContractItemNumber ? newContractItemNumber.padStart(20, '0') : '',
    });
  } catch (error: any) {
    console.log(error.data);
  }
};
export const LoadNestedRecordEmbbeded = (options: any) => async (dispatch: any) => {
  console.log('LoadRecord Options', options);
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}tablescreen/showrecord?tableID=${options.tableID}
      &screenID=${options.screenID}&where=${options.where}&lang=${options.lang}`,
      {
        headers: {
          accept: '*/*',
        },
      },
    );

    const res = await response.data.data.map((item: any) => (
      {
        [item.id || item]: {
          default: item.default,
          id: item.id,
          label: item.label,
          helpertext: item.helpertext,
          type: item.type,
          obj: item.obj,
          query: item.query,
          selectList: item.selectList,
          chain: item.chain,
          valForm: item.value,
          attr: item.attr,
          mask: item.mask,
          required: item.attr?.includes('required'),
          gridId: item.gridId,
          validate: item.validate,
          visible: item.visible || 'true',
          tab: item.tab,
          childsDependency: item?.childsDependency,
        },
      }
    ));
    // add customCondition attribute for the detail view on condition screen
    const doc2conditon = await [...res, {
      CustomCondition: {
        default: '',
        id: 'CustomCondition',
        label: 'CustomCondition',
        helpertext: 'CustomCondition',
        type: 'aftx',
        tab: 'Detail',
        obj: {
          '\u0000Select\u0000selectList': [],
          '\u0000*\u0000parm': {
            label: 'CustomCondition',
            enabled: true,
            display: true,
            iconBackground: false,
            multiple: false,
            tagmultiple: false,
            subtext: false,
            bootstrapcol: true,
            icon: '',
            class: '',
            fieldbar: true,
            forcefieldbar: false,
            showundefinedtext: true,
            dropdownheight: 10,
            nothingselected: null,
            value: '',
            livesearch: true,
            options: [
              {
                label: 'Yes',
                value: true,
                class: '',
                selected: false,
              },
              {
                label: 'No',
                value: false,
                class: '',
                selected: false,
              },
            ],
            'data-validate': '',
          },
          '\u0000*\u0000detect': null,
          '\u0000*\u0000PrefixID': '',
          '\u0000*\u0000nameID': 'CustomCondition',
          '\u0000*\u0000scrollOpts': [],
          prefixID: 'SL',
        },
        chain: [],
        valForm: '',
        attr: [],
        mask: '',
        required: false,
        gridId: null,
        validate: '',
        visible: true,
      },
    }];
    const result = await doc2conditon.reduce((acc: any, item: any) => ({ ...acc, ...item }), {});
    // add the customCondition to the tabs structure
    if (options.tableID === 'Doc2Condition') {
      response.data.fieldTabs.Detail.unshift('CustomCondition');
    }
    const resultAll = await Object.keys(response.data.fieldTabs).reduce(
      (acc: any, tab: any) => ({
        ...acc,
        [tab]: response.data.fieldTabs[tab].map((field: any) => (!field.startsWith('#') ? result[field] : { type: 'grid', id: field })),
      }),
      {},
    );
    // const resultAll = await response.data.data.reduce(
    //   (h: any, tabs: any) => Object.assign(h, {
    //     [tabs.tab]: (h[tabs.tab] || []).concat({
    //       default: tabs.default,
    //       id: tabs.id,
    //       label: tabs.label,
    //       helpertext: tabs.helpertext,
    //       type: tabs.type,
    //       obj: tabs.obj,
    //       query: tabs.query,
    //       selectList: tabs.selectList,
    //       chain: tabs.chain,
    //       valForm: tabs.value,
    //       attr: tabs.attr,
    //       mask: tabs.mask,
    //       required: tabs.attr.includes('required'),
    //       gridId: tabs.gridId,
    //       validate: tabs.validate,
    //       visible: tabs.visible,
    //     }),
    //   }),
    //   {},
    // );
    const values = await response.data.data.reduce(
      (h: any, tabs: any) => Object.assign(h, {
        [tabs.id]: tabs.value || '',
      }),
      {},
    );
    // for Doc2Condition Exention custom condition field defaultvalue should be false
    if (options.tableID === 'Doc2Condition') {
      if (values.FieldName !== '') {
        values.CustomCondition = false;
      } else {
        values.CustomCondition = true;
      }
    }
    const allTypes = await new Set(
      Object.values(
        response.data.data.reduce(
          (h: any, tabs: any) => Object.assign(h, {
            [tabs.id]: tabs.type,
          }),
          {},
        ),
      ),
    );

    const activelabels = await response.data.data.reduce(
      (h: any, tabs: any) => Object.assign(h, {
        [tabs.id]: tabs.label,
      }),
      {},
    );

    const fields = options?.fields
      ? options.fields.split(',')
      : Object.keys(activelabels);
    const activeLabelsTableView = fields.map(
      (value: any) => activelabels[value] || value,
    );
    const mappedData: any = await Object.values(resultAll);
    const Ids = Object.entries(mappedData)
      .map((elt: any) => elt?.[1]?.map((element: any) => [element?.id, element?.valForm]))
      .flat();
    // const FormatedLabels = await Object.entries(activelabels)
    //   .map((elt: any) => elt[1].map((element: any) => [element.id, element.valForm]))
    //   .flat();
    // const arrayToObject = Ids.reduce((o, k) => ({ ...o, [k]: '' }), {});
    // const Ids:any = mappedData.map((v: any)=>v.map((element: any) => [element.id, element.valForm]));
    // const mapData = new Map(Ids);
    const arrayToObject = Object.fromEntries(Ids);
    const { grid } = response.data;
    Object.keys(grid).forEach((key: any) => {
      grid[key].fieldlist = grid[key].fieldlist.map((field:any) => field.map((item:any) => result[item]));
    });

    dispatch({
      type: LOAD_NESTED_EMBEDDED_RECORD,
      payload: resultAll,
      cloneForm: arrayToObject,
      parsedValues: values,
      labels: activeLabelsTableView,
      allTypes,
      allLabels: activelabels,
      grid: Object.entries(grid),
      whereModify: options?.where,
      unitCalculator: response.data?.unitCalculator,
    });
  } catch (error: any) {
    console.log('error Load Record Action:', error);
  }
};
export const LoadAllColumnsEmbbededTables = (options: screenOption) => async (dispatch: any) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}tablescreen/?tableID=${options.tableID}
        &page=${options.page}&results_per_page=${options.results_per_page}`,
      {
        headers: {
          accept: '*/*',
        },
      },
    );
    const allTableColumns = Object.keys(response.data.data[0]);
    // const AllfiltredColumns = allTableColumns.filter((item:any) => !options.Selectedcolumns.includes(item));
    dispatch({ type: GET_ALL_NESTED_EMBBEDED_COLUMNS, payload: allTableColumns });
  } catch (error: any) {
    console.log(error.data);
  }
};
export const SwitchNestedEmbeddedNavigation = (options: string) => async (dispatch: any) => {
  try {
    dispatch({ type: SWITCH_NESTED_EMBEDDED_NAVIGATION, payload: options });
  } catch (error: any) {
    console.log(error.data);
  }
};
