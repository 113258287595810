/* eslint-disable react/require-default-props */
import React from 'react';

// Librairies
import {
  Dialog, DialogTitle, IconButton, Paper,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

// Styles
import Draggable from 'react-draggable';
import { useDialogStyles } from './dialog.styles';

interface IDialogCompoentProps {
  onClose: () => void;
  open: boolean;
  title: string;
  className?: any;
}

const PaperComponent = (props: any) => (
  <Draggable
    handle="#draggable-dialog-title"
    cancel={'[class*="MuiDialogContent-root"]'}
  >
    <Paper {...props} />
  </Draggable>
);

export const DialogComponent: React.FC<IDialogCompoentProps> = ({
  open,
  onClose,
  title,
  children,
  className,
}): JSX.Element => {
  const classes = useDialogStyles();
  return (
    <Dialog
      onClose={onClose}
      open={open}
      className={className}
      classes={{ paper: className }}
      maxWidth="md"
      fullWidth
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle
        classes={{ root: classes.dialogTitle }}
        className="flexStartSpaceBetweenRow"
        id="draggable-dialog-title"
      >
        {title}
        <IconButton size="small" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {children}
    </Dialog>
  );
};
