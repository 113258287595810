/* eslint-disable react/require-default-props */
import {
  Tab, Tabs, Typography, Box,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import EmptyTable from '../emptyTable';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  history: any,
  options: any;
}

const useStyles = makeStyles({
  tab: {
    textTransform: 'none',
  },
});

const TabPanel = (props: TabPanelProps) => {
  const {
    children, value, index, history, options, ...other
  } = props;
  const { allLabels } = useSelector((state: any) => state.ScreenReducer);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>
          <div
            style={{ display: 'flex', justifyContent: 'flex-start', margin: 10 }}
          >
            {Object.entries(options.modifyDep).map(([key, val], i) => (
              <Typography key={key} style={{ color: 'gray' }}>
                {i !== 0 && (
                <span style={{ margin: '0px 5px' }}>
                  -
                </span>
                )}
                {allLabels?.[key] || key}
                :
                {' '}
                {val}
              </Typography>
            ))}
          </div>
          <EmptyTable history={history} options={options} />
        </>
      )}
    </div>
  );
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const ChangeHistory = ({ options, history }: any) => {
  const [value, setValue] = React.useState(0);
  const classes = useStyles();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div>
      <div style={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label={options.tableID} {...a11yProps(0)} className={classes.tab} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0} history={history} options={options} />
      </div>
    </div>
  );
};

export default ChangeHistory;
