/* eslint-disable react/no-unescaped-entities */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { fontWeight } from '@mui/system';
import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem, Pagination, Select, Switch, TextField, Typography,
} from '@mui/material';
import { CheckBox } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ProgressBar from '../progressBar/ProgressBar';

const TankStatusTable = ({ rows, headCells }: any) => {
  const [Values, setValues] = React.useState<any>([]);
  const [search, setSearch] = React.useState<string>('');
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState<any>(10);
  const [currentPage, setCurrentPage] = React.useState<number>(parseInt('1', 10));
  const [searching, setSearching] = React.useState(false);
  const [FiltredRows, setFiltredRows] = React.useState([]);
  const [ShowOptionalFields, setShowOptionalFields] = React.useState(false);
  // Show optional Fields
  const switchOptionalFields = () => {
    setShowOptionalFields(!ShowOptionalFields);
  };
  // search
  const handleSearch = (searchStr:string) => {
    setSearch(searchStr);
    setSearching(true);
    const Rows:any = rows.filter((row:any) => row?.screenId?.indexOf(searchStr) > 1);
    setFiltredRows(Rows);
  };
  // row per page
  const handleChange = (e: any) => {
    console.log('test');
  };
  // handle pagination
  const handleChangePage = (event: any, newPage: number) => {
    setCurrentPage(newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const test = rows.map((val: any[]) => val);
  console.log(test[0]);
  return (
    <>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            minWidth: '30%',
            gap: 15,
            display: 'flex',
            alignItems: 'center',
            margin: '0px 10px',
          }}
        >
          <Typography>Show Optional Fields:</Typography>
          <Switch value={ShowOptionalFields} onChange={switchOptionalFields} />
        </div>
        <div style={{
          width: 'fit-content',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 4,
        }}
        >
          <TextField
            size="small"
            label="search"
            value={search}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSearch(e.target.value)}
          />
          <div style={{
            width: 'fit-content',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#bebebe',
            padding: 8,
          }}
          >
            Viewing 6 of 6
          </div>
        </div>

      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650, boxShadow: 12 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold', color: '#000' }} />
              <TableCell sx={{ fontWeight: 'bold', color: '#000' }}>
                <Typography>ATG</Typography>
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', color: '#000' }}>
                <Typography>Tank</Typography>
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', color: '#000' }}>
                <Typography>UOM</Typography>
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', color: '#000' }}>
                <Typography>Product</Typography>
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', color: '#000' }}>
                <Typography>Temperature</Typography>
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', color: '#000' }}>
                <Typography>Density</Typography>
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', color: '#000' }}>
                <Typography>Height</Typography>
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', color: '#000' }}>
                <Typography>Capacity</Typography>
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', color: '#000' }}>
                <Typography>Volume</Typography>
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', color: '#000' }}>
                <Typography>Available</Typography>
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', color: '#000' }}>
                <Typography>Product Level</Typography>
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', color: '#000' }}>
                <Typography>Water Level</Typography>
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', color: '#000' }}>
                <Typography>Percentage</Typography>
              </TableCell>
              { ShowOptionalFields && (
              <TableCell sx={{ fontWeight: 'bold', color: '#000', backgroundColor: '#c4deff' }}>
                <Typography>LO Alarm</Typography>
              </TableCell>
              ) }
              { ShowOptionalFields && (
              <TableCell sx={{ fontWeight: 'bold', color: '#000', backgroundColor: '#c4deff' }}>
                <Typography>Lo Warning</Typography>
              </TableCell>
              ) }
              { ShowOptionalFields && (
              <TableCell sx={{ fontWeight: 'bold', color: '#000', backgroundColor: '#c4deff' }}>
                <Typography>Hi Warning</Typography>
              </TableCell>
              ) }
              { ShowOptionalFields && (
              <TableCell sx={{ fontWeight: 'bold', color: '#000', backgroundColor: '#c4deff' }}>
                <Typography>Hi Alarm</Typography>
              </TableCell>
              ) }
              <TableCell sx={{ fontWeight: 'bold', color: '#000' }}>
                <Typography>Level Status</Typography>
              </TableCell>
              { ShowOptionalFields && (
              <TableCell sx={{ fontWeight: 'bold', color: '#000', backgroundColor: '#c4deff' }}>
                <Typography>Valve Status</Typography>
              </TableCell>
              ) }
            </TableRow>
          </TableHead>
          <TableBody sx={{ boxShadow: 12 }}>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item: any) => (
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align="left">
                  <Select
                    size="small"
                    variant="standard"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={rowsPerPage}
                    label="Rows"
                    onChange={handleChange}
                    style={{ width: 50 }}
                  >
                    <MenuItem className="f12" value={10}> </MenuItem>
                    <MenuItem className="f12" value={15}> </MenuItem>
                  </Select>
                </TableCell>
                <TableCell align="left">
                  On
                </TableCell>
                <TableCell align="left">
                  0001 - GEN ADD
                </TableCell>
                <TableCell align="left">
                  Gallons
                </TableCell>
                <TableCell align="left">
                  GENAD2
                </TableCell>
                <TableCell align="left">
                  60.0
                </TableCell>
                <TableCell align="left">
                  60.00
                </TableCell>
                <TableCell align="left">
                  18'00"00
                </TableCell>
                <TableCell align="left">
                  15,225
                </TableCell>
                <TableCell align="left">
                  -93,903
                </TableCell>
                <TableCell align="left">
                  -93,903
                </TableCell>
                <TableCell align="left">
                  null
                </TableCell>
                <TableCell align="left">
                  null
                </TableCell>
                <TableCell align="left">
                  <ProgressBar bgcolor="#b7ed42" completed={60} />
                </TableCell>
                { ShowOptionalFields && (
                <TableCell align="left">
                  -
                </TableCell>
                ) }
                { ShowOptionalFields && (
                <TableCell align="left">
                  -
                </TableCell>
                ) }
                { ShowOptionalFields && (
                <TableCell align="left">
                  -
                </TableCell>
                ) }
                { ShowOptionalFields && (
                <TableCell align="left">
                  -
                </TableCell>
                )}
                <TableCell align="left" sx={{ backgroundColor: '#e8e8e8' }}>
                  OK
                </TableCell>
                { ShowOptionalFields && (
                <TableCell align="left">
                  No Valve
                </TableCell>
                ) }
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
export default TankStatusTable;
