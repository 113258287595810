/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';

import { useStyles } from './style';

interface IProps {
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onClick: (param: any) => void;
    checked: boolean;
    label: string | null;
}

const CheckboxComponent: React.FC<IProps> = ({
  onChange, checked, label, onClick,
}) => {
  const classes = useStyles();
  return (
    <label className={classes.container} htmlFor="checkbox" onClick={onClick}>
      {label}
      <input type="checkbox" onChange={onChange} checked={checked} />
      <span className={classes.checkmark} />
    </label>
  );
};

export default CheckboxComponent;
