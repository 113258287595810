import { CLEAR_CALCULATIONS, SET_CALCULATIONS } from '../constants/record';

const INITIAL_STATE = {
  calculations: {
    calculate: '',
    unittarget: '',
    unitpaging: '',
    unit1000ths: '',
    unitccs: '',
  },
};

const unitCalculatorReducer = (
  state = INITIAL_STATE,
  { type, payload }: any,
) => {
  switch (type) {
    case SET_CALCULATIONS: {
      return {
        ...state,
        calculations: payload,
      };
    }
    case CLEAR_CALCULATIONS: {
      return {
        ...state,
        calculations: {
          calculate: state.calculations.calculate,
          unittarget: '',
          unitpaging: '',
          unit1000ths: '',
          unitccs: '',
        },
      };
    }
    default:
      return state;
  }
};

export default unitCalculatorReducer;
