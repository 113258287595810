/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Alert,
  CircularProgress,
  Collapse,
  IconButton,
  Snackbar,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation, useNavigate } from 'react-router-dom';
import { Close } from '@mui/icons-material';
import Subnavbar from '../../components/Sub-navbar/Subnavbar';
import {
  CloneRecordSubmit,
  dissmissConfirmationAlert,
  LoadRecord,
  UpdateRecord,
} from '../../redux/actions/ScreenActions';
import CreateRecordHeader from '../../components/createMaintcomp/createRecordHeader';
import CreateRecordBody from '../../components/createMaintcomp/createRecordBody';
import {
  LoadRecordEmbbeded,
  CloneRecordSubmitEmbedded,
} from '../../redux/actions/embbededScreensActions';
import EmbeddedSubnavbar from './subNavEmbedded/subNavEmbedded';
import { unsetChildValuesOfChain } from '../../utils';

const CreateMaintEmbeddedScreen = ({ handleEmbeddedClose }: any) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const {
    recordDetails,
    parsedValues,
    activeScreen,
    openConfirmationAlert,
    confirmationAlert,
  } = useSelector((state: any) => state.EmbbededScreenReducer);
  const {
    newContractNumber,
    newContractItemNumber,
  } = useSelector((state: any) => state.ScreenReducer);
  const [disabledFields, setDisabledFields] = useState([]);
  const [values, setValues] = useState<any>([]);
  const [open, setOpen] = useState(true);
  const [Visible, setVisible] = useState(false);
  const [isModifiy, setIsModifiy] = useState(false);
  const [valuesToSend, setValuesToSend] = useState<any>([]);
  const { Language } = useSelector((state: any) => state.LoginReducer);
  useEffect(() => {
    if (activeScreen) {
      dispatch(
        LoadRecordEmbbeded({
          tableID: activeScreen.tableID,
          screenID: activeScreen.screenId,
          where: '',
          fields: activeScreen.fields,
          lang: Language,
        }),
      );
    } else if (!activeScreen) {
      navigate('/home');
    }
  }, [activeScreen]);
  useEffect(() => {
    const parsedEmbededConfigs = JSON.parse(activeScreen.data);
    setValues(
      activeScreen?.screenId === 'MAINT-084'
        ? { ...parsedEmbededConfigs, CustomCondition: false }
        : activeScreen?.screenId === 'MAINT-174'
          ? { ...parsedEmbededConfigs, ContractItemNumber: newContractItemNumber }
          : parsedEmbededConfigs,
    );
    setValuesToSend(
      activeScreen?.screenId === 'MAINT-084'
        ? { ...parsedEmbededConfigs, CustomCondition: false }
        : activeScreen?.screenId === 'MAINT-174'
          ? { ...parsedEmbededConfigs, ContractItemNumber: newContractItemNumber }
          : parsedEmbededConfigs,
    );
    setDisabledFields(valuesToSend);
  }, [parsedValues]);

  const handleCloseConfirm = () => {
    setVisible(!Visible);
  };
  const handleClose = () => {
    dispatch(dissmissConfirmationAlert());
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    setValuesToSend({
      ...valuesToSend,
      [name]: value,
    });
    setIsModifiy(true);
  };
  const handleTimeDateChange = (
    name: any,
    newValue: any,
  ) => {
    setValues({
      ...values,
      [name]: newValue,
    });
    setValuesToSend({
      ...valuesToSend,
      [name]: newValue,
    });
    setIsModifiy(Object.keys(values)?.length > 0 || Object.keys(valuesToSend)?.length > 0);
  };
  const handleSelectChange = (
    label: any,
    e: React.ChangeEvent<HTMLInputElement>,
    newValue: any,
    _: any,
    chainedKeys?: string[],
  ) => {
    let newValues = { ...values, [label]: newValue };
    let newValuesToSend = { ...valuesToSend, [label]: newValue.value };

    // Handle value chaining for chain type
    if (chainedKeys) {
      const { resultValues, resultValuesToSend } = unsetChildValuesOfChain(
        chainedKeys,
        newValues,
        newValuesToSend,
      );
      newValues = resultValues;
      newValuesToSend = resultValuesToSend;
    }

    setValues(newValues);
    setValuesToSend(newValuesToSend);
    setIsModifiy(true);
  };
  const handleSave = () => {
    dispatch(
      CloneRecordSubmitEmbedded({
        table: activeScreen.tableID,
        navigate,
        backScreen: activeScreen.redirect,
        form_data: valuesToSend,
      }),
    );
  };
    // check custom condition for doc2condtion
  const customConditionReset = () => {
    if (activeScreen.screenId === 'MAINT-084' && values.CustomCondition?.value && Object.values(values).length > 0) {
      setValuesToSend({ ...valuesToSend, FieldName: '', TableName: '' });
      setValues({ ...values, FieldName: '', TableName: '' });
    } else if (activeScreen.screenId === 'MAINT-084' && !values.CustomCondition?.value && Object.values(values).length > 0) {
      setValuesToSend({ ...valuesToSend, Custom: '' });
      setValues({ ...values, Custom: '' });
    }
  };
  useEffect(() => {
    customConditionReset();
  }, [values?.CustomCondition?.value, values?.Custom, values?.TableName, values?.FieldName]);
  // check if the CustomCondition is true or false
  useEffect(() => {
    if (activeScreen.screenId === 'MAINT-084' && values.FieldName !== '' && Object.values(values).length > 0) {
      setValuesToSend({ ...valuesToSend, CustomCondition: false });
      setValues({
        ...values,
        CustomCondition:
          {
            label: 'No', value: false, class: '', selected: false,
          },
      });
    } else if (activeScreen.screenId === 'MAINT-084' && values.FieldName === '' && Object.values(values).length > 0) {
      setValuesToSend({ ...valuesToSend, CustomCondition: true });
      setValues({
        ...values,
        CustomCondition:
          {
            label: 'Yes', value: true, class: '', selected: true,
          },
      });
    }
  }, []);

  // update the UOM for contract Item screen
  useEffect(() => {
    if (activeScreen.screenId === 'MAINT-174') {
      setValues({ ...valuesToSend, UOMDisplay: values?.UOMStored });
      setValuesToSend({ ...valuesToSend, UOMDisplay: valuesToSend?.UOMStored });
    }
  }, [values?.UOMStored]);
  const compare = (currentValue: any) => {
    const result = currentValue?.obj['\u0000*\u0000parm']?.options?.filter(
      (e: any) => e?.value === values[currentValue?.id],
    );
    return result ? result[0]?.label : '';
  };

  const { _, ...restData } = recordDetails;
  return Object.keys(recordDetails).length === 0 ? (
    <div
      style={{
        width: '100%',
        height: '90vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress />
    </div>
  ) : (
    <>
      <EmbeddedSubnavbar
        label
        menuOptionType="simpleItems"
        // menuOptions={FilterSaved}
        handleEmbeddedClose={handleEmbeddedClose}
        exportTable={() => {
          console.log('sdfds');
        }}
      />
      {/* <Collapse in={open}>
        <Alert
          severity="warning"
          action={(
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          )}
          sx={{ mb: 2 }}
        >
          <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
            <Typography style={{ fontWeight: 'bold', color: 'orange' }}>Warning!</Typography>
            {' '}
            You must change the key fields before saving!
          </div>
        </Alert>
      </Collapse> */}
      <Collapse in={new Date(values?.EffectiveDate)?.getTime() > new Date(values?.ExpirationDate)?.getTime()}>
        <Alert
          severity="warning"
          action={(
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
            >
              <Close fontSize="inherit" />
            </IconButton>
          )}
          sx={{ mb: 2 }}
        >
          <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>

            <Typography style={{ color: 'black' }}>Effective Date is AFTER Expiration Date</Typography>

          </div>
        </Alert>
      </Collapse>
      <CreateRecordHeader
        embedded
        Visible={Visible}
        options={activeScreen.redirect}
        handleCloseConfirm={handleCloseConfirm}
        dataHeader={_}
        // routeBack={routeBack}
        handleSelectChange={handleSelectChange}
        handleInputChange={handleInputChange}
        values={values}
        handleSave={handleSave}
        isModifiy={isModifiy}
        compare={compare}
        valuesToSend={valuesToSend}
        disabledFields={disabledFields}
        handleTimeDateChange={handleTimeDateChange}
        isEmbedded
      />
      <CreateRecordBody
        dataTabs={restData}
        handleSelectChange={handleSelectChange}
        handleInputChange={handleInputChange}
        handleTimeDateChange={handleTimeDateChange}
        values={values}
        compare={compare}
        valuesToSend={valuesToSend}
        isEmbedded
      />
      <Snackbar
        open={openConfirmationAlert}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          {confirmationAlert}
        </Alert>
      </Snackbar>
    </>
    // )
  );
};

export default CreateMaintEmbeddedScreen;
