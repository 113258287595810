/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { fontWeight } from '@mui/system';
import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem, Pagination, Select, TextField, Typography,
} from '@mui/material';
import { CheckBox } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const GroupsDetailTable = ({ rows, headCells }: any) => {
  const [Values, setValues] = React.useState<any>([]);
  const [search, setSearch] = React.useState<string>('');
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState<any>(10);
  const [currentPage, setCurrentPage] = React.useState<number>(parseInt('1', 10));
  const [searching, setSearching] = React.useState(false);
  const [FiltredRows, setFiltredRows] = React.useState([]);
  // search
  const handleSearch = (searchStr:string) => {
    setSearch(searchStr);
    setSearching(true);
    const Rows:any = rows.filter((row:any) => row?.screenId?.indexOf(searchStr) > 1);
    setFiltredRows(Rows);
  };
  // row per page
  const handleChange = (e: any) => {
    console.log('test');
  };
  // handle pagination
  const handleChangePage = (event: any, newPage: number) => {
    setCurrentPage(newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const test = rows.map((val: any[]) => val);
  console.log(test[0]);
  return (
    <>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            minWidth: '30%',
            gap: 15,
            display: 'flex',
            alignItems: 'center',
            margin: '0px 10px',
          }}
        >
          <Typography className="f12">Items per page:</Typography>
          <Select
            size="small"
            variant="standard"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={rowsPerPage}
            label="Rows"
            onChange={handleChangeRowsPerPage}
            style={{ width: 70 }}
          >
            <MenuItem className="f12" value={10}>auto</MenuItem>
            <MenuItem className="f12" value={15}>15</MenuItem>
            <MenuItem className="f12" value={20}>20</MenuItem>
            <MenuItem className="f12" value={25}>25</MenuItem>
            <MenuItem className="f12" value={35}>35</MenuItem>
            <MenuItem className="f12" value={50}>50</MenuItem>
            <MenuItem className="f12" value={rows?.length || 100}>all</MenuItem>
          </Select>
        </div>
        <TextField
          size="small"
          label="search"
          value={search}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSearch(e.target.value)}
        />
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650, boxShadow: 12 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold', color: '#000' }}>
                Screen ID / Description
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', color: '#000' }} />
              <TableCell sx={{ fontWeight: 'bold', color: '#000' }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Create</InputLabel>
                  <Select
                    size="small"
                    variant="standard"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Create"
                    onChange={handleChange}
                    style={{ width: 130 }}
                  >
                    <MenuItem value={10}>Select All</MenuItem>
                    <MenuItem value={15}>De-select All</MenuItem>
                  </Select>
                </FormControl>
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', color: '#000' }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Read</InputLabel>
                  <Select
                    size="small"
                    variant="standard"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Read"
                    onChange={handleChange}
                    style={{ width: 130 }}
                  >
                    <MenuItem value={10}>Select All</MenuItem>
                    <MenuItem value={15}>De-select All</MenuItem>
                  </Select>
                </FormControl>
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', color: '#000' }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Update</InputLabel>
                  <Select
                    size="small"
                    variant="standard"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Update"
                    onChange={handleChange}
                    style={{ width: 130 }}
                  >
                    <MenuItem value={10}>Select All</MenuItem>
                    <MenuItem value={15}>De-select All</MenuItem>
                  </Select>
                </FormControl>
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', color: '#000' }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Delete</InputLabel>
                  <Select
                    size="small"
                    variant="standard"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Delete"
                    onChange={handleChange}
                    style={{ width: 130 }}
                  >
                    <MenuItem value={10}>Select All</MenuItem>
                    <MenuItem value={15}>De-select All</MenuItem>
                  </Select>
                </FormControl>
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', color: '#000' }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">eXecute</InputLabel>
                  <Select
                    size="small"
                    variant="standard"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="eXecute"
                    onChange={handleChange}
                    style={{ width: 130 }}
                  >
                    <MenuItem value={10}>Select All</MenuItem>
                    <MenuItem value={15}>De-select All</MenuItem>
                  </Select>
                </FormControl>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ boxShadow: 12 }}>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item: any) => (
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align="left">
                  {`${item.screenId} - ${item.label}`}
                </TableCell>
                <TableCell align="left">
                  <Select
                    size="small"
                    variant="standard"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={rowsPerPage}
                    label="Rows"
                    onChange={handleChange}
                    style={{ width: 130 }}
                  >
                    <MenuItem value={10}>Select All</MenuItem>
                    <MenuItem value={15}>De-select All</MenuItem>
                  </Select>
                </TableCell>
                <TableCell align="left">
                  <input type="checkbox" value="select" checked />
                </TableCell>
                <TableCell align="left">
                  <input type="checkbox" value="select" checked />
                </TableCell>
                <TableCell align="left">
                  <input type="checkbox" value="select" checked />
                </TableCell>
                <TableCell align="left">
                  <input type="checkbox" value="select" checked />
                </TableCell>
                <TableCell align="left">
                  <input type="checkbox" value="select" checked />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div
        style={{
          width: '99%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          margin: '20px 5px 80px 5px',
        }}
      >
        {rows.length ? (
          <Typography variant="caption">
            {`Showing ${
              (currentPage - 1) * Number(rowsPerPage) + 1
            } to ${Math.min(
              currentPage * Number(rowsPerPage),
              rows?.length,
            )} of ${rows?.length} entries`}

          </Typography>
        ) : (
          <Typography variant="caption">No records available</Typography>
        )}
        <Pagination count={Math.ceil(rows.length)} onChange={handleChangePage} page={currentPage} variant="outlined" shape="rounded" style={{ alignSelf: 'flex-end' }} />
      </div>

    </>
  );
};
export default GroupsDetailTable;
