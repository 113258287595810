import { TextField } from '@mui/material';
import React from 'react';
import Table from '../TableView/Table';

import configOptions from '../../dummyData/configOptionsData.json';

const ConfigOptions = (props:any) => {
  const { name, value, setValue } = props;
  const [filter, setFilter] = React.useState<string>('');
  const handleCursorChange = (cursor: number) => {
    let counter = cursor - 1;
    while (counter >= 0 && value[counter].charCodeAt(0) !== 32 && value[counter].charCodeAt(0) !== 10) {
      counter -= 1;
    }
    const wordToFilter = value.slice(counter + 1, cursor).replace('-', '').replace('+', '').split('=')[0];
    console.log(wordToFilter);
    setFilter(wordToFilter.toLowerCase());
  };

  const filteredOptions = React.useMemo(() => configOptions.filter((item: any) => item.option.toLowerCase().includes(filter)), [filter]);

  return (
    <div style={{ display: 'table', height: '50vh' }}>
      <div style={{ display: 'table-row' }}>
        <div style={{ width: '59%', height: '100%', display: 'table-cell' }}>
          <TextField className="textfield" style={{ width: '100%', height: '100%' }} multiline rows={12} value={value || ''} onChange={(e:any) => setValue(e)} name={name} onKeyUp={(e:any) => handleCursorChange(e.target.selectionStart)} />
        </div>
        <div style={{
          width: '100%', height: '100%', overflow: 'auto', display: 'table-cell',
        }}
        >
          <Table rows={filteredOptions} headCells={['Option', 'Description']} pages={filteredOptions.length || 0} />
        </div>
      </div>
    </div>
  );
};

export default ConfigOptions;
