import React from 'react';
import OpenDropdown from './OpenDropdown';

const Weekday = (props:any) => {
  const {
    key,
    label,
    name,
    defaultValue,
    value,
    onChange,
    size,
    options,
    isDisabled,
    type,
    obj,
    query,
    selectList,
    chain,
    stateValues,
    valuesToSend,
    isBatch,
  } = props;

  const adaptedValue = React.useMemo(() => value?.split('').reduce((acc:any, item:any, index:number) => (item === 'N' ? [...acc] : [...acc, { label: options[index].label, value: options[index].label }]), []) || [], [value]);
  const handleChange = (arg1:any, arg2:any, arg3:any) => {
    // onChange(e);
    const result = arg3 === null
      ? options.map((it: any) => 'N').join('')
      : options.map((item:any) => (arg3.find((item2:any) => item.label === item2.label) ? 'Y' : 'N')).join('');

    console.log('result', result);
    onChange({ target: { value: result, name } });
  };

  const isOptionEqualToValue = (option:any, item: any) => option.value === item.value;

  return (
    <OpenDropdown
      key={key}
      label={label}
      name={name}
      isBatch={isBatch}
      defaultValue={defaultValue}
      value={adaptedValue}
      isOptionEqualToValue={isOptionEqualToValue}
      onChange={handleChange}
      size={size}
      options={options.map((option:any) => ({ label: option.label, value: option.label }))}
      isDisabled={isDisabled}
      type={type}
      obj={obj}
      query={query}
      selectList={selectList}
      chain={chain}
      stateValues={stateValues}
      valuesToSend={valuesToSend}
      multiple
    />
  );
};

export default Weekday;
