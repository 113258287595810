import React from 'react';

// Librairies
import {
  ButtonBase, DialogActions, DialogContent, Divider,
} from '@mui/material';

// Components
import { DialogComponent } from '../Dialog/Dialog.component';
import ChangeHistory from '../recordChangeHistory/index';

// Styles
import { useStyles } from './style';

interface IProps {
  onClose: () => void;
  open: boolean;
  options: any;
}

export const RecordChangeHistory: React.FC<IProps> = ({
  onClose,
  open,
  options,
}) => {
  const classes = useStyles();
  return (
    <>
      <DialogComponent
        onClose={onClose}
        open={open}
        title="Record Change History..."
      >
        <DialogContent>
          {open && <ChangeHistory handleClose={() => null} options={options} />}
        </DialogContent>
        <Divider variant="middle" />
        <DialogActions className={classes.dialogActions}>
          <span>History records limited to most recent 25</span>
          <ButtonBase onClick={onClose} className={classes.cancelButton}> Close </ButtonBase>
        </DialogActions>
      </DialogComponent>
    </>
  );
};
