import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import TmsHeader from '../../../components/header';
import TankStatusTable from '../../../components/tankStatusTable/TankStatusTable';
import { getScreensList } from '../../../redux/actions/ScreenActions';

const TankStatus = () => {
  const [Values, setValues] = useState([1, 2, 3, 4]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getScreensList());
  }, []);

  return (
    <>
      <TmsHeader />
      <div className="sub-nav-container">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h3 id="pagetitlebar_title" className="page-title">
            Tank Status
          </h3>
        </div>
      </div>
      <TankStatusTable rows={Values} />
    </>
  );
};

export default TankStatus;
