/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';

// Librairies
import { ButtonBase, Grid } from '@mui/material';
import clsx from 'clsx';

// Components
import TmsHeader from '../../../components/header';
import Checkbox from '../../../components/Checkbox';

// Styles
import { useStyles } from './style';

// Utils
const data = {
  SCAD: {
    Supplier: '[0 Rows, 0B]',
    Customer: '[0 Rows, 0B]',
    'Customer EDI': '[0 Rows, 0B]',
    'Customer Products': '[0 Rows, 0B]',
    Account: '[0 Rows, 0B]',
  },
  Carrers: {
    Carrer: '[0 Rows, 0B]',
    Driver: '[0 Rows, 0B]',
    Vehicle: '[0 Rows, 0B]',
  },
  Orders: {
    'Order item': '[0 Rows, 0B]',
    'Order seal': '[0 Rows, 0B]',
    'Order instruction': '[0 Rows, 0B]',
    'Order item location': '[0 Rows, 0B]',
  },
  'Trace log': {
    '07-10-2022': '1.54 MB',
    '08-10-2022': '1.50 MB',
    '09-10-2022': '1.54 MB',
    '15-10-2022': '1.80 MB',
    '22-10-2022': '1.44 MB',
    '26-10-2022': '1.30 MB',
    '30-10-2022': '1.79 MB',
  },
};

const FileExportScreen: React.FC = () => {
  const classes = useStyles();
  const [notExpandedList, setNotExpandedList] = useState<string[]>([]);
  const [checkedList, setCheckedList] = useState<string[]>([]);

  const traceLogLenth = Object.keys(data['Trace log'])
    .filter((x) => checkedList.includes(x)).length;

  const handleParentCheckboxChange = (key: 'SCAD' | 'Carrers') => {
    if (checkedList.includes(key)) {
      setCheckedList(
        checkedList.filter(
          (x) => x !== key && !Object.keys(data[key]).includes(x),
        ),
      );
    } else {
      const newList = [...Object.keys(data[key]), key];
      setCheckedList([...checkedList, ...newList]);
    }
  };

  const handleChildCheckboxChange = (
    child: string,
    parent: 'SCAD' | 'Carrers',
  ) => {
    const allChildsAreChecked = Object.keys(data[parent])
      .every((x) => [...checkedList, child].includes(x));
    if (checkedList.includes(child)) {
      if (allChildsAreChecked) {
        setCheckedList(checkedList.filter((x) => x !== child && x !== parent));
      } else {
        setCheckedList(checkedList.filter((x) => x !== child));
      }
    } else if (allChildsAreChecked) {
      setCheckedList([...checkedList, child, parent]);
    } else {
      setCheckedList([...checkedList, child]);
    }
  };

  const handleExpand = (key: string) => {
    if (notExpandedList.includes(key)) {
      setNotExpandedList(notExpandedList.filter((x) => x !== key));
    } else {
      setNotExpandedList([...notExpandedList, key]);
    }
  };

  return (
    <div className={classes.root}>
      <TmsHeader />
      <div className={classes.header}>
        <h3 className={classes.title}>File Export</h3>
      </div>
      <table className={clsx(classes.exportSummary, 'fullWidth')}>
        <tbody>
          <tr>
            <td>
              <ButtonBase className={classes.exportButton}>Export</ButtonBase>
            </td>
            <td>
              Total Export Items :
              {' '}
              {checkedList.length}
            </td>
            <td>Tables: 0</td>
            <td>
              Trace Logs:
              {' '}
              {traceLogLenth}
            </td>
            <td>Audit Logs: 0</td>
          </tr>
        </tbody>
      </table>
      <div className="flexFullWidth">
        <Grid xs={1} item style={{ width: '50%' }}>
          <div className={classes.wrapper}>
            {Object.entries(data)
              .slice(0, 3)
              .map(([key, elems]: any) => (
                <div key={key}>
                  <div
                    className={clsx('flexFullWidth', classes.parentItem)}
                    onClick={() => handleExpand(key)}
                  >
                    <Checkbox
                      onClick={(e) => {
                        e.stopPropagation();
                        handleParentCheckboxChange(key);
                      }}
                      onChange={() => null}
                      checked={checkedList.includes(key)}
                      label={key}
                    />
                  </div>
                  {!notExpandedList.includes(key) && (
                    <div>
                      {Object.entries(elems).map(([x, value]: any) => (
                        <div
                          key={`${value}${x}`}
                          className={clsx('flexFullWidth', classes.childItem)}
                        >
                          <Checkbox
                            onClick={(e) => {
                              e.stopPropagation();
                              handleChildCheckboxChange(x, key);
                            }}
                            onChange={() => null}
                            checked={checkedList.includes(x)}
                            label={`${x} - ${value}`}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
          </div>
        </Grid>
        <Grid xs={1} item style={{ width: '50%' }}>
          <div className={classes.wrapper}>
            {Object.entries(data)
              .slice(3)
              .map(([key, elems]: any) => (
                <div key={key}>
                  <div
                    className={clsx('flexFullWidth', classes.parentItem)}
                    onClick={() => handleExpand(key)}
                  >
                    <Checkbox
                      onClick={(e) => {
                        e.stopPropagation();
                        handleParentCheckboxChange(key);
                      }}
                      onChange={() => null}
                      checked={checkedList.includes(key)}
                      label={key}
                    />
                  </div>
                  {!notExpandedList.includes(key) && (
                    <div>
                      {Object.entries(elems).map(([x, value]: any) => (
                        <div
                          key={`${value}${x}`}
                          className={clsx('flexFullWidth', classes.childItem)}
                        >
                          <Checkbox
                            onClick={(e) => {
                              e.stopPropagation();
                              handleChildCheckboxChange(x, key);
                            }}
                            onChange={() => null}
                            checked={checkedList.includes(x)}
                            label={`${x} - ${value}`}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
          </div>
        </Grid>
      </div>
    </div>
  );
};

export default FileExportScreen;
