import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    overflow: 'hidden !important',
  },
  cancelButton: {
    color: 'black !important',
    textShadow: 'none !important',
    backgroundColor: '#ffbb33 !important',
    padding: '4px 10px !important',
    marginLeft: '10px !important',
    border: '1px solid transparent',
    '&:hover': {
      backgroundColor: '#FF8800 !important',
      color: 'white !important',
    },
  },
  confirmButton: {
    verticalAlign: 'middle',
    textAlign: 'center',
    backgroundColor: '#00C851 !important',
    color: 'black !important',
    padding: '4px 10px !important',
    border: '1px solid transparent',
    '&:hover': {
      backgroundColor: '#007E33 !important',
      color: 'white !important',
    },
  },
  item: {
    borderLeft: '1px solid #fff',
    padding: '5px 9px',
    whiteSpace: 'nowrap',
    borderRight: '1px solid #fff',
    borderTop: '1px solid #fff',
    fontSize: 12,
    fontWeight: 400,
  },
  itemWrapper: { border: '2px solid #ddd' },
  note: {
    fontFamily: 'Open Sans',
    pointerEvents: 'none',
    fontWeight: 400,
    fontSize: 12,
    fontStyle: 'italic',
    marginLeft: 15,
  },
});
