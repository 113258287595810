import React, { useState } from 'react';

// Librairies
import { ButtonBase, Popover, TextareaAutosize } from '@mui/material';
import { Check, CloseOutlined } from '@mui/icons-material';

// Styles
import { useStyles } from './style';

interface IProps {
  title: string;
  contentText: string;
}

const StystemStartupBodyMenu: React.FC<IProps> = ({ title, contentText }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <div className={classes.root}>
      <ButtonBase onClick={handleOpenPopover}>
        {contentText}
      </ButtonBase>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        classes={{ root: classes.popoverRoot, paper: classes.paperRoot }}
      >
        <div className={classes.header}>{title}</div>
        <div className={classes.wrapper}>
          <TextareaAutosize className={classes.textArea} value={contentText} />
          <ButtonBase className={classes.checkButton}>
            <Check className={classes.checkIcon} />
          </ButtonBase>
          <ButtonBase className={classes.closeButton} onClick={handleClosePopover}>
            <CloseOutlined />
          </ButtonBase>
        </div>
      </Popover>
    </div>
  );
};

export default StystemStartupBodyMenu;
