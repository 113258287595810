/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { useEffect } from 'react';

// Librairies
import {
  ButtonBase,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

// Components
import { DialogComponent } from '../Dialog/Dialog.component';
import CustomInput from '../customInput';
import { useStyles } from './styles';

// Actions
import {
  setCalculations,
  clearCalculations,
} from '../../redux/actions/RecordActions';

type calculationType = 'unittarget' | 'unitpaging' | 'unit1000ths' | 'unitccs';

const UnitCalculatorDialogContent: React.FC = () => {
  const { unitCalculator } = useSelector((state: any) => state.ScreenReducer);
  const { calculations } = useSelector(
    (state: any) => state.UnitCalculatorReducer,
  );
  const dispatch = useDispatch();

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    dispatch(
      setCalculations({ ...calculations, calculate: event.target.value }),
    );
  };

  useEffect(() => {
    if (!calculations.calculate) {
      const claculateType = unitCalculator.find(
        (x: any) => x.id === 'calculate',
      )?.options?.[0]?.value;
      dispatch(setCalculations({ ...calculations, calculate: claculateType }));
    }
  }, [unitCalculator?.length]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (calculations.calculate) {
      case 'c': {
        switch (event.target.name) {
          case 'unittarget': {
            if (calculations.unitpaging) {
              const unit1000ths = Number(event.target.value)
                * Number(calculations.unitpaging)
                * 10.0;
              const unitccs = (unit1000ths / 1000.0) * 3785.4118;
              dispatch(
                setCalculations({
                  ...calculations,
                  unittarget: event.target.value,
                  unit1000ths: unit1000ths.toFixed(4),
                  unitccs: unitccs.toFixed(4),
                }),
              );
            } else {
              dispatch(
                setCalculations({
                  ...calculations,
                  unittarget: event.target.value,
                }),
              );
            }
            break;
          }
          case 'unitpaging': {
            if (calculations.unittarget) {
              const unit1000ths = Number(event.target.value)
                * Number(calculations.unittarget)
                * 10.0;
              const unitccs = (unit1000ths / 1000.0) * 3785.4118;
              dispatch(
                setCalculations({
                  ...calculations,
                  unitpaging: event.target.value,
                  unit1000ths: unit1000ths.toFixed(4),
                  unitccs: unitccs.toFixed(4),
                }),
              );
            } else {
              dispatch(
                setCalculations({
                  ...calculations,
                  unitpaging: event.target.value,
                }),
              );
            }
            break;
          }
          case 'unit1000ths':
          case 'unitccs': {
            break;
          }
          default:
            break;
        }
        break;
      }
      case 'p': {
        switch (event.target.name) {
          case 'unittarget': {
            if (calculations.unit1000ths) {
              const unitpaging = Number(calculations.unit1000ths)
                / (Number(event.target.value) * 10.0);
              const unitccs = (Number(calculations.unit1000ths) / 1000.0) * 3785.4118;
              dispatch(
                setCalculations({
                  ...calculations,
                  unittarget: event.target.value,
                  unitpaging: unitpaging.toFixed(4),
                  unitccs: unitccs.toFixed(4),
                }),
              );
            } else {
              dispatch(
                setCalculations({
                  ...calculations,
                  unittarget: event.target.value,
                }),
              );
            }
            break;
          }
          case 'unit1000ths': {
            if (calculations.unittarget) {
              const unitpaging = Number(event.target.value)
                / (Number(calculations.unittarget) * 10.0);
              const unitccs = (Number(event.target.value) / 1000.0) * 3785.4118;
              dispatch(
                setCalculations({
                  ...calculations,
                  unit1000ths: event.target.value,
                  unitpaging: unitpaging.toFixed(4),
                  unitccs: unitccs.toFixed(4),
                }),
              );
            } else {
              dispatch(
                setCalculations({
                  ...calculations,
                  unit1000ths: event.target.value,
                }),
              );
            }
            break;
          }
          case 'unitpaging':
          case 'unitccs': {
            break;
          }
          default:
            break;
        }
        break;
      }
      case 'a': {
        switch (event.target.name) {
          case 'unitpaging': {
            if (calculations.unit1000ths) {
              const unittarget = Number(calculations.unit1000ths)
                / (Number(event.target.value) * 10.0);
              const unitccs = (Number(calculations.unit1000ths) / 1000.0) * 3785.4118;
              dispatch(
                setCalculations({
                  ...calculations,
                  unittarget: unittarget.toFixed(4),
                  unitpaging: event.target.value,
                  unitccs: unitccs.toFixed(4),
                }),
              );
            } else {
              dispatch(
                setCalculations({
                  ...calculations,
                  unitpaging: event.target.value,
                }),
              );
            }
            break;
          }
          case 'unit1000ths': {
            const unitccs = (Number(event.target.value) / 1000.0) * 3785.4118;
            if (calculations.unitpaging) {
              const unittarget = Number(event.target.value)
                / (Number(calculations.unitpaging) * 10.0);
              dispatch(
                setCalculations({
                  ...calculations,
                  unit1000ths: event.target.value,
                  unittarget: unittarget.toFixed(4),
                  unitccs: unitccs.toFixed(4),
                }),
              );
            } else {
              dispatch(
                setCalculations({
                  ...calculations,
                  unit1000ths: event.target.value,
                  unitccs: unitccs.toFixed(4),
                }),
              );
            }
            break;
          }
          case 'unittarget':
          case 'unitccs': {
            break;
          }
          default:
            break;
        }
        break;
      }
      default:
        break;
    }
  };

  return (
    <div className="flexColumn">
      {unitCalculator?.map((uc: any) => {
        switch (uc.type) {
          case 'select': {
            return (
              <FormControl
                key={uc.id}
                style={{
                  marginBottom: 10,
                  marginTop: 10,
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'row',
                }}
                fullWidth
              >
                <Typography
                  style={{
                    width: '30%',
                  }}
                >
                  Calculate
                </Typography>
                <Select
                  onChange={handleSelectChange}
                  style={{ width: 500, height: 40 }}
                  value={calculations.calculate}
                >
                  {uc?.options.map((option: any) => (
                    <MenuItem value={option.value} key={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
          }
          case 'input': {
            return (
              <CustomInput
                placeholder={uc.label}
                name={uc.id}
                onChange={handleInputChange}
                value={calculations[uc.id as calculationType]}
                isDisabled={false}
                label={uc.label}
                size={500}
                noAwayListener
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 20,
                  marginBottom: 10,
                }}
                labelStyle={{
                  width: '45%',
                }}
                isNumber
              />
            );
          }
          default: {
            return null;
          }
        }
      })}
    </div>
  );
};

interface IProps {
  onClose: () => void;
  open: boolean;
}

export const UnitCalculatorDialog: React.FC<IProps> = ({ onClose, open }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleClearCalculations = () => {
    dispatch(clearCalculations());
  };
  return (
    <>
      <DialogComponent onClose={onClose} open={open} title="Unit Calculator">
        <DialogContent>
          <UnitCalculatorDialogContent />
        </DialogContent>
        <Divider variant="middle" />
        <DialogActions classes={{ root: classes.actions }}>
          <ButtonBase
            className={classes.clearButton}
            onClick={handleClearCalculations}
          >
            Clear
          </ButtonBase>
          <ButtonBase className={classes.closeButton} onClick={onClose}>
            Close
          </ButtonBase>
        </DialogActions>
      </DialogComponent>
    </>
  );
};
