import { Dispatch } from 'redux';
import Api from '../api/index';
import { LOAD_SYSTEM_STARTUP } from '../constants/systemStartup';

export const fetchSystemStartup = () => async (dispatch: Dispatch) => {
  const response = await Api.get('workflow010/data');
  const payload = response.data.data.map((item: any) => {
    const fields = item.fields.map((field: any) => {
      if (field.obj) {
        return {
          id: field.id,
          options: field?.obj?.['\u0000*\u0000parm']?.options,
        };
      }
      return {
        id: field.id,
        value: field.value,
      };
    });
    return {
      row: item.row,
      fields,
    };
  });
  dispatch({
    type: LOAD_SYSTEM_STARTUP,
    payload,
  });
};
