import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {},
  input: {
    border: '1px solid #dddddd !important',
    padding: '2px 0px !important',
    borderRadius: 0,
    fontSize: 12,
    fontWeight: 400,
    color: 'black',
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
});
