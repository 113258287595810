import * as React from 'react';
import ReactDOM from 'react-dom';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ColumnsScreen from '../columns';
import {
  BatchDeleteRecord, DeleteRecord, LoadTables,
} from '../../redux/actions/ScreenActions';
import {
  LoadEmbbededTables,
  BatchDeleteRecordEmbedded,
  DeleteRecordEmbedded,
  SwitchEmbeddedNavigation,
} from '../../redux/actions/embbededScreensActions';
import ControlButton from '../controlButton';

const style = {
  position: 'absolute' as const,
  left: '10%',
  top: '10%',
  width: '70%',
  height: 170,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
};

const ConfirmationModalEmbedded = (props:any) => {
  const {
    Visible, handleCloseConfirm, options, isbatch, tableId, setSelected, setBatchMode,
  } = props;
  const { activeScreen } = useSelector((state: any) => state.EmbbededScreenReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClose = () => {
    handleCloseConfirm();
  };
  const handleDelete = () => {
    if (isbatch) {
      dispatch(BatchDeleteRecordEmbedded({
        options,
        navigate,
        tableId,
        activeScreen,
      }));
      dispatch(SwitchEmbeddedNavigation('tableScreen'));
      dispatch(LoadEmbbededTables({
        tableID: activeScreen.tableID,
        page: '1',
        results_per_page: '10',
        fields: activeScreen.fields,
        order_by: '',
        where: '',
        EmptyRows: activeScreen.EmptyRows,
        redirect: activeScreen.redirect,
        keys: activeScreen.keys,
        screenId: activeScreen.screenId,
      }));
      handleClose();
      setSelected([]);
      setBatchMode(false);
    } else {
      dispatch(DeleteRecordEmbedded({
        options,
        navigate,
        tableId,
        activeScreen,
      }));
      dispatch(SwitchEmbeddedNavigation('tableScreen'));
    }
    setBatchMode(false);
  };

  return ReactDOM.createPortal(
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={Visible}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 460,
      }}
    >
      <Fade in={Visible}>
        <Box sx={style}>
          <div style={{ padding: 10, backgroundColor: '#d2d2d2' }}>
            <Typography>Remove Entry</Typography>
          </div>
          <div style={{
            height: 'auto', display: 'flex', justifyContent: 'center', alignSelf: 'center', padding: '25px 0px',
          }}
          >
            <Typography>You are about to remove this record. Do you want to continue?</Typography>
          </div>
          <Divider />
          <div style={{
            height: 'auto', padding: 10, display: 'flex', justifyContent: 'flex-end', alignItems: 'center',
          }}
          >
            <div style={{
              height: 'auto', display: 'flex', gap: 5, alignItems: 'center', alignSelf: 'center',
            }}
            >
              <ControlButton
                label="cancel"
                color="#ffbb33"
                disabled={false}
                handleClick={handleClose}
              />
              <ControlButton
                label="yes"
                color="#00c851"
                disabled={false}
                handleClick={handleDelete}
              />
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>,
    document.getElementById('draggable') as HTMLElement,
  );
};
export default ConfirmationModalEmbedded;
