import { CircularProgress } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import BasicTable from '../../../components/basicTable/BasicTable';
import TmsHeader from '../../../components/header';

const GroupsTableView = () => {
  const [Values, setValues] = useState<any>([]);
  const [Loading, setLoading] = useState(true);
  const getDataForGroups = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}workflow094/data`);
      console.log(res.data);
      setValues(res.data.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getDataForGroups();
  }, []);

  return (
    <>
      <TmsHeader />
      <div className="sub-nav-container">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h3 id="pagetitlebar_title" className="page-title">
            Groups
          </h3>
        </div>
      </div>
      {Loading ? <CircularProgress /> : <BasicTable rows={Values} /> }
    </>
  );
};

export default GroupsTableView;
