import React from 'react';

// Libraries
import {
  ButtonBase,
  DialogActions, Divider, TextareaAutosize, Typography,
} from '@mui/material';
import clsx from 'clsx';

// Components
import { DialogComponent } from '../Dialog/Dialog.component';

// Styles
import { useStyles } from './style';

interface IProps {
  onClose: () => void;
  open: boolean;
}

const SystemStartupDialog: React.FC<IProps> = ({ onClose, open }) => {
  const classes = useStyles();
  return (
    <DialogComponent title="System Startup" onClose={onClose} open={open}>
      <div className={clsx('flexRow', classes.wrapper)}>
        <Typography className={classes.label}>Details</Typography>
        <TextareaAutosize className={classes.textArea} />
      </div>
      <Divider variant="middle" />
      <DialogActions>
        <ButtonBase classes={{ root: classes.closeButton }}> Close </ButtonBase>
      </DialogActions>
    </DialogComponent>
  );
};

export default SystemStartupDialog;
