import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  inputStyle: {
    display: 'flex',
    alignItems: 'center',
    gap: 20,
    marginBottom: 10,
  },
  clearButton: {
    color: 'black',
    textShadow: 'none',
    backgroundColor: '#00C851',
    border: '1px solid transparent',
    padding: '6px 10px',
  },
  closeButton: {
    color: 'black',
    textShadow: 'none',
    backgroundColor: '#ffbb33',
    border: '1px solid transparent',
    padding: '6px 10px',
  },
  actions: {
    padding: '8px 25px',
  },
});
