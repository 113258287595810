import React from 'react';
import {
  Box, Modal, Paper, SelectChangeEvent, Tab,
} from '@mui/material';
import Slide from '@mui/material/Slide';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import CustomDropdown from '../customDropdown';
import CustomInput from '../customInput';
import Plcconfig from '../plcconfigComponent/plcconfig';
import CustomRcuscreen from '../customTextArea/customTextArea';
import ConfigOptions from '../ConfigOptions/ConfigOptions';
import EmbeddedNavigator from '../../screens/embeddedNavigator/embeddedNavigator';
import {
  LoadEmbbededTables,
  SwitchEmbeddedNavigation,
  LoadRecordEmbbeded,
} from '../../redux/actions/embbededScreensActions';
import Weekday from '../customDropdown/Weekday';
import LoadDataGrid from '../LoadDataGrid/LoadDataGrid';
import CustomField from '../CustomField/CustomField';
import { LoadNestedEmbbededTables, SwitchNestedEmbeddedNavigation } from '../../redux/actions/nestedEmbeddedTabsActions';
import NestedEmbeddedNavigator from '../../screens/nestedEmbeddedScreens/nestedEmbeddedNavigation';

const FormBodyTerminal = (props: any) => {
  const {
    dataTabs,
    alerts,
    handleSelectChange,
    handleInputChange,
    handleTimeDateChange,
    values,
    compare,
    dataHeader,
    valuesToSend,
    showOptionalFields,
    handleShowOptionalFields,
    isEmbedded,
  } = props;
  const { activeScreen, whereDetailCondition, embeddedTabsLabels } = useSelector(
    (state: any) => (isEmbedded ? state.EmbbededScreenReducer : state.ScreenReducer),
  );
  const {
    ScreensList,
  } = useSelector(
    (state: any) => (state.ScreenReducer),
  );
  console.log('aleeeeeeeeeeeeert', alerts);
  const Ids = Object.entries(dataTabs)
    .map((elt: any) => elt[1].map((element: any) => [element?.id, element?.valForm]))
    .flat();
  const mapData = new Map(Ids);
  const arrayToObject = Object.fromEntries(mapData);
  const [indice, setIndice] = React.useState('0');
  const bodyheightresponsive = () => 60 / dataHeader.length;
  const [modalOpen, setModalOpen] = React.useState(false);
  const [NestedEmbeddedmodalOpen, setNestedEmbeddedmodalOpen] = React.useState(false);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setIndice(newValue);
  };
  const embeddedStyle = {
    display: 'flex',
    alignItems: 'flex-end',
    width: '100%',
  };
  const dispatch = useDispatch();

  // show optional fields fix
  const handleClickTab = (tab: any) => {
    if (!tab?.[1]?.embbeded) {
      return;
    }
    const screen = ScreensList.filter(
      (item: any) => item.screenId === tab[0],
    )[0];
    console.log('taaaaaaaaaab', isEmbedded);

    if (!screen) {
      return;
    }

    (async () => {
      const fields = Array.from(new Set([...screen?.fields?.split(',')])).join(
        ',',
      );
      const embeddedQuery = await screen.keys
        .split(',')
        .map((val: any, key: any) => (activeScreen.keys.split(',').includes(val) ? val : null))
        .filter((el: any) => el !== null);
      const QueryDependingValues = embeddedQuery.reduce(
        (acc: any, item: any) => ({ ...acc, [item]: JSON.parse(whereDetailCondition)[item] }),
        {},
      );
      console.log('screen', screen);
      const FinalEmbeddedQuery: any = await Object.keys(QueryDependingValues)
        .map((item: any) => `${item}='${QueryDependingValues[item]}'`)
        .join(' AND ');
      if (isEmbedded === false) {
        await dispatch(
          LoadEmbbededTables({
            tableID: screen.tableId,
            screenId: screen.screenId,
            page: 1,
            results_per_page: '10',
            fields: screen.fields ? fields : '',
            fieldsConcatenated: screen.fieldsConcatenated,
            order_by: '',
            where: FinalEmbeddedQuery,
            keys: screen.keys || '',
            dependentKeyEmbedded: activeScreen.keys,
            data: whereDetailCondition,
            labels: screen.labels || '',
            hidden: screen.hiddenColumns || '',
          }),
        );
        await dispatch(
          LoadRecordEmbbeded({
            tableID: screen.tableId,
            screenID: screen.screenId,
            fields: screen.fields || '',
            where: '',
          }),
        );
        await dispatch(SwitchEmbeddedNavigation('tableScreen'));
        setModalOpen(true);
      }
      // if (isEmbedded === true) {
      //   setNestedEmbeddedmodalOpen(true);
      //   console.log('tessssssssssssssssssssst');
      //   await dispatch(
      //     LoadNestedEmbbededTables({
      //       tableID: screen.tableId,
      //       screenId: screen.screenId,
      //       page: 1,
      //       results_per_page: '10',
      //       fields: screen.fields ? fields : '',
      //       fieldsConcatenated: screen.fieldsConcatenated,
      //       order_by: '',
      //       where: FinalEmbeddedQuery,
      //       keys: screen.keys || '',
      //       dependentKeyEmbedded: activeScreen.keys,
      //       data: whereDetailCondition,
      //       labels: screen.labels || '',
      //       hidden: screen.hiddenColumns || '',
      //     }),
      //   );
      //   await dispatch(
      //     LoadNestedRecordEmbbeded({
      //       tableID: screen.tableId,
      //       screenID: screen.screenId,
      //       fields: screen.fields || '',
      //       where: '',
      //     }),
      //   );
      //   await dispatch(SwitchNestedEmbeddedNavigation('tableScreen'));
      // }
    })();
  };
  const handleCloseEmbedded = () => {
    setModalOpen(false);
    setIndice('0');
  };
  console.log('handleCloseEmbedded', NestedEmbeddedmodalOpen);
  return (
    <>
      <Box sx={{
        width: '100%', typography: 'body1',
      }}
      >
        <TabContext value={indice}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', minHeight: 'auto' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example" sx={{ minHeight: 'auto' }}>
              {Object.entries(dataTabs).map((tabInfo: any, idx: any) => (
                <Tab
                  className="f12"
                  label={embeddedTabsLabels?.[tabInfo[0]] || tabInfo[0]}
                  sx={{
                    minHeight: 'auto',
                    padding: '8px',
                    backgroundColor:
                      tabInfo[1]?.filter((emb: any) => emb?.embbeded === true)
                        .length === 1
                        ? '#e6f1ff'
                        : '#f6f6f6',
                    textTransform: 'none',
                  }}
                  value={idx.toString()}
                  onClick={() => handleClickTab(dataTabs[tabInfo[0]])}
                />
              ))}
            </TabList>
          </Box>
          {activeScreen?.screenId === 'MAINT-164' && indice === '0' ? (
            <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}>
              {Object.entries(dataTabs).map((tabInfo: any, index: any) => tabInfo[1].map((val: any) => (
                <TabPanel sx={{ padding: '0px', flex: '1 0 21%' }} value={index.toString()}>
                  <CustomField
                    val={val}
                    values={values}
                    valuesToSend={valuesToSend}
                    handleInputChange={handleInputChange}
                    handleSelectChange={handleSelectChange}
                    handleTimeDateChange={handleTimeDateChange}
                    isEmbedded={isEmbedded}
                    size="100%"
                  />
                </TabPanel>
              )))}
            </div>
          ) : (
            <div style={{
              height: `${alerts ? '55vh' : '61vh'}`,
              overflowY: 'scroll',
              width: '100%',
              marginBottom: '30px',
            }}
            >
              {Object.entries(dataTabs).map((tabInfo: any, index: any) => tabInfo[1].map((val: any) => (
                <TabPanel sx={{ padding: '0px' }} value={index.toString()}>
                  {!val?.visible && !showOptionalFields && val?.type !== 'grid' ? (
                    <></>
                  ) : (
                    <CustomField
                      val={val}
                      values={values}
                      valuesToSend={valuesToSend}
                      handleInputChange={handleInputChange}
                      handleSelectChange={handleSelectChange}
                      handleTimeDateChange={handleTimeDateChange}
                      size="99%"
                      isEmbedded={isEmbedded}
                    />
                  )}
                </TabPanel>
              )))}

            </div>
          )}
        </TabContext>
      </Box>
      <Modal
        style={embeddedStyle}
        open={modalOpen}
        hideBackdrop
      >
        <Paper style={{ width: '100%', height: '70vh', overflowY: 'scroll' }}>
          <EmbeddedNavigator handleCloseEmbedded={handleCloseEmbedded} handleClose={() => dispatch(SwitchEmbeddedNavigation('tableScreen'))} />
          {/* <Modal
            style={{ ...embeddedStyle, zIndex: 999 }}
            open={NestedEmbeddedmodalOpen}
          > */}
          <Paper style={{
            width: '100%', height: '30vh', overflowY: 'scroll', zIndex: 999,
          }}
          >
            <NestedEmbeddedNavigator handleCloseEmbedded={() => console.log('test')} handleClose={() => dispatch(SwitchNestedEmbeddedNavigation('tableScreen'))} />
          </Paper>
          {/* </Modal> */}
        </Paper>
      </Modal>
    </>
  );
};

export default FormBodyTerminal;
