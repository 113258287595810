import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {},
  header: {
    width: '100%',
    backgroundColor: '#eee',
    margin: '5px 0',
    padding: '5px 0',
  },
  title: {
    padding: '0 5px 0 5px',
    fontSize: 22,
    letterSpacing: -1,
    display: 'block',
    color: '#666',
    margin: '0 0 5px 0',
    fontWeight: 100,
  },
});
