/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
import React from 'react';

// Librairies
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';

// Styles
import { useStyles } from './style';

// Utils
const tableHeader: string[] = [
  'Bay',
  'Type',
  'Max Trucks Loaded/Staged',
  'Card In Description',
  'Card Out Description',
  'Extended Messaging',
  'Traffic Name',
  'Processing',
];

interface IProps {
  handleClickRow?: () => void;
}

const TrafficTable: React.FC<IProps> = ({ handleClickRow }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Table classes={{ root: classes.tableRoot }}>
        <TableHead>
          <TableRow classes={{ root: classes.headerRow }}>
            {tableHeader.map((h) => (
              <TableCell classes={{ root: classes.headerCell }} key={h}>
                {h}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow onClick={handleClickRow} className={classes.tableRow}>
            {tableHeader.map((field: string) => (field === 'Status' ? (
              <TableCell
                key={field}
                classes={{ root: classes.bodyCellEmpty }}
              >
                <div
                  style={{
                    width: 20,
                    height: 20,
                    backgroundColor: '#32CD32',
                  }}
                />
              </TableCell>
            ) : (
              <TableCell classes={{ root: classes.bodyCell }} key={field}>
                {field}
              </TableCell>
            )))}
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

export default React.memo(TrafficTable);
