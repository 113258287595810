import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  wrapper: {
    padding: '0px 10px',
    marginBottom: 10,
  },
  searchInput: {
    fontWeight: '400 !important',
    fontSize: '12px !important',
    lineHeight: '1.42857143 !important',
    padding: '4px 4px',
    color: '#333333',
    backgroundColor: '#fff',
    border: '1px solid #e5e5e5',
    height: 33,
    width: 200,
  },
  subHeaderContainer: {
    padding: '0px 10px',
    marginBottom: 5,
  },
  label: {
    marginRight: 5,
    fontSize: 12,
    fontWeight: 400,
    display: 'flex',
    alignItems: 'center',
  },
  freezeButton: {
    borderWidth: '1px 1px 1px 5px !important',
    borderColor: '#4d90fe !important',
    color: 'black !important',
    textShadow: 'none !important',
    backgroundColor: '#ddd !important',
    border: '1px solid transparent !important',
    padding: '4px 10px !important',
    '&:hover': {
      color: '#fff !important',
      backgroundColor: '#545454 !important',
    },
  },
  closeButton: {
    borderWidth: '1px 1px 1px 5px !important',
    borderColor: '#ffb848 !important',
    color: 'black !important',
    textShadow: 'none !important',
    backgroundColor: '#ddd !important',
    border: '1px solid transparent !important',
    padding: '4px 10px !important',
    '&:hover': {
      color: '#fff !important',
      backgroundColor: '#545454 !important',
    },
  },
  actionsWrapper: {
    margin: 5,
    marginLeft: 30,
    gap: 15,
    display: 'flex',
  },
});
