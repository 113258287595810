import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {},
  header: {
    width: '100%',
    backgroundColor: '#eee',
    margin: '5px 0',
    padding: '5px 0',
    marginBottom: 10,
  },
  reviewContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    width: '70%',
    gap: '25px',
  },
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  review: {
    display: 'flex',
    gap: '5px',
  },
});
