import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {},
  header: {
    width: '100%',
    backgroundColor: '#eee',
    margin: '5px 0',
    padding: '5px 0',
  },
  title: {
    padding: '0 5px 0 5px',
    fontSize: 22,
    letterSpacing: -1,
    display: 'block',
    color: '#666',
    margin: '0 0 5px 0',
    fontWeight: 100,
  },
  exportSummary: {
    marginBottom: 10,
  },
  exportButton: {
    borderColor: '#4d90fe !important',
    borderWidth: '1px 1px 1px 5px !important',
    border: 'solid !important',
    color: 'black !important',
    textShadow: 'none !important',
    backgroundColor: '#ddd !important',
    padding: '4px 10px !important',
    fontSize: '12px !important',
    fontWeight: 400,
    '&:hover': {
      backgroundColor: '#545454 !important',
      color: 'white !important',
    },
  },
  wrapper: {
    border: '1px solid #D8D8D8',
    padding: 5,
    marginBottom: 5,
    height: 'calc(100vh - 150px)',
    overflow: 'auto',
  },
  parentItem: {
    backgroundColor: '#f2f2f2',
    marginBottom: '2px',
  },
  childItem: {
    backgroundColor: '#fafafa',
    margin: '2px 2px 2px 20px',
  },
});
