import { Dispatch } from 'redux';
import Api from '../api/index';
import {
  CLEAR_CALCULATIONS,
  CLEAR_RECORD_HISTORY,
  FETCH_RECORD_HISTORY,
  SET_CALCULATIONS,
} from '../constants/record';

// Utils
const doesValueMatchesRecord = (value: any, modifyDep: any) => Object.entries(modifyDep)
  .every((md: any) => value[md[0]]?.trim() === md[1]?.trim());

const filterChangeHistoryByRecord = (data: any, keys: any) => data
  .filter((elem: any) => doesValueMatchesRecord(JSON.parse(`{${elem?.new_value.replace(/\\n/g, '\\n')
    .replace(/\\'/g, "\\'")
    .replace(/\\"/g, '\\"')
    .replace(/\\&/g, '\\&')
    .replace(/\\r/g, '\\r')
    .replace(/\\t/g, '\\t')
    .replace(/\\b/g, '\\b')
    .replace(/\\f/g, '\\f')}}`), keys));

export const fetchRecordHistory = (tableId: string, keys: any) => async (dispatch: Dispatch, getState: any) => {
  try {
    const response = await Api.get('formscreen/get-change-history', {
      params: {
        tableID: tableId,
      },
    });
    const filtredHistory: any = filterChangeHistoryByRecord(
      response.data.data?.reverse() ?? [],
      keys,
    );
    dispatch({
      type: FETCH_RECORD_HISTORY,
      payload: filtredHistory,
    });
  } catch (err: any) {
    console.log(err?.response?.data);
  }
};

export const clearRecordHistory = () => ({
  type: CLEAR_RECORD_HISTORY,
});

export const setCalculations = (newCalcualtions: any) => ({
  type: SET_CALCULATIONS,
  payload: newCalcualtions,
});

export const clearCalculations = () => ({
  type: CLEAR_CALCULATIONS,
});
