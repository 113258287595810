/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoadEmbbededTables } from '../../redux/actions/embbededScreensActions';
import CloneMaintEmbeddedScreen from '../embbededScreens/cloneMaintEmbeddedScreen';
import CreateMaintEmbeddedScreen from '../embbededScreens/createMaintEmbeddedScreen';
import DetailsMaintEmbeddedScreen from '../embbededScreens/detailsMaintEmbeddedScreen';
import EmbbededMaintTableScreen from '../embbededScreens/embbededTableScreen';
import LoginPage from '../LoginPage/LoginPage';
import DetailMaintScreen from '../maint-screen/details-maint-screen';
import MaintTableScreen from '../maint-screen/TableScreen';
import BatchEditEmbedded from '../embbededScreens/BatchEditEbeddedScreen';
import BatchModeUpdateEmbedded from '../../components/batchModeUpdate/BatchModeUpdateEmbedded';
import Footer from '../../components/Footer/Footer';

const EmbeddedNavigator = (props: any) => {
  const {
    handleClose,
    handleCloseEmbedded,
  } = props;

  const {
    activeScreen,
    embeddedNavigation,
  } = useSelector(
    (state: any) => state.EmbbededScreenReducer,
  );

  return (
    <>
      <div style={{
        height: '89%',
      }}
      >
        {
  !activeScreen ? (<></>) : (embeddedNavigation === 'tableScreen'
    ? <EmbbededMaintTableScreen handleclosingEmbedded={handleCloseEmbedded} />
    : embeddedNavigation === 'details'
      ? <DetailsMaintEmbeddedScreen handleEmbeddedClose={handleCloseEmbedded} />
      : embeddedNavigation === 'batchEdit'
        ? <BatchEditEmbedded />
        : embeddedNavigation === 'batchUpdate'
          ? <BatchModeUpdateEmbedded />
          : embeddedNavigation === 'clone'
            ? <CloneMaintEmbeddedScreen handleEmbeddedClose={handleCloseEmbedded} />
            : embeddedNavigation === 'create'
              ? <CreateMaintEmbeddedScreen handleEmbeddedClose={handleCloseEmbedded} />
              : <LoginPage />
  )
}
      </div>
      <Footer />
    </>
  );
};

export default EmbeddedNavigator;
