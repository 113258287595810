import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    padding: '0px 10px',
  },
  tableRoot: {
  },
  headerCell: {
    borderBottom: 'none !important',
    padding: '0px !important',
    fontSize: '110%',
    fontWeight: 'bold',
  },
  headerRow: {
    boxShadow: '0 1px 7px #555',
  },
  bodyCell: {
    borderBottom: 'none !important',
    padding: '8px 0px !important',
  },
  bodyCellEmpty: {
    borderBottom: 'none !important',
    padding: '8px 7px !important',
  },
  tableRow: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#fbf8e9 !important',
    },
  },
  boxLine: {
    borderBottom: 1, borderColor: '#eee', minHeight: 'auto', margin: '20px 0px', borderStyle: 'solid',
  },

});
