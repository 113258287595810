/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import axios from 'axios';
import { Delete } from '@mui/icons-material';
import {
  TextField,
  Typography,
  Divider,
  Button,
  Autocomplete,
  Select,
  MenuItem,
  FormControl,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  LoadTables,
  SaveFilter,
  DeleteFilter,
} from '../../redux/actions/ScreenActions';
import { SAVE_SCREEN_FILTER, SET_MESSAGE } from '../../redux/constants/Screens';

interface myProps {
  handleClose: () => void;
}

const useStyles = makeStyles({
  itemContainer: {
    marginBottom: 5,
  },
});

const FilterScreen = ({ handleClose }: myProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const {
    FilterSaved, activeScreen, allSizes, allTableColumns, allLabels, activeFilter,
  } = useSelector((state: any) => state.ScreenReducer);
  const [selectedFields, setSelectedFields] = useState<any>(
    FilterSaved?.[activeScreen.screenId]?.filters || [],
  );
  const [match, setMatch] = useState<string>('OR');
  const [filterText, setFilterText] = useState<string>('');
  const [filterValue, setFilterValue] = useState<any>({ value: '', label: '' });

  const [loadedFilters, setLoadedFilters] = useState<any>([]);

  const handleChangeFilter = (filter: any) => {
    console.log('filter', filter);
    const filterAdapter: any = {
      '<': '<',
      '=': '=',
      '!=': '!=',
      '>': '>',
      like: 'like',
      'not like': 'not like',
    };
    const payload = filter?.value?.filter_data?.map((item: any) => ({
      field: item.ColumnName,
      condition: filterAdapter[item.Compare.toLowerCase()],
      value: item.CompareValue,
    }));
    setSelectedFields(payload || []);
    setFilterValue(filter);
  };

  React.useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}formscreen/get-filters?tableID=${activeScreen.tableID}`,
      )
      .then((res) => {
        console.log(res);
        const filtersToLoad = res?.data?.data?.filters?.filter(
          (item: any) => item.filter_name,
        );
        setLoadedFilters([
          { filter_name: '', filter_data: [] },
          ...filtersToLoad,
        ]);
        const adaptedFilter = filtersToLoad.find(
          (item: any) => item.filter_name === activeFilter?.[activeScreen.screenId],
        );
        if (adaptedFilter) {
          handleChangeFilter({
            value: adaptedFilter,
            label: adaptedFilter.filter_name,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  //
  // React.useEffect(() => { console.log(selectedFields); }, [selectedFields]);

  const allFields = allTableColumns
    .filter((item: any) => item.charAt(0) !== '#')
    .map((item: any) => ({ value: item, label: allLabels[item] || item, maxLength: allSizes[item] || item }));
  const options = [
    { value: '', label: '-- Select --' },
    { value: '<', label: 'Less Than' },
    { value: '=', label: 'Equal' },
    { value: '!=', label: 'Not Equal' },
    { value: '>', label: 'Greater Than' },
    { value: 'like', label: 'Contains' },
    { value: 'not like', label: 'Not Contains' },
  ];

  const handleSelectedField = (e: any, option: any) => {
    if (option === null) return;

    setSelectedFields([
      ...selectedFields,
      { field: option.value, condition: '', value: '' },
    ]);
  };

  const handleSetMatch = (e: any, option: any) => {
    if (option === null) return;

    setMatch(option === 'All Fields' ? ' AND ' : ' OR ');
  };

  const handleSaveFilter = async (filterName: string) => {
    if (filterName === undefined || filterName === '') {
      dispatch({
        type: SAVE_SCREEN_FILTER,
        payload: '',
        screenId: activeScreen.screenId,
      });
      return;
    }

    const filterAdapter: any = {
      '<': 'less',
      '=': 'equal',
      '!=': 'not_equal',
      '>': 'greater',
      like: 'contains',
      'not like': 'not_contains',
    };
    const filters = selectedFields.map((item: any) => ({
      column: item.field,
      operator: filterAdapter[item.condition],
      compare_value: item.value,
    }));
    const payload = {
      table: activeScreen.tableID,
      relation: match === ' AND ' ? 'all' : 'any',
      filter: filters,
      filter_name: filterName,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}formscreen/save-filters`,
        payload,
      );
      dispatch({
        type: SET_MESSAGE,
        message: `Filter ${filterName} set successfully`,
      });
      dispatch({
        type: SAVE_SCREEN_FILTER,
        payload: filterName,
        screenId: activeScreen.screenId,
      });
    } catch (err) {
      console.error(err);
    }
    console.log('payload', payload);
  };

  const handleSubmit = () => {
    const whereQuery = selectedFields
      .map((item: any) => (item.condition === 'like' || item.value === 'not like'
        ? { ...item, value: `%25${item.value}%25` }
        : item))
      .map((item: any) => `${item.field} ${item.condition} '${item.value}'`)
      .join(match);
    const whereQueryLabel = selectedFields
      .map((item: any) => (item.condition === 'like' || item.value === 'not like'
        ? { ...item, value: `%25${item.value}%25` }
        : item))
      .map(
        (item: any) => `${allLabels[item.field]} ${item.condition} '${item.value}'`,
      )
      .join(match);
    dispatch(
      LoadTables({
        tableID: activeScreen.tableID,
        page: activeScreen.page,
        results_per_page: activeScreen.results_per_page,
        where: whereQuery,
        fields: activeScreen.fields,
        keys: activeScreen.keys,
        defaultcolumns: activeScreen.defaultcolumns,
        EmptyRows: activeScreen.EmptyRows,
        redirect: activeScreen.redirect,
        screenId: activeScreen.screenId,
        whereRecord: activeScreen.whereRecord,
        order_by: '',
        fieldsConcatenated: activeScreen.fieldsConcatenated,
        labels: activeScreen.labels || '',
        hidden: activeScreen.hidden || '',
      }),
    );

    dispatch(
      SaveFilter(activeScreen.screenId, { filters: selectedFields, match }),
    );

    handleSaveFilter(filterValue?.value?.filter_name);

    handleClose();
  };
  const matchConfiguration: any = Object.values(FilterSaved);
  console.log('selected fields: ', matchConfiguration?.[0]?.match);
  const handleSelectCondition = (e: any, index: any) => {
    // console.log(e.target.value);
    setSelectedFields(
      selectedFields.map((item: any, itemIndex: any) => (index !== itemIndex ? item : { ...item, condition: e.target.value })),
    );
  };

  const handleChangeValue = (e: any, index: any) => {
    // console.log(e.target.value);
    setSelectedFields(
      selectedFields.map((item: any, itemIndex: any) => (index !== itemIndex ? item : { ...item, value: e.target.value })),
    );
  };

  const handleAddFilter = () => {
    const payload = {
      value: { filter_name: filterText, filter_data: [] },
      label: `${filterText} [Temporary]`,
    };
    setLoadedFilters([...loadedFilters, payload.value]);
    setFilterValue(payload);
    setSelectedFields([]);
  };

  const handleRemoveFilter = () => {
    try {
      axios.delete(
        `${process.env.REACT_APP_API_URL}tablescreen/deleterec?tableID=DPFilter where TableName='${activeScreen.tableID}' and Name='${filterValue.value.filter_name}'--&where=%5B%7B%27folio_mo%27%3A%2704%27%2C%27folio_no%27%3A%27001%27%2C%27supplier_no%27%3A%270000000000%27%2C%27prod_id%27%3A%27LUBAD3%27%7D%5D`,
      );
      dispatch({
        type: SAVE_SCREEN_FILTER,
        payload: '',
        screenId: activeScreen.screenId,
      });
      dispatch(DeleteFilter(activeScreen.screenId));
      dispatch(
        LoadTables({
          tableID: activeScreen.tableID,
          page: activeScreen.page,
          results_per_page: activeScreen.results_per_page,
          where: '',
          fields: activeScreen.fields,
          keys: activeScreen.keys,
          defaultcolumns: activeScreen.defaultcolumns,
          EmptyRows: activeScreen.EmptyRows,
          redirect: activeScreen.redirect,
          screenId: activeScreen.screenId,
          whereRecord: activeScreen.whereRecord,
          order_by: '',
          fieldsConcatenated: activeScreen.fieldsConcatenated,
          labels: activeScreen.labels || '',
          hidden: activeScreen.hidden || '',
        }),
      );
      handleClose();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '460px',
          backgroundColor: '#fff',
          position: 'relative',
          paddingBottom: '1rem',
        }}
      >
        <div style={{ paddingBottom: '2rem' }}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              gap: '1rem',
              margin: '0.5rem 0',
            }}
          >
            <div style={{ width: '70%' }}>
              <div
                style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}
              >
                <div
                  style={{
                    width: '25rem',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <span>Filter</span>
                </div>
                <Autocomplete
                  style={{ width: '100%' }}
                  options={
                    loadedFilters
                      ? loadedFilters.map((item: any) => ({
                        value: item,
                        label: item.filter_name || '--Select--',
                      }))
                      : []
                  }
                  value={filterValue}
                  onChange={(e: any, value: any) => handleChangeFilter(value)}
                  renderInput={(params) => (
                    <div style={{ display: 'flex' }}>
                      <TextField
                        {...params}
                        size="small"
                        onChange={(e: any) => setFilterText(e.target.value)}
                      />
                      <Button
                        variant="outlined"
                        onClick={handleAddFilter}
                        disabled={filterText.length < 1}
                      >
                        Add
                      </Button>
                    </div>
                  )}
                />
              </div>
              <div
                style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}
              >
                <div
                  style={{
                    width: '25rem',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <span>Add Search Field Max. 5</span>
                </div>
                <Autocomplete
                  disabled={selectedFields.length >= 5}
                  style={{ width: '100%' }}
                  onChange={handleSelectedField}
                  options={allFields}
                  renderInput={(params) => (
                    <TextField {...params} size="small" />
                  )}
                />
              </div>
            </div>

            <div style={{ width: '30%' }}>
              <div
                style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}
              >
                <div
                  style={{
                    width: '5rem',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <span>Share</span>
                </div>
                <Autocomplete
                  disableClearable
                  style={{ width: '10rem' }}
                  defaultValue="No"
                  options={['No', 'Yes']}
                  renderInput={(params) => (
                    <TextField {...params} size="small" />
                  )}
                />
              </div>
              <div
                style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}
              >
                <div
                  style={{
                    width: '5rem',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <span>Match</span>
                </div>
                <Autocomplete
                  disableClearable
                  style={{ width: '10rem' }}
                  onChange={handleSetMatch}
                  defaultValue="ALL Fields"
                  options={['All Fields', 'Any Field']}
                  renderInput={(params) => (
                    <TextField {...params} size="small" />
                  )}
                />
              </div>
            </div>
          </div>

          <div
            style={{
              width: '100%',
              display: 'flex',
              gap: '1rem',
              marginTop: '2rem',
            }}
          >
            <div
              style={{
                width: '25%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <span>Field</span>
            </div>
            <div
              style={{
                width: '25%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <span>Condition</span>
            </div>
            <div
              style={{
                width: '50%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <span>Value</span>
            </div>
          </div>
          {selectedFields.map((item: any, index: any) => (
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                gap: '1rem',
                margin: '1rem 0',
              }}
            >
              <div
                style={{
                  width: '25%',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  marginLeft: '10%',
                }}
              >
                <span>{allLabels[item.field]}</span>
              </div>
              <div
                style={{
                  width: '25%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <FormControl style={{ width: '100%' }} size="small">
                  <Select
                    value={item.condition}
                    onChange={(e: any) => {
                      handleSelectCondition(e, index);
                    }}
                  >
                    {options.map((option, key) => (
                      <MenuItem value={option.value}>{option.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div
                style={{
                  width: '50%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <TextField
                  style={{ width: '100%' }}
                  size="small"
                  value={item.value}
                  inputProps={{
                    maxLength: allSizes[item.field].toString(),
                  }}
                  onChange={(e: any) => {
                    handleChangeValue(e, index);
                  }}
                />
                <Button
                  onClick={(e) => {
                    setSelectedFields(
                      selectedFields.filter(
                        (_: any, indexToDelete: any) => index !== indexToDelete,
                      ),
                    );
                  }}
                >
                  <Delete />
                </Button>
              </div>
            </div>
          ))}
        </div>
        <Divider variant="middle" />
        {
          activeScreen.tableID === 'Carrier' && (
          <>
            <div style={{ padding: '0.5rem 1rem' }}>
              <Typography variant="body2">VSX Filter</Typography>
              <div className={clsx('flexRowAlignCenter', classes.itemContainer)}>
                <span style={{
                  width: '25%',
                  alignItems: 'flex-end',
                  display: 'flex',
                  flexDirection: 'column',
                  paddingRight: '10px',
                }}
                >
                  Supplier
                </span>
                <Autocomplete
                  disableClearable
                  style={{ width: '75%' }}
                  onChange={handleSetMatch}
                  options={[]}
                  renderInput={(params) => (
                    <TextField {...params} size="small" />
                  )}
                />
              </div>
              <div className={clsx('flexRowAlignCenter', classes.itemContainer)}>
                <span style={{
                  width: '25%',
                  alignItems: 'flex-end',
                  display: 'flex',
                  flexDirection: 'column',
                  paddingRight: '10px',
                }}
                >
                  Customer
                </span>
                <Autocomplete
                  disableClearable
                  style={{ width: '75%' }}
                  onChange={handleSetMatch}
                  options={[]}
                  renderInput={(params) => (
                    <TextField {...params} size="small" />
                  )}
                />
              </div>
              <div className={clsx('flexRowAlignCenter', classes.itemContainer)}>
                <span style={{
                  width: '25%',
                  alignItems: 'flex-end',
                  display: 'flex',
                  flexDirection: 'column',
                  paddingRight: '10px',
                }}
                >
                  Account
                </span>
                <Autocomplete
                  disableClearable
                  style={{ width: '75%' }}
                  onChange={handleSetMatch}
                  options={[]}
                  renderInput={(params) => (
                    <TextField {...params} size="small" />
                  )}
                />
              </div>
              <div className={clsx('flexRowAlignCenter', classes.itemContainer)}>
                <span style={{
                  width: '25%',
                  alignItems: 'flex-end',
                  display: 'flex',
                  flexDirection: 'column',
                  paddingRight: '10px',
                }}
                >
                  Destination
                </span>
                <Autocomplete
                  disableClearable
                  style={{ width: '75%' }}
                  onChange={handleSetMatch}
                  options={[]}
                  renderInput={(params) => (
                    <TextField {...params} size="small" />
                  )}
                />
              </div>
            </div>
            <Divider variant="middle" />
            <div style={{ padding: '0.5rem 1rem' }}>
              <Typography variant="body2">Certification</Typography>
              <div className={clsx('flexRowAlignCenter', classes.itemContainer)}>
                <span style={{
                  width: '25%',
                  alignItems: 'flex-end',
                  display: 'flex',
                  flexDirection: 'column',
                  paddingRight: '10px',
                }}
                >
                  Warning Dates
                </span>
                <Autocomplete
                  disableClearable
                  style={{ width: '75%' }}
                  onChange={handleSetMatch}
                  options={[]}
                  renderInput={(params) => (
                    <TextField {...params} size="small" />
                  )}
                />
              </div>
              <div className={clsx('flexRowAlignCenter', classes.itemContainer)}>
                <span style={{
                  width: '25%',
                  alignItems: 'flex-end',
                  display: 'flex',
                  flexDirection: 'column',
                  paddingRight: '10px',
                }}
                >
                  Denial Dates
                </span>
                <Autocomplete
                  disableClearable
                  style={{ width: '75%' }}
                  onChange={handleSetMatch}
                  options={[]}
                  renderInput={(params) => (
                    <TextField {...params} size="small" />
                  )}
                />
              </div>
              <div className={clsx('flexRowAlignCenter', classes.itemContainer)}>
                <span style={{
                  width: '25%',
                  alignItems: 'flex-end',
                  display: 'flex',
                  flexDirection: 'column',
                  paddingRight: '10px',
                }}
                >
                  Locked
                </span>
                <Autocomplete
                  disableClearable
                  style={{ width: '75%' }}
                  onChange={handleSetMatch}
                  options={[]}
                  renderInput={(params) => (
                    <TextField {...params} size="small" />
                  )}
                />
              </div>
            </div>
            <Divider variant="middle" />
          </>
          )
        }
        <span className="takeTheRest" />
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: 5,
            bottom: '0',
            width: '100%',
            padding: '0.5rem 1rem',
            boxSizing: 'border-box',
          }}
        >
          <Button
            disabled={
              !filterValue?.value?.filter_name
              || filterValue?.value?.filter_name !== filterValue?.label
            }
            variant="contained"
            color="error"
            onClick={handleRemoveFilter}
            sx={{
              borderRadius: '0px !important',
              color: 'black',
            }}
          >
            Remove
          </Button>
          <Button
            variant="contained"
            color="success"
            sx={{
              borderRadius: '0px !important',
              color: 'black',
            }}
            disabled={selectedFields.some(
              (field: any) => field.condition === '',
            )}
            onClick={handleSubmit}
          >
            Apply
          </Button>
          <Button
            variant="contained"
            color="warning"
            sx={{
              borderRadius: '0px !important',
              color: 'black',
              backgroundColor: '#ffbb33',
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FilterScreen;
