import * as React from 'react';
import ReactDOM from 'react-dom';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ColumnsScreen from '../columns';
import {
  BatchDeleteRecord, CloseColumnModal, DeleteRecord, LoadTables, openColumnModal,
} from '../../redux/actions/ScreenActions';
import ControlButton from '../controlButton';

const style = {
  position: 'absolute' as const,
  left: '10%',
  top: '10%',
  width: '70%',
  height: 170,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
};

const ConfirmationNextPrevious = (props:any) => {
  const {
    Visible, handleCloseConfirm, options, isbatch, tableId, setSelected, setBatchMode, handleCloseModalYes, handleDiscard, handleSaveMove, Step,
  } = props;
  const { activeSreen } = useSelector((state: any) => state.ScreenReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClose = () => {
    handleCloseConfirm();
  };
  const handleSave = () => {
    handleSaveMove();
  };

  return ReactDOM.createPortal(
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={Visible}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 460,
      }}
    >
      <Fade in={Visible}>
        <Box sx={style}>
          <div style={{ padding: 10, backgroundColor: '#d2d2d2' }}>
            <Typography>{`Move to ${Step.toLowerCase()} record`}</Typography>
          </div>
          <div style={{
            height: 'auto', display: 'flex', justifyContent: 'center', alignSelf: 'center', padding: '25px 0px',
          }}
          >
            <Typography>{`Changes have been made. You can Save your changes and move ${Step.toLowerCase()} or Discard your changes and move next.`}</Typography>
          </div>
          <Divider />
          <div style={{
            height: 'auto', padding: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center',
          }}
          >
            <Typography color="red">This action cannot be undone.</Typography>
            <div style={{
              height: 'auto', display: 'flex', gap: 5, alignItems: 'center', alignSelf: 'center',
            }}
            >
              <ControlButton
                label={`Save - Move ${Step}`}
                color="#00c851"
                disabled={false}
                handleClick={handleSave}
              />
              <ControlButton
                label={`Discard - Move ${Step}`}
                color="#red"
                disabled={false}
                handleClick={handleDiscard}
              />
              <ControlButton
                label="Cancel"
                color="#ffbb33"
                disabled={false}
                handleClick={handleClose}
              />

            </div>
          </div>
        </Box>
      </Fade>
    </Modal>,
    document.getElementById('draggable') as HTMLElement,
  );
};
export default ConfirmationNextPrevious;
