/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/function-component-definition */
import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Add, IndeterminateCheckBox, PlusOne } from '@mui/icons-material';
import CustomDropdown from '../customDropdown';
import CustomField from '../CustomField/CustomField';
import CustomInput from '../customInput';
import { useStyles } from '../../screens/Workflows/bulkTransaction/style';
import BulkTransactionProductStep from '../bulkTransactionProducts/bulkTransactionProductStep';

export default function VerticalLinearStepper({
  data, values, handleInputChange, handleSelectChange, valuesToSend, isBulkTransaction, visible,
}: any) {
  const [activeStep, setActiveStep] = React.useState(0);
  const classes = useStyles();
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div
      style={{
        width: '100%',
        padding: '20px 0px',
        display: 'flex',
        justifyContent: 'center !important',
        alignItems: 'center !important',
      }}
    >
      <Stepper activeStep={activeStep} orientation="vertical" sx={{ width: '100%' }}>
        {data?.map((step:any, index:any) => (
          <Step
            key={index}
            sx={{
              width: '100%',
              padding: '0px',
            }}
            onClick={() => setActiveStep(index)}
          >
            <StepLabel
              icon={index === activeStep ? <IndeterminateCheckBox color="inherit" /> : <Add />}
              sx={{
                backgroundColor: index === activeStep ? '#d8f0ff' : '#ffffff',
                border: '1px solid gray',
                fontWeight: 'bold',
              }}
            >
              <Typography variant="h6">
                {`${step[0].stepID}`}
                {index === activeStep ? '(Editing)'
                  : index < activeStep ? (
                    <div className={classes.container}>
                      <div className={classes.reviewContainer}>
                        {step.map((val:any, key:any) => (

                          val.value && (
                          <div className={classes.review}>
                            <Typography variant="body2" fontFamily="Open Sans" fontSize={14} fontWeight="bolder">
                              {val?.label}
                              {' '}
                            </Typography>
                            <Typography variant="body2" fontFamily="Open Sans" fontSize={14}>
                              {values?.[val?.id]?.value}
                              {' '}
                              -
                              {' '}
                              {values?.[val?.id]?.label}
                            </Typography>
                          </div>
                          )

                        ))}
                      </div>
                    </div>
                  )
                    : <></>}

              </Typography>
            </StepLabel>
            <StepContent>
              { isBulkTransaction && activeStep !== 3 ? (
                step.map((val:any, key:any) => (
                  !val.visible && !visible ? (
                    <></>
                  ) : (
                    ([
                      'aftx',
                      'tlist',
                      'country',
                      'state',
                      'dualsel01',
                      'routecode',
                      'dualselYN',
                      'aftx:inven_units',
                      'chain',
                      'emailgroup',
                      'faxgroup',
                      'dualselGN',
                      'transval',
                      'termid',
                      'product',
                      'language',
                      'carrier',
                      'accessprofile',
                      'dualselAI',
                      'shippingmethodid',
                      '',
                    ].includes(val?.type))
                      ? (
                        <CustomDropdown
                          key={uuidv4()}
                          val={val}
                          isBatch={false}
                          label={val?.label}
                          name={val?.id}
                          defaultValue={val?.value}
                          isDisabled={false}
                          value={values[val?.id]}
                          isOptionEqualToValue={(option: any, value: any) => option?.label === value}
                          onChange={handleSelectChange}
                          size="70%"
                          margin={0}
                          options={
                            val?.obj['\u0000*\u0000parm'].options
                          }
                          type={val?.type}
                          obj={val?.obj}
                          query={val?.query}
                          required={val?.attr?.includes('required')}
                          selectList={val?.selectList}
                          chain={val?.chain}
                          stateValues={values}
                          valuesToSend={valuesToSend}
                        />
                      )
                      : (
                        <CustomInput
                          timeType
                          type={val?.type}
                          label={val?.label}
                          required={val?.required}
                          name={val?.id}
                          defaultValue={val?.valForm}
                          attr={val?.attr}
                          value={val?.value}
                          onChange={handleInputChange}
                          size="70%"
                          maxLength={val?.obj['\u0000*\u0000parm'].maxLength}
                          inputProps={{
                            maxLength: val?.obj['\u0000*\u0000parm'].maxLength,
                          }}
                          placeholder={val.helpertext}
                          isDisabled={false}
                          validate={val?.validate}
                        />
                      )
                  )))
              ) : <BulkTransactionProductStep />}

              <Box sx={{ mb: 2 }}>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {index === data.length - 1 ? 'Finish' : 'Next Step'}
                  </Button>
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Prev Step
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === data.length && (
      <Paper square elevation={0} sx={{ p: 3 }}>
        <Typography>All steps completed - you&apos;re finished</Typography>
        <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
          Reset
        </Button>
      </Paper>
      )}
    </div>
  );
}
