import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import Subnavbar from '../../components/Sub-navbar/Subnavbar';
import { LoadRecord } from '../../redux/actions/ScreenActions';
import FormBodyTerminal from '../../components/formBodyTermDetail/formBodyTerminal';
import FormHeaderTerminal from '../../components/FormHeaderTermDetail/FormHeaderTerminal';

const TermProfileForm = () => {
  const location = useLocation();
  const routeBack = location.state;
  console.log(routeBack);
  const dispatch = useDispatch();
  const { recordDetails } = useSelector((state: any) => state.ScreenReducer);

  useEffect(() => {
    dispatch(LoadRecord(location.state));
  }, []);

  const { _, ...restData } = recordDetails;

  return (
    recordDetails.length === 0 ? (
      <div style={{
        width: '100%',
        height: '90vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      >
        <CircularProgress />
      </div>
    )
      : (
        <>
          <Subnavbar
            label="Terminal Profile: Detail"
            menuOptionType="simpleItems"
          />
          <FormHeaderTerminal
            dataHeader={_}
            routeBack={routeBack}
          />
          <FormBodyTerminal dataTabs={restData} />
        </>
    // )
      )
  );
};

export default TermProfileForm;
