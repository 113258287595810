/* eslint-disable max-len */
import React from 'react';
import TextField from '@mui/material/TextField';
import ReactInputMask from 'react-input-mask';

const MaskInput = (props: any) => {
  const {
    mask,
    isMaskValueFractional,
    maskPlaceholder,
    onChange,
    value,
    size,
    name,
    isParentSetOfDatagridField,
  } = props;

  const applyMask = (val: string, decimals: number, fractions: number) => {
    if (val === '') {
      return val;
    }

    return `${String(val?.split('.')[0] ?? val).padStart(decimals, '0')}.${String(val?.split('.')[1] ?? '').padEnd(fractions, '0')}`;
  };

  const [maskedValue, setMaskedValue] = React.useState(value);

  React.useEffect(() => {
    setMaskedValue(applyMask(value, mask?.split('.')[0]?.length, mask?.split('.')[1]?.length));
  }, [mask]);

  // Mask is applied on whole numeric values
  const handleChangeWithNumericMask = (e: any) => {
    onChange({ target: { name, value: e.target.value.replace(/[^\d]/g, '') } });
  };

  // Mask is applied on fractional numeric values only on the UI
  // Value is sent unmasked; e.g: 12.000 is sent as 12000
  const handleChangeUnmasked = (e: any) => {
    const decimalLength = mask?.split('.')[0]?.length || mask?.length;
    const fractionalLength = mask?.split('.')[1]?.length;
    const isFractional = e.target.value?.includes('.');

    if (!isFractional) {
      if (decimalLength + 1 > e.target.value?.length) {
        setMaskedValue(e.target.value);
        onChange({ target: { name, value: e.target.value } });
      }
    } else if (fractionalLength + 1 > e.target.value?.split('.')[1]?.length) {
      setMaskedValue(e.target.value);
      onChange({ target: { name, value: e.target.value } });
    }
  };

  const handleBlurMasked = () => {
    setMaskedValue(applyMask(value, mask?.split('.')[0]?.length, mask?.split('.')[1]?.length));
  };

  if (isMaskValueFractional) {
    return (
      <TextField
        type="number"
        value={maskedValue}
        onChange={(e: any) => handleChangeUnmasked(e)}
        onBlur={handleBlurMasked}
        className="textfield"
        placeholder={maskPlaceholder}
        size="small"
        style={{
          flex: 3, width: size,
        }}
        sx={{
          '& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
            padding: '4px 8px',
            border: '1px solid #dddddd',
          },
          '& .MuiInputBase-root': {
            '& > fieldset': {
              borderLeft: '5px solid #555',
              borderRadius: '0px !important',
            },
          },
          width: size,
        }}
      />
    );
  }

  return (
    <ReactInputMask
      mask={mask}
      value={value}
      onChange={(e: any) => handleChangeWithNumericMask(e)}
      style={{ flex: 3 }}
    >
      { (maskProps: any) => (
        <TextField
          className="textfield"
          {...maskProps}
          size="small"
          sx={{
            '& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
              padding: '4px 8px',
              border: '1px solid #dddddd',
            },
            '& .MuiInputBase-root': {
              '& > fieldset': {
                borderLeft: '5px solid #555',
                borderRadius: '0px !important',
              },
            },
            width: size,
          }}
        />
      )}
    </ReactInputMask>
  );
};

export default MaskInput;
