import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    border: '1px solid #555',
    background: '#eee',
    zIndex: 1,
    position: 'absolute',
  },
  table: {
    tableLayout: 'fixed',
    border: '1px solid #eee',
    width: '100%',
    borderCollapse: 'collapse',
    marginBottom: '5px',
  },
  td: {
    textAlign: 'center',
    border: '1px solid #eee',
    padding: '0 2px',
  },
  keybButton: {
    fontSize: 'calc(8px + 0.55vh)',
    borderWidth: 1,
    borderColor: '#ddd',
    padding: '5px 12px',
    backgroundColor: 'white',
    width: '100%',
    display: 'block',
    paddingLeft: 0,
    paddingRight: 0,
    cursor: 'pointer',
    userSelect: 'none',
    '&:hover': {
      color: '#333',
      backgroundColor: '#e6e6e6',
      borderColor: '#adadad',
    },
    '&:active': {
      color: '#fff',
      backgroundColor: '#3A3A40',
    },
  },
  tr: { height: 28 },
  enterButton: {
    backgroundColor: '#35aa47',
    color: 'white',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#1d943b !important',
      outline: 'none !important',
    },
    '&:active': {
      borderColor: '#494F57 !important',
      borderWidh: '1px !important',
    },
  },
  stopButton: {
    backgroundColor: '#d84a38',
    color: 'white',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#d51400 !important',
      outline: 'none !important',
    },
    '&:active': {
      borderColor: '#494F57',
    },
  },
});
