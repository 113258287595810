import { CheckBox, Close, Delete } from '@mui/icons-material';
import {
  Autocomplete, Button, Checkbox, Divider, MenuItem, Select, TextField, Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';

const useStyles = makeStyles({
  root: {},
  header: {
    width: '80%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '10px 0px',

  },
  dropdownUnit: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
  container: {
    border: '0.5px solid black',
    width: '99%',
    padding: '5px',
    margin: '10px 0px',
  },
  product: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});
const UniteOfMeasure = [
  {
    val: 'G',
    label: 'G: Gallons',
  },
  {
    val: 'B',
    label: 'B: Barrels',
  },
];
const BulkTransactionProductStep = () => {
  const classes = useStyles();
  const [products, setProducts] = useState<any>([]);
  const addProducts = () => {
    setProducts([...products, { prodId: '', productName: '' }]);
  };
  const removeFormFields = (i:any) => {
    const deleteProd:any = [...products];
    deleteProd.splice(i, 1);
    setProducts(deleteProd);
  };
  return (
    <div>
      <div className={classes.header}>
        <Button variant="contained" color="success" sx={{ textTransform: 'none' }} onClick={addProducts}>Add New Product</Button>
        <div className={classes.dropdownUnit}>
          <Typography>
            Unit Of Measure
          </Typography>
          <Autocomplete
            disablePortal
            size="small"
            id="combo-box-demo"
            options={UniteOfMeasure}
            sx={{
              '& .MuiInputBase-root': {
                '& > fieldset': {
                  color: 'gray',
                  borderLeft: '5px solid #ff3b3b',
                  borderRadius: '0px !important',
                },
              },
              width: 300,
            }}
            renderInput={(params) => <TextField {...params} label="Unite Of Measure" />}
          />
          <Checkbox disabled />
          <Typography>
            Show / Hide Base Unit Qty
          </Typography>
        </div>

      </div>
      {products.map((product:any, key:any) => (
        <div className={classes.container}>
          <Typography color="blueviolet">
            Products
          </Typography>
          <Divider sx={{ color: 'blue' }} />
          <div className={classes.product}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={0}
              label="Products"
              onChange={() => console.log('test')}
              size="small"
              sx={{ width: '20%' }}
            >
              <MenuItem value={0}>No Data Available</MenuItem>
            </Select>
            <Close onClick={() => removeFormFields(key)} />
          </div>
        </div>
      ))}
    </div>
  );
};
export default BulkTransactionProductStep;
