import React, { useState } from 'react';

// Librairies
import { ButtonBase, InputBase } from '@mui/material';
import clsx from 'clsx';

// Components
import SubHeader from '../../../components/subHeader';
import TmsHeader from '../../../components/header';
import OptionsButton from '../../../components/optionsButton';
import SingleItemOptions from '../../../components/SingleItemOptions';
import FolioFreezeCloseTable from '../../../components/FolioFreezeCloseTable';
import Select from '../../../components/Select';
import ConfirmationDialog from '../../../components/ConfirmDialog';
import Checkbox from '../../../components/Checkbox';

// Styles
import { useStyles } from './style';

const FolioFreezeClose: React.FC = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [freezeConfirmDialogOpen, setFreezeConfirmDialogOpen] = useState<boolean>(false);
  const [closeFolioConfirmDialogOpen, setCloseFolioConfirmDialogOpen] = useState<boolean>(false);
  const [closeChecked, setCloseChecked] = useState<boolean>(false);

  const handleOptionClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenFreezeConfirmDialog = () => {
    setFreezeConfirmDialogOpen(true);
    handleCloseMenu();
  };

  const handleCloseFreezeConfirmDialog = () => {
    setFreezeConfirmDialogOpen(false);
  };

  const handleOpenCloseFolioConfirmDialog = () => {
    setCloseFolioConfirmDialogOpen(true);
    handleCloseMenu();
  };

  const handleCloseCloseFolioConfirmDialog = () => {
    setCloseFolioConfirmDialogOpen(false);
  };

  const handleCheckBoxChange = () => {
    setCloseChecked(!closeChecked);
  };

  return (
    <>
      <TmsHeader />
      <SubHeader title="Folio Freeze/Close">
        <OptionsButton handleClick={handleOptionClick} />
        <SingleItemOptions
          anchorEl={anchorEl}
          onClose={handleCloseMenu}
          openDialog={() => null}
          itemLabel="Columns ..."
        />
      </SubHeader>
      <div className={classes.actionsWrapper}>
        <ButtonBase
          className={classes.freezeButton}
          onClick={handleOpenFreezeConfirmDialog}
        >
          Freeze
        </ButtonBase>
        <ButtonBase
          className={classes.closeButton}
          onClick={handleOpenCloseFolioConfirmDialog}
        >
          Close
        </ButtonBase>
      </div>
      <div
        className={clsx('flexStartSpaceBetweenRow', classes.subHeaderContainer)}
      >
        <div className={clsx('flexRow')}>
          <span className={classes.label}>Records :</span>
          <Select
            options={['Auto', 10, 20, 35, 50, 'All']}
            width={200}
            value="Auto"
          />
        </div>
        <div className="flexRow">
          <span className={classes.label}>Search :</span>
          <InputBase className={classes.searchInput} />
        </div>
      </div>
      <FolioFreezeCloseTable handleClickRow={() => null} />
      <ConfirmationDialog
        open={freezeConfirmDialogOpen}
        handleCloseAgree={handleCloseFreezeConfirmDialog}
        message="Would you like to Freeze Folio July 001?"
        handleCloseCancel={handleCloseFreezeConfirmDialog}
        title="Freeze Folio"
        cancelLabel="No"
        confirmLabel="Yes"
      />
      <ConfirmationDialog
        open={closeFolioConfirmDialogOpen}
        handleCloseAgree={handleCloseCloseFolioConfirmDialog}
        message="Would you like to Close Folio April 006?"
        handleCloseCancel={handleCloseCloseFolioConfirmDialog}
        title="Close Folio"
        cancelLabel="No"
        confirmLabel="Yes"
      >
        <Checkbox
          label="Close up to"
          checked={closeChecked}
          onChange={() => null}
          onClick={handleCheckBoxChange}
        />
        <Select options={[]} style={{ width: '100%' }} />
      </ConfirmationDialog>
    </>
  );
};

export default FolioFreezeClose;
