/* eslint-disable react/no-unknown-property */
/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useRef } from 'react';
import {
  StyleSheet, View, Text,
} from '@react-pdf/renderer';
import { useReactToPrint } from 'react-to-print';
import { Button, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const styles = StyleSheet.create({
  body: {
    padding: '20px',
    width: '100%',
  },
  table: {
    flexDirection: 'column',
    borderSpacing: '5px',
    width: '100%',
  },
  title: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  columns: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    pAlign: 'center',
    margin: '5px',
    width: '100%',
  },
  coldiv: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    flex: 1,
  },
  p: {
    fontSize: 10,
    pAlign: 'left',
    fontWeight: 'thin',
    flexWrap: 'wrap',
    wordWrap: 1,
    flexShrink: 1,
    pOverflow: 'ellipsis',
    overflow: 'hidden',
    flexGrow: 1,
  },
  header: {
    fontFamily: 'Helvetica',
  },
  footer: {
    position: 'absolute',
    bottom: 5,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: 999,
    left: 10,
    right: 10,
  },
  footerContent: {
    fontSize: 12,
    color: 'grey',
  },
  divider: {
    borderBottom: '1px solid black',
    width: '100%',
  },
  top: {
    fontSize: 10,
  },
  topTitle: {
    flexDirection: 'row',
    marginVertical: 15,
    alignSelf: 'center',
    width: '100%',
  },
  topLeft: {
    height: 'auto',
  },
});

const PDFFile = () => {
  const { state }:any = useLocation();
  const { data, redirect } = state || {};
  const {
    activeScreen,
    ScreensList,
  } = useSelector((stateReducer: any) => stateReducer?.ScreenReducer);
  const componentRef:any = useRef();
  const navigate = useNavigate();
  const handleExport = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'export-data',
    onAfterPrint: () => navigate(`${redirect}`),
  });
  console.log('spllllit', data.header[1]);
  const getScreenNameByIdAndMenuType = () => {
    const currentScreen = ScreensList.filter((e: any) => e.tableId === activeScreen.tableID)[0];
    return currentScreen?.label;
  };
  useEffect(() => {
    setTimeout(() => {
      handleExport();
    }, 5000);
  }, []);
  handleExport();
  return (
    <>
      <div ref={componentRef} style={styles.body}>
        <div style={{
          width: '100%', flexDirection: 'row', alignItems: 'center', display: 'flex', gap: 100,
        }}
        >
          <p style={{
            alignSelf: 'flex-start', fontSize: 8, marginRight: 50,
          }}
          >
            {data.header[2][1]}
          </p>
          <div style={{ display: 'flex', flexWrap: 'nowrap', justifyContent: 'center' }}>
            { data.header.map((topTitle:any, keyTop:any) => (
              <p style={styles.top}>{topTitle.join(' ')}</p>
            ))}
          </div>
        </div>
        <div style={{
          display: 'inline-block',
          height: '50px',
          lineHeight: 0,
        }}
        >
          <p style={{ fontFamily: 'Open Sans', fontSize: '40px' }}>{getScreenNameByIdAndMenuType()}</p>
          <p style={{ fontFamily: 'Open Sans', fontSize: '40px' }}>{data.header[2]?.toString()?.split(',')?.[1]}</p>
          <p style={{ fontFamily: 'Open Sans', fontSize: '40px' }}>{data.header[1]?.join(' ')}</p>
        </div>
        <div style={styles.table}>
          <div style={styles.columns}>
            {Object.keys(data.body[0]).map((column:any, indexcol:any, length:any) => (
              <div style={styles.coldiv}>
                <p style={styles.title}>
                  {column}
                </p>
              </div>
            ))}
          </div>
          <div style={styles.divider} />
          {data?.body?.map((val:any, key:any) => (
            <div style={{ ...styles.columns, borderBottom: '0.5px solid gray' }}>
              {Object.keys(data?.body[0]).map((col:any, index:any, num:any) => (
                <div style={styles.coldiv}>
                  <p>
                    {val[col]}
                  </p>
                </div>

              ))}
            </div>
          ))}
        </div>
        <View style={styles.footer}>
          <p style={styles.footerContent}>
            about:blank
          </p>
          <Text
            style={styles.footerContent}
            render={({ pageNumber, totalPages }:any) => `${pageNumber} / ${totalPages}`}
          />
        </View>

      </div>
    </>
  );
};

export default PDFFile;
