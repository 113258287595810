/* eslint-disable max-len */
/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import {
  Button, Checkbox, FormControlLabel, FormGroup, InputAdornment, MenuItem, Pagination, Select, Switch, TextField,
} from '@mui/material';
import {
  Close, ClosedCaption, Edit, Search,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import {
  DeleteRecord, LoadAllColumnsTables, LoadTables, SetBatchMode, SetRecordsList, StartLoading, StopLoading,
} from '../../redux/actions/ScreenActions';
import ColumnsModal from '../CustomModal/ColumnsModal';
import ConfirmationModal from '../confirmationModal';
import useDebounce from '../../utils/searchDebounce';
import TransitionsModal from '../CustomModal';
import { isObject } from '../../utils';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number,
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
  order: Order;
  orderBy: string;
  headCells: any;

}
interface EnhancedTabledataProps {
  headCells: any;
  rows: any;
  pages:any;
}

const EnhancedTableHead = (props: EnhancedTableProps) => {
  const {
    order,
    orderBy,
    headCells,
    onRequestSort,
  } = props;
  const createSortHandler = (property: any) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead sx={{ boxShadow: 3 }}>
      <TableRow>
        {headCells?.map((headCell:any) => (
          <TableCell
            key={headCell}
            align={headCell.numeric ? 'right' : 'left'}
            padding="none"
            sortDirection={orderBy === headCell ? order : false}
            style={{
              background: '#F5F5F5', border: '2px solid #fff', fontWeight: 'bold', padding: '5px 20px',
            }}
          >
            <TableSortLabel
              active={orderBy === headCell}
              direction={orderBy === headCell ? order : 'asc'}
              onClick={createSortHandler(headCell)}
              style={{ width: '100%', justifyContent: 'space-between' }}
            >
              {headCell}
              {orderBy === headCell ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const EnhancedTable = (props: EnhancedTabledataProps) => {
  const {
    rows, headCells, pages,
  } = props;

  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<any>('');
  const [selected, setSelected] = React.useState<any>([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState<any>(10);
  const [searching, setSearching] = React.useState(true);
  const [Visible, setVisible] = React.useState(false);
  const column = (rows && rows[0]) ? Object.keys(rows?.[0]) : [];
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [search, setSearch] = React.useState<string>('');

  const Rows = React.useMemo(() => rows?.filter((item:any) => Object.values(item).some((value:any) => value?.toLowerCase().includes(search.toLowerCase()))), [rows, search]);

  function extractContent(s:any) {
    const span:HTMLElement = document.createElement('span');
    span.innerHTML = isObject(s) ? s.label : s;
    return span.textContent || span.innerText;
  }

  const lookupPropertyName: any = {
    Option: 'option',
    Description: 'description',
  };

  const lookupPropertyLabel: any = {
    option: 'Option',
    description: 'Description',
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any,
  ) => {
    const isAsc = orderBy === lookupPropertyName[property] && order === 'asc';

    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(lookupPropertyName[property]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, row: any) => {
    console.log('click');
  };

  const handleChange = async (event:any) => {
    setRowsPerPage(event.target.value as number);
    const maxValueForPage = Math.ceil(pages / event.target.value as number);
    setCurrentPage(Math.max(1, maxValueForPage < currentPage ? maxValueForPage : currentPage));
  };

  const handleChangePage = async (event:any, targetPage:any) => {
    setCurrentPage(targetPage);
  };
  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows?.length) : 0;
  // Load all table columns

  const handleSearch = (searchStr:string) => {
    setSearch(searchStr);
    setCurrentPage(1);
  };

  return (
    <Box sx={{ padding: '0px 8px' }}>
      <Box sx={{ mb: 2 }}>
        <Box sx={{
          width: '97%', mb: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '5px 0px',
        }}
        >
          <div style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
          >
            <TextField
              size="small"
              label="search"
              onChange={(e:React.ChangeEvent<HTMLInputElement>) => handleSearch(e.target.value)}
            />
          </div>

        </Box>
        {(searching && Rows?.length !== 0) || (!searching && rows?.length && headCells?.length) ? (
          <TableContainer sx={{ boxShadow: 5 }}>
            <Table
              sx={{ boxShadow: 3, width: '100%' }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                order={order}
                orderBy={lookupPropertyLabel[orderBy]}
                onRequestSort={handleRequestSort}
                headCells={headCells}
              />

              <TableBody sx={{ boxShadow: 3 }}>

                {stableSort(searching ? Rows : rows, getComparator(order, orderBy))
                  .slice((currentPage - 1) * rowsPerPage, (currentPage - 1) * rowsPerPage + rowsPerPage)
                  .map((row: any, index, key:any) => (
                    <TableRow
                      hover
                      onClick={(event:any) => handleClick(event, row)}
                      role="checkbox"
                      tabIndex={-1}
                      key={uuidv4() as any}
                      selected={false}
                      sx={{
                        backgroundColor: index % 2 ? '#F5F5F5' : 'white',
                        cursor: 'pointer',
                        '&:hover': {
                          background: '#F7EBD0',
                        },
                      }}
                    >
                      {
                             column?.map((v:any) => (
                               <TableCell
                                 sx={{
                                   padding: '10px 20px',
                                   border: '2px solid #fff',
                                   fontSize: 12,
                                   width: 'auto',
                                   //  backgroundColor: row[v].search('DATA INTEGRITY FAILURE') === -1 ? 'white' : '#F7EBD0',

                                 }}
                               >
                                 {extractContent(row[v])}
                               </TableCell>
                             ))
     }
                    </TableRow>
                  ))}
              </TableBody>

            </Table>
          </TableContainer>
        ) : (
          <TableContainer sx={{ boxShadow: 5, width: '100%' }}>
            <Table
              sx={{ minWidth: 750, boxShadow: 3 }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                headCells={headCells}
              />
              <div style={{ width: '100%' }}><Typography style={{ marginLeft: 10 }}>No matching records found</Typography></div>
            </Table>
          </TableContainer>
        )}
        <div style={{
          display: 'flex', justifyContent: 'space-between', margin: '10px 0px',
        }}
        >
          <div style={{
            minWidth: '30%',
            gap: 15,
            display: 'flex',
            alignItems: 'center',
          }}
          >
            <Typography className="f12">
              Items per page:
            </Typography>
            <Select
              size="small"
              variant="standard"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={rowsPerPage}
              label="Rows"
              onChange={handleChange}
              style={{ width: 70 }}
            >
              <MenuItem className="f12" value={10}>auto</MenuItem>
              <MenuItem className="f12" value={15}>15</MenuItem>
              <MenuItem className="f12" value={20}>20</MenuItem>
              <MenuItem className="f12" value={25}>25</MenuItem>
              <MenuItem className="f12" value={35}>35</MenuItem>
              <MenuItem className="f12" value={50}>50</MenuItem>
              <MenuItem className="f12" value={100}>all</MenuItem>
            </Select>
            {Rows.length > 0 && <Typography variant="caption">{`Showing ${(currentPage - 1) * Number(rowsPerPage) + 1} to ${Math.min(currentPage * Number(rowsPerPage), Rows.length)} of ${Rows.length} entries`}</Typography>}
          </div>
          <Pagination count={Math.ceil((Rows?.length || 0) / rowsPerPage)} onChange={handleChangePage} page={currentPage} variant="outlined" shape="rounded" style={{ alignSelf: 'flex-end' }} />
        </div>

      </Box>
      <ColumnsModal />
      <TransitionsModal />
    </Box>
  );
};
export default EnhancedTable;
