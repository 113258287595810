import React from 'react';

// Librairies
import { Divider, Menu, MenuItem } from '@mui/material';

// Styles
import { useStyles } from './style';

interface IProps {
  anchorEl: any;
  onClose: () => void;
}

const DeviceCommunicationsOptions: React.FC<IProps> = ({
  anchorEl,
  onClose,
}) => {
  const classes = useStyles();
  return (
    <Menu
      classes={{ root: classes.root, list: classes.list, paper: classes.paper }}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
    >
      <MenuItem className="f12" classes={{ root: classes.menuItem }}>Summary View</MenuItem>
      <Divider />
      <MenuItem className="f12" classes={{ root: classes.menuItem }}>Columns</MenuItem>
      <MenuItem className="f12" classes={{ root: classes.menuItem }}>Filter...</MenuItem>
      <Divider />
      <MenuItem className="f12" classes={{ root: classes.menuItem }}>
        Export Visible Columns (CSV)
      </MenuItem>
      <MenuItem className="f12" classes={{ root: classes.menuItem }}>
        Export All Columns (CSV)
      </MenuItem>
      <MenuItem className="f12" classes={{ root: classes.menuItem }}>
        Export Visible Columns (XLSX)
      </MenuItem>
      <MenuItem className="f12" classes={{ root: classes.menuItem }}>
        Export All Columns (XLSX)
      </MenuItem>
    </Menu>
  );
};

export default DeviceCommunicationsOptions;
