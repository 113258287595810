import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TmsHeader from '../../../components/header';
import VerticalLinearStepper from '../../../components/verticalStepper/verticalStepper';
import {
  GetBulkTransactionData,
  getScreensList,
} from '../../../redux/actions/ScreenActions';
import BulkTransactionMenu from './bulkTransactionMenu';
import { useStyles } from './style';

const BulkTransaction = () => {
  const classes = useStyles();
  const [Values, setValues] = useState({});
  const [ValuesToSend, setValuesToSend] = useState({});
  const [visible, setVisible] = useState(false);
  const { loading, stepperData } = useSelector(
    (state: any) => state.ScreenReducer,
  );
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValues({
      ...Values,
      [name]: value,
    });
    setValuesToSend({
      ...ValuesToSend,
      [name]: value,
    });
  };
  const handleSelectChange = (
    label: any,
    e: React.ChangeEvent<HTMLInputElement>,
    newValue: any,
  ) => {
    setValues({
      ...Values,
      [label]: newValue,
    });
    setValuesToSend({
      ...ValuesToSend,
      [label]: newValue.value,
    });
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getScreensList());
    dispatch(GetBulkTransactionData());
  }, []);
  console.log('stepperData', stepperData);
  return loading ? (
    <div
      style={{
        width: '100%',
        height: '90vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress />
    </div>
  ) : (
    <>
      <TmsHeader />
      <div className="sub-nav-container">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h3 id="pagetitlebar_title" className="page-title">
            Bulk Transaction
          </h3>
        </div>
        <BulkTransactionMenu setVisible={setVisible} visible={visible} />
      </div>
      <VerticalLinearStepper
        data={stepperData}
        values={Values}
        ValuesToSend={ValuesToSend}
        handleSelectChange={handleSelectChange}
        handleInputChange={handleInputChange}
        isBulkTransaction
        visible={visible}

      />
    </>
  );
};

export default BulkTransaction;
