import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Search } from '@mui/icons-material';
import './HomeSearchInput.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import { getScreensList, LoadRecord, LoadTables } from '../../redux/actions/ScreenActions';
import useWindowDimensions from '../../utils/windowDimensions';

const SmartNavSearch = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { height, width } = useWindowDimensions();
  const { ScreensList, loading } = useSelector((state: any) => state.ScreenReducer);
  const {
    Language,
  } = useSelector((state: any) => state.LoginReducer);
  return (
    <Autocomplete
      freeSolo
      disableClearable
      id="free-solo-2-demo"
      placeholder="Good Afternoon. How Can I help you"
      options={ScreensList}
      onChange={async (event: any, option: any) => {
        if (option.type === 'Workflows') {
          navigate(`${option.path}`);
          return;
        }
        navigate('/home');

        const fields = Array.from(new Set([...option.fields.split(',')])).join(',');
        dispatch(
          LoadTables({
            tableID: option.tableId,
            page: '1',
            results_per_page: `${Math.ceil(height / 60)}`,
            fields: option.fields ? fields : '',
            fieldsConcatenated: option.fieldsConcatenated,
            order_by: '',
            where: option.screenConfiguration || '',
            redirect: `/maint/${option.screenId}`,
            screenId: option.screenId,
            keys: option.keys || '',
            defaultcolumns: option.defaultcolumns || '',
            lang: Language,
            labels: option.labels || '',
            hidden: option.hiddenColumns || '',
            navigate,
          }),
        );
        dispatch(LoadRecord({
          tableID: option.tableId,
          screenID: option.screenId,
          fields: option.fields || '',
          where: '',
        }));
      }}
      sx={{ width: '300px' }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Good Afternoon. How Can I help you"
          size="small"
          color="info"
          sx={{
            backgroundColor: '#ececec',
          }}
          variant="standard"
        />
      )}
    />
  );
};

export default SmartNavSearch;
