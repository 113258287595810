import { CircularProgress, TextField } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import RakStautsCard from '../../../components/cardRackStatus/rackStatusCard';
import CustomInput from '../../../components/customInput';
import TmsHeader from '../../../components/header';
import { getScreensList } from '../../../redux/actions/ScreenActions';

const RackStatus = () => {
  const [Values, setValues] = useState([1, 2, 3, 4, 5, 6, 7, 8]);
  const [Loading, setLoading] = useState(true);
  const history = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getScreensList());
  }, []);

  const getRackStatusData = async () => {
    try {
      const response = await axios.get(
        'https://api-dev-tf.toptechc1.com/v1/workflow060/data',
      );
      setValues(response.data);
      console.log(response.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getRackStatusData();
  }, []);
  return (
    <>
      <TmsHeader />
      <div className="sub-nav-container">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h3 id="pagetitlebar_title" className="page-title">
            Rack Status
          </h3>
        </div>
      </div>
      <div
        style={{
          width: '74%',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          alignSelf: 'flex-end',
          marginLeft: '25%',
          marginBottom: '10px',
        }}
      >
        <CustomInput
          placeholder="Dynamic filter"
          value=""
          type="text"
          size={1300}
          label="Filter"
          isDisabled={false}
          defaultValue=""
          maxLength={100}
          name="rackStatusFilter"
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignSelf: 'center',
            width: 'inherit',
            padding: 10,
            marginTop: 10,
            backgroundColor: '#e5e5e5',
          }}
        >
          viewing 8 of 8
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexWrap: 'wrap',
          gap: '10px',
        }}
      >
        {Values.map((item: any) => (
          <RakStautsCard history={history} />
        ))}
      </div>
    </>
  );
};

export default RackStatus;
