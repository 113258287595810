import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import TmsHeader from '../../../components/header';
import VerticalLinearStepper from '../../../components/verticalStepper/verticalStepper';
import { getScreensList } from '../../../redux/actions/ScreenActions';

const NonBulkTransaction = () => {
  const [Values, setValues] = useState({});
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getScreensList());
  }, []);
  return (
    <>
      <TmsHeader />
      <div className="sub-nav-container">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h3 id="pagetitlebar_title" className="page-title">
            Non Bulk Transaction
          </h3>
        </div>
      </div>
      <VerticalLinearStepper />
    </>

  );
};

export default NonBulkTransaction;
