import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    padding: '0px 10px',
  },
  tableRoot: {
  },
  headerCell: {
    borderBottom: 'none !important',
    padding: '0px !important',
  },
  headerRow: {
    boxShadow: '0 1px 7px #555',
  },
  bodyCell: {
    borderBottom: 'none !important',
    padding: '8px 0px !important',
  },
  bodyCellEmpty: {
    borderBottom: 'none !important',
    padding: '8px 7px !important',
  },
  tableRow: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#fbf8e9 !important',
    },
  },
  button: {
    color: '#ccc !important',
    backgroundColor: '#eee !important',
    border: '1px solid #bbb !important',
    padding: '0.5em 1em !important',
  },
  buttonContainer: {
    display: 'flex',
    gap: 5,
  },
  label: {
    fontSize: 12,
    fontWeight: 400,
  },
  actionsContainer: {
    marginTop: 10,
  },
});
