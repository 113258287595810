import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { CircularProgress, Pagination } from '@mui/material';
import {
  fetchRecordHistory,
  clearRecordHistory,
} from '../../redux/actions/RecordActions';

const useStyles = makeStyles({
  table: {
    marginTop: 15,
  },
  tableCell: {
    padding: '5px 9px',
    borderBottom: '1px solid #d2d2d2',
    height: 23,
    fontSize: 12,
    whiteSpace: 'nowrap',
  },
  headerRow: {
    boxShadow: '0 1px 7px #555',
  },
  tableRow: {},
  headerCell: {
    padding: '5px 9px',
    fontWeight: 'bold',
    border: '1px solid #fff',
    backgroundColor: '#fff !important',
    color: '#222 !important',
    whiteSpace: 'nowrap',
    borderBottom: '1px solid #bdb7b7',
  },
  newValueStyle: {
    background: '#dfd',
    color: 'green',
  },
  oldValueStyle: {
    color: 'red',
    background: '#ffedea',
  },
  paginationContainer: {
    marginTop: 5,
  },
});

const EmptyTable = ({ options }: any) => {
  const { allLabels } = useSelector((state: any) => state.ScreenReducer);
  const classes = useStyles();
  const { history: fetchedHistory, loading } = useSelector(
    (state: any) => state.RecordReducer,
  );
  const [history, setHistory] = useState(fetchedHistory || []);
  const disptach = useDispatch();
  const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => {
    disptach(fetchRecordHistory(options.tableID, options?.modifyDep));
    return () => {
      disptach(clearRecordHistory());
    };
  }, []);

  useEffect(() => {
    setHistory(fetchedHistory.slice(0, 10));
  }, [fetchedHistory?.length]);

  const handlePaginationChange = (event: any, targetPage: any) => {
    setCurrentPage(targetPage);
    const selectedData = fetchedHistory.slice(
      (targetPage - 1) * 10,
      targetPage * 10,
    );
    setHistory(selectedData);
  };

  return (loading || history.length === 0 ? <CircularProgress />
    : (
      <>
        <TableContainer component={Paper} className={classes.table}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow classes={{ head: classes.headerRow }}>
                {Object.entries(allLabels).map(([key, value]: any) => (
                  <TableCell key={key} classes={{ root: classes.headerCell }}>
                    {value || key}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!history.length ? (
                <TableRow>
                  <TableCell
                    style={{
                      textAlign: 'center',
                    }}
                    colSpan={Object.entries(allLabels).length}
                  >
                    No matching records found
                  </TableCell>
                </TableRow>
              ) : (
                history?.map((elem: any) => {
                  const newValueObj = JSON.parse(`{${elem?.new_value}}`.replaceAll('\n', '').trim());
                  const oldValueObj = elem?.old_value
                    ? JSON.parse(`{${elem?.old_value}}`.replaceAll('\n', '').trim())
                    : {};
                  return (
                    <TableRow classes={{ root: classes.tableRow }} key={elem?.id}>
                      {Object.keys(allLabels).map((key: any) => {
                        const valueChanged: boolean = newValueObj?.[key]?.trim()
                          !== oldValueObj?.[key]?.trim() && elem?.old_value;
                        return (
                          <TableCell
                            key={key}
                            classes={{ root: classes.tableCell }}
                          >
                            <div style={{ display: 'flex', gap: 5 }}>
                              <span
                                className={
                                valueChanged ? classes.newValueStyle : undefined
                              }
                              >
                                {newValueObj?.[key]}
                              </span>
                              {valueChanged && (
                              <span
                                className={
                                  valueChanged
                                    ? classes.oldValueStyle
                                    : undefined
                                }
                              >
                                {oldValueObj?.[key]}
                              </span>
                              )}
                            </div>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {!history.length ? (
          <span> No records available </span>
        ) : (
          <Pagination
            count={Math.ceil(fetchedHistory?.length / 10)}
            onChange={handlePaginationChange}
            page={currentPage}
            className={classes.paginationContainer}
          />
        )}
      </>
    )
  );
};
export default EmptyTable;
