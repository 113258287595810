import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import Subnavbar from '../../components/Sub-navbar/Subnavbar';
import { LoadRecord } from '../../redux/actions/ScreenActions';
import FormBodyTerminal from '../../components/formBodyTermDetail/formBodyTerminal';
import FormHeaderTerminal from '../../components/FormHeaderTermDetail/FormHeaderTerminal';
import BatchModeFormHeaderTerminal from '../../components/FormHeaderTermDetail/BatchModeHeaderDetails';
import BatchModeFormBody from '../../components/formBodyTermDetail/BatchModeFormBody';
import TmsHeader from '../../components/header';
import { LoadRecordEmbbeded } from '../../redux/actions/embbededScreensActions';
import EmbeddedSubnavbar from './subNavEmbedded/subNavEmbedded';
import { unsetChildValuesOfChain } from '../../utils';

const BatchEditEmbedded = () => {
  const dispatch = useDispatch();
  const { recordDetails, activeScreen } = useSelector((state: any) => state.EmbbededScreenReducer);
  const [values, setValues] = useState<any>([]);
  const [isModifiy, setIsModifiy] = useState(false);
  const [valuesToSend, setValuesToSend] = useState<any>([]);
  const navigate = useNavigate();
  const {
    Language,
  } = useSelector((state: any) => state.LoginReducer);
  useEffect(() => {
    if (activeScreen) {
      dispatch(LoadRecordEmbbeded({
        tableID: activeScreen.tableID,
        screenID: activeScreen.screenId,
        where: '',
        fields: activeScreen.fields,
        fieldsConcatenated: activeScreen.fieldsConcatenated,
        lang: Language,
      }));
    } else if (!activeScreen) {
      navigate('/home');
    }
  }, [activeScreen]);
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    setValuesToSend({
      ...valuesToSend,
      [name]: value,
    });
    setIsModifiy(true);
  };
  const handleSelectChange = (
    label:any,
    e:React.ChangeEvent<HTMLInputElement>,
    newValue:any,
    _: any,
    chainedKeys?: string[],
  ) => {
    let newValues = { ...values, [label]: newValue };
    let newValuesToSend = { ...valuesToSend, [label]: newValue.value };

    // Handle value chaining for chain type
    if (chainedKeys) {
      const { resultValues, resultValuesToSend } = unsetChildValuesOfChain(
        chainedKeys,
        newValues,
        newValuesToSend,
      );
      newValues = resultValues;
      newValuesToSend = resultValuesToSend;
    }

    setValues(newValues);
    setValuesToSend(newValuesToSend);
    setIsModifiy(true);
  };
  console.log('valuesToSend', valuesToSend);
  const compare = (currentValue: any) => {
    const result = currentValue?.obj['\u0000*\u0000parm']?.options?.filter((e: any) => (e?.value === values[currentValue?.id]));
    return result ? result[0]?.label : '';
  };

  // useEffect(() => {
  //   if (!activeScreen) {
  //     navigate('/home');
  //   } else if (activeScreen) {
  //     dispatch(LoadRecord(location.state));
  //   }
  // }, []);

  const { _, ...restData } = recordDetails;

  return (
    recordDetails.length === 0 ? (
      <div style={{
        width: '100%',
        height: '90vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      >
        <CircularProgress />
      </div>
    )
      : (
        <>
          <EmbeddedSubnavbar
            label={`${activeScreen.tableID}: Detail`}
            menuOptionType="simpleItems"
            // menuOptions={FilterSaved}
          />
          <BatchModeFormHeaderTerminal
            options={activeScreen.redirect}
            dataHeader={_}
            handleSelectChange={handleSelectChange}
            handleInputChange={handleInputChange}
            values={values}
            valuesToSend={valuesToSend}
            isModifiy={isModifiy}
            compare={compare}
            isEmbedded
          />
          <BatchModeFormBody
            dataTabs={restData}
            handleSelectChange={handleSelectChange}
            handleInputChange={handleInputChange}
            compare={compare}
            valuesToSend={valuesToSend}
            values={values}
          />
        </>
    // )
      )
  );
};

export default BatchEditEmbedded;
