import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    paddingLeft: '5px !important',
  },
  list: {
    border: '1px solid #aaa !important',
  },
  menuItem: {
    padding: '1px 20px !important',
    fontSize: '12px !important',
  },
  paper: {
    transitionDuration: '0s !important',
    marginLeft: -12,
  },
});
