import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  cancelButton: {
    color: 'black !important',
    textShadow: 'none !important',
    backgroundColor: '#ffbb33 !important',
    padding: '4px 10px !important',
    marginLeft: '10px !important',
    border: '1px solid transparent',
    '&:hover': {
      backgroundColor: '#FF8800 !important',
      color: 'white !important',
    },
  },
  dialogActions: {
    justifyContent: 'space-between',
    padding: '10px 20px',
  },
});
