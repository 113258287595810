import React, { useState } from 'react';

// Librairies
import { ButtonBase, InputBase } from '@mui/material';
import { ArrowDropDownOutlined } from '@mui/icons-material';
import clsx from 'clsx';

// Components
import SystemStartupTable from '../../../components/systemStartupTable';
import SystemStartupOptions from '../../../components/systemStartupOptions';
import TmsHeader from '../../../components/header';
import SystemStartupDialog from '../../../components/systemStartupDialog';

// Styles
import { useStyles } from './style';

const SystemStartup: React.FC = () => {
  const [systemStartupAnchorEl, setSystemStartupAnchorEl] = useState<null | HTMLElement>(null);
  const [systemStartupDialogOpen, setSystemStartupDialogOpen] = useState<boolean>(false);
  const [searchText, setSearchText] = useState('');
  const classes = useStyles();

  const handleOptionClick = (event: React.MouseEvent<HTMLElement>) => {
    setSystemStartupAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setSystemStartupAnchorEl(null);
  };

  const handleOpenSystemStartupDialog = () => {
    setSystemStartupDialogOpen(true);
  };

  const handleCloseSystemStartupDialog = () => {
    setSystemStartupDialogOpen(false);
  };

  const handleChangeSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  return (
    <div className={classes.root}>
      <TmsHeader />
      <div className={clsx(classes.header, 'flexStartSpaceBetweenRow')}>
        <h3 className={classes.title}>
          System Start-Up:
          {' '}
          <small>Determine start-up order</small>
        </h3>
        <div className={classes.buttonGroup}>
          <ButtonBase
            className={classes.runningButton}
            onClick={handleOpenSystemStartupDialog}
          >
            Running
          </ButtonBase>
          <ButtonBase
            className={classes.optionsButton}
            onClick={handleOptionClick}
          >
            Options
            <ArrowDropDownOutlined fontSize="small" />
          </ButtonBase>
          <SystemStartupOptions
            anchorEl={systemStartupAnchorEl}
            onClose={handleCloseMenu}
          />
        </div>
      </div>
      <div className="flexStartSpaceBetweenRow" style={{ padding: '5px 5px' }}>
        <div>
          <ButtonBase className={classes.saveButton}>Save</ButtonBase>
          <ButtonBase className={classes.cancleButton}>Cancel</ButtonBase>
        </div>
        <div>
          <InputBase
            className={classes.input}
            placeholder="Search"
            value={searchText}
            onChange={handleChangeSearchInput}
          />
          <span className={classes.helperText}>Viewing 63 of 63</span>
        </div>
      </div>
      <SystemStartupTable />
      <SystemStartupDialog
        open={systemStartupDialogOpen}
        onClose={handleCloseSystemStartupDialog}
      />
    </div>
  );
};

export default SystemStartup;
