import { CircularProgress } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import ControlButton from '../../../components/controlButton';
import CustomInput from '../../../components/customInput';
import GroupsDetailTable from '../../../components/groupsTable/groupsTable';
import TmsHeader from '../../../components/header';
import { getScreensList } from '../../../redux/actions/ScreenActions';

const GroupsDetailsPage = () => {
  const [Values, setValues] = useState([]);
  const location = useLocation();
  const history = useNavigate();
  const GroupName: any = location.state;
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getScreensList());
  }, [dispatch]);
  const {
    loading, ScreensList,
  } = useSelector((state: any) => state.ScreenReducer);
  return (
    <>
      <TmsHeader />
      <div className="sub-nav-container">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h3 id="pagetitlebar_title" className="page-title">
            Groups: Detail
          </h3>
        </div>
      </div>
      <div style={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingLeft: '10px',
        gap: 10,
      }}
      >
        <ControlButton
          label="Remove"
          color="red"
          disabled={false}
          handleClick={() => console.log('Remove')}
        />
        <ControlButton
          label="Save"
          color="#00c851"
          disabled={false}
          handleClick={() => console.log('save')}
        />
        <ControlButton
          label="Cancel"
          color="#ffbb33"
          disabled={false}
          handleClick={() => history('/workflow/Groups')}
        />
      </div>
      <CustomInput
        label="Group Name"
        name="group"
        defaultValue={GroupName}
        value={GroupName}
        size={1500}
        isDisabled
        type="text"
      />
      { loading ? <CircularProgress /> : <GroupsDetailTable rows={ScreensList} />}
    </>
  );
};

export default GroupsDetailsPage;
