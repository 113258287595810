import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ControlButton from '../../../components/controlButton';
import TmsHeader from '../../../components/header';
import VerticalLinearStepper from '../../../components/verticalStepper/verticalStepper';
import { getScreensList } from '../../../redux/actions/ScreenActions';
import OrderMaintenanceMenu from './OrderMaintenanceMenu';

const OrderMaintenanceNewRecord = () => {
  const [Values, setValues] = useState([]);
  const dispatch = useDispatch();
  const history = useNavigate();
  useEffect(() => {
    dispatch(getScreensList());
  }, []);

  const handleBackButton = () => {
    history('/workflow/order-maintenance');
  };
  return (
    <>
      <TmsHeader />
      <div className="sub-nav-container">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h3 id="pagetitlebar_title" className="page-title">
            Order Maintenance
          </h3>
        </div>
        <div style={{
          marginRight: '10px',
        }}
        >
          <OrderMaintenanceMenu type="details" />
        </div>
      </div>
      <div style={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        gap: '10px',
        padding: '10px',
      }}
      >
        <ControlButton
          color="#ffbb33"
          label="Back"
          handleClick={handleBackButton}
          disabled={false}
        />
        <ControlButton
          color="red"
          label="Remove"
          handleClick={handleBackButton}
          disabled
        />
      </div>
      <VerticalLinearStepper />
    </>

  );
};

export default OrderMaintenanceNewRecord;
