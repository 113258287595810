/* eslint-disable max-len */

import { removeFromObject } from '../../utils';
import {
  GET_ALL_NESTED_EMBBEDED_COLUMNS, LOAD_NESTED_EMBEDDED_RECORD, LOAD_NESTED_EMBEDDED_SCREEN, SWITCH_NESTED_EMBEDDED_NAVIGATION,
} from '../constants/Screens';

  type action = {
    type: string;
    payload: any;
    screen?:any;
    cloneForm?:any;
    parsedValues?:any;
    RecordsList?: any;
    labels?: any;
    allTypes?: any;
    message?: any;
    embbededTabsConfig?: any;
    modifyDep?: any;
    allLabels?: any;
    grid?: any;
    screenId?: any;
    unitCalculator?:any;
    whereModify?: any;
    embeddedTabsLabels?: any;
    newContractItemNumber?: any;
  };

  type ScreenState = {
    data: [];
    loading: boolean;
    columns:[];
    Selectedcolumnss:string;
    savedFilter:string;
    recordDetails:any;
    activeScreen:any;
    recordsColumns:any,
    allTableColumns:[];
    ColumnsVisibleModal:boolean;
    BatchModeQuery:any
    ScreensList:[]
    changeRecordModal:boolean;
    FilterDropdowStatus:boolean;
    embbededTabsConfig:[];
    embeddedNavigation:string;
    modifyDep: any;
    grid: any;
    exportLoading:any;
    activeFilter: any;
    FilterSaved: any;
  };
const initialState = {
  data: [],
  loading: true,
  columnss: [],
  allTableColumns: [],
  recordsColumns: {},
  recordDetails: [],
  ColumnsVisibleModal: false,
  BatchModeQuery: [],
  clone: null,
  ScreensList: [],
  RecordsList: [],
  changeRecordModal: false,
  FilterDropdowStatus: false,
  activeScreenLabels: [],
  confirmationAlert: '',
  openConfirmationAlert: false,
  embbededTabsConfig: [],
  grid: null,
  exportLoading: false,
  activeFilter: {},
  FilterSaved: {},
  embeddedNavigation: 'tableScreen',
  modifyDep: null,
  whereModify: '',
  newContractItemNumber: '',
};

const NestedEmbbededScreenReducer = (
  state: any = initialState,
  action: any,
): ScreenState => {
  switch (action.type) {
    case LOAD_NESTED_EMBEDDED_SCREEN:
      return {
        ...state,
        data: action.payload,
        activeScreen: action.screen,
        newContractItemNumber: action.newContractItemNumber,
      };
    case LOAD_NESTED_EMBEDDED_RECORD:
      return {
        ...state,
        loading: false,
        recordDetails: action.payload,
        clone: action.cloneForm,
        parsedValues: action.parsedValues,
        activeScreenLabels: action.labels,
        allTypes: action.allTypes,
        embbededTabsConfig: action.embbededTabsConfig,
        modifyDep: action.modifyDep,
        allLabels: action.allLabels,
        grid: action.grid,
        unitCalculator: action.unitCalculator,
        whereModify: action.whereModify,
      };
    case GET_ALL_NESTED_EMBBEDED_COLUMNS:
      return {
        ...state,
        loading: false,
        allTableColumns: action.payload,
      };
    case SWITCH_NESTED_EMBEDDED_NAVIGATION:
      return {
        ...state,
        loading: false,
        embeddedNavigation: action.payload,
      };
    default:
      return state;
  }
};

export default NestedEmbbededScreenReducer;
