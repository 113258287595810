import React from 'react';

// Component
import { ArrowDropDownOutlined } from '@mui/icons-material';
import { ButtonBase } from '@mui/material';

// Styles
import { useStyles } from './style';

interface IProps {
    handleClick: (event: React.MouseEvent<HTMLElement>) => void;
}

const OptionsButton: React.FC<IProps> = ({ handleClick }) => {
  const classes = useStyles();
  return (
    <ButtonBase
      className={classes.optionsButton}
      onClick={handleClick}
    >
      Options
      <ArrowDropDownOutlined fontSize="small" />
    </ButtonBase>
  );
};

export default OptionsButton;
