/* eslint-disable no-unused-expressions */
import React from 'react';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SelectChangeEvent } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import ControlButton from '../controlButton';
import CustomDropdown from '../customDropdown';
import CustomInput from '../customInput';
import './style.css';
import ConfirmationModal from '../confirmationModal';
import CustomField from '../CustomField/CustomField';
import { SwitchEmbeddedNavigation } from '../../redux/actions/embbededScreensActions';

const CreateRecordHeader = (props: any) => {
  const {
    dataHeader,
    tableId,
    Visible,
    handleCloseConfirm,
    options,
    values,
    compare,
    NumberRangeCheck,
    handleSelectChange,
    valuesToSend,
    handleInputChange,
    handleSave,
    isModifiy,
    embedded,
    isEmbedded,
    disabledFields,
    handleTimeDateChange,
  } = props;

  const { recordDetails, activeScreen } = useSelector(
    (state: any) => (embedded ? state.EmbbededScreenReducer : state.ScreenReducer),
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleCancel = () => {
    embedded
      ? dispatch(SwitchEmbeddedNavigation('tableScreen'))
      : navigate(`${options}`);
  };
  const confirmChange = () => {
    handleSave();
  };
  const requiredFields = React.useMemo(() => {
    const fields = Object.keys(recordDetails).reduce((acc: any, tab: any) => {
      if (tab === 'System') {
        return [...acc];
      }
      const tabRequiredFields = recordDetails?.[tab].reduce(
        (acc2: any, field: any) => {
          if (!field?.required || field?.id === 'ContractNumber') {
            return [...acc2];
          }
          return [...acc2, field];
        },
        [],
      );
      return [...acc, ...tabRequiredFields];
    }, []);
    return fields;
  }, [recordDetails]);
  const validationFields = React.useMemo(() => {
    const fields = Object.keys(recordDetails).reduce((acc: any, tab: any) => {
      if (tab === 'System') {
        return [...acc];
      }
      const tabValidateFields = recordDetails?.[tab].reduce(
        (acc2: any, field: any) => {
          if (!embedded) {
            if (!field?.validate) {
              return [...acc2];
            }
            return [...acc2, field];
          }
          if (!field?.required) {
            return [...acc];
          }
          return [...acc2, field];
        },
        [],
      );
      return [...acc, ...tabValidateFields];
    }, []);
    return fields;
  }, [recordDetails]);
  const shouldDisableSaveButton = React.useMemo(() => {
    let requiredIsOkay = false;
    // console.log('reqqqqqqqqqqqqqqqq', requiredFields);
    requiredIsOkay = requiredFields.every(
      (field: any) => valuesToSend[field.id] !== '',
    );
    // eslint-disable-next-line
    let validateIsOkay: boolean = false;

    if (activeScreen?.tableID === 'Carrier') {
      // \. is needed on regex to present dot else it means anything
      // eslint-disable-next-line
      const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      validateIsOkay = valuesToSend.email_address === '' ? true : !!valuesToSend.email_address?.match(mailformat);
    } else if (activeScreen?.tableID === 'Tank') {
      validateIsOkay = validationFields.every((field: any) => (
        !Number.isNaN(valuesToSend[field?.id])
          && (valuesToSend[field?.id] === ''
            || (field?.id === 'tank_group' || field?.id === 'tank_id'
              ? /^[A-Za-z0-9 ]+$/.test(valuesToSend[field?.id])
              : /^\d+$/.test(valuesToSend[field.id])))
      ));
    } else {
      validateIsOkay = validationFields.every(
        (field: any) => !Number.isNaN(valuesToSend[field?.id])
          && (values[field?.id] === ''
            || /^\d+$/.test(valuesToSend[field?.id])),
      );
    }
    return !(requiredIsOkay && validateIsOkay);
  }, [requiredFields, validationFields, valuesToSend]);

  return (
    <>
      <Paper
        sx={{
          width: '98%',
          padding: '5px',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <div className="headerContainer">
          <div className="controlers">
            <ControlButton
              label="Create"
              color="#00c851"
              // create Button should always be enabled on condition screen on embedded mode
              disabled={shouldDisableSaveButton}
              handleClick={confirmChange}
            />
            <ControlButton
              label="Cancel"
              color="#ffbb33"
              disabled={false}
              handleClick={handleCancel}
            />
          </div>
        </div>
      </Paper>

      <div>
        <div className="fields">
          {dataHeader?.map((val: any, key: any) => {
            const tabOpt = val?.obj?.['\u0000*\u0000parm'];
            let defVal;
            let optionsResult;
            if (tabOpt?.options !== undefined) {
              if (tabOpt?.options.length === 0) {
                defVal = 'Nothing Selected';
                optionsResult = Array.of({ label: defVal });
              } else {
                defVal = tabOpt?.options[0].label;
                optionsResult = tabOpt?.options;
              }
            } else if (tabOpt?.nothingselected !== null) {
              if (val?.selectList) {
                defVal = tabOpt?.selecttext;
                optionsResult = val?.selectList;
              } else {
                defVal = tabOpt?.nothingselected;
                optionsResult = tabOpt?.options;
              }
            }
            const defaultValue = val?.obj?.['\u0000*\u0000parm']?.options;
            return (
              <CustomField
                val={val}
                values={values}
                valuesToSend={valuesToSend}
                handleInputChange={handleInputChange}
                handleSelectChange={handleSelectChange}
                handleTimeDateChange={handleTimeDateChange}
                size="99%"
                tableId={tableId}
                headerDisabled={embedded ? disabledFields[val?.id] !== undefined : false}
              />
            );
          })}
        </div>
      </div>
      <ConfirmationModal
        Visible={Visible}
        handleCloseConfirm={handleCloseConfirm}
        options={options}
      />
    </>
  );
};

export default CreateRecordHeader;
