import React from 'react';

// Librairies
import { InputBase } from '@mui/material';
import clsx from 'clsx';

// Components
import SubHeader from '../../../components/subHeader';
import TmsHeader from '../../../components/header';
import WorkflowTable from '../../../components/WorkflowTable';
import Select from '../../../components/Select';

// Styles
import { useStyles } from './style';

// Utils
const tableHeader: string[] = [
  'Terminal ID',
  'Tank ID',
  'Height',
  'Tank Unit',
];

const TankStrappingTables: React.FC = () => {
  const classes = useStyles();

  return (
    <>
      <TmsHeader />
      <SubHeader title="Tank Strapping Tables" />
      <div
        className={clsx('flexStartSpaceBetweenRow', classes.subHeaderContainer)}
      >
        <div className={clsx('flexRow')}>
          <span className={classes.label}>Records :</span>
          <Select
            options={['Auto', 10, 20, 35, 50, 'All']}
            width={200}
            value="Auto"
          />
        </div>
        <div className="flexRow">
          <span className={classes.label}>Search :</span>
          <InputBase className={classes.searchInput} />
        </div>
      </div>
      <WorkflowTable handleClickRow={() => null} headerItems={tableHeader} />
    </>
  );
};

export default TankStrappingTables;
