import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Alert,
  CircularProgress,
  Collapse,
  IconButton,
  Snackbar,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { Close } from '@mui/icons-material';
import Subnavbar from '../../components/Sub-navbar/Subnavbar';
import {
  CloneRecordSubmit,
  dissmissConfirmationAlert,
  LoadRecord,
  UpdateRecord,
} from '../../redux/actions/ScreenActions';
import CloneRecordHeader from '../../components/FormHeaderTermDetail/CloneRecordHeader';
import CloneRecordBody from '../../components/formBodyTermDetail/CloneRecordsBody';
import TmsHeader from '../../components/header';
import { unsetChildValuesOfChain } from '../../utils';

const CloneRecord = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const {
    recordDetails,
    recordsColumns,
    activeScreen,
    parsedValues,
    openConfirmationAlert,
    confirmationAlert,
    newContractNumber,
  } = useSelector((state: any) => state.ScreenReducer);
  const { Language } = useSelector((state: any) => state.LoginReducer);
  const [values, setValues] = useState<any>([]);
  const [valuesToSend, setValuesToSend] = useState<any>([]);
  const [open, setopen] = useState(true);
  //   console.log(location.state);
  const [opencontractDate, setopencontractDate] = useState(false);
  const [openValidationError, setOpenValidationError] = useState(true);
  const [Visible, setVisible] = useState(false);
  const [isModifiy, setIsModifiy] = useState(false);
  const [openValidationErrorStartStop, setopenValidationErrorStartStop] = useState(false);
  const [openValidationErrorNext, setopenValidationErrorNext] = useState(false);
  const [openValidationErrorIncr, setopenValidationErrorIncr] = useState(false);
  const [openValidationErrorMessages, setopenValidationErrorMessages] = useState<any>(false);
  //   useEffect(() => {
  //     dispatch(LoadRecord(location.state));
  //   }, []);

  const handleCloseConfirm = () => {
    setVisible(!Visible);
  };
  // for Contract and contract Item we should delete contract number from values and values to send because it's not required
  // const DeleteContractNumber = () => {
  //   delete values?.ContractNumber;
  //   delete valuesToSend?.ContractNumber;
  // };
  useEffect(() => {
    setValues(parsedValues);
    setValuesToSend(parsedValues);
  }, [parsedValues]);
  const handleClose = () => {
    dispatch(dissmissConfirmationAlert());
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    setValuesToSend({
      ...valuesToSend,
      [name]: value,
    });
    setIsModifiy(true);
  };
  const handleSelectChange = (
    name: any,
    e: React.ChangeEvent<HTMLInputElement>,
    newValue: any,
    _: any,
    chainedKeys?: string[],
  ) => {
    let newValues = { ...values, [name]: newValue };
    let newValuesToSend = { ...valuesToSend, [name]: newValue.value };

    // Handle value chaining for chain type
    if (chainedKeys) {
      const { resultValues, resultValuesToSend } = unsetChildValuesOfChain(
        chainedKeys,
        newValues,
        newValuesToSend,
      );
      newValues = resultValues;
      newValuesToSend = resultValuesToSend;
    }

    setValues(newValues);
    setValuesToSend(newValuesToSend);
    setIsModifiy(true);
  };
  const handleSave = () => {
    dispatch(
      CloneRecordSubmit({
        table: activeScreen.tableID,
        navigate,
        backScreen: activeScreen.redirect,
        form_data: activeScreen.tableID === 'Contract' ? { ...valuesToSend, ContractNumber: newContractNumber } : valuesToSend,
      }),
    );
  };
  const handleTimeDateChange = (name: any, newValue: any) => {
    setValues({
      ...values,
      [name]: `${newValue?.slice(6, 10)}-${newValue?.slice(
        3,
        5,
      )}-${newValue?.slice(0, 2)} ${newValue?.slice(10, newValue?.length)}`,
    });
    setValuesToSend({
      ...valuesToSend,
      [name]: `${newValue?.slice(6, 10)}-${newValue?.slice(
        3,
        5,
      )}-${newValue?.slice(0, 2)} ${newValue?.slice(10, newValue?.length)}`,
    });
    setIsModifiy(
      Object.keys(values)?.length > 0 || Object.keys(valuesToSend)?.length > 0,
    );
  };
  // EXTENSIONS: Number Range validation
  const checknumberRange = () => {
    if (
      activeScreen.screenId === 'MAINT-210'
      && Number(values?.StartNumber) >= Number(values?.StopNumber)
    ) {
      setopenValidationErrorStartStop(true);
    } else {
      setopenValidationErrorStartStop(false);
    }
    if (
      activeScreen.screenId === 'MAINT-210'
      && Number(values?.IncrementNumber)
        >= Number(values?.StopNumber) - Number(values?.StartNumber)
    ) {
      setopenValidationErrorIncr(true);
    } else {
      setopenValidationErrorIncr(false);
    }
    if (
      activeScreen.screenId === 'MAINT-210'
      && (Number(values?.StartNumber) >= Number(values?.NextNumber)
        || Number(values?.NextNumber) >= Number(values?.StopNumber))
    ) {
      setopenValidationErrorNext(true);
    } else {
      setopenValidationErrorNext(false);
    }
  };
  useEffect(() => {
    checknumberRange();
  }, [valuesToSend, values]);
  const compare = (currentValue: any) => {
    const result = currentValue?.obj['\u0000*\u0000parm']?.options?.filter(
      (e: any) => e?.value === values[currentValue?.id],
    );
    return result ? result[0]?.label : '';
  };
  console.log('result', valuesToSend);
  // contract extention check dates
  const checkDates = () => {
    if (
      activeScreen.screenId === 'MAINT-210'
      && valuesToSend?.EffectiveDate > valuesToSend?.ExpirationDate
      && valuesToSend?.EffectiveDate !== ''
      && valuesToSend?.ExpirationDate !== ''
    ) {
      setopencontractDate(true);
    } else setopencontractDate(false);
  };
  useEffect(() => {
    checkDates();
    // DeleteContractNumber();
  }, [valuesToSend]);

  const { _, ...restData } = recordDetails;

  return recordDetails.length === 0 ? (
    <div
      style={{
        width: '100%',
        height: '90vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress />
    </div>
  ) : (
    <>
      <TmsHeader />
      <Subnavbar
        label={`${activeScreen.tableID}: Detail`}
        menuOptionType="simpleItems"
      />
      <Collapse in={open}>
        <Alert
          severity="warning"
          action={(
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setopen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          )}
          sx={{ mb: 2 }}
        >
          <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
            <Typography style={{ fontWeight: 'bold', color: 'orange' }}>
              Warning!
            </Typography>
            {' '}
            You must change the key fields before saving!
          </div>
        </Alert>
      </Collapse>
      <Collapse
        in={
          activeScreen.screenId === 'MAINT-173'
          && new Date(valuesToSend?.EffectiveDate)?.getTime()
            > new Date(valuesToSend?.ExpirationDate)?.getTime()
        }
      >
        <Alert
          severity="warning"
          action={(
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setopencontractDate(false);
              }}
            >
              <Close fontSize="inherit" />
            </IconButton>
          )}
          sx={{ mb: 2 }}
        >
          <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
            <Typography style={{ fontWeight: 'bold', color: 'orange' }}>
              Warning!
            </Typography>
            {' '}
            Effective Date is AFTER Expiration Date
          </div>
        </Alert>
      </Collapse>
      <Collapse
        in={
          openValidationErrorNext
          || openValidationErrorIncr
          || openValidationErrorStartStop
        }
      >
        <Alert
          severity="error"
          action={(
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setopenValidationErrorIncr(false);
                setopenValidationErrorNext(false);
                setopenValidationErrorStartStop(false);
              }}
            >
              <Close fontSize="inherit" />
            </IconButton>
          )}
          sx={{ mb: 2 }}
        >
          <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
            <Typography style={{ color: 'black' }}>
              {activeScreen.screenId === 'MAINT-210'
                && Number(values?.StartNumber) >= Number(values?.StopNumber)
                && 'Start number cannot be greater or equal than Stop number. '}
              {activeScreen.screenId === 'MAINT-210'
                && (Number(values?.StartNumber) >= Number(values?.NextNumber)
                  || Number(values?.NextNumber) >= Number(values?.StopNumber))
                && 'Next number must be between Start and Stop numbers, '}
              {activeScreen.screenId === 'MAINT-210'
                && Number(values?.IncrementNumber)
                  >= Number(values?.StopNumber) - Number(values?.StartNumber)
                && 'Increment number can not be greater or equal than Stop number-Start number. '}
            </Typography>
          </div>
        </Alert>
      </Collapse>
      <CloneRecordHeader
        Visible={Visible}
        options={activeScreen.redirect}
        handleCloseConfirm={handleCloseConfirm}
        dataHeader={_}
        compare={compare}
        // routeBack={routeBack}
        handleSelectChange={handleSelectChange}
        handleInputChange={handleInputChange}
        values={values}
        handleSave={handleSave}
        isModifiy={isModifiy}
        valuesToSend={valuesToSend}
        handleTimeDateChange={handleTimeDateChange}
      />
      <CloneRecordBody
        dataTabs={restData}
        handleSelectChange={handleSelectChange}
        handleInputChange={handleInputChange}
        values={values}
        compare={compare}
        valuesToSend={valuesToSend}
        handleTimeDateChange={handleTimeDateChange}
      />
      <Snackbar
        open={openConfirmationAlert}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          {confirmationAlert}
        </Alert>
      </Snackbar>
    </>
    // )
  );
};

export default CloneRecord;
