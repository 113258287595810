/* eslint-disable react/require-default-props */
import { Autocomplete, TextField } from '@mui/material';
import React from 'react';

// Styles
import { useStyles } from './Style';

interface ISelectProps {
  options: any[];
  width?: number | string;
  value?: string;
  style?: React.CSSProperties
}

const Select: React.FC<ISelectProps> = ({
  options, width, value, style,
}) => {
  const classes = useStyles();
  return (
    <Autocomplete
      style={{ width, ...style }}
      value={value}
      classes={{ root: classes.root, input: classes.autoCompleteInput }}
      renderInput={(params: any) => (
        <TextField
          classes={{ root: classes.input }}
          placeholder="-- Select --"
          {...params}
        />
      )}
      options={options}
    />
  );
};

export default Select;
