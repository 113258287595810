import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    width: 290,
    border: 'solid 0px black',
    height: 310,
    display: 'flex',
    flexDirection: 'column',
    margin: 1,
  },
  header: {
    paddingLeft: 5,
    fontSize: 18,
    border: '1px solid #7A8299',
  },
  body: {
    color: 'black',
    textAlign: 'center',
    backgroundColor: '#C5FFFF',
    border: '1px solid #7A8299',
    fontFamily: 'DroidSans, monospace',
    fontSize: 'calc(8px + 0.55vh)',
    fontWeight: 100,
    padding: 2,
    margin: 0,
    cursor: 'pointer',
    flexGrow: 1,
  },
});
