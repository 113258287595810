/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
import React from 'react';
import { Box, Tab, Typography } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import CustomDropdown from '../customDropdown';
import CustomInput from '../customInput';
import Plcconfig from '../plcconfigComponent/plcconfig';
import CustomRcuscreen from '../customTextArea/customTextArea';
import LoadDataGrid from '../LoadDataGrid/LoadDataGrid';
import Weekday from '../customDropdown/Weekday';
import CustomField from '../CustomField/CustomField';

const CreateRecordBody = (props: any) => {
  const {
    dataTabs, handleSelectChange, handleInputChange, values, compare, valuesToSend, showOptionalFields, isEmbedded, handleTimeDateChange,
  } = props;
  const {
    ScreensList, activeScreen, whereDetailCondition, embeddedTabsLabels,
  } = useSelector(
    (state: any) => (isEmbedded ? state.EmbbededScreenReducer : state.ScreenReducer),
  );
  const Ids = Object.entries(dataTabs)
    .map((elt: any) => elt[1].map((element: any) => [element?.id, element?.valForm]))
    .flat();
  const mapData = new Map(Ids);
  const arrayToObject = Object.fromEntries(mapData);
  const [indice, setIndice] = React.useState('0');
  console.log('ffffffffffffffffffffff', activeScreen?.screenId);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setIndice(newValue);
  };
  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={indice}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            {Object.keys(dataTabs).map((tabInfo: any, idx: any) => (
              <Tab label={tabInfo} value={idx.toString()} />
            ))}
          </TabList>
        </Box>
        {/* Folio skip days screen behave like a grid but we don't have a grid layout on the backend so it was handled here instead */}
        {activeScreen?.screenId === 'MAINT-164' && indice === '0' ? (
          <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}>
            {Object.entries(dataTabs).map((tabInfo: any, index: any) => tabInfo[1].map((val: any) => (
              <TabPanel sx={{ padding: '0px', flex: '1 0 21%' }} value={index.toString()}>
                <CustomField
                  val={val}
                  values={values}
                  valuesToSend={valuesToSend}
                  handleInputChange={handleInputChange}
                  handleSelectChange={handleSelectChange}
                  size="100%"
                  isEmbedded={isEmbedded}
                />
              </TabPanel>
            )))}
          </div>
        ) : (
          <div style={{
            height: '60vh',
            overflowY: 'scroll',
            width: '100%',
          }}
          >
            {Object.entries(dataTabs).map((tabInfo: any, index: any) => tabInfo[1].map((val: any) => (
              <TabPanel sx={{ padding: '0px' }} value={index.toString()}>
                {!val?.visible && !showOptionalFields && val?.type !== 'grid' ? (
                  <></>
                ) : (
                  <CustomField
                    val={val}
                    values={values}
                    valuesToSend={valuesToSend}
                    handleInputChange={handleInputChange}
                    handleSelectChange={handleSelectChange}
                    handleTimeDateChange={handleTimeDateChange}
                    size="99%"
                    isEmbedded={isEmbedded}
                  />
                )}
              </TabPanel>
            )))}

          </div>
        )}
      </TabContext>
    </Box>
  );
};

export default CreateRecordBody;
