/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';

// Librairies
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

// Styles
import { useStyles } from './style';

// Components
import ProcessMenu from '../processMenu';
import SystemStartupPopover from '../systemStartupPopover';
import Select from '../Select';
import { fetchSystemStartup } from '../../redux/actions/systemStartup';

// Utils
const tableHeader: string[] = [
  'Group',
  'Command',
  'State',
  'Posted Name',
  'Arguments',
  'Notify',
  'Delay',
  'Description',
];

const formatStatusSelctOptions = (options: any[]) => options?.map((option) => ({
  ...option,
  label:
      option?.label === 'MANAGE_STARTUP_ENABLED'
        ? 'Enabled'
        : option?.label === 'MANAGE_STARTUP_DISABLED'
          ? 'Disabled'
          : 'Masked',
}));

const SystemStartupTable: React.FC = () => {
  const classes = useStyles();
  const systemStartupData = useSelector(
    (state: any) => state.SystemStartupReducer.data,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSystemStartup());
  }, []);

  const displayCellInput = (field: any, index: number) => {
    switch (index) {
      case 1: {
        const fieldOptions = field?.options;
        const selectedValue = fieldOptions?.find((opt: any) => opt?.selected);
        return (
          <Select
            options={fieldOptions || []}
            width={50}
            value={selectedValue}
          />
        );
      }
      case 2: {
        const fieldOptions = field?.options;
        const formattedOptions = formatStatusSelctOptions(fieldOptions);
        const selectedValue = formattedOptions?.find(
          (opt: any) => opt?.selected,
        );
        return (
          <Select
            options={fieldOptions ? formattedOptions : []}
            width={50}
            value={selectedValue}
          />
        );
      }
      default:
        return <SystemStartupPopover title={tableHeader[index]} contentText={field.value} />;
    }
  };

  return (
    <div className={classes.root}>
      <Table classes={{ root: classes.tableRoot }}>
        <TableHead>
          <TableRow classes={{ root: classes.headerRow }}>
            <TableCell key="process" />
            <TableCell key="status" />
            {tableHeader.map((h) => (
              <TableCell classes={{ root: classes.headerCell }} key={h}>
                {h}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {systemStartupData?.map((row: any) => (
            <TableRow key={row.row}>
              <TableCell classes={{ root: classes.bodyCellEmpty }}>
                <ProcessMenu />
              </TableCell>
              <TableCell classes={{ root: classes.bodyCellEmpty }}>
                <div
                  style={{
                    width: 20,
                    height: 20,
                    backgroundColor: '#32CD32',
                  }}
                />
              </TableCell>
              {row.fields.map((field: any, index: number) => (
                <TableCell classes={{ root: classes.bodyCell }} key={field.id}>
                  {displayCellInput(field, index)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default React.memo(SystemStartupTable);
