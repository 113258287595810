import { Button, Checkbox, FormControlLabel } from '@mui/material';
import React, { useState } from 'react';
import ChangeTankCard from '../../components/changeTankCard/changeTankCard';
import TmsHeader from '../../components/header';
import Subnavbar from '../../components/Sub-navbar/Subnavbar';

const TankData = [
  {
    id: '0001',
    name: 'GEN ADD',
    Tank: 'Tank - 0001',
    product: 'GENAD 2',
  },
  {
    id: '0002',
    name: 'Ethanol',
    Tank: 'Tank - 0002',
    product: '000Eth',
  },
  {
    id: '0003',
    name: 'slop',
    Tank: 'Tank - 0003',
    product: '000100',
  },
  {
    id: '0004',
    name: 'JET A',
    Tank: 'Tank - 0004',
    product: '000040',
  },
  {
    id: '0005',
    name: 'CBOB',
    Tank: 'Tank - 0005',
    product: '000005',
  },
];
const ChangeActiveTank = () => {
  const [ChooseFromTank, setChooseFromTank] = useState<any>(TankData);
  const [ChooseToTank, setChooseToTank] = useState<any>([]);
  const [ChangeTank, setChangeTank] = useState<any>([]);
  // choose from tank
  const chooseFromTank = (e: any) => {
    console.log('item selected: ', e.target);
    const choosedItems = TankData.filter((item) => item.id !== e.target.value);
    setChooseToTank(choosedItems);
  };
  return (
    <>
      <TmsHeader />
      <div className="sub-nav-container">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h3 id="pagetitlebar_title" className="page-title">
            Change Active Tank
          </h3>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: '50px',
          padding: '10px',
        }}
      >
        <FormControlLabel control={<Checkbox />} label="Product Change" />
        <FormControlLabel control={<Checkbox />} label="Auto Restart Bays" />
        <Button variant="contained" sx={{ textTransform: 'none' }} disabled>Switch Tank</Button>
      </div>
      <div
        style={{
          width: '99%',
          height: '90vh',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            width: '100%',
            height: '40vh',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <ChangeTankCard
            title="Choose from Tank"
            values={ChooseFromTank}
            backgroundColor="#f9f9f9"
            isSearching
            chooseFromTank={chooseFromTank}
          />
          <ChangeTankCard
            title="Choose to Tank"
            values={ChooseToTank}
            backgroundColor="#fffff1"
            isSearching
          />
        </div>
        <ChangeTankCard
          title="Change Tank"
          values={ChangeTank}
          backgroundColor="#f2fff2"
          isSearching={false}
        />
      </div>
    </>
  );
};

export default ChangeActiveTank;
