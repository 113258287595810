import React, { useEffect } from 'react';

// Components
import { useDispatch } from 'react-redux';
import AuditLogsSearchCrad from '../../../components/auditLogsSearchCrad';
import SubHeader from '../../../components/subHeader';

// Styles
import { useStyles } from './style';
import TmsHeader from '../../../components/header';
import { getScreensList } from '../../../redux/actions/ScreenActions';

const AuditLogsScreen: React.FC = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getScreensList());
  }, []);
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <TmsHeader />
      <SubHeader title="Audit Logs" />
      <AuditLogsSearchCrad />
    </div>
  );
};

export default AuditLogsScreen;
