import React, { useState } from 'react';

// Librairies
import { InputBase } from '@mui/material';
import clsx from 'clsx';

// Components
import SubHeader from '../../../components/subHeader';
import TmsHeader from '../../../components/header';
import OptionsButton from '../../../components/optionsButton';
import MarqueeManagerOptions from '../../../components/MarqueeManagerOptions';
import MarqueeManagerDialog from '../../../components/MarqueeManagerDialog';
import MarqueeManagerTable from '../../../components/MarqueeManagerTable';

// Styles
import { useStyles } from './style';

const MarqueeManagerScreen: React.FC = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [marqueeManagerDilogOpen, setMarqueeManagerDilogOpen] = useState<boolean>(false);

  const handleOptionClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenMarqueeManagerDialog = () => {
    setMarqueeManagerDilogOpen(true);
    handleCloseMenu();
  };
  const handleCloseMarqueeManagerDialog = () => {
    setMarqueeManagerDilogOpen(false);
  };

  return (
    <>
      <TmsHeader />
      <SubHeader title="Marquee Manager">
        <OptionsButton handleClick={handleOptionClick} />
        <MarqueeManagerOptions
          anchorEl={anchorEl}
          onClose={handleCloseMenu}
          openDialog={handleOpenMarqueeManagerDialog}
        />
      </SubHeader>
      <div className={clsx('flexRow', classes.wrapper)}>
        <span className="takeTheRest" />
        <div>
          <InputBase className={classes.input} placeholder="Search" />
          <span className={classes.helperText}>Viewing 0 of 0</span>
        </div>
      </div>
      <MarqueeManagerTable handleClickRow={() => null} />
      <MarqueeManagerDialog
        onClose={handleCloseMarqueeManagerDialog}
        open={marqueeManagerDilogOpen}
      />
    </>
  );
};

export default MarqueeManagerScreen;
