import axios from 'axios';
import {
  GET_USER_LANGUAGE, LOGIN_FAIL, LOGIN_SUCCESS, SET_USER_LANGUAGE,
} from '../constants';
import { START_LOADING, STOP_LOADING } from '../constants/Screens';

interface loginProps {
    username: string;
    password: string;
  }

export const signin = (userLog: loginProps, navigate: any) => async (dispatch:any) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}authentification`,
      { username: userLog.username, password: userLog.password },
      {
        headers: {
          accept: '*/*',
          'Content-Type': 'application/json',
        },
      });

    dispatch({ type: LOGIN_SUCCESS, payload: response });
    localStorage.setItem('jwt', response.data.jwt);
    localStorage.setItem('username', response.data.username);
    localStorage.setItem('refreshToken', response.data.refreshToken);
    navigate('./home', { replace: true });
  } catch (err: any) {
    dispatch({ type: LOGIN_FAIL, payload: 'Bad username or password' });
  }
};
export const getToken = () => async (dispatch:any) => {
  try {
    const token = localStorage.getItem('jwt');
    if (token) {
      dispatch({ type: LOGIN_SUCCESS, payload: token });
    } else {
      dispatch({ type: LOGIN_FAIL, payload: 'Session expired' });
    }
  } catch (err: any) {
    dispatch({ type: LOGIN_FAIL, payload: 'Bad username or password' });
  }
};
export const setLanguage = ({ values, navigate }: any) => async (dispatch:any) => {
  try {
    localStorage.setItem('language', values);
    dispatch({ type: SET_USER_LANGUAGE, payload: values });
    navigate('./home', { replace: true });
  } catch (err: any) {
    console.log('error', err);
  }
};
export const getLanguage = () => async (dispatch:any) => {
  try {
    const userLanguage = localStorage.getItem('language');
    console.log('userLanguage', userLanguage);
    dispatch({ type: GET_USER_LANGUAGE, payload: userLanguage });
  } catch (err: any) {
    console.log('error', err);
  }
};
export const logout = () => async (dispatch:any) => {
  try {
    await localStorage.removeItem('jwt');
    dispatch({ type: GET_USER_LANGUAGE });
  } catch (err: any) {
    console.log('error', err);
  }
};
