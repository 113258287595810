import React from 'react';
import './Footer.css';

const Footer = () => (
  <div id="pagefooter" className="navbar-bifooter navbar-fixed-bottom" style={{ bottom: 0, marginTop: '30px' }}>

    <table className="footersize">
      <tbody>
        <tr>
          <td className="copyright">© 2014 - 2022 Toptech Systems, Inc.</td>
          <td className="targetname">TOPTECH (LONGWOOD, FL) </td>
          <td className="targetinfo">
            <span id="latency">
              <table className="ledbar">
                <tbody>
                  <tr>
                    <td className="on-subtle" />
                    <td className="on-subtle" />
                    <td className="on-subtle" />
                    <td className="on-subtle" />
                    <td className="on-subtle" />
                  </tr>
                </tbody>
              </table>
            </span>
            {' '}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
);

export default Footer;
