/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useRef, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Close, Info, Menu, Person, PowerSettingsNew, Search,
} from '@mui/icons-material';
import {
  Box, CircularProgress, FormControl, InputLabel, MenuItem, Select, Typography,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import './styles.css';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import HomeSearch from '../HomeSearchInput/HomeSearchInput';
import SmartNavSearch from '../HomeSearchInput/SmartSearchNavBar';
import { LoadRecord, LoadTables } from '../../redux/actions/ScreenActions';
import useWindowDimensions from '../../utils/windowDimensions';
import { logout } from '../../redux/actions';
import infoLogo from '../../assets/product_logo_full_white.png';

const TmsHeader = () => {
  const navigate = useNavigate();
  const [isSearching, setisSearching] = useState(false);
  const [open, setOpen] = useState(false);
  const [isDrawerOpen, setisDrawerOpen] = useState(false);
  const [isUserOpen, setisUserOpen] = useState(false);
  const [isInfoOpen, setisInfoOpen] = useState(false);
  const { height, width } = useWindowDimensions();
  const {
    loading, recentList, activeScreen, ScreensList,
  } = useSelector((state: any) => state.ScreenReducer);
  const {
    Language, isAuthenticated,
  } = useSelector((state: any) => state.LoginReducer);
  const recent = () => {
    navigate('/home');
  };
  const InfoRef = useRef(null);
  const dispatch = useDispatch();
  const recentNavigate = async (config: any) => {
    const option = ScreensList?.find((screen: any) => screen?.screenId === config?.screenId);

    if (!option) {
      console.log('Incorrect recent screen config!');
      return;
    }
    if (option.type === 'Workflows') {
      navigate(`${option.path}`);
      return;
    }

    const fields = Array.from(new Set([...option.fields.split(',')])).join(',');
    dispatch(
      LoadTables({
        tableID: option.tableId,
        page: '1',
        results_per_page: `${Math.ceil(height / 60)}`,
        fields: option.fields ? fields : '',
        fieldsConcatenated: option.fieldsConcatenated,
        order_by: '',
        where: option.screenConfiguration || '',
        redirect: `/maint/${option.screenId}`,
        screenId: option.screenId,
        keys: option.keys || '',
        defaultcolumns: option.defaultcolumns || '',
        lang: Language,
        labels: option.labels || '',
        navigate,
      }),
    );
    dispatch(LoadRecord({
      tableID: option.tableId,
      screenID: option.screenId,
      fields: option.fields || '',
      where: '',
      lang: Language,
    }));
  };
  const handleLogout = () => {
    dispatch(logout());
    navigate('/login');
  };
  const getScreenNameByIdAndMenuType = () => {
    const currentScreen = ScreensList.filter((e: any) => e?.tableId === activeScreen?.tableID)[0];
    return currentScreen?.screenId;
  };
  function useOutsideAlerter(ref:any) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setisInfoOpen(false);
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }
  useOutsideAlerter(InfoRef);
  return (
    <>
      <Box className="nav-header">
        <div className="menu-section">
          <div className="Recent-tool" onMouseEnter={() => setisDrawerOpen(true)} onClick={() => recent()}>
            <Typography fontSize={10} color="#fff">Recent</Typography>
            <ArrowDropDownIcon style={{ color: '#fff' }} />
          </div>
          <div className="Logo-mobile" onClick={() => recent()}>
            <Typography style={{
              fontSize: '22px',
              fontWeight: 600,
              color: '#fff',
            }}
            >
              TMS

            </Typography>
            <Typography style={{
              fontSize: '12px',
              fontWeight: 300,
              color: '#fff',
            }}
            >
              8.0.0

            </Typography>
          </div>
          <div className="nav-dropdown">
            <Typography fontSize={10} color="#fff">Business</Typography>
            <ArrowDropDownIcon style={{ color: '#fff' }} />
          </div>
          <div className="nav-dropdown">
            <Typography fontSize={10} color="#fff">Product</Typography>
            <ArrowDropDownIcon style={{ color: '#fff' }} />
          </div>
          <div className="nav-dropdown">
            <Typography fontSize={10} color="#fff">Operations</Typography>
            <ArrowDropDownIcon style={{ color: '#fff' }} />
          </div>
          <div className="nav-dropdown">
            <Typography fontSize={10} color="#fff">Manual Transactions</Typography>
            <ArrowDropDownIcon style={{ color: '#fff' }} />
          </div>
          <div className="nav-dropdown">
            <Typography fontSize={10} color="#fff">Documents</Typography>
            <ArrowDropDownIcon style={{ color: '#fff' }} />
          </div>
          <div className="nav-dropdown">
            <Typography fontSize={10} color="#fff">Inventory</Typography>
            <ArrowDropDownIcon style={{ color: '#fff' }} />
          </div>
          <div className="nav-dropdown">
            <Typography fontSize={10} color="#fff">Utilities</Typography>
            <ArrowDropDownIcon style={{ color: '#fff' }} />
          </div>
          <div className="nav-dropdown">
            <Typography fontSize={10} color="#fff">Settings</Typography>
            <ArrowDropDownIcon style={{ color: '#fff' }} />
          </div>
          <div className="nav-dropdown">
            <Typography fontSize={10} color="#fff">Admin</Typography>
            <ArrowDropDownIcon style={{ color: '#fff' }} />
          </div>
          <div className="nav-dropdown">
            <Typography fontSize={10} color="#fff">Reporting</Typography>
            <ArrowDropDownIcon style={{ color: '#fff' }} />
          </div>
        </div>
        <div className="tools">
          { isSearching && <SmartNavSearch /> }
          {
          loading ? <CircularProgress color="secondary" />
            : !isSearching ? <Search style={{ color: '#fff' }} onClick={() => setisSearching(!isSearching)} />
              : <Close style={{ color: '#fff' }} onClick={() => setisSearching(!isSearching)} />
        }

          {isAuthenticated && <Person style={{ color: '#fff' }} onClick={() => setisUserOpen(!isUserOpen)} />}
          {isAuthenticated
        && (
        <div className="tools-item" onClick={() => setisInfoOpen(!isInfoOpen)}>
          <Info style={{ color: '#fff' }} />
        </div>
        )}
        </div>
        <div className="mobile-menu" onClick={() => setOpen(!open)}>
          {!open ? <Menu style={{ color: '#fff' }} /> : <Close style={{ color: '#fff' }} /> }
        </div>
      </Box>
      {open && (
      <div className="menu-section-mobile">
        <div className="nav-dropdown-mobile">
          <Typography>Business</Typography>
          <ArrowDropDownIcon style={{ color: '#fff' }} />
        </div>
        <div className="nav-dropdown-mobile">
          <Typography>Product</Typography>
          <ArrowDropDownIcon style={{ color: '#fff' }} />
        </div>
        <div className="nav-dropdown-mobile">
          <Typography>Operations</Typography>
          <ArrowDropDownIcon style={{ color: '#fff' }} />
        </div>
        <div className="nav-dropdown-mobile">
          <Typography>Manual Transactions</Typography>
          <ArrowDropDownIcon style={{ color: '#fff' }} />
        </div>
        <div className="nav-dropdown-mobile">
          <Typography>Documents</Typography>
          <ArrowDropDownIcon style={{ color: '#fff' }} />
        </div>
        <div className="nav-dropdown-mobile">
          <Typography>Inventory</Typography>
          <ArrowDropDownIcon style={{ color: '#fff' }} />
        </div>
        <div className="nav-dropdown-mobile">
          <Typography>Utilities</Typography>
          <ArrowDropDownIcon style={{ color: '#fff' }} />
        </div>
        <div className="nav-dropdown-mobile">
          <Typography>Settings</Typography>
          <ArrowDropDownIcon style={{ color: '#fff' }} />
        </div>
        <div className="nav-dropdown-mobile">
          <Typography>Admin</Typography>
          <ArrowDropDownIcon style={{ color: '#fff' }} />
        </div>
        <div className="nav-dropdown-mobile">
          <Typography>Reporting</Typography>
          <ArrowDropDownIcon style={{ color: '#fff' }} />
        </div>
        <div className="nav-dropdown-mobile" onClick={() => setisUserOpen(!isUserOpen)}>
          <Person style={{ color: '#fff' }} />
          <ArrowDropDownIcon style={{ color: '#fff' }} />
        </div>
      </div>
      )}
      { isDrawerOpen && (
      <div className="recent-drawer-container" onMouseLeave={() => setisDrawerOpen(false)}>
        <div className="recent-list-header">
          <Typography>Recent Used Links</Typography>
        </div>
        <div className="recent-list" onClick={() => recent()}>
          <Typography>Home</Typography>
        </div>
        {recentList?.map((option:any) => (
          <div className="recent-list" key={uuidv4() as any} onClick={() => recentNavigate(option)}>
            <Typography>{option.label}</Typography>
          </div>
        ))}
      </div>
      )}
      { isUserOpen && (
      <div className="user-dropdown">
        <Link to="/user-settings" style={{ textDecoration: 'none', color: '#fff' }}><div>Settings</div></Link>
        <div
          style={{
            display: 'flex', alignItems: 'center', width: 'auto', marginTop: 10, cursor: 'pointer',
          }}
          onClick={handleLogout}
        >
          <PowerSettingsNew />
          {' '}
          Logout
        </div>
      </div>
      )}
      { isInfoOpen && (
      <div className="info-container" ref={InfoRef}>
        <img src={infoLogo} style={{ width: '200px', height: '10%' }} />
        <Typography fontSize="10" color="white">8.0.0 Build 0</Typography>
        <div className="text-container"><Typography variant="h5" fontSize={14} color="white">Toptech Systems, Inc.</Typography></div>
        <div style={{ borderBottom: '1px gray solid', padding: '0px 0px 5px 0px' }}>© 2014 - 2022 Toptech Systems, Inc.</div>
        <div style={{ width: '100%', textAlign: 'start' }}>
          <div className="text-container">User / Group: tms / Admin</div>
          <div className="text-container">
            Screen:
            {' '}
            {getScreenNameByIdAndMenuType()}
          </div>
        </div>
      </div>
      )}
    </>

  );
};

export default TmsHeader;
