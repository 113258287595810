/* eslint-disable no-nested-ternary */
import React from 'react';

// Librairies
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import clsx from 'clsx';

// Components
import Pagination from '../Pagination';

// Styles
import { useStyles } from './style';

interface IProps {
  handleClickRow: () => void;
  headerItems: any[];
}

const WorkflowTable: React.FC<IProps> = ({ handleClickRow, headerItems }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Table classes={{ root: classes.tableRoot }}>
        <TableHead>
          <TableRow classes={{ root: classes.headerRow }}>
            {headerItems.map((h) => (
              <TableCell classes={{ root: classes.headerCell }} key={h}>
                {h}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow onClick={handleClickRow} className={classes.tableRow}>
            {headerItems.map((field: string) => (field === 'Status' ? (
              <TableCell
                key={field}
                classes={{ root: classes.bodyCellEmpty }}
              >
                <div
                  style={{
                    width: 20,
                    height: 20,
                    backgroundColor: '#32CD32',
                  }}
                />
              </TableCell>
            ) : (
              <TableCell classes={{ root: classes.bodyCell }} key={field}>
                {field}
              </TableCell>
            )))}
          </TableRow>
        </TableBody>
      </Table>
      <div className={clsx(classes.footerWrapper, 'flexRowAlignCenter')}>
        <div className={classes.showingText}>Showing 1 to 14 of 14 entries</div>
        <span className="takeTheRest" />
        <Pagination />
      </div>
    </div>
  );
};

export default React.memo(WorkflowTable);
