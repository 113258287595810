import React, { useState } from 'react';

// Librairies
import { Grid } from '@mui/material';
import clsx from 'clsx';

// Components
import TmsHeader from '../../../components/header';
import SubHeader from '../../../components/subHeader';
import OptionsButton from '../../../components/optionsButton';
import ManagePrintersMenu from '../../../components/managePrintersMenu';

// Styles
import { useStyles } from './style';

const ManagePrinters: React.FC = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOptionClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <div className={clsx(classes.root, 'fullHeight', 'flexColumn')}>
      <TmsHeader />
      <SubHeader title="Manage Printers">
        <OptionsButton handleClick={handleOptionClick} />
        <ManagePrintersMenu
          anchorEl={anchorEl}
          onClose={handleCloseMenu}
        />
      </SubHeader>
      <Grid container className={clsx(classes.container, 'takeTheRest')}>
        <Grid item xs={4} className={classes.itemWrapper}>
          <span className={classes.itemHeading}>
            Printers
          </span>
        </Grid>
        <Grid item xs={8} className={classes.itemWrapper}>
          <span className={classes.itemHeading}>
            Details
          </span>
        </Grid>
      </Grid>
    </div>
  );
};

export default ManagePrinters;
