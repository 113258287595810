/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoadEmbbededTables } from '../../redux/actions/embbededScreensActions';
import CloneMaintEmbeddedScreen from '../embbededScreens/cloneMaintEmbeddedScreen';
import CreateMaintEmbeddedScreen from '../embbededScreens/createMaintEmbeddedScreen';
import DetailsMaintEmbeddedScreen from '../embbededScreens/detailsMaintEmbeddedScreen';
import EmbbededMaintTableScreen from '../embbededScreens/embbededTableScreen';
import LoginPage from '../LoginPage/LoginPage';
import DetailMaintScreen from '../maint-screen/details-maint-screen';
import MaintTableScreen from '../maint-screen/TableScreen';
import BatchEditEmbedded from '../embbededScreens/BatchEditEbeddedScreen';
import BatchModeUpdateEmbedded from '../../components/batchModeUpdate/BatchModeUpdateEmbedded';
import Footer from '../../components/Footer/Footer';
import NestedEmbbededMaintTableScreen from './nestedEmbeddedTableScreen';

const NestedEmbeddedNavigator = (props: any) => {
  const {
    handleClose,
    handleCloseEmbedded,
  } = props;

  const {
    activeScreen,
    embeddedNavigation,
  } = useSelector(
    (state: any) => state.NestedEmbbededScreenReducer,
  );

  return (
    <>
      <div style={{
        height: '89%',
      }}
      >
        {
  !activeScreen ? (<></>) : (embeddedNavigation === 'tableScreen'
    ? <NestedEmbbededMaintTableScreen handleclosingEmbedded={handleCloseEmbedded} />
    : <LoginPage />
  )
}
      </div>
      <Footer />
    </>
  );
};

export default NestedEmbeddedNavigator;
