/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { TextField, Typography } from '@mui/material';
import React from 'react';
import { AnyObjectSchema } from 'yup';
import CustomInput from '../customInput';

type inputType = {
  size?: number;
  label: string;
  type?: string;
  maxLength?: number;
  placeholder?: string;
  isDisabled: boolean;
  name?: string;
  value?: any;
  options?: any;
  onChange?: any;
  inputProps?: any;
  error?: boolean;
  helperText?: string;
  defaultValue?: string;
  numericValue?: boolean;
  phone?: boolean;
  timeType?: boolean;
  dateType?: boolean;
  mask?: any;
  maskChar?: any;
  key?: any;
  required?: boolean;
};

const Plcconfig = (props: inputType) => {
  const {
    size,
    label,
    placeholder,
    isDisabled,
    type,
    maxLength,
    name,
    options,
    value,
    onChange,
    inputProps,
    error,
    helperText,
    required,
    defaultValue,
    numericValue,
    phone,
    timeType,
    dateType,
    mask,
    maskChar,
    key,
  } = props;

  const newValue = React.useMemo(() => {
    const values: string[] = value?.split(' ') || [];
    const newValues: string[] = values.length > 6 ? values.slice(0, 7).concat(values.slice(7).join(' ')) : values;
    const result = newValues.map((item: any) => (!item ? '.' : item));
    return result;
  }, [value]);

  const handleChange = (e: any, index: number) => {
    const adaptedValue = Array.from(newValue);
    adaptedValue[index] = index === 7 ? e?.target?.value?.trimLeft() || '.' : e?.target?.value?.trim() || '.';
    onChange({ target: { name: 'plc_config', value: adaptedValue.join(' ') } });
  };

  return (
    <div
      style={{
        display: 'flex',
        width: '76%',
        justifySelf: 'flex-end',
        height: '10vh',
        margin: '10px 0px 10px 370px',
      }}
    >
      <Typography className="f12" style={{ width: '15%', height: '10vh' }}>{label}</Typography>
      <div
        style={{
          display: 'flex',
          width: '100%',
        }}
      >
        {options.map((option: any, index: number) => (
          <div
            style={{ display: 'flex', flexDirection: 'column', width: '60%' }}
          >
            <div className="f12" style={{ backgroundColor: '#7c87a6', color: 'white' }}>
              {option?.label}
            </div>
            <div style={{ width: '100%', height: '3Opx' }}>
              <CustomInput
                name={option?.label}
                label=""
                size="100%"
                value={newValue?.[index] === '.' ? '' : newValue[index]}
                onChange={(e: any) => handleChange(e, index)}
                isDisabled={false}
                inputProps={{
                  maxLength: option.size,
                }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Plcconfig;
