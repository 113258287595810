import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {},
  closeButton: {
    color: 'black !important',
    textShadow: 'none !important',
    backgroundColor: '#ffbb33 !important',
    border: '1px solid transparent !important',
    padding: '6px 10px !important',
    marginRight: '15px !important',
  },
  label: { paddingTop: 5, fontSize: 12, fontWeight: 400 },
  textArea: {
    resize: 'vertical',
    height: '300px !important',
    width: '75% !important',
    margin: '2px 10px !important',
  },
  wrapper: { padding: '5px 10px 5px 10px', justifyContent: 'flex-end' },
});
