/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import * as React from 'react';
import Stack from '@mui/material/Stack';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import {
  Button,
  Checkbox,
  ClickAwayListener,
  Fab,
  Input,
  InputAdornment,
  InputBase,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';

type inputType = {
  inputPlaceholder?: string;
  size?: any;
  label: string;
  type?: any;
  maxLength?: number;
  placeholder?: string;
  isDisabled: boolean;
  name?: string;
  value?: any;
  onChange?: any;
  inputProps?: any;
  error?: boolean;
  helperText?: string;
  defaultValue?: string;
  numericValue?: boolean;
  phone?: boolean;
  timeType?: boolean;
  dateType?: boolean;
  mask?: any;
  maskChar?: any;
  key?: any;
  required?: boolean;
  validate?: string;
  val?: any;
  attr?: any;
  style?: any;
  labelStyle?: any;
  inputStyle?: any;
  noAwayListener?: boolean;
  isNumber?: boolean;
  margin?: any;
  isBatch?: any;
  datagrid?: boolean;
};

const CustomDateTime = (props: any) => {
  const {
    inputPlaceholder,
    size,
    margin,
    label,
    placeholder,
    isDisabled,
    type,
    isBatch,
    maxLength,
    name,
    value,
    onChange,
    inputProps,
    error,
    helperText,
    required,
    defaultValue,
    numericValue,
    phone,
    timeType,
    dateType,
    attr,
    mask,
    maskChar,
    key,
    validate,
    val,
    style,
    labelStyle,
    inputStyle,
    noAwayListener,
    isNumber,
    datagrid,
  } = props;
  const [open, setOpen] = React.useState(false);
  const [inFocus, setInFocus] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const [isElementChecked, setIsElementChecked] = React.useState(false);
  const [localChangesMade, setlocalChangesMade] = React.useState(undefined);
  const [isValidEmail, setIsValidEmail] = React.useState(true);
  const [datePickerOpen, setDatePickerOpen] = React.useState(false);
  const [pickerDate, setPickerDate] = React.useState(() => {
    if (value) {
      return new Date(value);
    }
    return new Date('00/00/2099 00:00:18');
  });
  const [dateAnchorEl, setDateAnchorEl] = React.useState<null | HTMLElement>(
    null,
  );
  const handleDateInputClick = (event: React.MouseEvent<HTMLInputElement>) => {
    setDateAnchorEl(event.currentTarget);
    setDatePickerOpen(true);
  };
  console.log('isDisabled', isDisabled);
  return (
    <div
      style={
        style || {
          width: '100%',
          alignSelf: 'center',
          marginTop: margin !== '0px' ? '5px' : '0px',
          display: 'flex',
          alignItems: 'center',
          gap: 10,
          justifyContent: 'flex-end',
        }
      }
    >
      <div
        style={{
          flex: !datagrid ? 1 : 'none',
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'right',
        }}
      >
        {!datagrid && (
          <Typography
            className="f12"
            style={{ flex: 1, textAlign: 'right', ...(labelStyle || null) }}
          >
            {label}
          </Typography>
        )}
        {/* {!['grid'].includes(type)
      && required === false
      && isBatch === 'Batch' && (
        <Checkbox
          checked={isElementChecked}
          onClick={() => enableDisableLocalChanges()}
        />
      )} */}
      </div>
      <div
        style={
          margin !== '0px'
            ? {
              flexWrap: 'nowrap',
              display: 'flex',
              flex: 3,
            }
            : {
              // width: '100%',
              marginTop: datagrid ? '0px' : '10px',
              display: 'flex',
              gap: 10,
              flex: 2,
            }
        }
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            PopperProps={{ anchorEl: dateAnchorEl }}
            onOpen={() => {
              isDisabled ? setDatePickerOpen(false) : setDatePickerOpen(true);
            }}
            onClose={() => setDatePickerOpen(false)}
            inputFormat="yyyy-dd-MM"
            value={value}
            readOnly={isDisabled}
            open={datePickerOpen}
            onChange={(newValue: any) => {
              const options = {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
              };
              const newValueFormatted = newValue?.toLocaleDateString(
                'pt-BR',
                options,
              );
              onChange(name, newValueFormatted);
            }}
            renderInput={() => (
              <TextField
                onClick={() => {
                  isDisabled
                    ? setDatePickerOpen(false)
                    : setDatePickerOpen(true);
                }}
                value={
                  value
                  // value?.search('-') === 4
                  //   ? value
                  //   : value?.search('-') !== 4
                  //     ? `${value?.slice(6, 10)}-${value?.slice(
                  //       3,
                  //       5,
                  //     )}-${value?.slice(0, 2)} ${value?.slice(
                  //       10,
                  //       value?.length,
                  //     )}`
                  //     : ''
                }
                onChange={onChange}
                placeholder={placeholder || label}
                sx={{
                  '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
                    padding: '4px 4px',
                    border: '1px solid #E4E4E4',
                    fontSize: '12px',
                  },
                  width: '98.5%',
                  borderLeft: '5px solid #555',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '0px !important',
                  },
                  borderRadius: '0px !important',
                  '& input[type="date"]::-webkit-calendar-picker-indicator': {
                    display: 'inlineBlock',
                    background: isDisabled ? '#f8f8f8' : 'transparent',
                    '& .MuiInputBase-root': {
                      borderRadius: '0px !important',
                      '& > fieldset': {
                        borderColor:
                          required && value === '' ? 'error' : 'transparent',
                      },
                    },
                    bottom: 0,
                    color: 'transparent',
                    cursor: 'pointer',
                    height: 'auto',
                    left: 0,
                    position: 'absolute',
                    borderLeft: '5px solid #555',
                    right: 0,
                    top: 0,
                    width: size,
                  },
                  '& input[type="date"]:disabled': {
                    borderLeft: '5px solid #555',
                  },
                }}
                disabled={isDisabled}
              />
            )}
          />
        </LocalizationProvider>
      </div>
    </div>
  );
};
export default CustomDateTime;
