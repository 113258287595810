/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import FormControl from '@mui/material/FormControl';
import { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Checkbox, Typography } from '@mui/material';
import './style.css';
import { isObject } from '../../utils/index';

type selectProps = {
  label: string;
  size: number;
  options: any;
  isDisabled: boolean;
  name?: any;
  value?: any;
  defaultValue?: string;
  onChange?: any;
  isOptionEqualToValue?: any;
  helperText?: any;
  key?: any;
  type?: any;
  obj?: any;
  query?: any;
  chain?: any;
  stateValues?: any;
  selectList?: any;
  required?: any;
  isBatch?: any;
  valuesToSend?: any;
  multiple?: boolean;
  margin?: any;
};

const OpenDropdown = ({
  type,
  obj,
  query,
  margin,
  isBatch,
  valuesToSend,
  required,
  selectList,
  chain,
  stateValues,
  label,
  size,
  options,
  isDisabled,
  name,
  value,
  defaultValue,
  helperText,
  onChange,
  isOptionEqualToValue,
  key,
  multiple,
}: selectProps) => {
  const [dynamicValues, setDynamicValues] = React.useState<any>([]);
  React.useEffect(() => {
    if (['chain'].includes(type)) {
      if (selectList) {
        setDynamicValues([
          { label: '-- Select --', value: '' },
          ...selectList.map((item: any) => ({
            label: Object.values(item).join(' - '),
            value: Object.values(item)[0],
          })),
        ]);
      } else {
        const whereQuery = query?.where.map(
          (item: any) => `${Object.keys(item)[0]}='${
            valuesToSend[
              (Object.values(item)[0] as string)
                .replace('[', '')
                .replace(']', '')
            ] || Object.values(item)[0]
          }'`,
        );
        const fetchQuery = `${
          process.env.REACT_APP_API_URL
        }tablescreen/tablemaintdata/?tableID=${query.table}
        &page=1&results_per_page=10000&fields=${query.fields.join(
    ',',
  )}&where=${whereQuery.join(' AND ')}`;
        if (type === 'weekday') {
          console.log('fetchQuery', fetchQuery);
        }
        fetch(fetchQuery)
          .then((res) => res.json())
          .then((res) => {
            setDynamicValues(
              res.data.map((item: any) => ({
                label: `${item[query.fields[0]].value} - ${item[query.fields[1]]?.value}`,
                value: item[query.fields[0]].value,
              })),
            );
          });
      }
    }
  }, [type, stateValues]);
  console.log('options', isBatch, label, value, type);
  // const [firstOpt, ...restOpt] = options || [];
  // const compare = () => {
  //   const result = dynamicValues.filter((a:any) => stateValues[name]?.value === a.value);
  //   return result[0]?.label;
  // };
  const optionsObject = React.useMemo(
    () => (type === 'chain' ? dynamicValues : options)?.reduce(
      (acc: any, item: any) => ({
        ...acc,
        [item.value]: { label: item.label, value: item.value },
      }),
      [],
    ),
    [dynamicValues, options],
  );

  const [isOpen, setIsOpen] = React.useState(false);
  const [selectionDays, setSelectionDays] = React.useState<any>(value);

  const [isElementChecked, setIsElementChecked] = React.useState(false);
  const [localChangesMade, setlocalChangesMade] = React.useState(undefined);

  const handleLocalChanges = (_name: any, e: any, selection: any, _label: any) => {
    console.log(`handleLocalChanges x name: ${_name} e/selection`, e, selection, value);
    if (selection !== (null || undefined) && selection !== value) {
      setlocalChangesMade(selection);
      setIsElementChecked(true);

      if (isBatch === 'Batch') {
        // onChange(isElementChecked ? localChangesMade : { target: { name, value: undefined } });
        onChange(_name, e, !isElementChecked === true ? localChangesMade : null, _label);
        console.log(`onChange isElementChecked ${isElementChecked} localChangesMade ${localChangesMade}`);
      }
    }
  };

  const enableDisableLocalChanges = () => {
    setIsElementChecked(!isElementChecked);
    if (isBatch === 'Batch') {
      if (localChangesMade !== undefined) {
        // onChange(!isElementChecked ? localChangesMade : { target: { name, value: undefined } });
        console.log('enableDisableLocalChanges: localChangesMade NOT undefined isElementChecked: ', !isElementChecked);
      } else if (localChangesMade === undefined) {
        // onChange({ target: { name, value: !isElementChecked ? null : undefined } });
        onChange(name, null, !isElementChecked ? null : localChangesMade, null);
        console.log('enableDisableLocalChanges: localChangesMade IS undefined isElementChecked: ', !isElementChecked);
      }
    }
  };

  return (
    <div
      key={key}
      style={{
        width: '100%',
        alignSelf: 'center',
        marginTop: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: 10,
      }}
    >
      <div
        style={{
          flex: 1, flexGrow: 1, display: 'inline-flex', alignItems: 'center',
        }}
      >
        <Typography className="f12" style={{ flex: 1, textAlign: 'right' }}>{label}</Typography>
        {!['grid'].includes(type)
        && (required === false || type === 'weekday')
        && isBatch === 'Batch' && <Checkbox checked={isElementChecked} onClick={() => enableDisableLocalChanges()} />}
      </div>
      <FormControl
        disabled={isDisabled}
        size="small"
        variant="outlined"
        color={isDisabled ? 'error' : 'info'}
        sx={{ flex: 3 }}
      >
        <Autocomplete
          className="autocomplete opendropdown"
          disablePortal
          disableClearable
          value={
            dynamicValues?.[name]
              ? dynamicValues?.[name]
              : value === '' || value === undefined
                ? { label: '-- Select --', value: '' }
                : isObject(value)
                  ? value
                  : type === 'weekday'
                    ? selectionDays.length === 0 ? isOpen ? [] : { label: 'Nothing Selected', value: '' } : selectionDays
                    : optionsObject?.[value] || {
                      label: `${!value ? '-- Select --' : value}`,
                      value,
                    }
          }
          disabled={isDisabled}
          disableCloseOnSelect={multiple}
          id="simple-select-autocomplete"
          options={
            dynamicValues?.length > 0
              ? dynamicValues
              : options || ['No Data Available']
          }
          onChange={(e: any, newValue: any, reason: any) => {
            console.log('onChange', e, newValue, reason);
            setSelectionDays(newValue);
            if (reason === 'selectOption' || reason === 'removeOption') {
              e.preventDefault();
              e.stopPropagation();
            }
          }}
          open={isOpen}
          onBlurCapture={(e: any) => {
            setIsOpen(false);
            console.log('onBlurCapture', selectionDays);

            if (isBatch === 'Batch') {
              handleLocalChanges(name, e, selectionDays, label);
            } else {
              onChange(name, e, selectionDays, label);
            }
          }}
          onOpen={() => {
            console.log('openX');
            setIsOpen(true);
          }}
          onClose={(e: any) => {
            console.log('closeX');
            setIsOpen(true);
          }}
          getOptionLabel={(option) => (isObject(option) ? option.label : option)}
          multiple={selectionDays.length === 0 && !isOpen ? false : multiple}
          isOptionEqualToValue={isOptionEqualToValue}
          sx={{ width: size || 1000, color: '#f7f7f7' }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={helperText}
              defaultValue={defaultValue}
              size="small"
              sx={{
                '& .MuiInputBase-root': {
                  '& > fieldset': {
                    borderColor: required && value === '' ? 'red' : 'gray',
                    color: 'gray',
                    borderLeft: required
                      ? '5px solid #ff3b3b'
                      : '5px solid #555',
                    borderRadius: '0px !important',
                  },
                },
                '& .MuiInputBase-root.Mui-disabled': {
                  '& > fieldset': {
                    borderLeft: required
                      ? '5px solid #ff3b3b'
                      : '5px solid #555',
                    borderRadius: '0px !important',
                  },
                },
              }}
            />
          )}
        />
      </FormControl>
    </div>
  );
};
OpenDropdown.defaultProps = {
  name: undefined,
  isBatch: undefined,
  value: undefined,
  defaultValue: undefined,
  isOptionEqualToValue: undefined,
  helperText: undefined,
  onChange: onchange,
  key: null,
  multiple: false,
};
export default OpenDropdown;
