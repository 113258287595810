import React from 'react';

// Libraries
import {
  ButtonBase,
  DialogActions,
  DialogContent,
  Divider,
} from '@mui/material';

// Components
import { DialogComponent } from '../Dialog/Dialog.component';

// Styles
import { useStyles } from './style';

interface IProps {
  title: string;
  message: string | JSX.Element;
  open: boolean;
  handleCloseAgree: () => void;
  handleCloseCancel: () => void;
  confirmLabel: string;
  cancelLabel: string;
}

const ConfirmationDialog: React.FC<IProps> = ({
  title,
  message,
  open,
  handleCloseAgree,
  handleCloseCancel,
  confirmLabel,
  cancelLabel,
  children,
}) => {
  const classes = useStyles();
  return (
    <DialogComponent title={title} onClose={handleCloseCancel} open={open}>
      <DialogContent>
        <div>{message}</div>
        <div className={classes.childrenWrapper}>{children}</div>
      </DialogContent>
      <Divider variant="middle" />
      <DialogActions>
        <ButtonBase
          classes={{ root: classes.confirmButton }}
          onClick={handleCloseAgree}
        >
          {confirmLabel}
        </ButtonBase>
        <ButtonBase
          classes={{ root: classes.cancelButton }}
          onClick={handleCloseCancel}
        >
          {cancelLabel}
        </ButtonBase>
      </DialogActions>
    </DialogComponent>
  );
};

export default ConfirmationDialog;
