import { FETCH_RECORD_HISTORY, CLEAR_RECORD_HISTORY } from '../constants/record';

const INITIAL_STATE = {
  history: [],
  loading: true,
};

const recordReducer = (
  state = INITIAL_STATE,
  { type, payload }: any,
) => {
  switch (type) {
    case FETCH_RECORD_HISTORY: {
      return {
        ...state,
        history: payload,
        loading: false,
      };
    }
    case CLEAR_RECORD_HISTORY: {
      return {
        ...state,
        history: [],
      };
    }
    default:
      return state;
  }
};

export default recordReducer;
