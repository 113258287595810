import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    minWidth: 220,
  },
  widthBorder: {
    minWidth: 300,
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      width: 6,
      height: '99%',
      display: 'block',
      backgroundColor: '#555 !important',
    },
  },
  input: {
    padding: '0 !important',
    '& .MuiOutlinedInput-root': {
      padding: '0 !important',
      borderRadius: 0,
      fontSize: 12,
      fontWeight: 400,
      color: 'black',
    },
  },
  autoCompleteInput: {
    padding: '4.5px 8px 4.5px 12px !important',
  },
});
