/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import {
  Alert, Button, CircularProgress, LinearProgress, Snackbar, Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Subnavbar from '../../components/Sub-navbar/Subnavbar';
import EnhancedTable from '../../components/TableView/TableView';
import response from '../../dummyData/response.json';
import {
  dissmissConfirmationAlert,
  exporTableToExcelCsvPdf,
  LoadAllColumnsTables,
  LoadColumns,
  LoadRecord,
  LoadTables,
  LoadTabs,
} from '../../redux/actions/ScreenActions';
import TmsHeader from '../../components/header';
import EmptyTable from '../../components/emptyTable';
import {
  dissmissEmbeddeddConfirmationAlert,
  LoadAllColumnsEmbbededTables,
  LoadEmbbededTables,
  LoadEmbeddedTabs,
  LoadRecordEmbbeded,
} from '../../redux/actions/embbededScreensActions';
import EmbeddedEnhancedTable from '../../components/TableView/EmbeddedTableView';
import { LoadNestedEmbbededTables, LoadNestedRecordEmbbeded } from '../../redux/actions/nestedEmbeddedTabsActions';

interface MaintParams {
  tableId: string | undefined;
  screenId: string | undefined;
  fields: string | undefined;
  maintDetails: string | undefined;
  BatchModeScreen: string | undefined;
  backScreen: string | undefined;
  page: string | undefined;
  results_per_page: string | undefined;
  handleEmbeddedClose : any
}
const NestedEmbbededMaintTableScreen = ({ handleclosingEmbedded }:any) => {
  const { screenId } = useParams();
  const {
    loading,
    data,
    activeScreen,
    activeScreenLabels,
    exportLoading,
    confirmationAlert,
    openConfirmationAlert,
    reviewWhereCondition,
    recordDetails,
    handleEmbeddedClose,
  } = useSelector((state: any) => state.NestedEmbbededScreenReducer);

  const { Language } = useSelector((state: any) => state.LoginReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (!activeScreen) {
      navigate('/home');
    } else if (activeScreen) {
      const delayed = async () => {
        await dispatch(
          LoadNestedEmbbededTables({
            tableID: activeScreen.tableID,
            page: '1',
            results_per_page: '10',
            fields: activeScreen.fields,
            order_by: '',
            where: activeScreen.where,
            EmptyRows: activeScreen.EmptyRows,
            redirect: activeScreen.redirect,
            screenId: activeScreen.screenId,
            whereRecord: activeScreen.whereRecord,
            dependentKeyEmbedded: activeScreen.dependentKeyEmbedded,
            keys: activeScreen.keys,
            lang: Language,
            data: activeScreen.data,
            fieldsConcatenated: activeScreen.fields,
            labels: activeScreen.labels || '',
            hidden: activeScreen.hidden || '',
          }),
        );
        setTimeout(() => {
          dispatch(LoadEmbeddedTabs(activeScreen.screenId));
        }, 1000);
        // dispatch(LoadColumns());
        await dispatch(
          LoadAllColumnsEmbbededTables({
            tableID: activeScreen.tableID,
            page: '1',
            results_per_page: '10',
            fields: '',
            where: '',
            order_by: '',
            Selectedcolumns: activeScreen.fields,
          }),
        );
        await dispatch(
          LoadNestedRecordEmbbeded({
            tableID: activeScreen.tableID,
            screenID: activeScreen.screenId,
            fields: activeScreen.fields,
            where: '',
            lang: Language,
          }),
        );
      };
      delayed();
    }
  }, []);
  // const tableOptionsprop = {
  //   tableID: activeScreen.tableID,
  //   page: '1',
  //   results_per_page: '10',
  //   fields: activeScreen.fields,
  //   order_by: '',
  //   where: '',
  // };
  const handleClose = () => {
    dispatch(dissmissEmbeddeddConfirmationAlert());
  };
  return loading || Object.keys(recordDetails).length === 0 ? (
    <div
      style={{
        width: '100%',
        height: '90vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress />
    </div>
  ) : (
    <>
      {/* <TmsHeader /> */}
      {/* <EmbeddedSubnavbar
        label
        menuOptionType="exportItems"
        // menuOptions={FilterSaved}
        handleEmbeddedClose={handleclosingEmbedded}
        exportTable={dispatch(exporTableToExcelCsvPdf)}
      /> */}
      {exportLoading && <LinearProgress />}
      <EmbeddedEnhancedTable
        pages={data.totalItems}
        headCells={activeScreenLabels}
        rows={data.data}
        tableOpt={activeScreen}
        routeDetails="/maint-details"
        screenID={screenId}
        backScreen={`/maint/${screenId}/`}
        BatchModeScreen="/batch-mode-Terminal-profile"
      />
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={openConfirmationAlert}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={confirmationAlert.includes('successfully')
            ? 'success' : 'error'}
        >
          {confirmationAlert.includes('Review') ? (
            <>
              <div>
                <Typography variant="h6">
                  Success
                </Typography>
              </div>
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              >
                {confirmationAlert.replace('Review', '').split(':')[0]}
                .
                <Button
                  size="small"
                  variant="text"
                  sx={{ textTransform: 'capitalize' }}
                  onClick={() => {
                    handleClose();
                    try {
                      if (confirmationAlert.includes('successfully') && confirmationAlert.split(':')[1]) {
                        navigate('/maint-details', {
                          state: {
                            tableID: activeScreen.tableID,
                            screenID: screenId,
                            where: reviewWhereCondition,
                            routeDetails: `/maint/${screenId}/`,
                            modifyDep: reviewWhereCondition,
                          },
                        });
                      } else {
                        navigate(-1);
                      }
                    } catch (e:any) {
                    // eslint-disable-next-line no-unused-expressions
                      console.log(e.message);
                    }
                  }}
                >
                  {' ( Review )'}
                </Button>

              </div>
            </>
          ) : (confirmationAlert.includes('successfully'))
            ? (
              <div>
                <Typography variant="h6">
                  Success
                </Typography>

                {confirmationAlert}
              </div>
            )
            : (
              <div>
                <Typography variant="h6">
                  Failed
                </Typography>

                {confirmationAlert.split(':')[1] && (
                <Typography variant="h6">
                  {confirmationAlert.split(':')[1]}
                </Typography>
                )}
              </div>
            )}
        </Alert>
      </Snackbar>
    </>
  );
};

export default NestedEmbbededMaintTableScreen;
