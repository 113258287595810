/* eslint-disable @typescript-eslint/no-var-requires */
import { Divider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ControlButton from '../../../components/controlButton';
import CustomDropdown from '../../../components/customDropdown';
import CustomInput from '../../../components/customInput';
import TmsHeader from '../../../components/header';
import EnhancedTable from '../../../components/TableView/TableView';
import { getScreensList } from '../../../redux/actions/ScreenActions';
import OrderMaintenanceMenu from './OrderMaintenanceMenu';

const OrderMaintenance = () => {
  const [Values, setValues] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getScreensList());
  }, []);

  return (
    <>
      <TmsHeader />
      <div className="sub-nav-container">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h3 id="pagetitlebar_title" className="page-title">
            Order Maintenance
          </h3>
        </div>
        <div style={{
          marginRight: '10px',
        }}
        >
          <OrderMaintenanceMenu type="main" />
        </div>
      </div>

      <EnhancedTable
        pages={1}
        headCells={[
          'Order Type',
          'Order Status',
          'Order Number',
          'Host Order Number',
          'PO Number',
          'Supplier',
          'Customer',
          'Account',
          'Scheduled Date',
          'Expiration Date',
        ]}
        rows={[]}
        tableOpt={{
          keys: 'OrderType, OrderStatus, OrderNumber',
          fields: 'OrderType, OrderStatus, OrderNumber, Host Order Number, Parameters, PONumber, Supplier, Customer, Account, ScheduledDate, ExpirationDate ',
          ScreenID: 'Workflow-161',
          tableId: 'OrderMaintenance',
          search: '',
        }}
        routeDetails="/workflow/order-maintenace-details"
        screenID="Workflow-161"
        backScreen="/workflow/order-maintenace"
        BatchModeScreen="/batch-mode"
      />
    </>
  );
};

export default OrderMaintenance;
