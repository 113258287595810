import React, { useEffect } from 'react';

// Librairies
import { useDispatch } from 'react-redux';

// Components
import TmsHeader from '../../../components/header';
import SubHeader from '../../../components/subHeader';
import EnhancedTable from '../../../components/TableView/TableView';

// Actions
import { getScreensList } from '../../../redux/actions/ScreenActions';

const SystemUtilityQueue = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getScreensList());
  }, []);

  return (
    <>
      <TmsHeader />
      <SubHeader title="Report Queue" />
      <EnhancedTable
        pages={1}
        headCells={[
          'Submit Date',
          'Reference Number',
          'Status',
          'Description',
          'Parameters',
          'Comment',
        ]}
        rows={[]}
        tableOpt={{
          keys: 'SubmitDate, refNum, Status',
          fields: 'SubmitDate, refNum, Status, Description, Parameters, Comment',
          ScreenID: 'Workflow-017',
          tableId: 'systemUtilityQueue',
          search: '',
        }}
        routeDetails="/maint-details"
        screenID="Workflow-017"
        backScreen="/workflow/system-utility-queue"
        BatchModeScreen="/batch-mode"
      />
    </>
  );
};

export default SystemUtilityQueue;
