/* eslint-disable default-case */
/* eslint-disable no-console */
/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ClickAwayListener, Paper, TextField, Tooltip, Typography,
} from '@mui/material';
import React, { useState } from 'react';

type TankCardProps = {
  title: string;
  backgroundColor: string;
  isSearching: boolean;
  values: any;
  chooseFromTank?: any;
};
const ChangeTankCard = ({
  title,
  backgroundColor,
  isSearching,
  values,
  chooseFromTank,
}: TankCardProps) => {
  const [open, setOpen] = useState(false);
  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };
  const handleClick = (e:any) => {
    chooseFromTank(e);
    handleTooltipOpen();
  };
  return (
    <div
      style={{
        width: '100%',
        border: '1px solid #d5d5d5',
        margin: '10px',
        backgroundColor,
      }}
    >
      <div
        style={{
          width: '100%',
          height: '20%',
          display: 'flex',
          justifyContent: isSearching ? 'flex-end' : 'center',
          gap: '150px',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <Typography variant="h5">{title}</Typography>
        {isSearching && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginRight: '10px',
            }}
          >
            <TextField
              size="small"
              style={{ width: '150px' }}
              placeholder="Search..."
            />
            <div
              style={{
                backgroundColor: '#e5e5e5',
                width: 'fit-content',
                padding: '0px 10px',
                height: '40px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              viewing
              {' '}
              {values.length}
              {' '}
              of
              {' '}
              {values.length}
            </div>
          </div>
        )}
      </div>
      <div
        style={{
          width: '100%',
          height: '28vh',
          display: 'flex',
          gap: '10px',
          padding: '10px',
          flexWrap: 'wrap',
        }}
      >
        {values.map((val: any, index: any) => (
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <div style={{ flexWrap: 'nowrap', display: 'flex' }}>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                placement="top"
                arrow
                color="red"
                onClose={handleTooltipClose}
                open={open}
                title="Add"
              >
                <div
                  key={val.name}
                  style={{
                    border: '1px solid #71a4c1',
                    width: 'fit-content',
                    padding: '10px 20px',
                    height: 'fit-content',
                    backgroundColor: 'transparent',
                    cursor: 'pointer',
                  }}
                >
                  <Typography
                    color="#0027ff"
                    style={{ textDecoration: 'underline' }}
                    onClick={handleClick}
                  >
                    {val.name}
                  </Typography>
                </div>
              </Tooltip>
            </div>
          </ClickAwayListener>
        ))}
      </div>
    </div>
  );
};

export default ChangeTankCard;
