export const CLEAR_ERROR_CODE = 'CLEAR_ERROR_CODE';
export const LOAD_SCREEN = 'LOAD_SCREEN';
export const LOAD_TABS = 'LOAD_TABS';
export const COLUMNS_LIST = 'COLUMNS_LIST';
export const SET_COLUMNS = 'SET_COLUMNS';
export const SAVE_FILTER = 'SET_FILTER';
export const LOAD_RECORD = 'LOAD_RECORD';
export const LOAD_RECORD_DEFAULT_COLUMNS = 'LOAD_RECORD_DEFAULT_COLUMNS';
export const RESET_BATCH_RECORDS_LIST = 'RESET_BATCH_RECORDS_LIST';
export const DOWNLOAD_FORMAT_EXCEL = 'DOWNLOAD_FORMAT_EXCEL';
export const DOWNLOAD_FORMAT_CSV = 'DOWNLOAD_FORMAT_CSV';
export const DELETE_FILTER = 'DELETE_FILTER';
export const BATCH_MODE_COLUMS = 'BATCH_MODE_COLUMS';
export const GET_ALL_COLUMNS = 'GET_ALL_COLUMNS';
export const UPDATE_VISIBLE_USER_COLUMNS = 'UPDATE_VISIBLE_USER_COLUMNS';
export const UPDATE_HIDDEN_USER_COLUMNS = 'UPDATE_HIDDEN_USER_COLUMNS';
export const OPEN_COLUMN_MODAL = 'OPEN_COLUMN_MODAL';
export const CLOSE_COLUMN_MODAL = 'CLOSE_COLUMN_MODAL';
export const SAVE_BATCH_MODE_QUERY = 'SAVE_BATCH_MODE_QUERY';
export const UPDATE_RECORD = 'UPDATE_RECORD';
export const DELETE_RECORD = 'DELETE_RECORD';
export const START_LOADING = 'START_LOADING';
export const STOP_LOADING = 'STOP_LOADING';
export const GET_MAINT_SCREENS = 'GET_MAINT_SCREENS';
export const UPDATE_SCREEN_CONFIGURATION = 'UPDATE_SCREEN_CONFIGURATION';
export const SET_RECORD_LIST = 'SET_RECORD_LIST';
export const CLOSE_CHANGE_HISTORY_MODAL = 'CLOSE_CHANGE_HISTORY_MODAL';
export const OPEN_CHANGE_HISTORY_MODAL = 'OPEN_CHANGE_HISTORY_MODAL';
export const OPEN_FILTER_MODAL = 'OPEN_FILTER_MODAL';
export const CLOSE_FILTER_MODAL = 'CLOSE_FILTER_MODAL';
export const DISSMISS_CONFIRMATION_ALERT = 'DISSMISS_CONFIRMATION_ALERT';
export const START_LOADING_EXPORT = 'START_LOADING_EXPORT';
export const STOP_LOADING_EXPORT = 'STOP_LOADING_EXPORT';
export const CLEAN_ACTIVE_SCREEN_CACHE = 'CLEAN_ACTIVE_SCREEN_CACHE';
// Embbeded Screens
export const LOAD_EMBEDDED_SCREEN = 'LOAD_EMBEDDED_SCREEN';
export const GET_ALL_EMBBEDED_COLUMNS = 'GET_ALL_EMBBEDED_COLUMNS';
export const LOAD_EMBEDDED_RECORD = 'LOAD_EMBEDDED_RECORD';
export const UPDATE_EMBEDDED_RECORD = 'UPDATE_EMBEDDED_RECORD';
export const SWITCH_EMBEDDED_NAVIGATION = 'SWITCH_EMBEDDED_NAVIGATION';
export const SET_MESSAGE = 'SET_MESSAGE';
export const LOAD_EMBEDDED_TABS = 'LOAD_EMBEDDED_TABS';
export const SAVE_SCREEN_FILTER = 'SAVE_SCREEN_FILTER';
export const BATCH_MODE_COLUMS_EMBEDDED = 'BATCH_MODE_COLUMS_EMBEDDED';
export const SET_RECORD_LIST_EMBEDDED = 'SET_RECORD_LIST_EMBEDDED';
export const DELETE_RECORD_EMBEDDED = 'DELETE_RECORD_EMBEDDED';
export const SAVE_BATCH_MODE_QUERY_EMBEDDED = 'SAVE_BATCH_MODE_QUERY_EMBEDDED';
export const OPEN_COLUMN_MODAL_EMBEDDED = 'OPEN_COLUMN_MODAL_EMBEDDED';
export const CLOSE_COLUMN_MODAL_EMBEDDED = 'CLOSE_COLUMN_MODAL_EMBEDDED';
export const OPEN_FILTER_MODAL_EMBEDDED = 'OPEN_FILTER_MODAL_EMBEDDED';
export const CLOSE_FILTER_MODAL_EMBEDDED = 'CLOSE_FILTER_MODAL_EMBEDDED';
export const START_LOADING_EMBEDDED = 'START_LOADING_EMBEDDED';
export const STOP_LOADING_EMBEDDED = 'STOP_LOADING_EMBEDDED';
export const START_LOADING_EXPORT_EMBEDDED = 'START_LOADING_EXPORT_EMBEDDED';
export const STOP_LOADING_EXPORT_EMBEDDED = 'STOP_LOADING_EXPORT_EMBEDDED';
export const SAVE_FILTER_EMBEDDED = 'SAVE_FILTER_EMBEDDED';
export const DELETE_FILTER_EMBEDDED = 'DELETE_FILTER_EMBEDDED';
export const SET_MESSAGE_EMBEDDED = 'SET_MESSAGE_EMBEDDED';
export const SAVE_SCREEN_FILTER_EMBEDDED = 'SAVE_SCREEN_FILTER_EMBEDDED';
export const DISSMISS_EMBEDDED_CONFIRMATION_ALERT = 'DISSMISS_EMBEDDED_CONFIRMATION_ALERT';
// nav recent screen generator
export const SET_RECENT_SCREENS = 'SET_RECENT_SCREENS';
export const GET_TERMINAL_NAME = 'GET_TERMINAL_NAME';
// workflow screens
export const GET_DATA_BULK_TRANSACTION = 'GET_DATA_BULK_TRANSACTION';
// nested embedded tabs
export const LOAD_NESTED_EMBEDDED_RECORD = 'LOAD_NESTED_EMBEDDED_RECORD';
export const GET_ALL_NESTED_EMBBEDED_COLUMNS = 'GET_ALL_NESTED_EMBBEDED_COLUMNS';
export const LOAD_NESTED_EMBEDDED_SCREEN = 'LOAD_NESTED_EMBEDDED_SCREEN';
export const SWITCH_NESTED_EMBEDDED_NAVIGATION = 'SWITCH_NESTED_EMBEDDED_NAVIGATION';
