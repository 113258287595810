import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    overflowX: 'scroll',
    height: 600,
  },
  tableRoot: {
    minWidth: 2000,
  },
  headerCell: {
    borderBottom: 'none !important',
    padding: '0px !important',
  },
  headerRow: {
    boxShadow: '0 4px 6px -3px #555',
  },
  bodyCell: {
    borderBottom: 'none !important',
    padding: '8px 0px !important',
  },
  bodyCellEmpty: {
    borderBottom: 'none !important',
    padding: '8px 7px !important',
    width: 30,
  },
});
