/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { ArrowDownward, Title } from '@mui/icons-material';
import {
  Card, Divider, FormControl, InputLabel, MenuItem, Select, Typography,
} from '@mui/material';
import React from 'react';
import './style.css';

const RakStautsCard = ({ history }:any) => (
  <Card sx={{
    elevation: 7, padding: 3, width: '20%', border: '1px solid black',
  }}
  >
    <div className="RackStatusHeader">
      <div
        onClick={() => history('/workflow/rack-status-details')}
        style={{ cursor: 'pointer' }}
      >
        <ArrowDownward />
      </div>

      <Typography variant="h6">
        BAY05-BAY 5
      </Typography>
      <FormControl sx={{ width: '30%' }}>
        <InputLabel id="demo-simple-select-label">Options</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="Options"
        >
          <MenuItem value="Stop">Stop</MenuItem>
          <MenuItem value="Drain">Drain</MenuItem>
          <MenuItem value="Restart">Restart</MenuItem>
        </Select>
      </FormControl>
    </div>
    <Divider />
    <div style={{
      width: '100%',
      height: 'fit-content',
      backgroundColor: '#95e695',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '5px 0px',
    }}
    >
      <Typography variant="h2">IDLE</Typography>
    </div>
    <div className="RackStatusBody">
      <div>
        <Typography variant="body2">Driver:</Typography>
        <Typography variant="body2">Carier:</Typography>
      </div>
      <div>
        <Typography variant="body2">Supplier:</Typography>
        <Typography variant="body2">Customer:</Typography>
      </div>
    </div>
    <Divider />
    <div className="rackStatusFooter">
      <Typography variant="body1">
        Presets:
      </Typography>
      <div style={{ backgroundColor: 'gray', width: '100%', padding: 10 }}>
        DIESEL
      </div>
      <div style={{ backgroundColor: '#95e695', width: '100%', padding: 10 }}>
        JET
      </div>
    </div>

  </Card>
);

export default RakStautsCard;
