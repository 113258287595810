import { LOAD_SYSTEM_STARTUP, CLEAR_SYSTEM_STARTUP } from '../constants/systemStartup';

const INITIAL_STATE = {
  data: [],
};

const recordReducer = (
  state = INITIAL_STATE,
  { type, payload }: any,
) => {
  switch (type) {
    case LOAD_SYSTEM_STARTUP: {
      return {
        ...state,
        data: payload,
      };
    }
    case CLEAR_SYSTEM_STARTUP: {
      return {
        ...state,
        data: [],
      };
    }
    default:
      return state;
  }
};

export default recordReducer;
