/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';

// Librairies
import clsx from 'clsx';
import { ButtonBase, Divider, Grid } from '@mui/material';

// Components
import TmsHeader from '../../../components/header';
import SubHeader from '../../../components/subHeader';
import OptionsButton from '../../../components/optionsButton';
import EmcsConfigurationMenu from '../../../components/EmcsConfigurationMenu';

// Styles
import { useStyles } from './style';
import Select from '../../../components/Select';

const EmcsConfiguration: React.FC = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOptionClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <div className={clsx(classes.root, 'fullHeight', 'flexColumn')}>
      <TmsHeader />
      <SubHeader title="EMCS Configuration">
        <OptionsButton handleClick={handleOptionClick} />
        <EmcsConfigurationMenu anchorEl={anchorEl} onClose={handleCloseMenu} />
      </SubHeader>
      <div className={clsx('takeTheRest', classes.container)}>
        <div className={classes.header}>
          <ButtonBase className={classes.saveButton}>Save</ButtonBase>
          <ButtonBase className={classes.cancleButton}>Cancel</ButtonBase>
        </div>
        <Divider className={classes.divider} />
        <div>
          <Grid className={clsx('flexRow', 'fullWidth', classes.wrapper)}>
            <Grid
              xs={4}
              width="25%"
              className={clsx('flexColumn', classes.inputWrapper)}
            >
              <label className={classes.labelWrapper}> ARC Request Mode </label>
            </Grid>
            <Grid xs={8} width="75%">
              <Select options={[]} />
            </Grid>
          </Grid>
          <Grid className={clsx('flexRow', 'fullWidth', classes.wrapper)}>
            <Grid
              xs={4}
              width="25%"
              className={clsx('flexColumn', classes.inputWrapper)}
            >
              <label className={classes.labelWrapper}> ARC Request Server Trac </label>
            </Grid>
            <Grid xs={8} width="75%">
              <Select options={[]} />
            </Grid>
          </Grid>
          <Grid className={clsx('flexRow', 'fullWidth', classes.wrapper)}>
            <Grid
              xs={4}
              width="25%"
              className={clsx('flexColumn', classes.inputWrapper)}
            >
              <label className={classes.labelWrapper}> Bypass </label>
            </Grid>
            <Grid xs={8} width="75%">
              <Select options={[]} />
            </Grid>
          </Grid>
          <Grid className={clsx('flexRow', 'fullWidth', classes.wrapper)}>
            <Grid
              xs={4}
              width="25%"
              className={clsx('flexColumn', classes.inputWrapper)}
            >
              <label className={classes.labelWrapper}> Resend On Error </label>
            </Grid>
            <Grid xs={8} width="75%">
              <Select options={[]} />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default EmcsConfiguration;
