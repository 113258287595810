/* eslint-disable max-len */
import React from 'react';
import TextField from '@mui/material/TextField';
import ReactInputMask from 'react-input-mask';

const ProductMaskInput = (props: any) => {
  const {
    mask,
    isMaskValueFractional,
    maskPlaceholder,
    onChange,
    onClick,
    onFocus,
    onUnfocus,
    value,
    size,
    name,
    maxLength,
    isParentSetOfDatagridField,
  } = props;

  const decimalDigits = mask?.split('.')[0]?.length;
  const fractionalDigits = mask?.split('.')[1]?.length ?? 0;

  // Mask is applied on whole numeric values
  const handleChangeWithNumericMask = (e: any) => {
    onChange({ target: { name, value: e.target.value.replace(/[^\d]/g, '') } });
  };

  // Mask is applied on fractional numeric values only on the UI
  // Value is sent unmasked; e.g: 12.000 is sent as 12000
  const handleChangeUnmasked = (e: any) => {
    const inputValue = e.target.value;

    // dot is before intended mask placement else it is according to mask
    if (inputValue?.includes('.') && (inputValue[inputValue?.length - 1] === '.' || inputValue?.length - 1 < decimalDigits)) {
      const firstOccurence = inputValue?.indexOf('.');
      const MultipleOccurence = inputValue?.split('.')?.length > 1;
      if (MultipleOccurence) {
        const fixedValue = `${inputValue?.slice(0, firstOccurence + 1)}${inputValue?.slice(firstOccurence + 1, inputValue?.length).replace('.', '')}`;
        onChange({ target: { name, value: fixedValue } });
      } else {
        onChange({ target: { name, value: inputValue } });
      }
      return;
    }
    const newValue = inputValue?.replace('.', '').replace(new RegExp(`(\\d{${decimalDigits}})(\\d{0,${fractionalDigits}})`), '$1.$2');
    if (inputValue === `${newValue}.`) {
      onChange({ target: { name, value: newValue?.slice(0, newValue?.length - 1) } });
    } else if (Number(inputValue) === Number(newValue)) {
      onChange({ target: { name, value: newValue } });
    } else {
      onChange({ target: { name, value: inputValue } });
    }
  };

  const handleBlurMasked = () => {
    if (value?.search('.') === -1 && value) {
      const decimal = value ? value?.slice(0, decimalDigits)?.padStart(3, '0') : '';
      const fractional = value ? value?.slice(decimalDigits, value.length).padEnd(fractionalDigits, '0') : '';
      if (value) {
        onChange({ target: { name, value: `${decimal}.${fractional}` } });
      }
    } else if (value !== '') {
      // dot is before intended mask placement else it is according to mask
      // eslint-disable-next-line no-lonely-if
      if (value?.length - 1 < decimalDigits) {
        const propValue = value;
        const firstOccurence = value?.indexOf('.');
        // Remove any extra typed dots
        const fixedValue = `${propValue?.slice(0, firstOccurence + 1)}${propValue?.slice(firstOccurence + 1, propValue?.length).replace('.', '')}`;
        onChange({ target: { name, value: `${fixedValue?.split('.')[0]?.padStart(decimalDigits, '0')}.${(fixedValue?.split('.')[1] ?? '')?.padEnd(fractionalDigits, '0')}` } });
      } else {
        const dotIndex = value?.indexOf('.');
        const shouldApplyPureMask = dotIndex !== -1 && dotIndex === decimalDigits;
        if (shouldApplyPureMask) {
          const rawValue = value?.replace('.', '');
          const OriginValuedecimal = rawValue ? rawValue?.slice(0, decimalDigits)?.padStart(decimalDigits, '0') : '';
          const OriginValuefractional = rawValue ? rawValue?.slice(decimalDigits, value.length).padEnd(fractionalDigits, '0') : '';
          if (rawValue) {
            onChange({ target: { name, value: `${OriginValuedecimal}.${OriginValuefractional}` } });
          }
        } else {
          const rawValue = value?.replace('.', '');
          const OriginValuedecimal = rawValue ? rawValue?.slice(0, dotIndex)?.padStart(decimalDigits, '0') : '';
          const OriginValuefractional = rawValue ? rawValue?.slice(dotIndex, value.length).padEnd(fractionalDigits, '0') : '';
          if (rawValue) {
            onChange({ target: { name, value: `${OriginValuedecimal}.${OriginValuefractional}` } });
          }
        }
      }
    }
    onUnfocus(false);
  };

  const handleKeyDown = (event: any) => {
    // Keycode for backspace keyboard button
    if (event.keyCode === 8 && value !== '') {
      event.preventDefault();
      onChange({ target: { name, value: value?.slice(0, value?.length - 1) } });
    }
  };

  React.useEffect(() => {
    handleBlurMasked();
  }, [mask]);

  if (isMaskValueFractional) {
    return (
      <TextField
        type="text"
        value={value}
        onChange={(e: any) => handleChangeUnmasked(e)}
        onBlur={handleBlurMasked}
        onKeyDown={handleKeyDown}
        onClick={onClick}
        onFocus={onFocus}
        className="textfield"
        placeholder={maskPlaceholder}
        inputProps={{ maxLength: maxLength + 1 }}
        size="small"
        style={{ flex: 3 }}
        sx={{
          '& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
            padding: '4px 8px',
            border: '1px solid #dddddd',
          },
          '& .MuiInputBase-root': {
            '& > fieldset': {
              borderColor:
              isParentSetOfDatagridField
                ? 'red'
                : 'gray',
              borderLeft: isParentSetOfDatagridField
                ? '5px solid red'
                : '5px solid #555',
              borderRadius: '0px !important',
            },
          },
          '& .MuiInputBase-root.Mui-disabled': {
            '& > fieldset': {
              borderColor:
              isParentSetOfDatagridField
                ? 'red'
                : 'transparent',
              borderLeft: isParentSetOfDatagridField
                ? '5px solid red'
                : '5px solid #555',
              borderRadius: '0px !important',
              color: '#000',
            },
          },
          width: size,
        }}
        // error={isParentSetOfDatagridField}
        color={
          isParentSetOfDatagridField
            ? 'error'
            : 'primary'
        }
        // helperText={
        //   isParentSetOfDatagridField
        //     ? 'can not be negative, must be numeric'
        //     : null
        // }
      />
    );
  }

  return (
    <ReactInputMask
      mask={mask}
      value={value}
      onChange={(e: any) => handleChangeWithNumericMask(e)}
      style={{ flex: 3 }}
    >
      { (maskProps: any) => (
        <TextField
          className="textfield"
          {...maskProps}
          size="small"
          sx={{
            '& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
              padding: '4px 8px',
              border: '1px solid #dddddd',
            },
            '& .MuiInputBase-root': {
              '& > fieldset': {
                borderLeft: '5px solid #555',
                borderRadius: '0px !important',
              },
            },
            width: size,
          }}
        />
      )}
    </ReactInputMask>
  );
};

export default ProductMaskInput;
