import React from 'react';
import {
  BrowserRouter, Navigate, Route, Routes,
} from 'react-router-dom';
import BatchModeUpdate from '../components/batchModeUpdate';
import LandingPage from '../screens/Landingpage/LandingPage';
import LoginPage from '../screens/LoginPage/LoginPage';
import MaintTableScreen from '../screens/maint-screen/TableScreen';
import BatchModeTerminalProfile from '../screens/TerminalProfile/BatchModeTerminalProfile';
import TermProfileForm from '../screens/TerminalProfile/TerminalForm';
import DetailMaintScreen from '../screens/maint-screen/details-maint-screen';
import CloneRecord from '../screens/maint-screen/CloneRecord';
import CreateRecord from '../screens/maint-screen/createMaintScreen';
import ChangePassword from '../screens/Workflows/ChangePassword';
import PrivateRoutes from './privateRoutes';
import UserSettings from '../screens/userSettings/userSettings';
import Footer from '../components/Footer/Footer';
import GroupsTableView from '../screens/Workflows/groups/groupsTableView';
import ChangeActiveTank from '../screens/Workflows/changeActiveTank';
import RackStatus from '../screens/Workflows/rackStatus/rackStatus';
import GroupsDetailsPage from '../screens/Workflows/groups/groupsDetailPage';
import SystemStartup from '../screens/Workflows/systemStartup';
import TankStatus from '../screens/Workflows/tankStatus/TankStatus';
import NonBulkTransaction from '../screens/Workflows/nonBulkTransaction/nonBulkTransaction';
import BulkTransaction from '../screens/Workflows/bulkTransaction/bulkTransaction';
import OrderMaintenance from '../screens/Workflows/orderMaintenance/OrderMaintenance';
import OrderMaintenanceNewRecord from
  '../screens/Workflows/orderMaintenance/OrderMaintenanceNewRecord';
import SystemUtilityQueue from '../screens/Workflows/systemUtilityQueue/SystemUtilityQueue';
import ReportQueue from '../screens/Workflows/ReportQueue';
import Allocations from '../screens/Workflows/allocations/allocations';
import RackStatusDetail from '../screens/Workflows/rackStatus/rackStatusDetail';
import AuditLogsScreen from '../screens/Workflows/auditLogsScreen';
import RCUDittoScreen from '../screens/Workflows/rcuDitto';
import FileExport from '../screens/Workflows/fileExport';
import DeviceCommunications from '../screens/Workflows/deviceCommunications';
import ManagePrinters from '../screens/Workflows/managePrinters';
import EmcsConfiguration from '../screens/Workflows/emcsConfiguration';
import ViewTraceLogs from '../screens/Workflows/viewTraceLogs';
import ViewTotalizerData from '../screens/Workflows/viewTotalizerData';
import MarqueeManagerScreen from '../screens/Workflows/marqueeManager';
import TrafficScreen from '../screens/Workflows/traffic';
import TankGaugeEntryScreen from '../screens/Workflows/tankGaugeEntry';
import VipScullyCommunicationsScreen from '../screens/Workflows/vipScullyCommunications';
import './style.css';
import FolioFreezeClose from '../screens/Workflows/folioFreezeClose';
import TankStrappingTables from '../screens/Workflows/tankStrappingTables';
import PDFFile from '../components/exportCompenent/exportComponent';

const Main = () => (
  <>
    <div className="navigationContainer">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<PrivateRoutes><LandingPage /></PrivateRoutes>} />
          <Route path="/batch-mode" element={<BatchModeTerminalProfile />} />
          <Route path="/batch-mode-confirm" element={<BatchModeUpdate />} />
          <Route path="/exported-file" element={<PDFFile />} />
          <Route path="/user-settings" element={<UserSettings />} />
          <Route path="/maint/:screenId" element={<MaintTableScreen />} />
          <Route path="termProfileform" element={<TermProfileForm />} />
          <Route path="maint-details" element={<DetailMaintScreen />} />
          <Route path="/clone-record" element={<CloneRecord />} />
          <Route path="/create-record" element={<CreateRecord />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/testComponent" element={<ChangeActiveTank />} />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="/workflow/Groups" element={<GroupsTableView />} />
          <Route path="/workflow/audit-logs" element={<AuditLogsScreen />} />
          <Route path="/workflow/rcu-ditto" element={<RCUDittoScreen />} />
          <Route path="/workflow/change-active-tank" element={<ChangeActiveTank />} />
          <Route path="/workflow/rack-status" element={<RackStatus />} />
          <Route path="/workflow/Groups-details" element={<GroupsDetailsPage />} />
          <Route path="/workflow/tank-gauge-entry" element={<TankGaugeEntryScreen />} />
          <Route path="/workflow/System-startup" element={<SystemStartup />} />
          <Route path="/workflow/file-export" element={<FileExport />} />
          <Route path="/workflow/folio-freeze-close" element={<FolioFreezeClose />} />
          <Route path="/workflow/device-communications" element={<DeviceCommunications />} />
          <Route path="/workflow/tank-strapping-tables" element={<TankStrappingTables />} />
          <Route path="/workflow/marquee-manager" element={<MarqueeManagerScreen />} />
          <Route path="/workflow/manage-printers" element={<ManagePrinters />} />
          <Route path="/workflow/emcs-configuration" element={<EmcsConfiguration />} />
          <Route path="/workflow/view-trace-logs" element={<ViewTraceLogs />} />
          <Route path="/workflow/traffic" element={<TrafficScreen />} />
          <Route path="/workflow/Non-bulk-transcation" element={<NonBulkTransaction />} />
          <Route path="/workflow/bulk-transcation" element={<BulkTransaction />} />
          <Route path="/workflow/report-queue" element={<ReportQueue />} />
          <Route path="/workflow/view-totalizer-data" element={<ViewTotalizerData />} />
          <Route path="/workflow/order-maintenance" element={<OrderMaintenance />} />
          <Route
            path="/workflow/order-maintenance-details"
            element={<OrderMaintenanceNewRecord />}
          />
          <Route path="/workflow/tank-status" element={<TankStatus />} />
          <Route path="/workflow/non-bulk-transacation" element={<NonBulkTransaction />} />
          <Route path="/workflow/bulk-transacation" element={<BulkTransaction />} />
          <Route path="/workflow/allocations" element={<Allocations />} />
          <Route path="/workflow/rack-status-details" element={<RackStatusDetail />} />
          <Route path="/workflow/system-utility-queue" element={<SystemUtilityQueue />} />
          <Route path="/workflow/vip-scully-communications" element={<VipScullyCommunicationsScreen />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </BrowserRouter>
    </div>

    <Footer />
  </>
);

export default Main;
