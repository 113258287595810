/* eslint-disable react/require-default-props */
import React from 'react';

// Librairies
import { IconButton, InputAdornment, InputBase } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

// Styles
import { useStyles } from './style';

interface ISelectProps {
  value?: string | number | null;
  style?: React.CSSProperties
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Select: React.FC<ISelectProps> = ({
  value, style, onChange,
}) => {
  const classes = useStyles();
  return (
    <InputBase
      onChange={onChange}
      classes={{ root: classes.input }}
      value={value}
      style={style}
      type="number"
      startAdornment={(
        <InputAdornment position="start">
          <IconButton size="small">
            <RemoveIcon />
          </IconButton>
        </InputAdornment>
      )}
      endAdornment={(
        <InputAdornment position="end">
          <IconButton size="small">
            <AddIcon />
          </IconButton>
        </InputAdornment>
      )}
    />
  );
};

export default Select;
