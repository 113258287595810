import React, { useEffect, useState } from 'react';

// Librairies
import { useDispatch } from 'react-redux';

// Components
import TmsHeader from '../../../components/header';
import OptionsButton from '../../../components/optionsButton';
import ViewTotalizerOptions from '../../../components/ViewTotalizerOptions';
import SubHeader from '../../../components/subHeader';
import EnhancedTable from '../../../components/TableView/TableView';

// Actions
import { getScreensList } from '../../../redux/actions/ScreenActions';

const SystemUtilityQueue = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOptionClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getScreensList());
  }, []);

  return (
    <>
      <TmsHeader />
      <SubHeader title="View Totalizer Data">
        <OptionsButton handleClick={handleOptionClick} />
        <ViewTotalizerOptions anchorEl={anchorEl} onClose={handleCloseMenu} />
      </SubHeader>
      <EnhancedTable
        pages={1}
        headCells={[
          'Product ID',
          'Meter',
          'Gross Totalizer',
          'Net Totalizer',
        ]}
        rows={[]}
        tableOpt={{
          keys: 'SubmitDate, refNum, Status',
          fields: 'SubmitDate, refNum, Status, Description, Parameters, Comment',
          ScreenID: 'Workflow-017',
          tableId: 'systemUtilityQueue',
          search: '',
        }}
        routeDetails="/maint-details"
        screenID="Workflow-017"
        backScreen="/workflow/system-utility-queue"
        BatchModeScreen="/batch-mode"
      />
    </>
  );
};

export default SystemUtilityQueue;
