import React, { useState } from 'react';

// Librairies
import { InputBase } from '@mui/material';
import clsx from 'clsx';

// Components
import SubHeader from '../../../components/subHeader';
import TmsHeader from '../../../components/header';
import OptionsButton from '../../../components/optionsButton';
import TankGaugeEntryOptions from '../../../components/TankGaugeEntryOptions';
import TankGaugeEntryTable from '../../../components/TankGaugeEntryTable';
import Select from '../../../components/Select';

// Styles
import { useStyles } from './style';

const TankGaugeEntryScreen: React.FC = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOptionClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <TmsHeader />
      <SubHeader title="Tank Gauge Entry">
        <OptionsButton handleClick={handleOptionClick} />
        <TankGaugeEntryOptions
          anchorEl={anchorEl}
          onClose={handleCloseMenu}
          openDialog={() => null}
        />
      </SubHeader>
      <div
        className={clsx('flexStartSpaceBetweenRow', classes.subHeaderContainer)}
      >
        <div className={clsx('flexRow')}>
          <span className={classes.label}>Records :</span>
          <Select
            options={['Auto', 10, 20, 35, 50, 'All']}
            width={200}
            value="Auto"
          />
        </div>
        <div className="flexRow">
          <span className={classes.label}>Search :</span>
          <InputBase className={classes.searchInput} />
        </div>
      </div>
      <TankGaugeEntryTable handleClickRow={() => null} />
    </>
  );
};

export default TankGaugeEntryScreen;
